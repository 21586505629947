import * as types from '../actions/types';

const initialState = {
    data: null,
    loading: false,
    importsByIdLoad: false
};

export default function importProfiles (state = initialState, action) {
  switch (action.type) {
    case types.SET_IMPORT_DATA:
      return {
        ...state,
        data: action.payload
      };
    case types.CLEAR_IMPORT_DATA:
      return {
        ...state,
        data: null
      };
    case types.SET_IMPORT_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case types.SET_IMPORTS_HISTORY:
      return {
        ...state,
        importsHistory: action.payload,
      };
    case types.SET_IMPORTS_BY_ID:
      return {
        ...state,
        importsById: action.payload,
      };
    case types.SET_IMPORTS_BY_ID_LOAD:
      return {
        ...state,
        importsByIdLoad: action.payload,
      };
    default:
      return state;
  }
}
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getRegions, editRegion, getRegionGroups, getFederalDistricts } from "../../actions/users";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import RegionsModal from "./RegionsModal";
import styles from "./Regions.module.css";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";


const Regions = ({
  data,
  districts,
  getRegions,
  editRegion,
  regionGroups,
  getRegionGroups,
  getFederalDistricts,
  userInfo,
  loading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [sort, setSort] = useState("");


  useEffect(() => {
    !regionGroups
      && getRegionGroups()
    getRegions(sort);
  }, [sort]);

  const openModal = (type, item) => () => {
    setSelectedItem(item);
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDepartment = (id) => {
    deleteDepartment(id);
  };

  const acceptConfirmation = () => {
    handleDeleteDepartment(confirmation.id);
    setConfirmation(null);
  };

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Регионы</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Регионы:</div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {data &&
              data.map((a) => (
                <div key={a.id} className={styles.tableRow}>
                  <div
                    className={styles.displayname}
                    onClick={openModal("EDIT", a)}
                  >
                    <span>
                      {a.regionName} {a.federalUnit}
                    </span>
                  </div>
                  <div className={styles.superuser}></div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.controls}>
          <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
          </div>
      </div>
      {confirmation && (
        <Confirmation
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      )}

      <RegionsModal
        userInfo={userInfo}
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        editRegion={editRegion}
        loading={loading}
        regionGroups={regionGroups}
        getFederalDistricts={getFederalDistricts}
        districts={districts}
        data={data}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.users.regionList,
  districts: state.users.federalDistricts,
  regionGroups: state.users.regionGroups,
  loading: state.departments.loading,
  userInfo: state.auth.user,
});

export default connect(mapStateToProps, { getRegions, editRegion, getRegionGroups, getFederalDistricts })(Regions);

import * as types from '../actions/types';

const initialState = {
    groups: null,
    materials: null,
    files_list: [],
    loading : false
  };

export default function materialGroups (state = initialState, action) {
  switch (action.type) {
    case types.SET_MATERIAL_GROUPS:
      return {
        ...state,
        groups : action.payload
      };
    case types.SET_MATERIALS:
      return {
        ...state,
        materials : action.payload
      }
    case types.SET_FILES_LIST:
      return {
        ...state,
        files_list : action.payload
      }
    default:
      return state;
  }
}
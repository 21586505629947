import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../fonts/fonts'
import ava from "../../assets/images/avatar.png"
import moment from "moment"
import { BACKEND_DOMAIN, selects } from '../../utils/const'
import logo2 from '../../assets/images/label.png'
import parse from 'html-react-parser'
import { strip } from '../../utils/smallHelpers'

const orientation = 'p'


export const createDocData = async (
    data,
    regions=[],
    regionDep=[],
    localDep=[],
    authData,
    sportData,
    activityData,
    name
    ) => {
    
    let ownRegion, ownRegionDep, ownLocalDep

    if (regions) { ownRegion = regions.find(it => it.id===data.regionId) }
    if (regionDep) { ownRegionDep = regionDep.find(it => it.id===data.regional_department_id) }
    if (localDep) { ownLocalDep = localDep.find(it => it.id===data.domestic_department_id) }

    const photosHumon = data && data.files.UnaProfileFiles && data.files.UnaProfileFiles[0]  && data.files.UnaProfileFiles.filter(it => (it.group === 'photo'));
    const characteristic = strip(data.characteristic)

    const img2 = new Image()
    img2.src = logo2

    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation, lineHeight: 1 })
    
    const img = new Image()
    img.src = ava
    
    let logo = null;

    const imgUrl = (photosHumon && photosHumon.length) ? `${BACKEND_DOMAIN || ""}${photosHumon[0]?.path}${photosHumon[0]?.name}` : null

    const addFooters = (doc, img) => {
        const pageCount = doc.internal.getNumberOfPages()
        const ownDepartment = authData.dapartment_name ? authData.dapartment_name : ""
  
        doc.setFont('Montserrat')
        for (var i = 1; i <= pageCount; i++) {
          doc.setFontSize(6)
          doc.setPage(i)
          i > 1 ? doc.addImage(img, 'png', 5, 5, 25, 25) : doc.addImage(img, 'png', 87, 10, 25, 25)
          doc.text(`ШТАБ РЕГИОНАЛЬНОГО ОТДЕЛЕНИЯ ВСЕРОССИЙСКОГО ДЕТСКО-ЮНОШЕСКОГО ВОЕННО-ПАТРИОТИЧЕСКОГО ДВИЖЕНИЯ "ЮНАРМИЯ"\n
          ${ownDepartment.toUpperCase()} - ${authData.phone} ${authData.email}`, 100, 289, { align: 'center' })
          doc.setFontSize(7)
          doc.text(`Отчет составлен пользователем: ${authData.displayname} ${moment().format("DD.MM.YYYY HH:mm")}`, 100, 4, "center")
        }
    }

    const extensionY = (value) => {
        y+=value
        if ( y>280 ) {
            doc.addPage()
            y=35
        }
    }

    const getCut = (txt, len) => {
        const regexp = new RegExp(`.{1,${len}}`, 'g')
        return txt.match(regexp)
    }

    const getDataUri = (url) => {
        let image = new Image();
        image.src = url;
        image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain
    
        return new Promise(res => {
            image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight; 
    
                //next three lines for white background in case png has a transparent background
                var ctx = canvas.getContext('2d');
                ctx.fillStyle = '#fff';  /// set white fill style
                ctx.fillRect(0, 0, canvas.width, canvas.height);
    
                canvas.getContext('2d').drawImage(this, 0, 0);
    
                res(canvas.toDataURL('image/jpeg'));
            };
        })
    }

    if (imgUrl) { logo = await getDataUri(imgUrl) } 

    doc.setFont("Montserrat");

    let y = 40

    imgUrl ? doc.addImage(logo, 'jpg', 10, y, 50, 50) : doc.addImage(img, 'png', 10, y, 50, 50)
    
    extensionY(17)
    doc.setFontSize(20).setFont(undefined, 'bold')
    doc.text('Цифровая книжка юнармейца:', 80, y)
    doc.setFont(undefined, 'normal')
    extensionY(10)
    doc.setFontSize(17).text(`${data.lastName} ${data.firstName} ${data.fathersName}`, 80, y)
    extensionY(10)
    doc.setFontSize(17).text(`(Личный номер: ${data.personalId ? data.personalId : "—"})`, 80, y)
    extensionY(23)
    doc.setFontSize(14).setFont(undefined, 'normal').text("Личные данные:", 100, y, "center")
    doc.setFontSize(10)
    extensionY(15)
    doc.setFont(undefined, "bold")
    doc.text(`Пол:`, 10, y)
    doc.text(`День рождения:`, 90, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(data.sex ? selects.sex.find(it => it.value===data.sex).name : "—", 10, y)
    doc.text(moment(data.birthday).format("DD.MM.YYYY"), 90, y)
    extensionY(10)
    doc.setFont(undefined, "bold")
    doc.text(`Регион:`, 10, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(ownRegion ? `${ownRegion.regionName} ${ownRegion.federalUnit}` : "—", 10, y)
    extensionY(10)
    doc.setFont(undefined, "bold")
    doc.text(`Индекс:`, 10, y)
    doc.text(`E-mail:`, 90, y)
    doc.text(`Контактный телефон:`, 150, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(data.postalCode ? data.postalCode : "—", 10, y)
    doc.text(data.email ? data.email : "—", 90, y)
    doc.text(data.phone ? data.phone : "—", 150, y)
    extensionY(10)
    doc.setFont(undefined, "bold")
    doc.text(`Населенный пункт:`, 10, y)
    doc.text(`Адрес:`, 90, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(data.city ? getCut(data.city, 31) : "—", 10, y)
    doc.text(data.address ? getCut(data.address, 51) : "—", 90, y)
    extensionY(10)
    doc.setFont(undefined, "bold")
    doc.text(`Серия-номер основного документа (свидетельство о рождении / паспорт):`, 10, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(data.passport ? data.passport : "—", 10, y)
    extensionY(15)
    doc.setFontSize(14).setFont(undefined, 'normal').text("Анкетные данные:", 100, y, "center")
    doc.setFontSize(10)
    extensionY(15)
    doc.setFont(undefined, "bold")
    doc.text(`Региональное отделение:`, 10, y)
    doc.text(`Местное отделение:`, 90, y)
    doc.text(`Название отряда:`, 150, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(ownRegionDep ? getCut(ownRegionDep.name, 31) : "—", 10, y)
    doc.text(ownLocalDep ? getCut(ownLocalDep.name, 24) : "—", 90, y)
    doc.text(name ? getCut(name, 24) : "—", 150, y)
    extensionY(10)
    doc.setFont(undefined, "bold")
    doc.text(`О себе:`, 10, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    const splitText = data.aboutSelf ? doc.splitTextToSize(data.aboutSelf, 180) : "—" 
    doc.text(splitText, 10, y)
    extensionY(10 + 5 * splitText.length)
    doc.setFont(undefined, "bold")
    extensionY(6)
    doc.text(`Причина вступления в ЮНАРМИЮ:`, 10, y)
    doc.text(`Дата заполнения анкеты:`, 90, y)
    doc.text(`Текущий статус:`, 150, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(data.joiningReason ? getCut(data.joiningReason, 24) : "—", 10, y)
    doc.text(data.signDate ? getCut( moment(data.signDate).format("DD.MM.YYYY"), 24): "—", 90, y)
    doc.text(data.status ? getCut(selects.status.find(it => it.value===data.status).name, 24) : "—", 150, y)
    extensionY(6 * (data.joiningReason ? getCut(data.joiningReason, 24).length : 1))
    doc.setFont(undefined, "bold")
    doc.text(`Дата первичного информарования:`, 10, y)
    doc.text(`Дата торжественного вручения книжки:`, 90, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(data.initial_notification_date ? moment(data.initial_notification_date).format("DD.MM.YYYY") : "—", 10, y)
    doc.text(data.certificate_issuing_date ? moment(data.certificate_issuing_date).format("DD.MM.YYYY") : "—", 90, y)
    extensionY(15)
    doc.setFontSize(14).setFont(undefined, 'normal').text("Данные родителей:", 100, y, "center")
    doc.setFontSize(10)
    extensionY(15)
    doc.setFont(undefined, "bold")
    doc.text(`ФИО матери:`, 10, y)
    doc.text(`Телефон:`, 90, y)
    doc.text(`E-mail:`, 150, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(`${data.motherLastName ? data.motherLastName : ""} ${data.motherFirstName ? data.motherFirstName : ""} ${data.motherFathersName ? data.motherFathersName : "—"}`, 10, y)
    doc.text(data.motherContactPhone ? data.motherContactPhone : "—", 90, y)
    doc.text(data.motherEmail ? data.motherEmail : "—", 150, y)
    extensionY(10)
    doc.setFont(undefined, "bold")
    doc.text(`ФИО отца:`, 10, y)
    doc.text(`Телефон:`, 90, y)
    doc.text(`E-mail:`, 150, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(`${data.fatherLastName ? data.fatherLastName : ""} ${data.fatherFirstName ? data.fatherFirstName : ""} ${data.fatherFathersName ? data.fatherFathersName : "—"}`, 10, y)
    doc.text(data.fatherContactPhone ? data.fatherContactPhone : "—", 90, y)
    doc.text(data.fatherEmail ? data.fatherEmail : "—", 150, y)
    extensionY(15)
    doc.setFontSize(14).setFont(undefined, 'normal').text("Образовательная организация:", 100, y, "center")
    doc.setFontSize(10)
    extensionY(15)
    doc.setFont(undefined, "bold")
    doc.text(`Название:`, 10, y)
    doc.text(`Класс:`, 90, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(data.school ? getCut(data.school, 31) : "—", 10, y)
    doc.text(data.class ? getCut(data.class, 31) : "—", 90, y)
    extensionY(9 + 4 * (data.school ? getCut(data.school, 31).length : 1))
    doc.setFontSize(14).setFont(undefined, 'normal').text("Руководитель ЮА:", 100, y, "center")
    doc.setFontSize(10)
    extensionY(15)
    doc.setFont(undefined, "bold")
    doc.text(`ФИО:`, 10, y)
    doc.text(`Телефон:`, 90, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(`${data.chiefLastName ? data.chiefLastName : ""} ${data.chiefFirstName ? data.chiefFirstName : ""} ${data.chiefFathersName ? data.chiefFathersName : "—"}`, 10, y)
    doc.text(data.chiefContactPhone ? data.chiefContactPhone : "—", 90, y)
    extensionY(15)
    doc.setFontSize(14).setFont(undefined, 'normal').text("Посещение спортивных секций:", 100, y, "center")
    doc.setFontSize(10)
    extensionY(5)
    doc.setFont(undefined, "bold")
    extensionY(6)
    doc.text("Название:", 10, y)
    doc.text("Краткое описание достижений:", 90, y)
    extensionY(6)
    doc.setFont(undefined, "normal")
    doc.text(data.sportSectionsName ? getCut(data.sportSectionsName, 31) : "—", 10, y)
    doc.text(data.sport_section_result ? getCut(data.sport_section_result, 31) : "—", 90, y)
    extensionY(6)
    !!sportData.length ? sportData?.forEach(it => {
        extensionY(10)
        doc.setFont(undefined, "bold")
        doc.text(`Название:`, 10, y)
        doc.text(`Краткое описание достижений:`, 90, y)
        extensionY(6)
        doc.setFont(undefined, "normal")
        doc.text(getCut(it.name, 31), 10, y)
        doc.text(getCut(it.result, 31), 90, y)
        extensionY(6 + 4 * (it.name ? getCut(it.name, 31).length : 1))
    }) : doc.text("", 10, y)
    
    extensionY(15)
    doc.setFontSize(14).setFont(undefined, 'normal').text("Участие в мероприятиях:", 100, y, "center")
    doc.setFontSize(10)
    extensionY(5)
    
    !!activityData.length ? activityData?.forEach(it => {
        extensionY(10)
        doc.setFont(undefined, "bold")
        doc.text(`Название:`, 10, y)
        doc.text(`Краткое описание достижений:`, 90, y)
        extensionY(6)
        doc.setFont(undefined, "normal")
        doc.text(getCut(it.name, 31), 10, y)
        doc.text(getCut(it.result, 31), 90, y)
        extensionY(9 + 4 * (it.name ? getCut(it.name, 31).length : 1))
    }) : doc.text("—", 10, y)
    
    extensionY(15)
    doc.setFontSize(14).setFont(undefined, 'normal').text("Характеристика:", 100, y, "center")
    doc.setFontSize(10)
    extensionY(10)
    doc.text(characteristic, 10, y)

    addFooters(doc, img2)

    doc.addPage('a4', orientation)
    doc.deletePage(doc.getCurrentPageInfo().pageNumber)

    return doc
  }
import { buildDefaultHeaders } from "./helper";
import { BACKEND_URL_PREFIX, newsOnPage } from "../utils/const";
import { checkAndUpdateToken } from "./auth"
import { SET_STREAMS_LIST, SET_STREAMS_BY_ID, STREAM_LOADING, SET_STREAMS_COUNT } from "./types";
import { createNotification } from "../components/Notifications/Notifications"

export const getStreamList = (page = 1, states = '', region = '', author = '', text = '', dateFrom = '', dateTo = '') => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        
        const offset = page * newsOnPage - newsOnPage;
        let params = {
            status : states,
            region_id : region,  
            profile_name : author,
            keyword : text,
            date_from : dateFrom,
            date_to : dateTo,
            offset: offset,
            limit: newsOnPage
        }

        let newParams = {};
        for (const [key, value] of Object.entries(params)) {
            if (value != '' && value != null && typeof value != 'object') newParams[key] = value
          }

        const urlParams = new URLSearchParams(newParams).toString();
        
        dispatch({ type: STREAM_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/web/stream?${urlParams}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const data = await response?.json();
            dispatch({ type: SET_STREAMS_LIST, payload: data?.data });
            const pageCount = Math.ceil(data?.total_rows_filtered / newsOnPage);
            dispatch({ 
                type: SET_STREAMS_COUNT,
                payload: { data: data?.total_rows_filtered, pageCount: pageCount } });
          } else {
            console.log('e')
          }
          dispatch({ type: STREAM_LOADING, payload: false });
    }
  }

  export const getStreamById = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        dispatch({ type: STREAM_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/web/stream/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: SET_STREAMS_BY_ID, payload: data });
          } else {
            console.log('e')
          }
          dispatch({ type: STREAM_LOADING, payload: false });
    }
  }

  export const changeStream = (id, numberPage, state, reg, author, text, dateFrom, dateTo) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: STREAM_LOADING, payload: true });
      const response = await fetch(`${BACKEND_URL_PREFIX}/web/stream/${id} `, {
        ...buildDefaultHeaders(getState),
        method: "put",
      });
  
      if (response.ok) {
        const data = await response.json();
        await dispatch(getStreamList(numberPage, state, reg, author, text, dateFrom, dateTo));
        if(data.blocked) {
            createNotification('info', 'Событие снято с публикации');
        } else {
            createNotification('success', 'Событие опубликовано в мобильном приложении');
        }
      } else {
        createNotification('error', 'Произошла ошибка');
      }
      dispatch({ type: STREAM_LOADING, payload: false });
    }
  };


import React from "react";
import { useDrop } from "react-dnd";
import styles from "./MapQuestion.module.css";
import okey from "../../assets/images/test/ok.png";

const Container = ({ item, accepts, lastDroppedItem, onDrop, idx }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: accepts,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  return (
    <div ref={drop} role="Dustbin" className={styles[`item${idx + 1}`]}>
      <img
        style={lastDroppedItem ? { opacity: "1" } : { opacity: "0" }}
        src={okey}
        alt="o_O"
      />
    </div>
  );
};

export { Container };

import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import styles from "./MatherialGroups.module.css"
import CustomInput from "../../components/CustomInput/CustomInput"
import { useFormik } from "formik";



const MaterialGroupsModal = ({
  type,
  selectedItem,
  addGroup,
  editGroup,
  accessRights,
  ...props
}) => {

  const updateRights = type === "ADD" || accessRights.ac_update

  const addGroupItem = (values) => {
    addGroup(values)
    props.onClose()
  }

  const editGroupItem = (values) => {
    editGroup(values)
    props.onClose()
  }

  const formik = useFormik({
    initialValues: type==="ADD" ? {name: ""} : selectedItem,
    onSubmit: values => type==="ADD" ? addGroupItem(values) : editGroupItem(values),
  })

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>{type === "ADD" ? "Добавление группы" : "Редактирование группы"}</div>
      <div className={styles.modalBody}>
        <div>
          <CustomInput
            title="Название группы"
            name="name"
            disabled={!updateRights}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </div>
        <div className={styles.modalButtons}>
          {updateRights && <ButtonRed onClick={formik.handleSubmit}>{type === "ADD" ? "Добавить" : "Сохранить"}</ButtonRed>}
          <ButtonRed onClick={props.onClose}>Закрыть</ButtonRed>
        </div>
      </div>
    </Modal>
  );
};


export default MaterialGroupsModal
import * as types from '../actions/types';

const initialState = {
    count: null,
    page_count: null,
    data: null,
    loading : false, 
    regions: null
  };

export default function tourism (state = initialState, action) {
  switch (action.type) {
    case types.SET_TOURISM_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount
      };
      case types.SET_TOURISM_DATA:
      return {
        ...state,
        data: action.payload
      };
      case types.SET_REGION_LIST_TOURISM:
      return {
        ...state,
        regions: action.payload
      };
    default:
      return state;
  }
}
import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { SET_YUNARMY_STAT, SET_YUNARMY_LOADING, ERROR } from "./types.js";
import { getModifyData } from "../utils/yunarmyStat";




export const getYunarmyStat =
  (years) =>
  async (dispatch, getState) => {
    dispatch({type: SET_YUNARMY_LOADING, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      
        const params = years.length>1 ? {} : {year: years[0]}
        const urlParams = new URLSearchParams(params).toString();

        const response = await fetch(
        `${BACKEND_URL_PREFIX}/yunarmy_digits/values?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        const modData = getModifyData(data)
        dispatch({ type: SET_YUNARMY_STAT, payload: modData });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({type: SET_YUNARMY_LOADING, payload: false})
};

export const setYunarmyStat = (data, years) => async (dispatch, getState) => {
    dispatch({type: SET_YUNARMY_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_digits/values`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        dispatch(getYunarmyStat(years))
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
  dispatch({type: SET_YUNARMY_LOADING, payload: false})
}
import styles from "./Button.module.css"


export const Button = ({
    title="",
    onClick = () => {},
    style={},
    type="",
    ...props
}) => {
    return (
        <button
            onClick={onClick}
            style={style}
            type={type}
            className={styles.button}
            {...props}
        >
            {title}
        </button>
    )
}
import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { ERROR, SET_CHIEFS_SCHEDULE_DATA, SET_CHIEFS_SCHEDULE_EVENT, SET_CHIEFS_SCHEDULE_LOADING } from "./types.js";



export const getCalendarPlan = (date) => async (dispatch, getState) => {
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const params = {};
    date && ( params.date = date )
    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/calendar_plan?${urlParams}`,
      {
        method: "GET",
        ...buildDefaultHeaders(getState),
      }
      );
      if (response.ok) {
        const payload = await response.json()
        dispatch({type: SET_CHIEFS_SCHEDULE_DATA, payload})
      } else {
        dispatch({ type: ERROR, payload: response });
      } 
  }
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: false})
}

export const getCalendarPlanEvent = (eventId) => async (dispatch, getState) => {
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/calendar_plan/${eventId}`,
      {
        method: "GET",
        ...buildDefaultHeaders(getState),
      }
      );
      if (response.ok) {
        const payload = await response.json()
        dispatch({type: SET_CHIEFS_SCHEDULE_EVENT, payload})
      } else {
        dispatch({ type: ERROR, payload: response });
      } 
  }
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: false})
}

export const createCalendarPlanEvent = (data, viewDate) => async (dispatch, getState) => {
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/calendar_plan`,
      {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState)
      }
      );
      if (response.ok) {
        await dispatch(getCalendarPlan(viewDate))
      } else {
        dispatch({ type: ERROR, payload: response });
      } 
  }
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: false})
}

export const updateCalendarPlanEvent = (eventId, data, viewDate) => async (dispatch, getState) => {
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/calendar_plan/${eventId}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState)
      }
      );
      if (response.ok) {
        await dispatch(getCalendarPlan(viewDate))
      } else {
        dispatch({ type: ERROR, payload: response });
      } 
  }
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: false})
}

export const deleteCalendarPlanEvent = (eventId, viewDate) => async (dispatch, getState) => {
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/calendar_plan/${eventId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
      );
      if (response.ok) {
        await dispatch(getCalendarPlan(viewDate))
      } else {
        dispatch({ type: ERROR, payload: response });
      } 
  }
  dispatch({type: SET_CHIEFS_SCHEDULE_LOADING, payload: false})
}
import React, {useEffect} from "react";
import { useDrop } from "react-dnd";

import styles from "./MatchQuestion.module.css";

const Container = ({ item, accepts, lastDroppedItem, onDrop }) => {


  const [{ isOver, canDrop }, drop] = useDrop({
    accept: accepts,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  return (
    <div className={styles.answerItem}>
      <div>{item}</div>
      <div ref={drop} role="Dustbin">
        {lastDroppedItem && <img src={lastDroppedItem} alt="o_O" />}
      </div>
    </div>
  );
};

export { Container };

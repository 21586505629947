import React, {useState} from 'react'
import styles from './styles.module.css'
import logo from '../img/logo.png'
import header from '../img/header.png'
import creatorI from '../img/creator.png'
import emblemCreater from '../img/emblemCreater.png'
import { Link } from 'react-router-dom'
import Video from '../Video/Video'
import TestWrapper from '../Test/TestWrapper'
import Article from '../Article/Article'
import buttonBack from '../img/buttonBack.png'

const Creator = () => {

  const testTitle = 'Юнармеец – созидатель — нацеленный на создание крепкой социально-активной семьи, формирование добрососедских отношений, профессионально самоопределившийся, ценящий труд, активно участвующий в групповых проектах и общественных инициативах, организующий волонтёрскую деятельность и активно участвующий в ней; ведет осмысленный и грамотный образ жизни в интернет-пространстве, корректно освещает мероприятия.'

  const testData = [
    {title: 'Делитесь своими навыками, вдохновляйте на изменения',
    questionList: [
      {
        question: 'Федеральный проект, организованный Движением «ЮНАРМИЯ» и Уполномоченным при Президенте Российской Федерации по правам ребенка для помощи детям-сиротам и детям, оставшимся без попечения родителей и детям с ОВЗ.',
        type: 'pick',
        answers: [{
          answer: 'ЮНАРМИЯ. Измени одну жизнь',
          bool: false
        },
        {
          answer: 'ЮНАРМИЯ. Наставничество',
          bool: true
        },
        {
          answer: 'ЮНАРМИЯ. Путевка в жизнь',
          bool: false
        }
        ] 
      },
      {
        question: 'Наставник – это',
        type: 'pick',
        answers: [{
          answer: 'тренер',
          bool: false
        },
        {
          answer: 'сотрудник организации, осуществляющий деятельность по общеобразовательным, дополнительным общеобразовательным программам',
          bool: false
        },
        {
          answer: 'участник программы наставничества, имеющий успешный опыт в достижении жизненного, личностного и профессионального результата',
          bool: true
        }
        ] 
      },
      {
        question: 'Какие действия выполняет наставник?',
        type: 'pick',
        answers: [{
          answer: 'Разрабатывает стратегию развития на несколько лет, даёт направления для развития, которые обучаемый сможет адаптировать под себя',
          bool: true
        },
        {
          answer: 'Помогает человеку в карьере, дает советы и смотрит «со стороны»',
          bool: false
        },
        {
          answer: 'Помогает принимать решения',
          bool: false
        }
        ] 
      },
      {
        question: 'В рамках масштабного проекта Министерства Обороны РФ «Дорога памяти» Главным Штабом Движения «ЮНАРМИЯ» была организована акция, основной задачей которой является увековеченье памяти о подвигах героев, которые спасли жизни, избавили мир от фашизма и поменяли ход истории к лучшему. Отдельное направление акции –увековечение участников специальной военной операции, удостоенных государственных наград Российской Федерации. С момента запускав 2021 году и по настоящее время акция прошла уже в 57 городах России. Как называется данная акция?',
        type: 'pick',
        answers: [{
          answer: 'На защите мира',
          bool: false
        },
        {
          answer: 'Верни Герою имя',
          bool: false
        },
        {
          answer: 'Граффити. Защитник',
          bool: true
        }
        ] 
      },
      {
        question: 'Коммуникация между индивидами без использования слов, представленных в прямой или какой-либо знаковой форме, называется…',
        type: 'pick',
        answers: [{
          answer: 'внушение',
          bool: false
        },
        {
          answer: 'вербальное общение',
          bool: false
        },
        {
          answer: 'невербальное общение',
          bool: true
        }
        ] 
      },
      {
        question: 'Какой древний философ был наставником Александра Македонского?',
        type: 'pick',
        answers: [{
          answer: 'Платон',
          bool: false
        },
        {
          answer: 'Диоген',
          bool: false
        },
        {
          answer: 'Аристотель',
          bool: true
        }
        ] 
      },
      {
        question: 'Какой русский поэт был наставником цесаревича Александра Николаевича – будущего императора Александра II?',
        type: 'pick',
        answers: [{
          answer: 'Николай Карамзин',
          bool: false
        },
        {
          answer: 'Николай Некрасов',
          bool: false
        },
        {
          answer: 'Василий Жуковский',
          bool: true
        }
        ] 
      },
      ///пропускаю вопрос с вводом текста
      {
        question: 'Кто автор фразы: «Сейте разумное, доброе, вечное….»',
        type: 'pick',
        answers: [{
          answer: 'Максим Горький',
          bool: false
        },
        {
          answer: 'Николай Гоголь',
          bool: false
        },
        {
          answer: 'Николай Некрасов',
          bool: true
        }
        ] 
      },
      {
        question: 'Терпимое и снисходительное отношение к чужим мнениям, верованиям, поведению, обычаям, культуре и идеям.',
        type: 'pick',
        answers: [{
          answer: 'межличностная',
          bool: false
        },
        {
          answer: 'аттракция',
          bool: false
        },
        {
          answer: 'толерантность',
          bool: true
        },
        {
          answer: 'менталитет',
          bool: false
        }
        ] 
      },
      {
        question: 'Какое название носит всероссийский юнармейский кинофестиваль?',
        type: 'pick',
        answers: [{
          answer: 'Юнармия. Киношок',
          bool: false
        },
        {
          answer: 'Юнармейский Кинотавр',
          bool: false
        },
        {
          answer: 'Юнармия в кадре',
          bool: true
        }
        ] 
      },
      {
        question: 'В каких годах впервые появилось понятие «Наставничество»?',
        type: 'pick',
        answers: [{
          answer: '20-30 гг. ХХ века',
          bool: true
        },
        {
          answer: '80-х гг. XX века',
          bool: false
        },
        {
          answer: '70-х гг. XX века',
          bool: false
        }
        ] 
      },
      {
        question: 'В рамках какого всероссийского конкурса, проводимого Движением «ЮНАРМИЯ» совместно с Организационным комитетом Всероссийского проекта «Эстафета поколений», его участники рассказывают о Героях, тружениках тыла, ковавших Великую Победу, передовиках производства, Героях Труда СССР и РФ, предприятия, внесшие вклад в Победу в Великой Отечественной войне, участниках войн и военных конфликтов, участниках специальной военной операции, иных категориях граждан, которые стали хорошим примером для подрастающего поколения.',
        type: 'pick',
        answers: [{
          answer: '«Герои среди нас»',
          bool: false
        },
        {
          answer: '«Никто не забыт, ничто не забыто»',
          bool: false
        },
        {
          answer: '«Герои, живущие рядом»',
          bool: true
        }
        ] 
      },
      {
        question: 'Кого, из нижеперечисленных литературных персонажей, можно считать примером наставника?',
        type: 'pick',
        answers: [{
          answer: 'Тимур Гараев',
          bool: true
        },
        {
          answer: 'Волька Костыльков',
          bool: false
        },
        {
          answer: 'Сергей Сыроежкин',
          bool: false
        }
        ] 
      },
      {
        question: 'Во время своей жизни этот человек был не только писателем, но и крупным деятелем социальной мысли. Он всегда стремился улучшить жизнь своих сограждан, и в этом направлении его усадьба стала не только местом отдыха и творчества, но и образовательным центром для местных жителей. О ком идет речь?',
        type: 'pick',
        answers: [{
          answer: 'Александр Пушкин',
          bool: false
        },
        {
          answer: 'Лев Толстой',
          bool: true
        },
        {
          answer: 'Антон Чехов',
          bool: false
        }
        ] 
      },
    ]
    },
    {title: 'Созидая, мы творим самих себя',
    questionList: [
    {
      question: 'ВВПОД «ЮНАРМИЯ» является некоммерческой организацией. В чем сущность любой некоммерческой организации?',
      type: 'pick',
      answers: [{
        answer: 'Некоммерческая организация должна только оказывать благотворительную поддержку людям',
        bool: false
      },
      {
        answer: 'Некоммерческая организация имеет социально ориентированные цели',
        bool: true
      },
      {
        answer: 'Некоммерческая организация должна быть массовой',
        bool: false
      }
      ] 
    },
      {
        question: 'Одно из базовых личностных качеств юнармейца – «социальная активность». Что это за качество?',
        type: 'pick',
        answers: [{
          answer: 'это постоянное нахождение в обществе других людей',
          bool: false
        },
        {
          answer: 'это активное следование всем социальным нормам и правилам',
          bool: false
        },
        {
          answer: 'это ориентир на позитивные преобразования «вокруг себя» ради общественного блага',
          bool: true
        }
        ] 
      },
      {
        question: 'Юнармейское движение призвано …',
        type: 'pick',
        answers: [{
          answer: 'воспитывать «гражданина Мира»',
          bool: false
        },
        {
          answer: 'развивать личностный потенциал детей, воспитывать качества патриота на основе, в том числе, российских доблестных воинских традиций',
          bool: true
        },
        {
          answer: 'заниматься только допризывной подготовкой',
          bool: false
        }
        ] 
      },
      {
        question: 'В чем основная историческая заслуга Петра I?',
        type: 'pick',
        answers: [{
          answer: 'Петр I основал Российскую империю',
          bool: false
        },
        {
          answer: 'Петр I прорубил окно в Европу',
          bool: false
        },
        {
          answer: 'Петр I создал комплексные условия для успешного развития нашей страны как самостоятельной цивилизации',
          bool: true
        }
        ] 
      },
      {
        question: 'Юнармеец – лидер и организатор. Какие управленческие качества являются самыми главными?',
        type: 'pick',
        answers: [{
          answer: 'самообладание, решительность, ответственность',
          bool: true
        },
        {
          answer: 'умение организовать процесс, командный голос, выносливость',
          bool: false
        },
        {
          answer: 'профессионализм (в каком-то деле), доброта, смелость',
          bool: false
        }
        ] 
      },
      {
        question: 'На каких принципах основывается деятельность Движения «ЮНАРМИЯ»?',
        type: 'pick',
        answers: [{
          answer: 'чести и достоинства',
          bool: false
        },
        {
          answer: 'равноправия, справедливости любви к людям и Родине',
          bool: true
        },
        {
          answer: 'уважение к различным культурам, убеждениям и образам жизни',
          bool: false
        }
        ] 
      },
      {
        question: 'Кто, согласно Конституции Российской Федерации, является главой государства?',
        type: 'pick',
        answers: [{
          answer: 'многонациональный народ',
          bool: false
        },
        {
          answer: 'председатель Правительства',
          bool: false
        },
        {
          answer: 'Президент',
          bool: true
        }
        ] 
      },
      {
        question: 'Юнармейский берет – отличный способ показать миру вокруг свою внутреннюю дисциплину. А как правильно его носить?',
        type: 'pick',
        answers: [{
          answer: 'на два пальца выше глаз, убрав волосы, отбив на правую сторону и закрепив точно посередине значок с орлом',
          bool: true
        },
        {
          answer: 'на затылке, кокетливо сместив набок и закрепив точно с посередине значок с орлом',
          bool: false
        },
        {
          answer: 'надвинув до середины лба и закрепив значок с орлом справой стороны берета',
          bool: false
        }
        ] 
      },
      {
        question: 'За что юнармейцы-участники Движения «ЮНАРМИЯ» могут награждаться знаком «Юнармейская доблесть» I степени?',
        type: 'pick',
        answers: [{
          answer: 'Личное мужество и героизм. Первое место на всероссийских и международных военно-спортивных играх, интеллектуальных, творческих и спортивных конкурсах и мероприятиях',
          bool: true
        },
        {
          answer: 'За активное участие в мероприятиях «ЮНАРМИИ». Призовые места на всероссийских и международных этапах военно-спортивных игр, спортивных и научных олимпиад, соревнованиях и конкурсах;',
          bool: false
        },
        {
          answer: 'Победу или призовые места во Всероссийской олимпиаде школьников',
          bool: false
        }
        ] 
      },
      {
        question: 'Ведущая мировая выставка вооружения и военной техники, представляющая собой авторитетную площадку передовых идей и разработок в интересах Вооруженных Сил Российской Федерации. Это:',
        type: 'pick',
        answers: [{
          answer: 'Международный военно-технический форум «Армия»',
          bool: true
        },
        {
          answer: 'Танковый биатлон',
          bool: false
        },
        {
          answer: 'Международный авиакосмический салон',
          bool: false
        }
        ] 
      }
    ]
    }
  ] 

  const articleData = [
    {
      title: 'Проект «Юнкор»',
      path: 'unkor',
      text: [
        'Проект «ЮНКОР» — объединяет корреспондентов «ЮНАРМИИ» со всей страны. Юнармейцы осваивают не только современную журналистику, а ещё 3D-технологии, дополненную и виртуальную реальности, работают с последними новинками техники, которые применяются в киберспорте и медиа.',
        'Юнкоров приглашают к информационному освещению масштабных событий таких как, международный технический форум «Армия», выставка гражданской авиации, лагерные смены в международных лагерях, юнармейские игры, патриотические фестивали.',
        'Сейчас более 5000 юнкоров освещают важные патриотические события: рассказывают об исторических датах, записывают видео с поддержкой специальной военной операции, ведут борьбу с ложной информацией в сети и взаимодействуют с ветеранами. Проект «ЮНКОР» призван способствовать знакомству ребят с профессией военного корреспондента.',
        'И именно для этого, а также для обмена ценным опытом, теоретического и практического обучения, развития сообщества юных журналистов России, с 28 июля по 1 августа, в Москве, проходил форум «ЮНКОР», объединивший более 130 юных корреспондентов из 29 регионов России.'  
      ]
    },
    {
      title: 'Совет юнармейских командиров',
      path: 'centerInic',
      text: [
        'Совет юнармейских командиров «Центр юнармейских инициатив» — постоянно действующие коллегиальные совещательные органы при Аппарате Главного Штаба и Штабах Региональных отделений.',
        'Советы представлены на двух уровнях: Всероссийском и региональном. В состав Всероссийского совета юнармейских командиров входят победители и призеры номинаций Всероссийского конкурса «Лидеры «ЮНАРМИИ». Региональный совет создается основании Положения о региональном Совете.',
        'Осенью 2023 г. в Москве состоится Форум «Центр юнармейских инициатив», в котором примут участие представители Всероссийского и региональных советов. В программе форума: образовательные тренинги и мастер-классы, упражнения на командообразование, мозговые штурмы с наставниками и многое другое.',
        'Итогом Форума станет утверждение участниками предложений в план развития и работы Движения на 2024 год.'
        ]
    },
    {
      title: 'Программа «ЮНАРМИЯ. Наставничество»',
      path: 'nastav',
      text: [
        'Программа «ЮНАРМИЯ. Наставничество» — федеральный проект, организованный Движением «ЮНАРМИЯ» и Уполномоченным при Президенте Российской Федерации по правам ребенка для помощи детям-сиротам и детям, оставшимся без попечения родителей, а также «трудным» подросткам.',
        'Проект «ЮНАРМИЯ. Наставничество» дает возможность юнармейцам стать друзьями для детей-сирот и «трудных» подростков, на которых хочется равняться, быть примером, советчиком, защитником.',
        '«ЮНАРМИЯ» показывает ребенку в трудной ситуации жизнь за пределами организации, помогает найти настоящих друзей среди неравнодушных взрослых, ровесников и старших товарищей.',
        'Для воспитанников детских организаций этот проект предоставляет возможность реализовать себя в социально-значимых мероприятиях совместно со сверстниками-юнармейцами, вступить в ряды Всероссийского детско-юношеского военно-патриотического общественного движения «ЮНАРМИЯ».'
      ]
    },
    {
      title: 'Программа «ЮНАРМИЯ. Лидеры»',
      path: 'lider',
      text: [
        'Программа «ЮНАРМИЯ. Лидеры» — поиск развитие и поддержка наставников и участников Движения «ЮНАРМИЯ». По-настоящему увлечь ребят и повести их за собой могут только настоящие наставники: образованные, харизматичные, владеющие современными технологиями в сфере патриотического воспитания.',
        'Готов стать лидером «ЮНАРМИИ»? Тогда обязательно прими участие в Конкурсе.',
        'Конкурс проводится в номинациях «Созидатель», «Исследователь», «Защитник» и «Атлет» в 3 этапа:',
        'Региональный этап (сроки проведения: февраль-март);',
        'Всероссийский заочный этап (срок проведения: март-апрель);',
        'Всероссийский заочный онлайн-этап (срок проведения: в период с 18по 28 апреля).',
        'Победители войдут в Совет юнармейских командиров «Центр юнармейских инициатив».'  
      ]
    },
    {
      title: 'Всероссийский юнармейский форум',
      path: 'forum',
      text: [
        'Всероссийский юнармейский форум — это возможность юнармейцам из разных уголков нашей страны познакомится друг с другом, обменятся впечатлениями, посетить мастер-классы, экскурсии и активности, тренировки с выдающимися спортсменами, военно-спортивные игры, сдать юнармейские нормативы и отпраздновать День Рождение любимого Движения.',
        'Ежегодно, Движение собирает семью, друзей и близких к себе на большой праздник.',
        'В 2023 г. Форум «ЮНАРМИЯ — СемьЯ», посвященный семилетию патриотического Движения собрал юнармейцев всей страны в Подмосковье.',
        'Форум показал насколько многогранны увлечения юных патриотов. Зарядки с чемпионами ЦСКА, кинопоказ картины «Небо» и обсуждение с авторами фильма, верёвочный курс с элементами военно-спортивных игр, мастер-классы по изготовлению блиндажных свечей, управление дронами и 3D-моделирование, скалодром, встречи с интересными спикерами.',
        'Торжественным завершением форума стал концерт в Учебно-методическом центре «Авангард». На сцену вышли творческие коллективы и заслуженные артисты: балет «Тодес», Денис Майданов, Родион Газманов, ЮТА, эстрадная группа «Юные патриоты» и другие.',
        'В рамках концерта прошла торжественная церемония награждения победителей Всероссийского конкурса «ЛИДЕРЫ ЮНАРМИИ», вручение медалей «Юнармейская доблесть» I степени отличившимся юнармейцам.'  
      ]
    }
  ]

  const data = [
    {link: 'Верни Герою имя Старт акции 26 марта', name: 'акция «Верни Герою имя»'},
    {link: 'Всеросийский юнармейский форум', name: 'Всеросийский юнармейский форум'},
    {link: 'Граффити в Донском Бочаров', name: 'Акция «Граффити. Защитник». Тульская область'},
    {link: 'Граффити. Защитник Республика Калмыкия', name: 'Акция "Граффити. Защитник". Республика Калмыкия'},
    {link: 'Кинофестиваль ЮНАРМИЯ в КАДРЕ', name: 'Кинофестиваль "ЮНАРМИЯ в кадре"'},
    {link: 'Кто такой юнармеец', name: 'Кто такой юнармеец?'},
    {link: 'Медифорум ЮНКОР', name: 'Медиафорум "ЮНКОР"'},
    {link: 'Парад у Дома Ветерана', name: 'Парад у Дома Ветерана'},
    {link: 'Что такое ЮНАРМИЯ', name: 'Что такое "ЮНАРМИЯ"'},
    {link: 'Юнармеец в профессии', name: 'Юнармеец в профессии'},
    {link: 'ЮНАРМИЯ', name: '4 образа юнармейца'},
  ]
  
  const dataLink = [
    {title: 'Проект «Юнкор»', link: 'article', id: 1},
    {title: 'Совет юнармейских командиров', link: 'article', id: 2},
    {title: 'Программа «ЮНАРМИЯ. Наставничество»', link: 'article', id: 3},
    {title: 'Программа «ЮНАРМИЯ. Лидеры»', link: 'article', id: 4},
    {title: 'Всероссийский юнармейский форум', link: 'article', id: 5},
    {title: 'Юнармейский норматив', link: 'test', id: 6},
    {title: 'Видео', link: 'video', id: 7},
  ]

  const [page, setPage] = useState('default');
  const [id, setId] = useState('');

  const handleClick = (el) => {
    setPage(el.link);
    setId(el.id)
  }

  const getPage = () => {
    switch (page) {
      case 'video':
        return <Video setPage={setPage} data={data} pathName='Видео Созидатель'/>
      case 'test':
        return <TestWrapper setPage={setPage} data={testData} testTitle={testTitle}/>
      case 'article':
        return <Article setPage={setPage} data={articleData} id={id}/>
        
      case 'default':
        return (
        <div className={styles.content}>
          <Link to='/panel' className={styles.back}><img src={buttonBack}/></Link>
          <div className={styles.contentImg}>
            <img src={emblemCreater} className={styles.emblemCreater} />  
            <img src={creatorI} className={styles.creater}/>  
          </div>

          <div className={styles.buttonWrapper}>
            {dataLink.map(el =>
                <div 
                onClick={() => handleClick(el)}
                className={styles[`button${el.id}`]} >
                <span className={styles.buttonTitle}>{el.title}</span>
              </div>
              )}
          </div>
        </div>)
    }
  }
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={header} className={styles.headerBg}/>
        <div className={styles.headerInfo}>
          <img src={logo} className={styles.logo}/>
          <div className={styles.titleBlock}>
            <div className={styles.titleH2}>ВСЕРОССИЙСКОЕ ВОЕННО-ПАТРИОТИЧЕСКОЕ <br/>ОБЩЕСТВЕННОЕ ДВИЖЕНИЕ</div>
            <div className={styles.titleH1}>"ЮНАРМИЯ"</div>
          </div>
        </div>
      </div>

      {getPage()}
    </div>
  )
}

export default Creator
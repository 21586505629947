import React, { useState, useEffect } from 'react'
import Modal from 'react-responsive-modal'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed'
import styles from './RatingParams.module.scss'

export const EditParamsModal = ({open, year, closeModal, getRatingCriterion, updateRatingParams, criterions}) => {
  
  const [data, setData] = useState([])

  useEffect(() => {
    if (!open) return;
    getRatingCriterion(year)
  }, [open])

  useEffect(() => {
    setData(criterions || [])
  }, [criterions])
  
  const saveParamsHandler = async () => {
    await updateRatingParams(data)
    closeModal()
  }

  const updateData = (parent, list) => {
    const newData = data.filter(el => el.parent !== parent)
    setData([...newData, ...list])
  }


  return (
    <Modal
      open={open}
      classNames={{ modal: styles.criterionsModal }}
      showCloseIcon={false}
    >
      <h2>Настройка критериев</h2>
      {data.length && <EditableList parent={0} criterions={data} updateData={updateData}/>}
      <div className={styles.buttonsBlock}>
        <ButtonRed onClick={saveParamsHandler}>Сохранить</ButtonRed>
        <ButtonRed onClick={closeModal}>Закрыть</ButtonRed>
      </div>
    </Modal>
  )
}


const EditableList = ({parent, criterions, updateData}) => {
  
  const [items, setItems] = useState([])
  const [draggingItem, setDraggingItem] = useState(null)
  const [parentItem, setParentItem] = useState(null)

  
  useEffect(() => {
    const items = criterions.filter(el => el.parent == parent)
    items.sort((a, b) => a.index_order - b.index_order)
    setItems(items)
    
    const parentItem = criterions.find(({id}) => id === parent)
    setParentItem(parentItem)
  }, [criterions])
  
  const onDragStart = (item) => (e) => {
    e.stopPropagation()
    setDraggingItem(item)
  }

  const onDragEnter = (index) => (e) => {
    e.preventDefault()
    const draggingIndex = items.findIndex(({id}) => id === draggingItem?.id)
    if (draggingItem !== null && index !== draggingIndex) {
      const newItems = [...items]
      const item = newItems.splice(draggingIndex, 1)[0]
      newItems.splice(index, 0, item)
      const _newItems = newItems.map((el, i) => ({...el, index_order: i}))
      setItems(_newItems)
      updateData(parent, _newItems)
    }
  }    

  const onDragEnd = () => {
    setDraggingItem(null)
  }

  const toggleVisibleHandler = (index) => () => {
    const newItems = [...items]
    newItems[index].visible = !newItems[index].visible
    setItems(newItems)
    updateData(parent, newItems)
  }

  const renderItem = (item, index) => {
    const {criterion, id, visible} = item
    return (
      <li key={id} style={{opacity: draggingItem?.id === item.id ? 0.5 : 1}}>
        <div
          draggable
          onDragStart={onDragStart(items[index])}
          onDragEnter={(e) => onDragEnter(index)(e)}
          onDragEnd={onDragEnd}
          onDragOver={e => e.preventDefault()}
        >
          <p>
            {criterion}
          </p>
          <input checked={visible} onClick={toggleVisibleHandler(index)} type='checkbox' disabled={parentItem && !parentItem?.visible}/>
        </div>
        {!parent && <EditableList parent={id} criterions={criterions} updateData={updateData} />}
      </li>
    )
  }

  return (
    <ul>{items.map(renderItem)}</ul>
  )
}

export default EditParamsModal
import * as types from "../actions/types";

const initialState = {
  count: null,
  page_count: null,
  doctypes: null,
  data: null,
  files_list: [],
  loading: false,
};

export default function documents(state = initialState, action) {
  switch (action.type) {
    case types.SET_DOCUMENTS:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_DOCTYPES:
      return {
        ...state,
        doctypes: action.payload,
      };
    case types.SET_DOCUMENTS_FILES_LIST:
      return {
        ...state,
        files_list: action.payload,
      };
    case types.SET_DOCUMENTS_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount,
      };
    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react'
import { Button } from '../../assets/common/Button/Button'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import styles from "./Users.module.css"
import { IoMdCloseCircleOutline } from "react-icons/io";


export default function DepartmentsModal({
  data,
  setCurrentRegion,
  getUserDepartments,
  id,
  userDeps,
  editUserDeps,
  editUserDepartments,
  changedDeps,
  editChangedDeps
}) {
  
  useEffect(() => {
    const changedItem = changedDeps.find(it => it.region_id===data.id)
    if (changedItem) {
      editUserDeps(changedItem.departments)
    } else {
      getUserDepartments(id, data.id)
    }
  }, [])
  
  const handleEditUserDeps = (id) => {
    let arr
    if (userDeps.includes(id)) {
      arr = userDeps.filter(it => it !== id)
    } else {
      arr = [...userDeps, id]
    }
    editUserDeps(arr)
  }

  const handleSaveUserDeps = () => {
    const payload = {
      region_id: data.id,
      departments: userDeps
    }
    let arr
    if (changedDeps.map(it => it.region_id).includes(data.id)) {
      const _arr = changedDeps.filter(it => it.region_id != data.id)
      arr = [..._arr, payload]
    } else {
      arr = [...changedDeps, payload]
    }
    editChangedDeps(arr)
    setCurrentRegion(null)
  }
  


  return (
    <div className={styles.depModal}>
      <h4>{`Местные отделения региона (${data.regionName} ${data.federalUnit}):`}</h4>
      <div className={styles.depList}>
        { data.domestic_departments.length ? data.domestic_departments.map( item =>
          <CustomCheckbox
            label={item.name}
            value={userDeps.includes(item.id)}
            onChange={() => handleEditUserDeps(item.id)}
          />
        ) : 
        <div className={styles.notification}>
          <IoMdCloseCircleOutline
            size={40}
          />
          <div>для выбранного Вами региона местные отделения отсутствуют</div>
        </div>
        }
      </div>
      <div className={styles.buttonsBlock}>
        <Button
          type='button'
          onClick={handleSaveUserDeps}
          title='Применить'
        />
        <Button
          type='button'
          onClick={() => setCurrentRegion(null)}
          title='Отменить'
        />
      </div>
    </div>
  )
}

import * as types from "../actions/types";

const initialState = {
  data: null,
  loading: false
};

export default function number_reports (state = initialState, action) {
  switch (action.type) {
    case types.SET_NUMBER_REPORT:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_NUMBER_REPORT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

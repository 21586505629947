import React, { useEffect, useMemo } from 'react'
import styles from './NumberRatingReport.module.css'
import { useDispatch, useSelector } from "react-redux"
import { getRegions } from '../../actions/users'
import { getDepartmentsByRegion } from "../../actions/departments";
import { useState } from 'react'
import Loader from '../../components/Loader/Loader'
import NoData from '../../components/NoData/NoData'
import { getRegionOptions } from '../../utils/getOptions'
import CustomDatepicker from '../../components/CustomDatepicker/CustomDatepicker'
import { RiFilePdf2Fill } from "react-icons/ri";
import Select from "react-select";
import { getNumberRatingAllClear, getNumberReport } from '../../actions/number_reports'
import moment from 'moment';
import addFont from '../../fonts/fonts';
import jsPDF from 'jspdf';


const initialParams = {
    regionId: [{label: "Все", value: "all"}],
    date_from: null,
    date_to: null,
}

export default function ActivityRatingReport() {
  
    const { regionList: regions } = useSelector(state => state.users)
    const { data, loading  } = useSelector(state => state.number_reports)
    const { userRegions, superuser } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const getInitialParams = () => {
      const regionId = superuser ? [{label: "Все", value: "all"}] : userRegions[0]
      parseInt(regionId) && dispatch(getDepartmentsByRegion(regionId))
      return {...initialParams, regionId}
    }

    const [params, setParams] = useState(getInitialParams)

    useEffect(() => {
      dispatch(getNumberRatingAllClear())
      if (params.regionId.length > 0 && params.date_from && params.date_to) {

        dispatch(getNumberReport(params))
      }
        !regions?.length && dispatch(getRegions())
    }, [params])

    const handleParamsChange = (e) => {
        setParams(prev => ({...prev, [e.target.name]: e.target.value}))
    }
    
    const handleChangeRegion = (e) => {
        if (e?.[e.length - 1]?.value === 'all') {
          setParams(prev => ({...prev, regionId: [{label: "Все", value: "all"}]}))
        } else {
          setParams(prev => ({...prev, regionId: e.filter(el => el.value !== 'all').map((el) => el)}))
        }
    }

    const getRegionList = useMemo(() => {
      const filteredRegions = superuser ? regions : regions.filter(({id}) => userRegions.includes(id))
      const regionList = getRegionOptions(filteredRegions)
      superuser && regionList.unshift({name: "Все", value: "all"})
      return regionList.map(({name, value}) => ({label: name, value}))
    }, [regions])

    const createDocData = async () => {
      addFont(jsPDF.API);
  
      const doc = new jsPDF({
        putOnlyUsedFonts: true,
        orientation: 'p',
      });
  
      doc.setFont("Montserrat");
  
      doc.text(`Численнось ВВПОД "Юнармия" в системе АИС`, 105, 15, {align: "center"})
      doc.text(`с ${moment(params.date_from).format('DD MMMM')} по ${moment(params.date_to).format('DD MMMM')} ${moment(params.date_from).format('YYYY')} г.`, 105, 25, {align: "center"})
      
      doc.autoTable({
        html: `#numberReport`,
        margin: {
          top: 25
        },
        styles: { font: 'Montserrat', fontSize: 8 },
        headStyles: {
          halign: 'center',
          valign: 'middle',
          lineWidth: 0.2,
          lineColor: 100,
          fontSize: 8,
        },
        bodyStyles: {
          halign: 'center',
          valign: 'middle',
          lineWidth: 0.2,
          lineColor: 100,
          fontSize: 8,
          textColor: '#000',
        },
        footStyles: {
          halign: 'center',
          valign: 'middle',
          lineWidth: 0.2,
          lineColor: 100,
          fontSize: 8,
        },
        showFoot: "lastPage",
        startY: 45
      })
  
      doc.addPage("a4", 'p');
      doc.deletePage(doc.getCurrentPageInfo().pageNumber);
      
      return doc;
    };
    
    const handlePdf = () => {
      createDocData().then((doc) => doc.save(`Численность ВВПОД.pdf`));
    }
    
    return (
    <div className={styles.wrapper}>
        <div className={styles.filtersBlock}>
            <div>

              <div className={styles.selectBlock}>
              <Select
                placeholder="Выберите регион"
                options={getRegionList}
                value={params.regionId}
                onChange={handleChangeRegion}
                noOptionsMessage={() => "ничего не найдено"}
                isMulti
              />
              </div>
              <CustomDatepicker
                  dateFormat="YYYY-MM-DD"
                  value={params.date_from}
                  title='Дата с'
                  name='date_from'
                  onChange={handleParamsChange}
              />
              
              <CustomDatepicker
                  dateFormat="YYYY-MM-DD"
                  value={params.date_to}
                  title='Дата по'
                  name='date_to'
                  onChange={handleParamsChange}
              />
            </div>
        </div>
        { data?.length ?
        <div className={styles.buttomPDF} onClick={(e) => handlePdf()}>
            <><i>{loading ? <Loader size={20} color = "#fff"/> : <RiFilePdf2Fill/>}</i> <span>Скачать в PDF</span></>
        </div>
          : null}

            {loading
                ? <Loader/>
                : (data?.length
                    ? <div className={styles.tableBlock}>
                            <table id="numberReport">
                                <thead>
                                    <tr>
                                        <th>№ п/п</th>
                                        <th>Регион</th>
                                        <th>Численность юнармейцев в АИС с {moment(params.date_from).format('DD MMMM')} по {moment(params.date_to).format('DD MMMM')}</th>
                                        <th>Прирост юнармейцев в АИС с {moment(params.date_from).format('DD MMMM')} по {moment(params.date_to).format('DD MMMM')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((el, i) => {
                                        return (
                                            <tr key={i}>
                                                <th>{++i}</th>
                                                <th>{el.region_name}</th>
                                                <td>{el.total}</td>
                                                <td>{el.per_period}</td>
                                            </tr>
                                        )
                                    })}
                                    
                                </tbody>
                            </table>
                      </div>
                    : <NoData text='данные по региону отсутствуют'/>
                  )
            }
    </div>
  )
}

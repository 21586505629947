import React from 'react';
import {Field} from 'redux-form';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const findOption = (options, value) => {
  if (value && !value?.value) {

    return options && options.find(option => option?.value === value);
  }
  return value;
}

const WrappedDropdownField = ({input, ...props}) => {
  return (
    <Dropdown
      controlClassName="select-filial"
      menuClassName="select-filial__menu"
      arrowClassName="arrow-closed"
      {...props}
      {...input}
      value={findOption(props.options, input.value)}
    />
  )
};

const DropdownField = props => {
  return(
    <Field
    component={WrappedDropdownField}
    {...props}
  />
  )
};

export default DropdownField;

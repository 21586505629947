import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./OptionsQuestion.module.css";

export function OptionsQuestion({
  question,
  answer,
  correct,
  image,
  answerImg,
  setOkey
}) {
  const [click, setClick] = useState([]);

  useEffect(() => {
    setClick([])
  }, [question])

  const onFalse = (i) => {
    setClick([...click, i]);
    setTimeout(() => {
      setClick([]);
    }, 2000);
  };

  const onTrue = (i) => {
    setClick([...click, i]);
    setOkey(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.question}>{question}</div>
      {!!image && <img src={image} alt="o_O"/>}
      <div className={styles.answer} style={image ? {marginTop: "50px"} : {marginTop: "100px"}}>
        {answer ? answer.map((item, i) => (
          <div
            className={`${styles.button} ${
              click.includes(i) && (i === correct ? styles.green : styles.red)
            }`}
            onClick={i === correct ? () => onTrue(i) : () => onFalse(i)}
          >
            {item}
          </div>
        )) : answerImg.map((item, i) => (
          <div
            className={`${styles.imgButton} ${
              click.includes(i) && (i === correct ? styles.green : styles.red)
            }`}
            onClick={i === correct ? () => onTrue(i) : () => onFalse(i)}
          >
            <img src={item} alt="o_O" />
          </div>
        ))}
      </div>
    </div>
  );
}

import React from 'react'
import styles from "./PickWho.module.css";

const PickWho = ({answers, setStep, isWhich, setWho, who}) => {

    const handleCheck = (bool) => {
        if (bool && isWhich) {
            const x = [...who]
            setWho(prev => [...x, bool])
        } 
        setStep(prev => prev + 1);
    }

    return (
    <div>
        {answers.map(el => <div className={styles.answer} onClick={() => handleCheck(el.bool)}>{el.answer}</div>)}
    </div>
  )
}

export default PickWho
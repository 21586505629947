import React from 'react'
import styles from './styles.module.css'
import LOGO from '../../assets/images/label.png'

const Index = () => {
    return (
        <>
        <div className={styles.header} style={{ backgroundImage: "url(/img/header.png)" }}>
            <img src={LOGO} alt="Юнармия" className={styles.logo}/>
            <h1>Всероссийское детско-юношеское военно-патриотическое общественное движение «ЮНАРМИЯ»</h1>
        </div>
        <div className={styles.container}>
            <div className={styles.content}>
                <h2>Удаление аккаунта</h2>
                <p>Обращаем Ваше внимание, что согласно Устава Всероссийского детско-юношеского
                    военно-патриотического общественного Движения «ЮНАРМИЯ» (далее – Движение, ВВПОД
                    «ЮНАРМИЯ»). Вступление в Движение и выход из него являются добровольными.</p>
                <p>Выход из Движения осуществляется по заявлению участника. Заявление подается в
                    орган, принявший соответствующего участника в Движение. Заявление пишется в
                    свободной форме на имя Начальника Главного Штаба Нагорного Н.В.</p>
                <p>Исключение участника по данному основанию производится в течение 10 (десяти)
                    календарных дней с даты подачи соответствующего заявления. В течение указанного
                    срока участник вправе забрать свое заявление, в этом случае исключение не
                    производится.</p>
                <p>По истечении указанного срока, орган, получивший заявление, исключает
                    участника из реестра и в срок не позднее следующего дня направляет информацию в
                    Аппарат Главного Штаба для исключения данных выбывшего участника из общего
                    реестра участников Движения.
                </p>
                <p>Участник, добровольно выбывший из рядов участников Движения вправе вступить в
                    него вновь на общих основаниях.</p>
                <p>Обратите внимание: удалить профиль сразу и навсегда не получится. Однако с
                    момента удаления аккаунт перестаёт быть доступным для других пользователей.
                </p>
                <p>ВАЖНО! ПОВТОРНАЯ РЕГИСТРАЦИЯ В МОБИЛЬНОМ ПРИЛОЖЕНИИ С ИСПОЛЬЗОВАНИЕМ РАНЕЕ
                    ВВЕДЕННОГО АДРЕСА ЭЛЕКТРОННОЙ ПОЧТЫ И ЛИЧНЫЙ ДАННЫХ БУДЕТ НЕДОСТУПНА!</p>
            </div>
        </div>
        </>
    )
}

export default Index
import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Home from "../pages/Home/Home";
import Layout from "../components/Layout/Layout";
import News from "../pages/News/News";

import AddNews from "../pages/AddNews/AddNews";
import Users from "../pages/Users/Users";
import { Test } from "../pages/Test/Test";
import EventsSchedule from "../pages/EventsSchedule/EventsSchedule";
import RegionDepartments from "../pages/RegionDepartments/RegionDepartments";
import AddEvent from "../pages/AddEvent/AddEvent";
import MethodicMaterialGroups from "../pages/MethodicMaterialGroups/MethodicMaterialGroups";
import MethodicMaterials from "../pages/MethodicMaterials/MethodicMaterials";
import Documents from "../pages/Documents/Documents";
import Tourism from "../pages/Tourism/Tourism";
import Equipment from "../pages/Equipment/Equipment";
import Videos from "../pages/Videos/Videos";
import Digital from "../pages/Digital/Digital";
import Unarmiyans from "../pages/Unarmiyans/Unarmiyans";
import Profile from "../pages/Profile/Profile";
import RegRatingGeneral from "../pages/RegRating/RegRatingGeneral";
import Tasks from "../pages/Tasks/Tasks";
import Import from "../pages/Import/Import";
import LocalDepartments from "../pages/LocalDepartments/LocalDepartments";
import Detachments from "../pages/Detachments/Detachments";
import Plug from "../pages/Plug/Plug";
import Quests from "../pages/Quests/Quests";
import YunarmyStat from "../pages/YunarmyStat/YunarmyStat";
import AnalyticalReport from "../pages/AnalyticalReport/AnalyticalReport";
import About from "../pages/About/About";
import Regulations from "../pages/Regulation/Regulations";
import Regions from "../pages/Regions/Regions";
import YunarmyHouses from "../pages/YunarmyHouses/YunarmyHouses";
import RatingParams from "../pages/RatingParams/RatingParams";
import Awards from "../pages/Awards/Awards";
import Confirm from "../pages/Confirm/Confirm";
import Private from "../pages/Private/Private";
import RegRating from "../pages/RegRating/RegRating";
import ActivityRatingReport from "../pages/ActivityRatingReport/ActivityRatingReport";
import NumberRatingReport from "../pages/NumberRatingReport/NumberRatingReport";
import RegionGroups from "../pages/RegionGroups/RegionGroups";
import MailsJournal from "../pages/MailsJournal/MailsJournal";
import Hero from "../pages/Hero/Hero";
import Mobile_app from "../pages/Mobile_app/Mobile_app";
import Badges from "../pages/Badges/Badges";
import LeaderContactsReport from "../pages/LeaderContactsReport/LeaderContactsReport";
import { PrivateRoute } from "../components/Routing";
import Roles from "../pages/Roles";
import RoleSettings from "../pages/RoleSettings";
import PopulationReport from "../pages/PopulationReport";
import Creator from "../pages/Panel/Creator";
import Athlete from "../pages/Panel/Athlete";
import Defender from "../pages/Panel/Defender";
import Researcher from "../pages/Panel/Researcher";
import ChiefsSchedule from "../pages/ChiefsSchedule";
import Panel from "../pages/Panel/Panel"
import MobileApp from "../pages/Mobile_app/Mobile_app";
import FederalDistricts from "../pages/FederalDistricts/FederalDistricts";
import VirtualAwards from "../pages/VirtualAwards/VirtualAwards";
import ViewEvent from "../pages/ViewEvent";
import Agree from "../pages/Agree/Index";
import Delete from "../pages/Delete/Index";
import Unavailable from "../pages/Unavailable";

export const PATHS = {
  ROOT: "/",
  VIEW_EVENT: "/event/:eventId",
  UNAVAILABLE: "/unavailable",
  AUTH: {
    HOME: "/home",
    NEWS: "/news",
    ADD_NEWS: "/news/add_news",
    EDIT_NEWS: "/news/edit_news/:newId?",
    EVENT: "/event",
    ADD_EVENT: "/event/add_event",
    EDIT_EVENT: "/event/edit_event/:newId?",
    ADD_TASK: "/add_task",
    EDIT_TASK: "/edit_task",
    USERS: "/users",
    TEST: "/test",
    ADS: "/ads",
    ADD_ADS: "/ads/add_ads",
    EDIT_ADS: "/ads/edit_ads/:newId?",
    EVENTS_SCHEDULE: "/eventsschedule",
    REGION_DEPARTMENTS: "/reg_departments",
    LOCAL_DEPARTMENTS: "/loc_departments",
    MATERIAL_GROUPS: "/material_groups",
    METHODIC_MATERIALS: "/methodic_materials",
    NPA: "/npa",
    REFERENCES: "/references",
    REGLAMENT_DOCS: "/reglament_docs",
    MAIN_DOCS: "/main_docs",
    MEET_DOCS: "/meet_docs",
    PARTNER_DOCS: "/partner_docs",
    HERO: "/hero",
    VDL_DOCS: "/vdl_docs",
    TOURISM: "/tourism",
    EQUIPMENT: "/equipment",
    VIDEOS: "/videos",
    DIGITAL_ACADEMY: "/digital_academy",
    UNARMIYANS: "/unarmiyans_list",
    PROFILE: "/profile/:userId?",
    REG_RATING_GEN: "/reg_rating_general",
    REG_RATING: "/reg_rating",
    QUESTS: "/quests",
    TASKS: "/tasks/:taskId?",
    IMPORT: "/import",
    DETACHMENTS: "/detachments",
    PLUG: "/plug",
    YUN_STAT: "/yun_stat",
    ANALYTICAL_REPORT: "/analytical_report",
    ACTIVITY_REPORT: "/activity_rating_report",
    NUMBER_REPORT: "/number_rating_report",
    POPULATION_REPORT: "/population_report",
    ABOUT: "/about",
    REGULATION: "/regulation",
    ORDERS: "/orders",
    REGIONS: "/regions",
    RATING_PARAMS: "/rating_params",
    AWARDS: "/awards",
    YUNARMY_HOUSES: "/yunarmy_houses",
    CONFIRM: "/confirm",
    PRIVATE: "/stream/:id?",
    LEADER_CONTACTS: "/leader_contacts",
    REGION_GROUPS: "/region_groups",
    MAILS_JOURNAL: "/mails_journal",
    BADGES: "/badges",
    ROLES: "/roles",
    ROLE_SETTINGS: "/roles/:roleId",
    CREATOR: "/panel/creator",
    ATHLETE: "/panel/athlete",
    DEFENDER: "/panel/Defender",
    RESEARCHER: "/panel/Researcher",
    CHIEFS_SHEDULE: "/chiefs_schedule",
    PANEL: "/panel",
    FEDERAL_DISTRICTS: "/federal_districts",
    VIRTUAL_AWARDS: '/virtual_awards',
    MOBILE_APP: '/mobile_app/login',
    AGREE: '/agree',
    DELETE: '/delete',
  },
};

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={PATHS.AUTH.CONFIRM} component={Confirm} />
        <Route exact path={PATHS.AUTH.MOBILE_APP} component={MobileApp} />
        <Route exact path={PATHS.AUTH.PANEL} component={Panel} />
        <Route exact path={PATHS.AUTH.CREATOR} component={Creator} />
        <Route exact path={PATHS.AUTH.ATHLETE} component={Athlete} />
        <Route exact path={PATHS.AUTH.DEFENDER} component={Defender} />
        <Route exact path={PATHS.AUTH.RESEARCHER} component={Researcher} />
        <Route exact path={PATHS.AUTH.AGREE} component={Agree} />
        <Route exact path={PATHS.AUTH.DELETE} component={Delete} />
        <Route exact path={PATHS.UNAVAILABLE} component={Unavailable} />
        <Layout>
           <Switch>
            <Route exact path={PATHS.ROOT} component={Auth} />
            <Route
              exact
              path={PATHS.VIEW_EVENT}
              render={(props) => props.match.params.eventId === 'add_event' ? <AddEvent {...props}/> : <ViewEvent/>}
            />
              <PrivateRoute>
                <Route exact path={PATHS.AUTH.HOME} component={Home} />
                <Route exact path={PATHS.AUTH.NEWS} component={News} />
                <Route exact path={PATHS.AUTH.EVENT} component={News} />
                <Route exact path={PATHS.AUTH.ADD_NEWS} component={AddNews} />
                <Route exact path={PATHS.AUTH.EDIT_NEWS} component={AddNews} />
                <Route exact path={PATHS.AUTH.ADD_EVENT} component={AddEvent} />
                <Route exact path={PATHS.AUTH.EDIT_EVENT} component={AddEvent} />
                <Route exact path={PATHS.AUTH.USERS} component={Users} />
                <Route exact path={PATHS.AUTH.TEST} component={Test} />
                <Route exact path={PATHS.AUTH.ADS} component={News} />
                <Route exact path={PATHS.AUTH.ADD_ADS} component={AddNews} />
                <Route exact path={PATHS.AUTH.DIGITAL_ACADEMY} component={Digital} />
                <Route exact path={PATHS.AUTH.EDIT_ADS} component={AddNews} />
                <Route exact path={PATHS.AUTH.QUESTS} component={Quests} />
                <Route exact path={PATHS.AUTH.ABOUT} component={About} />
                <Route exact path={PATHS.AUTH.REGULATION} component={Regulations} />
                <Route exact path={PATHS.AUTH.ORDERS} component={Regulations} />
                <Route exact path={PATHS.AUTH.EVENTS_SCHEDULE} component={EventsSchedule} />
                <Route exact path={PATHS.AUTH.REGION_DEPARTMENTS} component={RegionDepartments} />
                <Route exact path={PATHS.AUTH.LOCAL_DEPARTMENTS} component={LocalDepartments} />
                <Route exact path={PATHS.AUTH.DETACHMENTS} component={Detachments} />
                <Route exact path={PATHS.AUTH.MATERIAL_GROUPS} component={MethodicMaterialGroups} />
                <Route exact path={PATHS.AUTH.METHODIC_MATERIALS} component={MethodicMaterials} />
                <Route exact path={PATHS.AUTH.NPA} component={Documents} />
                <Route exact path={PATHS.AUTH.REFERENCES} component={Documents} />
                <Route exact path={PATHS.AUTH.REGLAMENT_DOCS} component={Documents} />
                <Route exact path={PATHS.AUTH.MAIN_DOCS} component={Documents} />
                <Route exact path={PATHS.AUTH.MEET_DOCS} component={Documents} />
                <Route exact path={PATHS.AUTH.PARTNER_DOCS} component={Documents} />
                <Route exact path={PATHS.AUTH.VDL_DOCS} component={Documents} />
                <Route exact path={PATHS.AUTH.TOURISM} component={Tourism} />
                <Route exact path={PATHS.AUTH.CHIEFS_SHEDULE} component={ChiefsSchedule} />
                <Route exact path={PATHS.AUTH.EQUIPMENT} component={Equipment} />
                <Route exact path={PATHS.AUTH.VIDEOS} component={Videos} />
                <Route exact path={PATHS.AUTH.UNARMIYANS} component={Unarmiyans} />
                <Route exact path={PATHS.AUTH.PROFILE} component={Profile} />
                <Route exact path={PATHS.AUTH.REG_RATING_GEN} component={RegRatingGeneral} />
                <Route exact path={PATHS.AUTH.REG_RATING} component={RegRating} />
                <Route path={PATHS.AUTH.TASKS} component={Tasks} />
                <Route exact path={PATHS.AUTH.IMPORT} component={Import} />
                <Route exact path={PATHS.AUTH.PLUG} component={Plug} />
                <Route exact path={PATHS.AUTH.YUN_STAT} component={YunarmyStat} />
                <Route exact path={PATHS.AUTH.REGIONS} component={Regions} />
                <Route exact path={PATHS.AUTH.RATING_PARAMS} component={RatingParams} />
                <Route exact path={PATHS.AUTH.AWARDS} component={Awards} />
                <Route exact path={PATHS.AUTH.VIRTUAL_AWARDS} component={VirtualAwards} />
                <Route exact path={PATHS.AUTH.POPULATION_REPORT} component={PopulationReport} />
                <Route exact path={PATHS.AUTH.YUNARMY_HOUSES} component={YunarmyHouses} />
                <Route exact path={PATHS.AUTH.ANALYTICAL_REPORT} component={AnalyticalReport} />
                <Route exact path={PATHS.AUTH.HERO} component={Hero} />
                <Route exact path={PATHS.AUTH.ACTIVITY_REPORT} component={ActivityRatingReport} />
                <Route exact path={PATHS.AUTH.NUMBER_REPORT} component={NumberRatingReport} />
                <Route exact path={PATHS.AUTH.MOBILE_APP} component={Mobile_app} />
                <Route exact path={PATHS.AUTH.PRIVATE} component={Private} />
                <Route exact path={PATHS.AUTH.REGION_GROUPS} component={RegionGroups} />
                <Route exact path={PATHS.AUTH.MAILS_JOURNAL} component={MailsJournal} />
                <Route exact path={PATHS.AUTH.BADGES} component={Badges} />
                <Route exact path={PATHS.AUTH.LEADER_CONTACTS} component={LeaderContactsReport} />
                <Route exact path={PATHS.AUTH.ROLE_SETTINGS} component={RoleSettings} />
                <Route exact path={PATHS.AUTH.ROLES} component={Roles} />
                <Route exact path={PATHS.AUTH.FEDERAL_DISTRICTS} component={FederalDistricts} />
              </PrivateRoute>
           </Switch>
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}

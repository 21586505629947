import React, { useEffect } from 'react'
import styles from './MailsJournal.module.css'
import { getCustomDocsInfo } from '../../actions/yunarmiyans'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Loader from "../../components/Loader/Loader"
import moment from 'moment'
import { useState } from 'react'
import DistributionModal from '../../components/DistributionModal/DistributionModal'

export default function MailsJournal({

}) {

    const dispatch = useDispatch()
    const { customDocsInfo: data, loading } = useSelector(state => state.yunarmians)

    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
      dispatch(getCustomDocsInfo())
    }, [])
    
  return (
    <div className={styles.mailsWrapper}>
        {selectedItem &&
        <DistributionModal
          onClose={() => setSelectedItem(null)}
          item={selectedItem}
        />}
        {loading
            ? <Loader top='55%' left='47%'/>
            : <ul>
                {data && data.map(mail => {
                  const initials = mail.created_by_name?.split(' ').splice(0, 2).map(word => word[0]) || 'НП'
                  return (
                    <li onClick={() => setSelectedItem(mail)}>
                        <div>{initials}</div>
                        <div className={styles.mailDescription}>
                            <div>{`${moment(mail.created).format('LLL')} (${mail.created_by_name})`}</div>
                            <div>{mail.subject}</div>
                        </div>
                    </li>
                  )
                })}
              </ul>
        }
    </div>
  )
}

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { BACKEND_DOMAIN } from '../../utils/const';

class WrappedImageField extends Component {
  state = {imagePreviewUrl: null}

  componentDidUpdate(prevProps) {
    if (this.props.input.value !== prevProps.input.value && typeof(this.props.input.value)=='object') {
      const reader = new FileReader();
      const file = this.props.input.value[0];
      if (file) {
        reader.onloadend = () => {
          this.setState({
            file,
            imagePreviewUrl: reader.result,
          });
        };

        reader.readAsDataURL(file);
      }
      else {
        this.setState({
          imagePreviewUrl: null,
        });
      }
    } else if (this.props.input.value !== prevProps.input.value) {
      this.setState({
        imagePreviewUrl: null,
      });
    }
  }

  // fileRead (e) {
  //   const reader = new FileReader();
  //     const file = this.props.input.value[0];
  //     if (file) {
  //       reader.onloadend = () => {
  //         this.setState({
  //           file,
  //           imagePreviewUrl: reader.result,
  //         });
  //       };

  //       reader.readAsDataURL(file);
  //     }
  //     else {
  //       this.setState({
  //         imagePreviewUrl: null,
  //       });
  //     }
  // }

  render() {
    const { input: {  onChange, value }, label, className, ...props } = this.props;
    const { imagePreviewUrl } = this.state;
    const imageURL = imagePreviewUrl || (value && `${BACKEND_DOMAIN || ""}`+value)

    return (
      <div className={className}>
        {label && <div>{label}</div>}
        {imageURL
          ? <img alt="" src={imageURL} />
          :
          <label>
            <input
              type="file"
              accept="image/jpeg,image/png,image/gif"
              onChange={onChange}
              {...props}
            />
            Выберите файл
          </label>
        }
      </div>
    );
  }
}

const ImageField = props => (
  <Field
    component={WrappedImageField}
    {...props}
  />
);

export default ImageField;


import React from "react";
import styles from "./FormDatePicker.module.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function FormInput({
  title = "",
  width = "",
  name = "",
  value,
  onChange = () => {},
  editMode = false,
  notValid = false
}) {

  return (
    <div
      className={`${styles.inputWrapper} ${notValid ? styles.notValid : null}`}
      style={{ width: width }}
    >
      {notValid && (
        <div className={styles.validDescr}>поле является обязательным</div>
      )}
      <div className={styles.title}>{title}</div>
      {editMode ? 
          <DatePicker
            className={styles.pickerProfile}
            selected={value ? (value.length===10 ? moment(value, "YYYY-MM-DD") : moment(value)) : null}
            name={name}
            showYearDropdown
            dateFormat="DD.MM.YYYY"
            locale={"ru"}
            onChange={e => onChange({target: {value: e ? moment(e).format("YYYY-MM-DD") : null, name: name, id: name}})}
          /> : 
          <div className={styles.block}>{value && moment(value).format("DD.MM.YYYY")}</div>
      }
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import styles from "./FederalDistricts.module.css";

const FederalDistrictsModal = ({
  open,
  type,
  selectedItem,
  onClose
}) => {
  
  const [item, setItem] = useState(selectedItem);

  useEffect(() => {
    setItem(selectedItem)
  }, [selectedItem]);


  const handleClose = () => {
    setItem(null);
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD" ? "Федеральный округ" : "Федеральный округ"}
      </div>
      {item && (
        <div className={styles.modalBody}>
          <div>
            <CustomInput
              value={item.name}
              title="Наименование"
              disabled
            />
            <CustomInput
              value={item.short_name}
              title="Сокращенное наименование"
              disabled
            />
            <CustomInput
              value={item.administrative_capital}
              title="Столица"
              disabled
            />
          </div>
          
          <div className={styles.modalButtons}>
            <ButtonRed onClick={handleClose}>Закрыть</ButtonRed>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default FederalDistrictsModal;

import React, {useState} from 'react'
import styles from './styles.module.css'
import logo from '../img/logo.png'
import header from '../img/header.png'
import defenderI from '../img/defender.png'
import emblemDefender from '../img/emblemDefender.png'
import { Link } from 'react-router-dom'
import Video from '../Video/Video'
import TestWrapper from '../Test/TestWrapper'
import Article from '../Article/Article'
import buttonBack from '../img/buttonBack.png'

const Defender = () => {
  
  const testTitle = 'Юнармеец - защитник — продвигающий и при необходимости защищающий цивилизационные и национальные ценности, стремящийся к самопознанию, саморазвитию, несущий ответственность за себя и своё окружение, владеющий начальной военной подготовкой.'

  const testData = [
    {title: 'День Героев Отечества',
    questionList: [
      {
        question: 'Герой России - оренбуржец, погибший при освобождении от боевиков ИГИЛ (запрещено в РФ) древней Пальмиры, вызвав огонь авиации на себя, стал не только российским, но и по-настоящему международным, героем. Его семья говорит о том, что из самых разных стран мира и сегодня продолжают приходить соболезнования в связи с утратой парня, мужество которого достойно уважения. Он является одним из символов героической борьбы с международным терроризмом.',
        type: 'pick',
        answers: [{
          answer: 'капитан третьего ранга Анатолий Солодков',
          bool: false
        },
        {
          answer: 'старший лейтенант Прохоренко Александр',
          bool: true
        },
        {
          answer: 'генерал-полковник Сергей Макаров',
          bool: false
        }
        ] 
      },
      {
        question: 'Как называется данная государственная награда?',
        type: 'pick',
        img: '/medal.jpg',
        answers: [{
          answer: 'Орден Святого Георгия',
          bool: true
        },
        {
          answer: 'Орден "За военные заслуги"',
          bool: false
        },
        {
          answer: 'Орден Почета',
          bool: false
        }
        ] 
      },
      {
        question: 'День Героев Отечества посвящен возрождению традиции чествования героев. Как назывался этот праздник в дореволюционной России?',
        type: 'pick',
        answers: [{
          answer: 'день георгиевских кавалеров',
          bool: true
        },
        {
          answer: 'день Андрея Первозданного',
          bool: false
        },
        {
          answer: 'День защитника Отечества',
          bool: false
        }
        ] 
      },
      {
        question: 'Красноармеец, Герой Советского Союза, закрыл собой амбразуру фашистского дзота, предоставив однополчанам возможность захватить опорный пункт.',
        type: 'pick',
        answers: [{
          answer: 'Валентин Александрович Котик',
          bool: false
        },
        {
          answer: 'Марат Иванович Казей',
          bool: false
        },
        {
          answer: 'Александр Матвеевич Матросов',
          bool: true
        }
        ] 
      },
      {
        question: 'Советский военный летчик, совершивший в самом начале ВОВ героический подвиг. Ценой собственной жизни он уничтожил колонну вражеской техники, направив в неё свой горящий бомбардировщик.',
        type: 'pick',
        answers: [{
          answer: 'Николай Францевич Гастелло',
          bool: true
        },
        {
          answer: 'Иван Никитович Кожедуб',
          bool: false
        },
        {
          answer: 'Александр Иванович Покрышкин',
          bool: false
        }
        ] 
      },
      {
        question: 'Как зовут ученого-океанолога, исследователя Арктики и Антарктики, удостоенного звания и Героя Советского Союза, и Героя Российской Федерации?',
        type: 'pick',
        answers: [{
          answer: 'Семен Челюскин',
          bool: false
        },
        {
          answer: 'Артур Чилингаров',
          bool: true
        },
        {
          answer: 'Иван Попанин',
          bool: false
        }
        ] 
      },
      {
        question: 'Медицинская сестра процедурного кабинета медицинской роты бригады оперативного назначения в городе Калач-на-Дону, сержант; первая и единственная женщина, получившая звание Героя России за боевые действия в Кавказских войнах.',
        type: 'pick',
        answers: [{
          answer: 'Ирина Янина',
          bool: true
        },
        {
          answer: 'Марем Арапханова',
          bool: false
        },
        {
          answer: 'Наталья Качуевская',
          bool: false
        }
        ] 
      },
      {
        question: 'Это дважды Герой Советского Союза родом из Кузбасса, первым совершил выход в открытый космос. В открытом космосе возникла нештатная ситуация – его скафандр вздулся.  Он никак не мог пролезть в шлюз. Тогда космонавт принял самостоятельное решение, стравив давление из скафандра, и нарушил инструкции, влезая в шлюх головой вперед. О ком идет речь?',
        type: 'pick',
        answers: [{
          answer: 'Герман Титов',
          bool: false
        },
        {
          answer: 'Борис Волынов',
          bool: false
        },
        {
          answer: 'Алексей Леонов',
          bool: true
        }
        ] 
      },
      {
        question: 'Высшей военной наградой в современной России является:',
        type: 'pick',
        answers: [{
          answer: 'Орден «За заслуги перед Отечеством»',
          bool: false
        },
        {
          answer: 'Орден Дружбы',
          bool: false
        },
        {
          answer: 'Орден Святого Георгия 4 степени',
          bool: true
        }
        ] 
      },
      {
        question: 'Многие из его высказываний стали крылатыми. Вот одно из них: «плох тот солдат, который не мечтает стать генералом». Кто автор этих слов?',
        type: 'pick',
        answers: [{
          answer: 'Александр Васильевич Суворов',
          bool: true
        },
        {
          answer: 'Павел Степанович Нахимов',
          bool: false
        },
        {
          answer: 'Петр Иванович Багратион',
          bool: false
        },
        {
          answer: 'Георгий Константинович Жуков',
          bool: false
        }
        ] 
      },
      {
        question: 'Как в древней Руси называли отважного, доблестного воина, богатыря?',
        type: 'pick',
        answers: [{
          answer: 'витязь',
          bool: true
        },
        {
          answer: 'опричник',
          bool: false
        },
        {
          answer: 'молодец',
          bool: false
        }
        ] 
      },
      {
        question: 'О чем эта старая русская загадка: «Глазами не увидишь, руками не возьмёшь, а без него в атаку не пойдёшь». О чём речь?',
        type: 'pick',
        answers: [{
          answer: 'Ура!',
          bool: true
        },
        {
          answer: 'Вперед!',
          bool: false
        },
        {
          answer: 'В бой!',
          bool: false
        }
        ] 
      },
      {
        question: 'Герой Российской Федерации, офицер Управления «В» Центра специального назначения Федеральной службы безопасности Российской Федерации, полковник, принимавший участие в освобождении заложников во время теракта в Беслане. Назовите его имя.',
        type: 'pick',
        answers: [{
          answer: 'Дмитрий Разумовский',
          bool: false
        },
        {
          answer: 'Михаил Кузнецов',
          bool: false
        },
        {
          answer: 'Вячеслав Бочаров',
          bool: true
        }
        ] 
      },
      {
        question: 'Великий полководец, без которого трудно представить Победу. Именно он командовал армией во время главных сражений',
        type: 'pickImage',
        answers: [{
          answer: '/zh1.jpg',
          bool: false
        },
        {
          answer: '/zh2.jpg',
          bool: false
        },
        {
          answer: '/zh3.jpg',
          bool: true
        }
        ] 
      },
      {
        question: 'Российский военный летчик, подполковник. Возглавлял службу безопасности полетов в Липецком авиацентре. С сентября 2015 года принимал участие в Военной операции России в Сирии. 24 ноября 2015 года его самолет был сбит турецким истребителем над горами Туркмен, пилоты катапультировались, но по ним был открыт огонь с земли. В результате один из пилотов погиб. 17 ноября 2021 года состоялась премьера художественного фильма «Небо», сюжет которого навеян судьбой этого лётчика.',
        type: 'pick',
        answers: [{
          answer: 'Андрей Дьяченко',
          bool: false
        },
        {
          answer: 'Олег Пешков',
          bool: true
        },
        {
          answer: 'Роман Филипов',
          bool: false
        }
        ] 
      },
      {
        question: 'Все знают, что Валентина Владимировна Терешкова, являющаяся одним из учредителей «ЮНАРМИИ», - первая в мире женщина-космонавт, Герой Советского Союза, единственная в мире женщина, совершившая космический полёт в одиночку. Но мало кто знает, что Валентина Владимировна имеет еще и воинское звание. Какое именно?',
        type: 'pick',
        answers: [{
          answer: 'генерал- майор',
          bool: true
        },
        {
          answer: 'полковник',
          bool: false
        },
        {
          answer: 'капитан',
          bool: false
        }
        ] 
      },
      {
        question: 'В конце 2022 года Движение «ЮНАРМИЯ» и Министерство Обороны Российской Федерации организовали патриотический концерт, который стал своего рода «поздравительной видео-открыткой», доступной к просмотру в записи военнослужащим специальной военной операции и солдатам из госпиталей. Как назывался данный концерт?',
        type: 'pick',
        answers: [{
          answer: '«Герои, живущие рядом»',
          bool: false
        },
        {
          answer: '«Скажи Героям Спасибо»',
          bool: true
        },
        {
          answer: '«На защите мира»',
          bool: false
        }
        ] 
      }
    ]
    },
    {title: 'Основы военной службы',
    questionList: [
    {
      question: 'Какой из государственных документов в ВС России обязывает служению верности и долга обороны нашего Отечества?',
      type: 'pick',
      answers: [{
        answer: 'Конституция РФ',
        bool: false
      },
      {
        answer: 'Военная присяга',
        bool: true
      },
      {
        answer: 'Кодекс Чести ВС РФ',
        bool: false
      }
      ] 
    },
      {
        question: 'Общее руководство Вооруженными силами РФ осуществляет:',
        type: 'pick',
        answers: [{
          answer: 'Министр обороны РФ',
          bool: false
        },
        {
          answer: 'Президент РФ',
          bool: true
        },
        {
          answer: 'Генеральный штаб Министерства обороны РФ',
          bool: false
        }
        ] 
      },
      {
        question: 'Запас ВС РФ предназначен для:',
        type: 'pick',
        answers: [{
          answer: 'развертывания армии при мобилизации и ее пополнения во время войны ',
          bool: true
        },
        {
          answer: 'создания резерва различных специалистов, необходимых в народном хозяйстве',
          bool: false
        },
        {
          answer: 'подготовки населения к ведению партизанских действий в случае необходимости',
          bool: false
        }
        ] 
      },
      {
        question: 'Первичный учет призывников и военнообязанных, проживающих на территории, где нет военкоматов, возложен на',
        type: 'pick',
        answers: [{
          answer: 'специальное уполномоченное лицо от воинского подразделения, расположенного в регионе',
          bool: false
        },
        {
          answer: 'заместителя руководителя органа местного самоуправления',
          bool: false
        },
        {
          answer: 'органы местного самоуправления поселений и городских округов',
          bool: true
        }
        ] 
      },
      {
        question: 'Место, где носят эполеты военные?',
        type: 'pick',
        answers: [{
          answer: 'на плечах',
          bool: true
        },
        {
          answer: 'на фуражке',
          bool: false
        },
        {
          answer: 'на рукавах',
          bool: false
        }
        ] 
      },
      {
        question: 'Как называется часть боевого патрона, которая поражает цель?',
        type: 'pick',
        answers: [{
          answer: 'пуля',
          bool: true
        },
        {
          answer: 'гильза',
          bool: false
        },
        {
          answer: 'капсюль',
          bool: false
        }
        ] 
      },
      {
        question: 'Какой тип воздушной техники разработан, чтобы ликвидировать такую же воздушную технику?',
        type: 'pick',
        answers: [{
          answer: 'штурмовик',
          bool: false
        },
        {
          answer: 'БЛПА',
          bool: false
        },
        {
          answer: 'истребитель',
          bool: true
        }
        ] 
      },
      {
        question: 'Что, из нижеперечисленного, военнослужащий может получить, не прикладывая каких-либо усилий?',
        type: 'pick',
        answers: [{
          answer: 'увольнение',
          bool: false
        },
        {
          answer: 'оружие',
          bool: false
        },
        {
          answer: 'наряд',
          bool: true
        }
        ] 
      },
      {
        question: 'Какой день считается началом военной службы? ',
        type: 'pick',
        answers: [{
          answer: 'День прибытия в военный комиссариат субъекта Российской Федерации',
          bool: false
        },
        {
          answer: 'День убытия из дома к месту прохождения службы',
          bool: false
        },
        {
          answer: 'День убытия из военного комиссариата субъекта Российской Федерации к месту прохождения службы',
          bool: true
        }
        ] 
      },
      {
        question: 'Какое звание является низшим в современных силовых структурах России?',
        type: 'pick',
        answers: [{
          answer: 'сержант',
          bool: false
        },
        {
          answer: 'ефрейтор',
          bool: false
        },
        {
          answer: 'рядовой',
          bool: true
        }
        ] 
      },
      {
        question: 'Официально данное воинское звание в России было введено Воинским уставом 1716 года. При этом титул с аналогичным названием впервые был пожалован в 1696 Петром I воеводе А. С. Шеину за успешные действия под Азовом. Но в современной России данное воинское звание законодательством не предусмотрено. Что это за звание?',
        type: 'pick',
        answers: [{
          answer: 'генералиссимус',
          bool: true
        },
        {
          answer: 'маршал',
          bool: false
        },
        {
          answer: 'генерал – рядовой',
          bool: false
        }
        ] 
      },
      {
        question: 'Какое воинское звание на данной фотографии у Юрия Гагарина?',
        type: 'pick',
        img: '/gagarin.jpg',
        answers: [{
          answer: 'майор',
          bool: true
        },
        {
          answer: 'капитан',
          bool: false
        },
        {
          answer: 'полковник',
          bool: false
        }
        ] 
      },
      {
        question: 'К видам ВС РФ относятся:',
        type: 'pick',
        answers: [{
          answer: 'РВСН, артиллерийские войска, войска ПВО, мотострелковые войска',
          bool: false
        },
        {
          answer: 'сухопутные войска, ВВС, ВМФ',
          bool: true
        },
        {
          answer: 'сухопутные войска, танковые войска, ВДВ',
          bool: false
        }
        ] 
      },
      {
        question: 'Какие войска не относятся к Министерству обороны РФ?',
        type: 'pick',
        answers: [{
          answer: 'инженерные войска',
          bool: false
        },
        {
          answer: 'медицинские войска',
          bool: false
        },
        {
          answer: 'внутренние войска',
          bool: true
        }
        ] 
      },
      {
        question: 'Какое воинское звание у Президента Российской Федерации Владимир Владимировича Путина?',
        type: 'pick',
        answers: [{
          answer: 'Генерал Армии',
          bool: false
        },
        {
          answer: 'Генералиссимус',
          bool: false
        },
        {
          answer: 'Полковник',
          bool: true
        }
        ] 
      },
      {
        question: 'На базе какого Всероссийского детского центра, расположенного в Краснодарском крае, в августе 2023 года пройдет юнармейская смена военно-патриотического лагеря «ЮНАРМЕЕЦ»?',
        type: 'pick',
        answers: [{
          answer: 'Орленок',
          bool: true
        },
        {
          answer: 'Артек',
          bool: false
        },
        {
          answer: 'Океан',
          bool: false
        }
        ] 
      },
      {
        question: 'Под каким девизом, в 2023 году, прошла всероссийская военно-спортивная игра «Победа»?',
        type: 'pick',
        answers: [{
          answer: '«Нам нужна одна ПОБЕДА, мы за ценой не постоим!»',
          bool: false
        },
        {
          answer: '«ПОБЕДА – одна на всех»',
          bool: true
        },
        {
          answer: 'Наша ПОБЕДА – гордость поколений!',
          bool: false
        }
        ] 
      },
    ]
    }
  ] 

  const articleData = [
    {
      title: 'Всероссийская военно-спортивная игра «Победа»',
      path: 'win',
      text: [
        'Всероссийская военно-спортивная игра «Победа» — это комплекс просветительно-соревновательных мероприятий по патриотическому воспитанию, физической культуре и основам безопасности жизнедеятельности для детей и подростков.',
        'Игра проводится в несколько этапов — на уровне муниципалитетов, субъектов Федерации, ежегодно, с сентября по май, проходят отборочные этапы. Лучшие из лучших в своем регионе попадают на Всероссийский финал.',
        'Каждый финал – это уникальное по своему масштабу и зрелищности событие. ',
        'Участники демонстрируют свои навыки в стрельбе, огневой, тактической и инженерной подготовке, основам связи, топографии, военной истории, строевой подготовке, а также проявляют и взаимовыручку, так как переход к следующему этапу невозможен, без прохождения предыдущего участка всей командой.',
        'Самый сложный этап – марш-бросок «Дорога победителей» и военизированная эстафета, где действующие военнослужащие воссоздают для команд реальные боевые условия.',
        'На площадках Всероссийской военно-спортивной игры «Победа» проходят не только соревнования, но и мастер-классы, зарядки с Чемпионами, встречи с настоящими Героями, экскурсии, ну и конечно же, новые знакомства с юнармейцами со всей страны.',
        'В 2023 году участие в игре «Победа» приняли более 850 мальчишек и девчонок от 14 до 17 лет из 84 субъектов Российской Федерации.'
      ]
    },
    {
      title: 'Военно-спортивные «Юнармейские игры»',
      path: 'warPlay',
      text: [
        'Военно-спортивные «Юнармейские игры» -— одно из важнейших зрелищных ежегодных мероприятий «ЮНАРМИИ».',
        'Этот ежегодный проект охватывает многие регионы страны и даёт юнармейцам прекрасную возможность попробовать себя в самых разных и самых требовательных к личной подготовке конкурсах.',
        'Участники преодолевают сложные испытания на военных полигонах, управляют дронами, оказывают первую доврачебную помощь, проходят полосу разведчика, узнают историю стрелкового оружия, проводят спасательные работы на поле боя, показывают свои навыки в горно-высотной подготовке, выходят на воду, выполняют нормативы на военных тренажёрах.',
        'В культурной части игр ребята также соревнуются в творческом конкурсе, конкурсах рисунков и вокала.',
        'В 2022 году в финалах этапов конкурсов приняло участие более 2100 человек. В отборочных этапах и в качестве зрителей принимало участие более 11 400 юнармейцев'
      ]
    },
    {
      title: 'Программа «ЮНАРМИЯ. Полигоны»',
      path: 'poligon',
      text: [
        'Программа «ЮНАРМИЯ. Полигоны» — комплекс военно-патриотических мероприятий «ЮНАРМИИ» на полигонах Министерства Обороны Российской Федерации, способствующих формированию и развитию социально значимых ценностей у детей и подростков, гражданственности и патриотизма при подготовке к военной службе.',
        'Юнармейцы под руководством опытных наставников – ветеранов и действующих военнослужащих – расширят свою базу навыков.',
        'Ребята знакомятся с уставом Вооруженных сил Российской Федерации, практикуются в стрельбе, демонстрируют навыки воздушно-десантной, строевой и физической подготовки, учатся работать с квадрокоптерами, посещают уроки по тактической медицине с участием бронетехники, квесты, отрабатывают тактические действий отряда с применением технологий Лазертага, а также смекалку и умение работать сообща.',
        'В 2023 г., совместно с Министерством Обороны Российской Федерации, а также Начальниками Штабов Региональных отделений, проведено более 19 мероприятий на полигонах Министерства Обороны, в которых приняло участие 1957 юнармейцев.'  
      ]
    },
    {
      title: 'Программа «Современный защитник Отечества»',
      path: 'father',
      text: [
        'Программа «Современный защитник Отечества» — это единая программа подготовки юнармейцев к военной службе. ',
        'Программа разработана на основе военно-спортивных образовательных технологий, позволяющих максимально систематизировать и оптимизировать учебные, воспитательные и тренировочные процессы, и направлена на подготовку участников юнармейских отрядов и Домов «ЮНАРМИИ» по 2-м направлениям: начальная военная и спортивная подготовка.',
        'Значимой особенностью программы является ведением системы аттестации участников (Юнармейский минимум), состоящей из контрольных нормативов.',
        'С ноября 2022 по март 2023 года Главный Штаб Движения «ЮНАРМИЯ» и Московский педагогический государственный университет запустили практический курс по обороне и безопасности «Защитник будущего». На площадках ребята оттачивали навыки тактической подготовки, метания гранат, изучали основы оказания первой помощи и тактической медицины, играли в подвижные игры.',
        'На финальном тренинге юнармейцев разделили на команды по трём возрастным категориям, чтобы каждый смог показать, что он знает и умеет.',
        'Ребята получили незабываемый опыт, яркие эмоции и новых друзей.'  
      ]
    }
  ]

  const data = [
    {link: '«ЮНАРМИЯ. Полигоны». Московская область', name: '"ЮНАРМИЯ. Полигоны"'},
    {link: 'Кто такой юнармеец', name: 'Кто такой юнармеец?'},
    {link: 'Победа финал', name: 'Всероссийская игра "Победа"'},
    {link: 'Современный защитник Отечества первые занятия', name: '"Современный защитник Отечества". Первые занятия'},
    {link: 'Техно Дог Биатлон', name: ' "Техно Дог Биатлон" в рамках всероссийских юнармейских игр'},
    {link: 'Финальный тренинг «Защитник будущего»', name: '"Современный защитник Отечества". Финальный тренинг'},
    {link: 'Что такое ЮНАРМИЯ', name: 'Что такое "ЮНАРМИЯ"'},
    {link: 'Юнармейские игры 2022 как это было', name: '"Юнармейские игры" 2022. Как это было.'},
    {link: 'ЮНАРМИЯ. Полигоны_2022', name: '"ЮНАРМИЯ. Полигоны". Общая информация'},
    {link: 'ЮНАРМИЯ', name: '4 образа юнармейца'}
  ]

  const dataLink = [
    {title: 'Всероссийская военно-спортивная игра «Победа»', link: 'article', id: 1},
    {title: 'Военно-спортивные «Юнармейские игры»', link: 'article', id: 2},
    {title: 'Программа «Юнармия. Полигоны»', link: 'article', id: 3},
    {title: 'Программа «Современный защитник Отечества»', link: 'article', id: 4},
    {title: 'Юнармейский норматив', link: 'test', id: 5},
    {title: 'Видео', link: 'video', id: 7},
  ]

  const [page, setPage] = useState('default');
  const [id, setId] = useState('');

  const handleClick = (el) => {
    setPage(el.link);
    setId(el.id)
  }

  const getPage = () => {
    switch (page) {
      case 'video':
        return <Video setPage={setPage} data={data} pathName='Видео Защитник'/>
      case 'test':
        return <TestWrapper setPage={setPage} data={testData} testTitle={testTitle}/>
        case 'article':
      return <Article setPage={setPage} data={articleData} id={id}/>
    
      case 'default':
        return (
        <div className={styles.content}>
          <Link to='/panel' className={styles.back}><img src={buttonBack}/></Link>
          <div className={styles.contentImg}>
            <img src={emblemDefender} className={styles.emblemCreater} />  
            <img src={defenderI} className={styles.creater}/>  
          </div>

          <div className={styles.buttonWrapper}>
            {dataLink.map(el =>
                <div 
                onClick={() => handleClick(el)}
                className={styles[`button${el.id}`]} >
                <span className={styles.buttonTitle}>{el.title}</span>
              </div>
              )}
          </div>
        </div>)
    }
  }
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={header} className={styles.headerBg}/>
        <div className={styles.headerInfo}>
          <img src={logo} className={styles.logo}/>
          <div className={styles.titleBlock}>
            <div className={styles.titleH2}>ВСЕРОССИЙСКОЕ ВОЕННО-ПАТРИОТИЧЕСКОЕ <br/>ОБЩЕСТВЕННОЕ ДВИЖЕНИЕ</div>
            <div className={styles.titleH1}>"ЮНАРМИЯ"</div>
          </div>
        </div>
      </div>

      {getPage()}
    </div>
  )
}

export default Defender
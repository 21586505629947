import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { 
  getRatingParams, 
  editRatingParam, 
  getRatingCriterion, 
  updateClosedDate, 
  getAllRatingParams, 
  removeAllRegions,
  removeRegById,
  addRating, 
  deleteRating,
  getRating_year,
  addRatingParam,
  deleteRatingParam,
  editRating,
  getRatingCriterionByYear,
  remoreIsNew, 
  updateRatingParams} from "../../actions/regionRating";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import RatingParamsModal from "./RatingParamsModal";
import styles from "./RatingParams.module.scss";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose, IoMdSettings  } from "react-icons/io";
import ModalAdd from "./ModalAdd";
import { AiFillPlusSquare, AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import EditParamsModal from "./EditParamsModal";




const RatingParams = ({
  data,
  editRatingParam,
  getRatingCriterion,
  editRegion,
  userInfo,
  criterions,
  addRating,
  getAllRatingParams,
  loading,
  all_data,
  removeAllRegions,
  deleteRating,
  removeRegById,
  getRating_year,
  remoreIsNew,
  deleteRatingParam,
  editRating,
  addRatingParam,
  updateRatingParams,
  getRatingCriterionByYear,
  isNew,
  regById,
  dataByYear
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [visible, setVisible] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [editParamsModal, setEditParamsModal] = useState(false)


  useEffect(() => {
    getAllRatingParams()
    // !criterions && getRatingCriterion()
  }, []);
  
  useEffect(() => {
    const result = []
    if (dataByYear) {
      dataByYear.filter(it => !it.parent).forEach(
        (item) => {
          const arr = dataByYear.filter(it => it.parent === item.id).map(_ => true)
          result.push(arr)
        }
      )
      setVisible(result)
    }
  }, [dataByYear])
  
  useEffect(() => {
    if (regById.year, regById.id) {
      getRatingCriterionByYear(regById.id, regById.year);
    }
  }, [regById.year, regById.id])

  const openModal = (type, item) => () => {
    setSelectedItem(item);
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDepartment = (id) => {
    deleteRating(id);
  };

  const acceptConfirmation = () => {
    handleDeleteDepartment(confirmation.id);
    setConfirmation(null);
  };

  const visibleChange = (i, ind) => {
    const arr = [...visible]
    arr[i][ind] = !arr[i][ind]
    setVisible(arr)
  }

  const handleSetRating = (id) => {
    getRating_year(id)
  }

  const handleBack = () => {
    removeAllRegions();
    remoreIsNew();
    removeRegById()
  }

  const handleAdd = () => {
    setModalAdd(true);
  }

  const handleAddParam = (item) => {

    addRatingParam(
      {
        name: "Новый параметр",
        access: "superuser",
        criterion_id: item.id,
        active: true,
        description: "",
        rate_year_id: regById.id
    }
    )
  }

  const handleDeleteParam = (id) => {
    deleteRatingParam(id, regById.id);
  }

  const handleEditRating = (data) => {
    setModalEdit(data)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Параметры рейтинга</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Параметры:</div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
            {(!data && !isNew) && <ButtonRed onClick={() => handleAdd()} style={{margin: '0 1rem'}}>Создать</ButtonRed>}
            {(!!data || isNew) && <ButtonRed onClick={() => handleBack()}>Назад</ButtonRed>}
          </div>
          <div className={styles.table}>
              {(!data && !isNew) && all_data?.map(el => 
              <div 
                key={el.id} 
                className={styles.tableRow}>
                <div className={styles.clickBlock} onClick={() => handleSetRating(el.id)}>
                <div className={styles.displayNameFirst}>{el.name}</div>
                <div className={styles.displayDescFirst}>{el.description}</div>
                </div>
                <div className={styles.edit}>
                  <IoMdSettings color='grey' size={22} onClick={() => setEditParamsModal(el.year)}/>
                </div>
                <div className={styles.edit}>
                  <AiTwotoneEdit onClick={() => handleEditRating(el)} />
                </div>
                <div className={styles.actions}>
                  <IoMdClose onClick={() => setConfirmation({text: el.name, id: el.id})}/>
                </div>
              </div>
                )}
          </div>
          <div className={styles.table}>
            {(data || isNew) && dataByYear &&  
            dataByYear.filter(it => !it.parent).map(
              (it,i) => <div key={it.criterion}>
                <div className={styles.tableRow} style={{pointerEvents: "none", backgroundColor: "#ff000038", fontWeight: "700"}}>
                    <div className={styles.displayname}>
                      {it.criterion}
                    </div>
                    <div className={styles.superuser}></div>
                </div>
                {dataByYear.filter(el => el.parent === it.id).map(
                    (item,ind) => <div key={item.criterion}>
                    <div
                      className={styles.tableRow}  
                      style={{paddingLeft: "30px", backgroundColor: "#0000ff36", fontWeight: "500"}}
                    >
                      <div className={styles.displayname}>
                      <i 
                        className={styles.iconPlus} 
                        title="Добавить"
                        onClick={() => handleAddParam(item)}
                        ><AiFillPlusSquare/></i>
                        <span onClick={() => visibleChange(i, ind)}>{item.criterion}</span>
                      </div>
                      <div className={styles.superuser}></div>
                    </div>
                    {visible && visible[i][ind] && data?.filter(elem => elem.criterion_id === item.id).map(
                        row => (
                          <div key={row.id} className={styles.tableRow} style={{paddingLeft: "60px"}}>
                            <div
                              className={styles.displayname}>
                            <div className={styles.paramsBlocks}>
                              <span onClick={openModal("EDIT", row)}>
                                {row.name}
                              </span>
                              </div>
                              <i 
                              className={styles.iconDelete} 
                              title="Удалить"
                              onClick={() => handleDeleteParam(row.id)}
                              ><AiFillDelete/></i>
                            </div>
                            <div className={styles.superuser}></div>
                          </div>
                        )
                      )
                    }
                    </div>
                  )
                }
              </div>
            )
            }
          </div>
        </div>
      </div>

      {confirmation && (
        <Confirmation
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      )}

      {
        !!modalAdd &&
        <ModalAdd 
          loading={loading} 
          setModalAdd={setModalAdd} 
          open={modalAdd} 
          type={'ADD'}
          onClose={() => setModalAdd(false)}
          addRating={addRating}/>
      }

      {
        !!modalEdit &&
        <ModalAdd 
          loading={loading} 
          setModalAdd={setModalAdd} 
          open={modalEdit}
          type={'EDIT'} 
          data={modalEdit}
          onClose={() => setModalEdit(false)}
          addRating={addRating}
          editRating={editRating}/>
      }

      <RatingParamsModal
        userInfo={userInfo}
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        editRegion={editRegion}
        editRatingParam={editRatingParam}
        loading={loading}
        data={data}
      />

      <EditParamsModal
        open={!!editParamsModal}
        year={editParamsModal}
        updateRatingParams={updateRatingParams}
        closeModal={() => setEditParamsModal(null)}
        getRatingCriterion={getRatingCriterion}
        criterions={criterions}
      />

    </>
  );
};


const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  loading: state.departments.loading,
  all_data: state.region_rating.all_rating_parameters,
  data: state.region_rating.rating_params,
  criterions: state.region_rating.criterion,
  closedDate: state.region_rating.closed_date,
  isNew: state.region_rating.isNew,
  regById: state.region_rating.regById,
  dataByYear: state.region_rating.dataByYear
});

export default connect(mapStateToProps, {
  getRatingParams,
  editRatingParam,
  getRatingCriterion,
  updateRatingParams,
  updateClosedDate,
  removeAllRegions,
  getAllRatingParams,
  addRating,
  remoreIsNew,
  removeRegById,
  deleteRatingParam,
  editRating,
  deleteRating,
  getRating_year,
  addRatingParam,
  getRatingCriterionByYear
})(RatingParams);

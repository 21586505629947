import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { InputFile } from "../../assets/common/InputFile/InputFile"
import { IoMdTrash } from 'react-icons/io';
import styles from "./Hero.module.css";
import {Slider} from './Slider';
import Loader from '../../components/Loader/Loader';

import { SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.css';
import { BACKEND_DOMAIN } from "../../utils/const";
import { createNotification } from "../../components/Notifications/Notifications";
import Select from "react-select";
import ReactQuill from "react-quill";
import Yamap from "../../components/shared/Yamap";
import moment from "moment";

const errorList = {
  name: '"Населенный пункт"',
  region_id: '"Регион"',
  type_obj: '"Тип"',
  responsible_team_name: '"Ответственный отряд"',
  department_id: '"Ответственный отряд"',
  description: '"Краткое описание"',
  place: '"Местоположение"',
  // search: '"Кто нашел"',
  address: '"Адрес"',
  object_name: '"Название объекта"',
  square: '"Площадь"'
}

const HeroModal = ({
  type,
  numberPage,
  selectedItem,
  editObject,
  addHero,
  uploadImage,
  deleteImage,
  regions,
//   accessRights,
  loading,
  troops = [],
  editHero,
  getHeroImage,
  removeTroops,
  hero_image,
  param,
  removeHeroImage,
  getTroops,
  getFile,
  ...props
}) => {
  
  // const updateRights = type === "ADD";
  const [item, setItem] = useState({responsible_team_name: '', type_obj: '0', region_id: 0, department_id: '', type: '', description: '', geolocation: '', search: ''});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [toDeleteImages, setToDeleteImage] = useState([]);
  const [imagesPreviews, setImagesPreviews] = useState([]);
  const [search, setSearch] = useState('');

  const regexPattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;

  useEffect(() => {
    item?.id && getHeroImage(item.id);

  }, [item?.id]);


  useEffect(() => {
    if ((!!item?.region_id || !!selectedItem?.region_id && search)) { 
      getTroops(item?.region_id || selectedItem?.region_id, search, item?.department_id);
    } 
  }, [item?.region_id, search, selectedItem?.region_id]);
  
  useEffect(() => {
    if (item?.region_id && (item?.region_id != selectedItem?.region_id)) {
      setSearch('');
      setItem(prev => ({...prev, department_id: null}))
      removeTroops();
    }
  }, [item?.region_id]);
  
    useEffect(() => {
      setImagesPreviews([])
      setToDeleteImage([]);
      setSelectedFiles([]);
      if (type === "ADD") {
        setItem({
          name: '',
          region_id: '',
          type: '',
          type_obj: '0',
          responsible_team_name: '',
          description: '',
          place: '',
          department_id: '',
          search: '',
          address: '',
          object_name: '',
          square: '',
          search_history: '',
        });
      } else {
        selectedItem && setItem({...selectedItem});
        if (hero_image?.length > 0) {
            const img = [...hero_image.map(el => `${BACKEND_DOMAIN || ''}${el.path_file}`)];
            setImagesPreviews(img);
          } else {
            setImagesPreviews([]);
          }
        }
      }, [selectedItem, type, hero_image]);

  // проверка на пустоту
  const strip = (html) => {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText;
  };

  // создание записи
  const addHeroItem = async () => {
    if (
      item?.name &&
      item?.region_id &&
      (item?.department_id || item.responsible_team_name) &&
      strip(item?.description) &&
      item?.place &&
      regexPattern.test(item?.place) &&
      item?.address &&
      item?.object_name &&
      item?.square) {
      await addHero(item, numberPage+1, param, selectedFiles.map(el => el.file));
      handleClose();
    } else {
      // обработка пустых полей
      createNotification("error", "Заполните все обязательные поля!");
    }
  };

  // редактирование записи
  const editHeroItem = async () => {
    if (
      item?.name &&
      item?.region_id &&
      (item?.department_id || item.responsible_team_name) &&
      strip(item?.description) &&
      item?.place &&
      regexPattern.test(item?.place) &&
      item?.address &&
      item?.object_name &&
      item?.square)
 {
      const {department_chief, department_email, ...payload} = item
      await editHero(item.id, payload, numberPage+1, param);

      if (toDeleteImages.length > 0) {
        toDeleteImages.forEach(el => deleteImage(item.id, hero_image?.find(_el => _el.path_file == `${'/files/' + el.split('/files/')[1]}`)?.id))
      }
      
      if (selectedFiles.length > 0) {
        selectedFiles.forEach(el => uploadImage(selectedItem.id, { file: el.file }));
      } 

      handleClose();
    } else {
      // обработка пустых полей
      createNotification("error", "Заполните все обязательные поля!");
    }
  };

  // загрузка картинок
  const handleUploadImage = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map(file => ({ file, url: URL.createObjectURL(file) }));

      setSelectedFiles(prev => [...prev, ...filesArray]);
      setImagesPreviews(prev => [...prev, ...filesArray.map(el => el.url)])
    }
  };

  const deletePhoto = (photo) => {
    if (!/^blob/g.test(photo)) {
      setToDeleteImage(prev => [...prev, photo])
    } else {
      const file = imagesPreviews.find(el => el.url === photo)
      URL.revokeObjectURL(file)
      setSelectedFiles(selectedFiles.filter(el => el.url !== photo))
    }
    setImagesPreviews(imagesPreviews.filter(name => name !== photo))
  };

  // отрисовка слайдера
  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <SwiperSlide key={photo}>
          <div className={`photoWrapper`}>
            <i className={styles.deletePhoto} onClick={() => deletePhoto(photo)}>
              {<IoMdTrash />}
            </i>
            <img
              src={photo}
              alt="фото"
              className={styles.imgUploder}
            />
          </div>
        </SwiperSlide>
      )
    })
  }


  // скрыть + очистить попап
  const handleClose = () => {
    setItem(null);
    removeHeroImage();
    removeTroops();
    props.onClose();
  }

  // получение начального Ответственного отряда
  const getDefaultvalue = () => {
    return troops?.find(el => el?.id == item?.department_id)?.name;
  }

  const handleGetReg = (e) => {
    if (e) {
      setSearch(e);
    }
  }

  const handleDownload = () => {
    if (selectedItem?.type == null  || 
        selectedItem?.address == null || 
        selectedItem?.square == null || 
        strip(selectedItem?.description) == null || 
        selectedItem?.id == null || 
        selectedItem?.name == null || 
        selectedItem?.region_id == null) {
        createNotification("error", "Заполните все обязательные поля и сохраните!");
        return;
    }
    const data = {
      hero_name: selectedItem?.type,
      address: selectedItem?.address,
      square: selectedItem?.square,
      description: strip(selectedItem?.description),
      hero_id: selectedItem?.id,
      detachment: selectedItem?.name,
      region_name: `${regions?.find(el => el?.id == selectedItem?.region_id)?.regionName} ${regions?.find(el => el?.id == selectedItem?.region_id)?.federalUnit}`,
      photos: selectedItem?.images?.map(el => ({path_file: el?.path_file.replace(/^\/files\//, '/')})) || []
    }
    getFile(data.hero_id);
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD"
          ? "Добавление объекта"
          : "Редактирование объекта"}
      </div>
      <div className={styles.modalBody}>
        <div>
          <div className={styles.firstBlock}>
            <div className={styles.firstBlockInner}>
            
            <p className={styles.news__label}>Населенный пункт {!item?.name ? <div className={styles.required}>Обязательное поле</div> : ''}</p>
              <input
                required
                maxLength="200"
                value={item?.name}
                style={{border: !item?.name ? '1px solid red' : '1px solid #ccc'}}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, name: e.target.value }))
                }
                className={styles.input}
                placeholder="Населенный пункт"
              />
              <br />

            <p className={styles.news__label}>Тип {!item?.type_obj ? <div className={styles.required}>Обязательное поле</div> : ''}</p>
            <div className={styles.selectBlock}>
                <select
                    className={styles.regionSelect}
                    style={{maxWidth: '100%', border: !item?.type_obj ? '1px solid red' : '1px solid #ccc'}}
                    placeholder="Регион"
                    onChange={e => setItem(prev => ({...prev, type_obj: e.target.value})) }
                    >
                        {
                        [{name: 'Герой', value: '0', id: 0} ,
                         {name: 'Мемориальный объект', value: '1', id: 1}].map(it => 
                            <option 
                              selected={it.id == item?.type_obj} 
                              key={it.id} 
                              value={it.value}>{it.name}</option>
                            )
                        }
                </select>
            </div>
            <p className={styles.news__label}>Регион {!item?.region_id ? <div className={styles.required}>Обязательное поле</div> : ''}</p>
            <div className={styles.selectBlock}>
                <select
                    className={styles.regionSelect}
                    style={{maxWidth: '100%', border: !item?.region_id ? '1px solid red' : '1px solid #ccc'}}
                    placeholder="Регион"
                    onChange={e => setItem(prev => ({...prev, region_id: e.target.value})) }
                    >
                        <option selected={item?.region_id === ''} value=''>Выбрать</option>
                        {
                        regions && regions.map(it => 
                            <option selected={it.id === item?.region_id} key={it.id} value={it.id}>{`${it.regionName} ${it.federalUnit}`}</option>)
                        }
                </select>
            </div>
            {item?.region_id != '96' 
            ?
            <p className={styles.news__label}>Ответственный отряд {!item?.department_id ? <div className={styles.required}>Обязательное поле</div> : ''}</p> 
            :
            <p className={styles.news__label}>Ответственный отряд {!item?.responsible_team_name ? <div className={styles.required}>Обязательное поле</div> : ''}</p> 
            }
            {((item && type != 'ADD') || (type == 'ADD')) &&
            <>
            {(item?.region_id != '96' ? 
            <div 
              className={styles.selectBlock} 
              style={{borderRadius: item?.region_id != '96' ? '4px' : '0', border: !item?.department_id ? '1px solid red' : '1px solid #ccc'}}>
                  <Select
                    style={{fontSize: '14px'}}
                    placeholder="Выберите ответственный отряд..."
                    value={{value: item?.department_id, label: troops?.find(el => el?.id == item?.department_id)?.name} || {value: 0, label: 'Выбрать'}}
                    options={[{value: '', name: 'Выбрать'}, ...troops].map((i) => ({ label: i.name, value: i.id }))}
                    onChange={e => setItem(prev => ({...prev, department_id: e.value}))}
                    noOptionsMessage={(e) => e?.value}
                    className={styles.filter}
                    onInputChange={(e) => handleGetReg(e)}
                    //   isMulti
                    />
                </div>
              :
              <div 
              className={styles.selectBlock} 
              style={{borderRadius: item?.region_id != '96' ? '4px' : '0', border: !item?.responsible_team_name ? '1px solid red' : '1px solid #ccc'}}>
              <input 
                required 
                value={item?.responsible_team_name} 
                onChange={(e) => setItem(prev => ({...prev, responsible_team_name: e.target.value}))} 
                className={styles.input} 
                style={{width: '100%'}}
                placeholder="Ответственный отряд" />
                </div>
              )}
                </>  
            }

            <p className={styles.news__label}>Название объекта {(!item?.object_name && item?.type_obj != '0') ? <div className={styles.required}>Обязательное поле</div> : ''}</p>
            <input
            required
            maxLength="250"
            value={item?.object_name}
            style={{border: (!item?.object_name && item?.type_obj != '0') ? '1px solid red' : '1px solid #ccc'}}
            onChange={(e) =>
                setItem((prev) => ({ ...prev, object_name: e.target.value }))
            }
            className={styles.input}
            placeholder="Название объекта"
            />
            <p className={styles.news__label}>Адрес {!item?.address ? <div className={styles.required}>Обязательное поле</div> : ''}</p>
            <input
            required
            maxLength="250"
            value={item?.address}
            style={{border: !item?.address ? '1px solid red' : '1px solid #ccc'}}
            onChange={(e) =>
                setItem((prev) => ({ ...prev, address: e.target.value }))
            }
            className={styles.input}
            placeholder="Адрес"
            />
            <p className={styles.news__label}>Площадь {(!item?.square && item?.type_obj != '0') ? <div className={styles.required}>Обязательное поле</div> : ''}</p>
            <input
            required
            maxLength="20"
            value={item?.square}
            style={{border: (!item?.square && item?.type_obj != '0') ? '1px solid red' : '1px solid #ccc'}}
            onChange={(e) =>
                setItem((prev) => ({ ...prev, square: e.target.value }))
            }
            className={styles.input}
            placeholder="Площадь"
            />
            
            <p className={styles.news__label}>Имя Героя</p>
            <input
            required
            maxLength="200"
            value={item?.type}
            // disabled={!updateRights}
            onChange={(e) =>
                setItem((prev) => ({ ...prev, type: e.target.value }))
            }
            className={styles.input}
            placeholder="Имя Героя"
            />

            <p className={styles.news__label}>Кто нашел 
            {/* {!item?.search ? <div className={styles.required}>Обязательное поле</div> : ''} */}
            </p>
            <input
            required
            maxLength="200"
            value={item?.search}
            // style={{border: !item?.search ? '1px solid red' : '1px solid #ccc'}}
            onChange={(e) =>
                setItem((prev) => ({ ...prev, search: e.target.value }))
            }
            className={styles.input}
            placeholder="Кто нашел"
            />
            </div>            

            <div className={styles.imageBlock}>

                <div className={styles.wrapperSliderEquipment}>
                  {
                    !loading ? 
                    <>
                    <Slider>{imagesPreviews.length > 0 && renderPhotos(imagesPreviews)}</Slider>
                    <div className={styles.wrapperBut}>
                      <InputFile
                        multiple
                        title="Выберите изображение"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => handleUploadImage(e)}
                      />
                    </div>
                    </>
                    :
                    <div className={styles.load}><Loader top="30px" left="40%"/></div>
                  }

                  {type == 'EDIT' ?                  
                    <button className={styles.inputButton} onClick={() => handleDownload()}>Скачать DOC</button>:<></>}
                  
                <div className={styles.wrapperSliderEquipmentSmall}>
                </div>
              </div>
            </div>
          </div>
          <p className={styles.news__label}>Краткое описание {!strip(item?.description) ? <div className={styles.required}>Обязательное поле</div> : '' }</p>
            <ReactQuill
            modules={{
              toolbar: {
                container: [
                  [{ 'align': [] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                  ['clean']
                ],
              },
            }}
              style={{border: !strip(item?.description) ? '1px solid red' : '1px solid #ccc'}}
              value={item?.description || ''}
              onChange={(e) => setItem((prev) => ({ ...prev, description: e }))}
            />

          <p className={styles.news__label}>История поиска Героя</p>
            <ReactQuill
              modules={{
                toolbar: {
                  container: [
                    [{ 'align': [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['clean']
                  ],
                },
              }}
              value={item?.search_history || ''}
              onChange={(e) => setItem((prev) => ({ ...prev, search_history: e }))}
            />

            <div className={styles.heroMap}>
              <p className={styles.news__label}>Местоположение 
                {!item?.place ? <div className={styles.required}>Обязательное поле</div> : ''}          
                {item?.place && !regexPattern.test(item?.place) ? <div className={styles.required}>Неверный формат координат</div> : <></>}
              </p>
              <div className={styles.searchPanel}>
                    <input 
                      style={{border: !item?.place || !regexPattern.test(item?.place) ? '1px solid red' : '1px solid #ccc'}}
                      type="text"
                      className={styles.searchInput} 
                      placeholder="Координатам"
                      value={item?.place}
                      onChange={(e) => setItem((prev) => ({ ...prev, place: e.target.value }))}
                      />
              </div>

              <div className={styles.mapWrapper}>
                <Yamap coordinate={item?.place} width={'100%'} heigth="300px" setItem={setItem} name="place"/>
              </div>
            </div>

            { type!=="ADD" &&
            <div>
              <div className={styles.infoBlock}>
            <span>Создал:</span>
            <span>{item?.created_by}</span>
            <span>{item?.created ? moment(item?.created).format("LLL") : ""}</span>
            </div>
            <div className={styles.infoBlock}>
              <span>Обновил:</span>
              <span>{item?.updated_by}</span>
              <span>{item?.updated ? moment(item?.updated).format("LLL") : ""}</span>
            </div>
              </div>
            }
        </div>
        <div className="settingModal__btns">
          {
          <button
            className="newsListHeader__btn btn_grey"
            type="button"
            onClick={type === "ADD" ? addHeroItem : editHeroItem}
          >
            {type === "ADD" ? "Добавить" : "Сохранить"}
          </button>}
          <button
            className="newsListHeader__btn btn_grey"
            onClick={handleClose}
            type="button"
          >
            Закрыть
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HeroModal;

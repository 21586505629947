import React from 'react'
import styles from './styles.module.css'
import { useHistory, useParams } from 'react-router-dom'
import {Access, Employees, Params} from './Tabs'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import { getPermissionGroupInfo } from '../../actions/permissions';



export default function RoleSettings () {
  
  const { roleId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { groupInfo } = useSelector(state => state.roles.permission_groups)

    useEffect(() => {
        dispatch(getPermissionGroupInfo(roleId))
    }, [])
    

  const groupName = groupInfo?.displayname

  return (
    <div className={styles.wrapper}>
        <h1>{groupName}</h1>
        <Tabs>
            <TabList className={styles.tabsBlock}>
                <Tab className={styles.tab} selectedClassName={styles.activeTab}>Сотрудники</Tab>
                <Tab className={styles.tab} selectedClassName={styles.activeTab}>Доступы</Tab>
                {/* <Tab className={styles.tab} selectedClassName={styles.activeTab}>Параметры</Tab> */}
            </TabList>
            <TabPanel>
                <Employees roleId={roleId}/>
            </TabPanel>
            <TabPanel>
                <Access roleId={roleId}/>
            </TabPanel>
            {/* <TabPanel>
                <Params/>
            </TabPanel> */}
        </Tabs>
    </div>
  )
}

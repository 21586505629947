import React from 'react'
import styles from "./Pick.module.css";

const Pick = ({answers, setStep, setPoints}) => {
  
    const handleCheck = (bool) => {
        if (bool) {
            setPoints(prev => prev + 1);
        }
        setStep(prev => prev + 1);
    }

    return (
    <div>
        {answers.map(el => <div className={styles.answer} onClick={() => handleCheck(el.bool)}>{el.answer}</div>)}
    </div>
  )
}

export default Pick
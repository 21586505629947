import React, {useState} from 'react'
import styles from './styles.module.css'
import buttonBack from '../img/buttonBack.png'

const Article = ({setPage, data, id}) => {
    const art = data[id-1];
    return (
        <div className={styles.wrapper}>
            <div className={styles.figure_1}></div>
            <div className={styles.figure_2}></div>
            <div className={styles.content}>
                <div className={styles.contentTop}>
                    <div className={styles.title}>{art.title}</div>
                    <div className={styles.wrapperImg}><img className={styles.mainImg} src={`../imgArticle/${art.path}/main.jpg`} /></div>

                    <div className={styles.wrapperText}>
                        {art?.text?.map(el =>
                            <div className={styles.wrapperText_item}>{el}</div>    
                        )}
                    </div>

                </div>
                <div className={styles.wrapperImg}>
                    <img className={styles.img} src={`../imgArticle/${art.path}/1.jpg`} />
                    <img className={styles.img} src={`../imgArticle/${art.path}/2.jpg`} />
                </div>

                <img src={buttonBack} className={styles.buttonBack} onClick={() => setPage('default')}/>
            </div>
        </div>
  )
}

export default Article
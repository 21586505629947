import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { IoMdClose } from "react-icons/io";
import { BACKEND_DOMAIN } from "../../utils/const";
import styles from "./Documents.module.css"
import { IoIosCloseCircle } from "react-icons/io";
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import CustomDatepicker from '../../components/CustomDatepicker/CustomDatepicker';
import moment from "moment";



const DocumentsModal = ({
  type,
  history,
  numberPage,
  selectedItem,
  typesList,
  article,
  addDocument,
  editDocument,
  getFilesList,
  accessRights,
  filesList,
  uploadFile,
  deleteFile,
  sUser,
  sort,
  filter,
  ...props
}) => {

  const updateRights = type === "ADD" || accessRights.ac_update

  const [item, setItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [_filesList, setFilesList] = useState([...filesList])
  const [addFilesList, setAddFilesList] = useState([])


  useEffect(() => {
    setFilesList(filesList)
  }, [filesList])
  

  useEffect(() => {
    if (type === "ADD") {
      setItem({ type: article, name: '' });
    } else {
      if (selectedItem) {
        getFilesList(selectedItem.id)

        const date_signed = getFrontDate(selectedItem.date_signed)
        const expiration_date = getFrontDate(selectedItem.expiration_date)
        setItem({...selectedItem, date_signed, expiration_date, deletedFiles: []});
      }
    }
  }, [selectedItem, type]);

  const getFrontDate = (date) => date ? moment(date, "YYYY-MM-DD").format("DD.MM.YYYY") : null
  const getBackDate = (date) => date ? moment(date, "DD.MM.YYYY").format("YYYY-MM-DD") : null

  const addDocumentItem = () => {
    const date_signed = getBackDate(item.date_signed)
    const expiration_date = getBackDate(item.expiration_date)
    const payload = {...item, date_signed, expiration_date}

    addDocument(payload, numberPage, addFilesList, sort, filter);
    setAddFilesList([])
    props.onClose();
  };

  const editDocumentItem = () => {
    const {id, is_expired, ...data} = item 
    const date_signed = getBackDate(item.date_signed)
    const expiration_date = getBackDate(item.expiration_date)
    const payload = {...data, date_signed, expiration_date}

    editDocument(item.id, article, payload, numberPage, addFilesList, sort, filter);
    setAddFilesList([])
    props.onClose();
  };

  const handleUploadFile = (e) => {
    if (e.target.files) {
      const files = Object.values(e.target.files)
      setAddFilesList(files)
    }
  };

  const handleDeleteFile = (ind) => {
    const name = _filesList[ind].file
    setConfirmation({name: name, index: ind});
  };

  const acceptConfirmation = (name) => {
    const arr = [..._filesList]
    arr.splice(confirmation.index, 1)
    setItem(prev => ({...prev, deletedFiles: [...prev.deletedFiles, name]}))
    setFilesList(arr)
    setConfirmation(null)
  };

  const rejectConfirmation = () => {
    setConfirmation(null)
  };

  const deleteCurrentFile = (ind) => {
    const arr = [...addFilesList]
    arr.splice(ind, 1)
    setAddFilesList(arr)
  }

  const closeModal = () => {
    setAddFilesList([])
    props.onClose()
  }

  const handleChange = (e) => {
    setItem((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const renderFile = ({ file }, index) => {
    const link = `${BACKEND_DOMAIN || ""}/files/document/${item.id}/${file}`
    return (
      <div className={styles.fileItem}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {file}
        </a>
        {updateRights
          ? <div className={styles.imgBlock} onClick={() => handleDeleteFile(index)}>
              <i><IoMdClose color={'red'} fontSize={24}/></i>
            </div>
          : <div style={{height:'2rem'}}></div>
        }
      </div>
    )
  }

  const renderAddFile = ({name}, index) => (
    <div key={index}>
      <span>{name}</span>
      <span onClick={() => deleteCurrentFile(index)}>
        <IoIosCloseCircle size={16}/>
      </span>
    </div>
  )

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation.name}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <div className={styles.title}>
        {type === "ADD" ? "Добавление документа" : "Редактирование документа"}
      </div>
      {item && <div className={styles.modalBody}>
        <div>
          <CustomInput
            name='name'
            title='Название'
            placeholder="Название"
            value={item.name}
            onChange={handleChange}
            disabled={!updateRights}
            />
          <CustomSelect
            name='type'
            title='Тип документа'
            placeholder="Тип документа"
            selectedValue={item.type}
            onChange={handleChange}
            disabled={!updateRights}
            options={typesList?.map((it) => ({ value: it.id, name: it.name }))}
            selwidth="100%"
            margin=".5rem 0 0"
            withoutFirst
          />
          <CustomDatepicker
            title='Дата заключения'
            name='date_signed'
            value={item.date_signed}
            style={{marginTop: '.5rem'}}
            onChange={handleChange}
            />
          <CustomDatepicker
            title='Срок окончания'
            name='expiration_date'
            value={item.expiration_date}
            style={{marginTop: '.5rem'}}
            onChange={handleChange}
          />
          <div className={styles.filesBlock}>
            {type!=="ADD" && filesList && (
              <div className={styles.filesList}>
                {_filesList.map(renderFile)}
              </div>
            )}
            <div className={styles.addFilesList}>
              {addFilesList?.map(renderAddFile)}
            </div>
            <div className={styles.input}>
              <InputFile 
                multiple
                disabled={!updateRights}
                title="Выберите файл для загрузки"
                onChange={(e) => handleUploadFile(e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.modalButtons}>
          {updateRights &&
          <ButtonRed onClick={type === "ADD" ? addDocumentItem : editDocumentItem}>
            {type === "ADD" ? "Добавить" : "Сохранить"}
          </ButtonRed>}
          <ButtonRed onClick={closeModal}>Закрыть</ButtonRed>
        </div>
      </div>}
    </Modal>
  );
};

export default DocumentsModal;

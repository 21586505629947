import React from 'react'
import { CgUnavailable } from "react-icons/cg";
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom';


const Unavailable = () => {
  
  const history = useHistory()
  
  return (
    <div className={styles.wrapper}>
      {/* <CgUnavailable size={200} color='red'/> */}
      <h1>Сервис временно недоступен</h1>
      <button onClick={() => history.push('/')} >На главную</button>
    </div>
  )
}

export default Unavailable
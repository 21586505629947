/* eslint-disable */
import React, {  useState, useEffect } from "react";
import { connect } from "react-redux";
import { getGroups, deleteGroup, addGroup, editGroup } from "../../actions/methodicMaterials";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import MaterialGroupsModal from "./MaterialGroupsModal";
import styles from "./MatherialGroups.module.css"
import { IoMdClose } from "react-icons/io";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";




const MethodicMaterialGroups = ({
  getGroups,
  groupList,
  deleteGroup,
  addGroup,
  editGroup,
  accessRights
}) => {

  const deleteRights = accessRights.ac_delete

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const [sort, setSort] = useState("");


  useEffect(() => {
    getGroups(sort)
  }, [sort]);

  const openModal = (type, item) => () => {
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteGroup = (id) => {
    deleteGroup(id)
  }

  const acceptConfirmation = () => {
    handleDeleteGroup(confirmation.id)
    setConfirmation(null)
  }

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Группы методических материалов</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
            {accessRights.ac_create && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Список групп:</div>
            <div className={styles.username}></div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {groupList &&
              groupList.map((a) => (
                <div key={a.id} className={styles.tableRow}>
                  <div
                    className={styles.displayname}
                    onClick={openModal("EDIT", a)}
                  >
                    <span>
                      {a.name}
                    </span>
                  </div>
                  <div className={styles.username}></div>
                  <div className={styles.superuser}></div>
                  <div className={styles.actions}>
                    {deleteRights
                      ? <IoMdClose onClick={() => setConfirmation({text: a.name, id: a.id})}/>
                      : <div style={{height:'1.5rem'}}></div>
                    }
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.controls}>
          <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
          </div>
        </div>
      </div>
      {confirmation &&
      <Confirmation 
        confirmation={confirmation.text}
        acceptConfirmation={acceptConfirmation}
        rejectConfirmation={() => setConfirmation(null)}

      />}
      {isModalOpen &&
      <MaterialGroupsModal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        accessRights={accessRights}
        selectedItem={selectedItem}
        editGroup={editGroup}
        addGroup={addGroup}
      />}
    </>
  );
};

const mapStateToProps = (state) => ({
  groupList: state.materialGroups.groups,
  lastUpdate: state.schedule.lastUpdate,
  accessRights: state.auth.accessRights.find(i => i.name==='teaching_material_group')||{}
})

export default connect(mapStateToProps, { getGroups, deleteGroup, addGroup, editGroup })(
  MethodicMaterialGroups
);

import React, {useState} from 'react'
import styles from './styles.module.css'
import logo from '../img/logo.png'
import header from '../img/header.png'
import athleteI from '../img/athlete.png'
import emblemAthlete from '../img/emblemAthlete.png'
import { Link } from 'react-router-dom'
import Video from '../Video/Video'
import TestWrapper from '../Test/TestWrapper'
import Article from '../Article/Article'
import buttonBack from '../img/buttonBack.png'

const Athlete = () => {

  const testTitle = 'Юнармеец-атлет — ведущий здоровый образ жизни, активно участвующий в организации и популяризации комплекса ГТО, военно-спортивных соревнованиях и сборах; подготовленный к военной службе.'

  const testData = [
    {title: 'ЦСКА',
    questionList: [
      {
        question: 'Сколько зрителей вмещает стадион ЦСКА?',
        type: 'pick',
        answers: [{
          answer: '30 000 человек',
          bool: true
        },
        {
          answer: '15 000 человек',
          bool: false
        },
        {
          answer: '100 000 человек',
          bool: false
        }
        ] 
      },
      {
        question: 'ЦСКА – самый титулованный клуб в истории отечественного хоккея. Число побед в каком из выигранных клубом турниров не является рекордным?',
        type: 'pick',
        answers: [{
          answer: 'кубок СССР',
          bool: false
        },
        {
          answer: 'кубок Континента',
          bool: false
        },
        {
          answer: 'кубок Открытия',
          bool: true
        }
        ] 
      },
      {
        question: '22 июня 1941 года, в день начала Великой Отечественной войны, в Киеве должна была состояться встреча 10-го тура чемпионата СССР между местным «Динамо» и ККА (перед началом сезона-41 ЦДКА был переименован в «Команду Красной Армии». Новое название, впрочем просуществовало недолго). Через четыре бесконечно долгих года, майским вечером 1945-го, киевляне пришли на первый послевоенный матч, в котором играли, конечно же, «Динамо» и ЦДКА. Многие болельщики держали в руках те самые, довоенные, билеты — они были действительны. Это был один из первых матчей после Победы. С каким счетом, не в пользу Армейцев, закончился данный матч.',
        type: 'pick',
        answers: [{
          answer: '7:0',
          bool: true
        },
        {
          answer: '7:5',
          bool: false
        },
        {
          answer: '2:1',
          bool: false
        },
        {
          answer: '1:0',
          bool: false
        }
        ] 
      },
      {
        question: 'Всем известно, что клубные цвета ЦСКА красный и синий. Но так было не всегда. Какие цвета были первыми цветами ЦСКА?',
        type: 'pick',
        answers: [{
          answer: 'красный и желтый',
          bool: false
        },
        {
          answer: 'белый и синий',
          bool: true
        },
        {
          answer: 'красный и зеленый',
          bool: false
        }
        ] 
      },
      {
        question: 'Впервые в истории вместе с другими советскими спортсменами армейцы приняли участие в Олимпийских играх, программа которых предусматривала 149 состязаний по 21 виду спорта. Это был триумф советских спортсменов. Несмотря на то, что это был их олимпийский дебют, атлеты завоевали 106 олимпийских медалей, в том числе 38 золотых, обогнали 67 стран и стали героями Олимпиады. В каком году спортсмены-армейцы дебютировали на Олимпийских играх?',
        type: 'pick',
        answers: [{
          answer: '1980',
          bool: false
        },
        {
          answer: '1952',
          bool: true
        },
        {
          answer: '1948',
          bool: false
        }
        ] 
      },
      {
        question: 'Какая дата считается официальным днем рождения спортивного клуба армии?',
        type: 'pick',
        answers: [{
          answer: '29 апреля 1923',
          bool: true
        },
        {
          answer: '27 августа 1911',
          bool: false
        },
        {
          answer: '22 декабря 1946',
          bool: false
        }
        ] 
      },
      {
        question: 'При создании в 1911 году у нынешнего ЦСКА было совсем другое название. И спустя годы было около 7 разных названий у клуба. Какого названия точно никогда не было у армейцев.',
        type: 'pick',
        answers: [{
          answer: 'Общество любителей лыжного спорта',
          bool: false
        },
        {
          answer: 'Опытно-показательная военно-спортивная площадка всевобуча',
          bool: false
        },
        {
          answer: 'Спортивный Дом Советской Армии',
          bool: true
        }
        ] 
      },
      {
        question: 'Эмблема ЦСКА сегодня',
        type: 'pickImage',
        answers: [{
          answer: '/csk1.png',
          bool: false
        },
        {
          answer: '/csk2.png',
          bool: false
        },
        {
          answer: '/csk3.png',
          bool: true
        }
        ] 
      },
      {
        question: 'С первых же дней Великой Отечественной войны спортивный отдел Центрального Дома Красной Армии изменил направление всей своей деятельности.Армейские атлеты первыми сменили спортивные снаряды на боевое оружие. Ударные лыжные батальоны, группы подрывников и разведчиков воевали на передовой линии фронта и в тылу врага. Те, кто остался в ЦДКА, переключились на работу в пунктах Всевобуча: занимались военно-физической подготовкой резерва действующей армии, готовили инструкторов рукопашного боя, лыжного дела, а также специальных лыжных частей Красной Армии, разведывательных и других подразделений.Кто из первых спортсменов-тяжелоатлетов в Красной Армии, в 1923 году ставший чемпионом СССР по тяжёлой атлетике, 10-12 сентября 1934 года на самолёте совершил рекордный по дальности и продолжительности перелёт по замкнутому маршруту за 75 часов.',
        type: 'pick',
        answers: [{
          answer: 'Громов Михаил Михайлович',
          bool: true
        },
        {
          answer: 'Покрышкин Александр Иванович',
          bool: false
        },
        {
          answer: 'Парфенов Анатолий Иванович',
          bool: false
        }
        ] 
      },
      {
        question: 'Какой известный российский музыкант, являющийся фанатом ЦСКА, написал песню, которую посвятил клубу?',
        type: 'pick',
        answers: [{
          answer: 'Егор Крид',
          bool: false
        },
        {
          answer: 'Shaman',
          bool: false
        },
        {
          answer: 'Баста',
          bool: true
        }
        ] 
      },
      {
        question: 'У футбольного ЦСКА есть свой, известный на всю страну, талисман. Фанаты ЦСКА со стажем утверждают, что впервые этот маскот появился после переезда футбольной команды на собственный стадион на улице Песчаной в начале 1970-х годов.Впрочем, ветераны, которые помнят еще матчи ОППВ в чемпионате Москвы в далекие двадцатые годы, говорят, что талисман – это прямая отсылка к коннице маршала Буденного. Талисман связан с песней тех лет, в которой есть такие слова: «Но от тайги до британских морей Красная Армия всех сильней».Кто или что является талисманов ПФК ЦСКА?',
        type: 'pick',
        answers: [{
          answer: 'подкова',
          bool: false
        },
        {
          answer: 'буденовка',
          bool: false
        },
        {
          answer: 'конь',
          bool: true
        }
        ] 
      },
      {
        question: 'У какого спортсмена ЦСКА было прозвище «Большой медведь»?',
        type: 'pick',
        answers: [{
          answer: 'Георгий Федотов',
          bool: false
        },
        {
          answer: 'Анатолий Тарасов',
          bool: false
        },
        {
          answer: 'Александр Рогулин',
          bool: true
        }
        ] 
      },
      {
        question: 'Чья полевая сумка хранится в музее спортивной славы ЦСКА?',
        type: 'pick',
        answers: [{
          answer: 'Георгия Булочкина',
          bool: false
        },
        {
          answer: 'Бронислава Верниковского',
          bool: true
        },
        {
          answer: 'Павла Бычкова',
          bool: false
        }
        ] 
      },
      {
        question: 'Назовите трехкратного олимпийского чемпиона по хоккею с шайбой, заслуженного мастера спорта, игравшего в составе ЦСКА.',
        type: 'pick',
        answers: [{
          answer: 'Владислав Третьяк',
          bool: true
        },
        {
          answer: 'Александр Овечкин',
          bool: false
        },
        {
          answer: 'Валерий Харламов',
          bool: false
        }
        ] 
      },
      {
        question: 'Какого советского спортсмена родители назвали в честь героя Советского союза Чкалова?',
        type: 'pick',
        answers: [{
          answer: 'Виктора Шувалова',
          bool: false
        },
        {
          answer: 'Валерия Харламова',
          bool: true
        },
        {
          answer: 'Всеволода Боброва',
          bool: false
        }
        ] 
      },
      {
        question: 'Какой фестиваль, посвященный Дню защиты Детей и 100-летию ЦСКА прошел на Центральном стадионе «Арсенал» в Туле в рамках проекта «ЮНАРМИЯ в спорте»?',
        type: 'pick',
        answers: [{
          answer: 'фестиваль по тег-регби',
          bool: true
        },
        {
          answer: 'фестиваль йоги',
          bool: false
        },
        {
          answer: 'вело фестиваль',
          bool: false
        }
        ] 
      },
      {
        question: 'Назовите легендарную российскую гимнастку, двукратную олимпийскую чемпионку, 9-кратную чемпионку мира, 13-кратную чемпионку Европы, заслуженного мастера спорта России, первого заместителя начальника ЦСКА являющуюся одним из учредителей Движения «ЮНАРМИЯ».',
        type: 'pick',
        answers: [{
          answer: 'Ишмуратова Светлана Ирековна',
          bool: false
        },
        {
          answer: 'Слесаренко Елена Владимировна',
          bool: false
        },
        {
          answer: 'Хоркина Светлана Васильевна',
          bool: true
        }
        ] 
      }
    ]
    },
    {title: 'Быстрее! Выше! Сильнее!',
    questionList: [
      {
        question: 'Найдите 1-ый официальный олимпийский талисман.',
        type: 'pickImage',
        answers: [{
          answer: '/talisman1.png',
          bool: false
        },
        {
          answer: '/talisman2.webp',
          bool: false
        },
        {
          answer: '/talisman3.webp',
          bool: true
        }
      ] 
    },
    {
      question: 'Талисман Игр-80 — хорошо знакомый всем добродушный Миша, которого придумал художник Виктор Чижиков. Но впервые в истории Олимпиад отдельный талисман получили яхтсмены — соревнования по парусному спорту проходили в Таллине. Кто же был их символом?',
      type: 'pick',
      answers: [{
        answer: 'тюлень',
        bool: true
      },
      {
        answer: 'выдра',
        bool: false
      },
      {
        answer: 'чайка',
        bool: false
      }
      ] 
    },
      
      {
        question: 'Решение о выборе столицы Олимпийских игр - 1980 МОК (Международный Олимпийский Комитет) принимал в 1974 году в Вене. Членам МОК предстояло сделать выбор между двумя городами. В итоге победу одержала столица СССР город Москва. С каким городом соревновалась Москва?',
        type: 'pick',
        answers: [{
          answer: 'Лондон',
          bool: false
        },
        {
          answer: 'Париж',
          bool: false
        },
        {
          answer: 'Лос-Анджелес',
          bool: true
        }
        ] 
      },
      {
        question: 'Какое название не является названием олимпийского объекта в Сочи?',
        type: 'pick',
        answers: [{
          answer: '«Роза Хутор»',
          bool: false
        },
        {
          answer: '«Ясная поляна» ',
          bool: true
        },
        {
          answer: '«Фишт»',
          bool: false
        }
        ] 
      },
      {
        question: 'В каких городах проходили соревнования Олимпиады - 80?',
        type: 'pick',
        answers: [{
          answer: 'Москва, Ленинград, Киев, Казань, Рига',
          bool: false
        },
        {
          answer: 'Москва, Ленинград, Киев, Минск, Таллин',
          bool: true
        },
        {
          answer: 'Москва, Новгород, Киев, Минск, Вильнюс',
          bool: false
        }
        ] 
      },
      {
        question: 'Какое событие, связанное с Сочинской Олимпиадой, произошло 7 октября 2013 года?',
        type: 'pick',
        answers: [{
          answer: 'начало эстафеты олимпийского огня Сочи – 2014',
          bool: true
        },
        {
          answer: 'открытие Олимпиады – 2014',
          bool: false
        },
        {
          answer: 'запуск часов с обратным отсчетом',
          bool: false
        }
        ] 
      },
      {
        question: 'Какими по счёту зимними олимпийскими играми были игры в Сочи 2014?',
        type: 'pick',
        answers: [{
          answer: 'XXII',
          bool: true
        },
        {
          answer: 'XX',
          bool: false
        },
        {
          answer: 'XXV',
          bool: false
        }
        ] 
      },
      {
        question: 'Сколько лет было нашей фигуристке Юлии Липницкой, которая после завершения командных соревнований по фигурному катанию стала самой юной чемпионкой в истории зимних Олимпиад?',
        type: 'pick',
        answers: [{
          answer: '14 лет',
          bool: false
        },
        {
          answer: '15 лет',
          bool: true
        },
        {
          answer: '16 лет',
          bool: false
        }
        ] 
      },
      {
        question: 'Какой бренд спортивной одежды изготавливал экипировку сборной СССР?',
        type: 'pick',
        answers: [{
          answer: 'adidas',
          bool: true
        },
        {
          answer: 'nike',
          bool: false
        },
        {
          answer: 'reebok',
          bool: false
        }
        ] 
      },
      {
        question: 'Куда улетел Олимпийский мишка?',
        type: 'pick',
        answers: [{
          answer: 'приземлился в Ленинграде',
          bool: false
        },
        {
          answer: 'в сказочный лес',
          bool: false
        },
        {
          answer: 'перелетел Москву-реку и приземлился недалеко от МГУ',
          bool: true
        }
        ] 
      },
      {
        question: 'Каждая Олимпиада начинается с церемонии открытия, и каждый раз она становится одним из самых ярких событий Игр. Чаще всего во время торжественного мероприятия организаторы стараются отдать должное прародителям Олимпийских игр — упомянуть традиции древних греков. Что придумали в Москве в 1980-м?',
        type: 'pick',
        answers: [{
          answer: 'Олимпийскую клятву спортсменов и судей произнесли на греческом',
          bool: false
        },
        {
          answer: 'Во время церемонии открытия по стадиону проехали квадриги с возницами',
          bool: true
        },
        {
          answer: 'Баскетболиста Сергея Белова, который зажег чашу с огнем Олимпиады, одели в древнегреческий костюм',
          bool: false
        }
        ] 
      },
      {
        question: 'Какого слова нет в слогане Зимних Олимпийских игр 2014 года в Сочи?',
        type: 'pick',
        answers: [{
          answer: 'зимние',
          bool: false
        },
        {
          answer: 'жаркие',
          bool: false
        },
        {
          answer: 'классные',
          bool: true
        }
        ] 
      },
      {
        question: 'Где НЕ был Олимпийский огонь XXII Зимних Олимпийских игр?',
        type: 'pick',
        answers: [{
          answer: 'на дне озера',
          bool: false
        },
        {
          answer: 'в подземной пещере',
          bool: true
        },
        {
          answer: 'в космосе',
          bool: false
        }
        ] 
      },
      {
        question: 'Медалей какого достоинства российская сборная завоевала больше всех на Олимпиаде в Сочи?',
        type: 'pick',
        answers: [{
          answer: 'Золото',
          bool: true
        },
        {
          answer: 'Серебро',
          bool: false
        },
        {
          answer: 'Бронза',
          bool: false
        }
        ] 
      },
      {
        question: 'Сколько лет отделяют Зимние Олимпийские игры в Сочи от Летних Олимпийских игр в Москве?',
        type: 'pick',
        answers: [{
          answer: '23',
          bool: false
        },
        {
          answer: '34',
          bool: true
        },
        {
          answer: '45',
          bool: false
        }
        ] 
      },
      {
        question: 'Где проходили XXXII Олимпийские игры, на которых Никита Нагорный завоевал золотую медаль?',
        type: 'pick',
        answers: [{
          answer: 'Токио, Япония',
          bool: true
        },
        {
          answer: 'Рио-де-Жанейро, Бразилия',
          bool: false
        },
        {
          answer: 'Сочи, Россия',
          bool: false
        }
        ] 
      },
      {
        question: 'Какой известный спортсмен-бобслеист, ныне Член Главного Штаба Движения «ЮНАРМИЯ», дважды принимал участие в Олимпийских играх',
        type: 'pick',
        answers: [{
          answer: 'Алексей Воевода',
          bool: false
        },
        {
          answer: 'Дмитрий Труненков',
          bool: true
        },
        {
          answer: 'Никита Нагорный',
          bool: false
        }
        ] 
      },
    ]
    }
  ]

  const articleData = [
    {
      title: 'Спортивный фестиваль «Юнармия в спорте»',
      path: 'sport',
      text: [
        'Спортивный фестиваль «Юнармия в спорте» — спортивные активности, командные квесты, любительские блиц-турниры по футболу, баскетболу и волейболу, сдача норм ГТО, а так же мастер-классы, лекции, автограф-сессии от олимпийских чемпионов.',
        'Первый фестиваль спорта среди отрядов Движения «ЮНАРМИЯ» – «ЮНАРМИЯ в спорте» прошёл в городе Сунжа Республики Ингушетия в октябре 2022 года.',
        'Команды юнармейских отрядов из многих регионов России приняли участие в Фестивале. Ребята соревновались за кубок «Юнармейский атлет». В списке этапов соревнований — сдача норм ГТО, неполная разборка-сборка автомата Калашникова, снаряжение магазина 30 патронами, надевание ОЗК на скорость, дриблинг-паркур, футбольный снайпер, хоккейный снайпер, баскетбольная дуэль и баскетбольный снайпер, а также настольный теннис.',
        'После прохождения всех этапов соревнования участников ждал обед в формате полевой кухни, после чего состоялись мастер-классы, лекции и автограф-сессии от легенд российского спорта — олимпийских чемпионов Никиты Нагорного и Татьяны Лебедевой, а также российского футболиста Руслана Пименова.'
      ]
    },
    {
      title: '«Летние юнармейские игры»',
      path: 'summer',
      text: [
        '«Летние юнармейские игры» — физкультурно-спортивная юнармейская смена в ВДЦ «Орленок».',
        'Мастер-классы от олимпийских чемпионов, командообразующие тренинги, уроки истории России, военно-спортивные игры, тренировки с профессиональным наставниками, все это ждет наших юнармейцев в «Орленке».',
        'Став участником программы, юнармейцы в тренировочном процессе, с профессиональными наставниками, по четырём видам спорта (лёгкой атлетике, плаванию, футболу, пляжному волейболу), смогут развивать морально-волевые качества и профессиональные спортивные навыки.',
        'Категория участников программы: Подростки 12-16, входящие в состав Всероссийского военно-патриотического детско-юношеского общественного движения «ЮНАРМИЯ», занимающиеся в спортивных школах и секциях по лёгкой атлетике, плаванию, мини-футболу, волейболу, имеющие значок ГТО, I, II или III спортивный разряд, звание «Мастер спорта» и «Кандидат в мастера спорта» и другие достижения в указанных видах спорта, любители, занимающиеся спортом и участвующие в муниципальных и школьных соревнованиях, а также принимающие активное участие в жизни Движения.'
      ]
    },
    {
      title: 'Юнармейский фестиваль по тег-регби',
      path: 'regbi',
      text: [
        'Что такое тэг-регби? Это веселая и захватывающая форма бесконтактного регби. Основные отличия от классической игры: упрощенные правила, а также наличие тэгов – двух лент на липучках, которые крепятся к поясу игрока. Цель каждой из команд - положить мяч на или за линию ворот противника.',
        'Интересно? Нам тоже! Именно поэтому, в июне 2023 г. на центральном стадионе «Арсенал» в Туле состоялся фестиваль по тэг-регби. Главной задачей фестиваля было в первую очередь участие: игра в удовольствие и знакомство с регби.',
        'В мероприятии приняли участие 18 команд из 12 регионов России, включая Донецкую и Луганскую Народные Республики.',
        'Участников ожидала яркая регбийная атмосфера, азартные матчи, интересная зона отдыха и активностей для зрителей, армейская полевая каша и памятные медали за участие каждому игроку.'
      ]
    },
  ]

  const data = [
    {link: 'КОРОТКИЙ РОЛИК_с титрами 13.07', name: 'Летние юнармейские игры в ВДЦ "Орленок"'},
    {link: 'Кто такой юнармеец', name: 'Кто такой юнармеец?'},
    {link: 'Фестиваль по тег-регби', name: 'Фестиваль по тег-регби'},
    {link: 'Что такое ЮНАРМИЯ', name: 'Что такое "ЮНАРМИЯ"'},
    {link: 'ЮНАРМИЯ в спорте', name: 'Фестиваль «ЮНАРМИЯ в спорте»'},
    {link: 'ЮНАРМИЯ', name: '4 образа юнармейца'}]
  
  const dataLink = [
    {title: 'Cпортивный фестиваль «ЮНАРМИЯ в спорте»', link: 'article', id: 1},
    {title: '«Летние юнармейские игры»', link: 'article', id: 2},
    {title: 'Юнармейский фестиваль по тег-регби', link: 'article', id: 3},
    {title: 'Юнармейский норматив', link: 'test', id: 4},
    {title: 'Видео', link: 'video', id: 5},
  ]
  
  const [page, setPage] = useState('default');
  const [id, setId] = useState('');

  const handleClick = (el) => {
    setPage(el.link);
    setId(el.id)
  }
  
  const getPage = () => {
    switch (page) {
      case 'video':
        return <Video setPage={setPage} data={data}  pathName='Видео Атлет'/>
      case 'test':
        return <TestWrapper setPage={setPage} data={testData} testTitle={testTitle}/>
      case 'article':
        return <Article setPage={setPage} data={articleData} id={id}/>

      case 'default':
        return (
        <div className={styles.content}>
          <Link to='/panel' className={styles.back}><img src={buttonBack}/></Link>
          <div className={styles.contentImg}>
            <img src={emblemAthlete} className={styles.emblemCreater} />  
            <img src={athleteI} className={styles.creater}/>  
          </div>

          <div className={styles.buttonWrapper}>
            {dataLink.map(el =>
                <div 
                onClick={() => handleClick(el)}
                className={styles[`button${el.id}`]} >
                <span className={styles.buttonTitle}>{el.title}</span>
              </div>
              )}
          </div>
        </div>)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={header} className={styles.headerBg}/>
        <div className={styles.headerInfo}>
          <img src={logo} className={styles.logo}/>
          <div className={styles.titleBlock}>
            <div className={styles.titleH2}>ВСЕРОССИЙСКОЕ ВОЕННО-ПАТРИОТИЧЕСКОЕ <br/>ОБЩЕСТВЕННОЕ ДВИЖЕНИЕ</div>
            <div className={styles.titleH1}>"ЮНАРМИЯ"</div>
          </div>
        </div>
      </div>

      {getPage()}
    </div>
  )
}

export default Athlete
import React, { useEffect, useMemo } from 'react'
import styles from './ActivityRatingReport.module.css'
import { useDispatch, useSelector } from "react-redux"
import { createActivityRating, getActivityRating, getActivityRatingAllClear, getActivityRatingLoadAllClear, getActivityRatingNew, getCheckStatus, getDownloadExcel } from '../../actions/reports'
import { getRegions } from '../../actions/users'
import { getDepartments, getDepartmentsByRegion } from "../../actions/departments";
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import { useState } from 'react'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../components/Loader/Loader'
import NoData from '../../components/NoData/NoData'
import { getRegionOptions } from '../../utils/getOptions'
import CustomDatepicker from '../../components/CustomDatepicker/CustomDatepicker'
import { BiSortUp, BiSortDown } from "react-icons/bi";

import { createNotification } from "../../components/Notifications/Notifications";
import { getExcelFromTable } from "../../utils/excel";
import { RiFileExcel2Line } from "react-icons/ri";


const initialParams = {
    regionId: 'all',
    domestic_departmentId: 'all',
    detachmentId: 'all',
    date_from: null,
    date_to: null,
    order_by: null,
    desc: null
}

const tableTitles = [
  {name: '№ п/п', sortValue: ''},
  {name: 'ФИО', sortValue: 'fio'},
  {name: 'Возраст', sortValue: 'birthday'},
  {name: 'Личный номер', sortValue: 'personal_id'},
  {name: 'Регион', sortValue: 'region'},
  {name: 'Название отряда', sortValue: 'detachment_name'},
  {name: 'Выполнено заданий / баллы', sortValue: 'task_count'},
  {name: 'Выполнено заданий по КДЕД / баллы', sortValue: 'calendar_ded_points'},
  {name: 'Опубликовано личных событий / Количество лайков за эти события', sortValue: 'streams_publication'},
  {name: 'Количество поставленных лайков', sortValue: 'streams_liking'},
  {name: 'Количество просмотренных цифровых курсов', sortValue: 'digital_academy_visit'},
  {name: 'Гимнастика (кол-во завершенных 10-ти дневных циклов/количество начисленных баллов)', sortValue: 'count_fitness_streak'},
  {name: 'Участие в мероприятиях', sortValue: 'activities_participation'}
]

const SUM_FIELDS = ['task_count', 'tasks_completion', 'calendar_ded_count', 'calendar_ded_points', 'streams_publication', 'personal_streams_likes', 'streams_liking', 'digital_academy_visit', 'count_fitness_streak', 'fitness_score', 'activities_participation'];

export default function ActivityRatingReport() {
  
    const { regionList: regions } = useSelector(state => state.users)
    const { activity_rating: data, loading, hash, check, newData, loadingAll, allData  } = useSelector(state => state.reports)
    const { depsByReg, detachments } = useSelector(state => state.departments)
    const { userRegions, superuser } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const getInitialParams = () => {
      const regionId = superuser ? 'all' : userRegions[0]
      parseInt(regionId) && dispatch(getDepartmentsByRegion(regionId))
      return {...initialParams, regionId}
    }

    const [params, setParams] = useState(getInitialParams)
    const [isCheck, setIsCheck] = useState(false)

    useEffect(() => {
        dispatch(getActivityRating(params, true))
        !regions.length && dispatch(getRegions())

        return () => {
          dispatch(getActivityRatingAllClear())
          dispatch(getActivityRatingLoadAllClear())
        } 
    }, [params])

    const renderTh = ({name, sortValue}) => {
      const sortByThis = sortValue && sortValue === params.order_by
      return (
        <th onClick={handleThClick(sortValue)}>
          {name}
          {sortByThis && (params.desc ? <BiSortUp/> : <BiSortDown/>)}
        </th>
      )
    }

    const handleThClick = (order_by)  => () => {
      const reClick = params.order_by === order_by
      const desc = !reClick || (reClick && params.desc) ? null : 'desc'
      setParams(prev => ({...prev, order_by, desc}))
    }

    const handleParamsChange = (e) => {
        setParams(prev => ({...prev, [e.target.name]: e.target.value}))
    }
    
    const handleChangeRegion = (e) => {
        const {value} = e.target
        handleParamsChange(e)
        setParams(prev => ({...prev, domestic_departmentId: "all", detachmentId: "all"}))
        value !== 'all' && dispatch(getDepartmentsByRegion(value))
    }
    
    const handleChangeDomDep = (e) => {
        const {value} = e.target
        handleParamsChange(e)
        setParams(prev => ({...prev, detachmentId: "all"}))
        value !== 'all' && dispatch(getDepartments(value, true))
    }
        
    const getMoreItems = () => {
        dispatch(getActivityRating(params))
    }

    const openProfilePage = (profile) => {
        const link = document.createElement('a')
        link.setAttribute('href', `/profile/${profile.profile_id}`);
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener norefferrer');
        link.click()
    }
    
    const domDepsArray = depsByReg?.map(({name, id}) => ({name, value: id})) || []
    const detachArray = detachments?.map(({name, id}) => ({name, value: id})) || []

    const getRegionName = () => {
      if (params.regionId === 'all' || !regions?.length) return ''
      const {federalUnit, regionName: name} = regions.find(reg => reg.id == params.regionId)
      return `по региону "${name} ${federalUnit}"`
    }

    const handleDownloadExcel = (e) => {
      if (isCheck) {
          e.stopPropagation()
        } else {
          setIsCheck(true);
          dispatch(createActivityRating(params, true))
        }
    }

    useEffect(() => {
      if (loadingAll === false) {
        if (allData && allData.length > 0) {
          const table = document.getElementById("analyticalReportForExcel")
          getExcelFromTable(table, 'Рейтинг активности юнармейцев')
        } else {
          createNotification('error', 'В списке нет ни одной активности')
        } 
      } 
    }, [allData, loadingAll])

    const getRegionList = useMemo(() => {
      const filteredRegions = superuser ? regions : regions.filter(({id}) => userRegions.includes(id))
      const regionList = getRegionOptions(filteredRegions)
      superuser && regionList.unshift({name: "Все", value: "all"})
      return regionList
    }, [regions])

      useEffect(() => {
        // if (newData && newData.length) return createNotification('error', 'Ошибка загрузки xlsx файла')
          if (!newData || newData.length === 0) {
            // return createNotification('error', 'Ошибка загрузки xlsx файла')
          } else {
            const a = document.createElement('a')
            a.href = newData
            a.download = `Рейтинг активности юнармейцев.xlsx`
            a.click()
            
            dispatch({type: 'SET_ACTIVITY_RATING_DATA_NEW', payload: null}) 
            dispatch({type: 'SET_CHECK_STATUS', payload: null})
            dispatch({type: 'SET_ACTIVITY_RATING_DATA_DOWNLOAD', payload: null})
          }
      }, [newData])

    useEffect(() => {
      let timer = null; 
      if (isCheck) {
      if (check && check?.status && check?.status == 'READY') {
      setIsCheck(false);

      dispatch(getDownloadExcel(hash))
      
      } else if (check && check?.status && check?.status == 'ERROR') {
          setIsCheck(false);
          createNotification('error', 'Ошибка');
          setIsCheck(false);
      } else {
      timer = setTimeout(() => {
      hash && dispatch(getCheckStatus(hash));
      }, 3000);
      }
      }
      return () => clearTimeout(timer);
    }, [check, hash, isCheck]); 

    const hasMore = data?.length && !(data.length%100)

    
    const sumRow = useMemo(() => data?.reduce((acc, item) => {
      SUM_FIELDS.forEach(el => acc[el] = (acc[el] || 0) + item[el])
      return acc
    }, {}), [hasMore])
    
    return (
    <div className={styles.wrapper}>
        <div className={styles.filtersBlock}>
            <div>
              <CustomSelect
                defaultValue={'all'}
                width="500px"
                title="Выберите регион"
                selectedValue={params.regionId}
                options={getRegionList}
                onChange={handleChangeRegion}
                name='regionId'
                withoutFirst
                />
              
              <CustomSelect
                width="500px"
                title="Выберите местное отделение"
                options={[{name: "Все", value: "all"}, ...domDepsArray]}
                onChange={handleChangeDomDep}
                name='domestic_departmentId'
                selectedValue={params.domestic_departmentId}
                disabled={params.regionId === 'all'}
                withoutFirst
                />
              
              <CustomSelect
                width="500px"
                title="Выберите отряд"
                options={[{name: "Все", value: "all"}, ...detachArray]}
                onChange={handleParamsChange}
                name='detachmentId'
                selectedValue={params.detachmentId}
                disabled={params.regionId === 'all' || params.domestic_departmentId === 'all'}
                withoutFirst
              />
            </div>
            <div>
              <CustomDatepicker
                  value={params.date_from}
                  title='Дата с'
                  name='date_from'
                  onChange={handleParamsChange}
              />
              
              <CustomDatepicker
                  value={params.date_to}
                  title='Дата по'
                  name='date_to'
                  onChange={handleParamsChange}
              />
            </div>
        </div>
        <div 
          className={styles.buttomExcel} 
          style={{cursor: `${isCheck ? 'not-allowed' : ''}`}} 
          onClick={(e) => handleDownloadExcel(e)}>
            <i>{isCheck ? <Loader size={20} color = "#fff"/> : <RiFileExcel2Line/>}</i> <span>Скачать в Excel</span>
          </div>
        
            <div className={styles.titleBlock}>
                <h3>Рейтинг активности юнармейцев</h3>
                <div>{`${getRegionName()} по состоянию на ${moment().format("DD.MM.YYYY")} года`}</div>
            </div>
            {loading
                ? <Loader/>
                : (data?.length
                    ? <div className={styles.tableBlock}>
                        <InfiniteScroll
                            dataLength={data.length}
                            next={getMoreItems}
                            hasMore={hasMore}
                            loader={<h4>Загрузка еще 100 профилей...</h4>}
                            >
                            <table id="analyticalReport">
                                <thead>
                                    <tr>{tableTitles.map(renderTh)}</tr>
                                </thead>
                                <tbody>
                                    {data.map((it, i) => {
                                        return (
                                            <tr key={i}>
                                                <th>{++i}</th>
                                                <th onClick={() => openProfilePage(it)}>{it.fio}</th>
                                                <td>{moment().diff(it.birthday, 'years')}</td>
                                                <td>{it.personal_id}</td>
                                                <td>{it.region}</td>
                                                <td>{it.detachment_name}</td>
                                                <td>{it.task_count}/{it.tasks_completion}</td>
                                                <td>{it.calendar_ded_count}/{it.calendar_ded_points}</td>
                                                <td>{it.streams_publication}/{it.personal_streams_likes}</td>
                                                <td>{it.streams_liking}</td>
                                                <td>{it.digital_academy_visit}</td>
                                                <td>{it.count_fitness_streak}/{it.fitness_score}</td>
                                                <td>{it.activities_participation}</td>
                                            </tr>
                                        )
                                    })}
                                    {!hasMore &&
                                      <tr>
                                        <th colSpan={2}>Итого:</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <td>{sumRow.task_count}/{sumRow.tasks_completion}</td>
                                        <td>{sumRow.calendar_ded_count}/{sumRow.calendar_ded_points}</td>
                                        <td>{sumRow.streams_publication}/{sumRow.personal_streams_likes}</td>
                                        <td>{sumRow.streams_liking}</td>
                                        <td>{sumRow.digital_academy_visit}</td>
                                        <td>{sumRow.count_fitness_streak}/{sumRow.fitness_score}</td>
                                        <td>{sumRow.activities_participation}</td>
                                      </tr>
                                    }
                                </tbody>
                            </table>
                            <table id="analyticalReportForExcel" hidden>
                                <thead>
                                    <tr><th>Рейтинг активности юнармейцев</th></tr>
                                    <tr><th>{`${getRegionName()} по состоянию на ${moment().format("DD.MM.YYYY")} года`}</th></tr>
                                    <tr>{tableTitles.map(renderTh)}</tr>
                                </thead>
                                <tbody>
                                    {allData.map((it, i) => {
                                        return (
                                            <tr key={i}>
                                                <th>{++i}</th>
                                                <th onClick={() => openProfilePage(it)}>{it.fio}</th>
                                                <td>{moment().diff(it.birthday, 'years')}</td>
                                                <td>{it.personal_id}</td>
                                                <td>{it.region}</td>
                                                <td>{it.detachment_name}</td>
                                                <td>{it.task_count}/{it.tasks_completion}</td>
                                                <td>{it.calendar_ded_count}/{it.calendar_ded_points}</td>
                                                <td>{it.streams_publication}/{it.personal_streams_likes}</td>
                                                <td>{it.streams_liking}</td>
                                                <td>{it.digital_academy_visit}</td>
                                                <td>{it.activities_participation}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <th colSpan={2}>Итого:</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <td>{sumRow.task_count}/{sumRow.tasks_completion}</td>
                                        <td>{sumRow.calendar_ded_count}/{sumRow.calendar_ded_points}</td>
                                        <td>{sumRow.streams_publication}/{sumRow.personal_streams_likes}</td>
                                        <td>{sumRow.streams_liking}</td>
                                        <td>{sumRow.digital_academy_visit}</td>
                                        <td>{sumRow.activities_participation}</td>
                                      </tr>
                                </tbody>
                            </table>
                        </InfiniteScroll>
                      </div>
                    : <NoData text='данные по региону отсутствуют'/>
                  )
            }
    </div>
  )
}

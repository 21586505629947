/* eslint-disable */
import { AUTH_FAILED } from './types';
import { AUTH_ERROR_MESSAGES as errors } from '../utils/const';

const requestMiddleware = store => next => (action) => {
  const result = next(action);
  const response = store.getState().auth.ERROR;
  if (
    response &&
    (/* response.status === 403 || */ response.status === 401)
  ) {
    store.dispatch({ type: AUTH_FAILED });
  }
  return result;
};

export default requestMiddleware;

import React, {useState} from 'react'
import styles from "./TestWrapper.module.css";
import Test from './Test/Test'
import buttonBack from '../img/buttonBack.png'

const TestWrapper = ({data, setPage, testTitle}) => {
  
  const [isTest, setIsTest] = useState([]);
  const [isWhich, setisWhich] = useState(false);

  const which = [
    {title: 'Пройди тест и узнай, каким юнармейцем тебя видят окружающие. Ответь на 10 вопросов и определи свои сильные стороны. Вперёд!',
    type: 'which',
    questionList: [
      {
        question: 'На школьной доске объявлений ты видишь анонсы мероприятий. В каком из них будешь участвовать с наибольшим интересом?',
        type: 'pick',
        img: '/which_1.png',
        answers: [{
          answer: '«Хвостики ищут дом» 🐦 — выставка-раздача бездомных животных. Приглашаются школьники для помощи в организации ✨ мероприятия.',
          bool: 'Защитник'
        },
        {
          answer: '«Субботний 🏅 триатлон» — соревнование для школьников по трём видам спорта: по бегу, прыжкам в длину и метанию мяча.',
          bool: 'Атлет'
        },
        {
          answer: 'Межпредметная олимпиада «Умное поколение» 📚.',
          bool: 'Исследователь'
        },
        {
          answer: '«Весёлые старты для всей семьи». Приглашаются школьники с родителями для участия в 👪 семейном конкурсе.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Вашему отряду поручили создать видеоролик о вашей деятельности. Чем ты сможешь помочь?',
        type: 'pick',
        img: '/which_2.png',
        answers: [{
          answer: 'Подготовлю оборудование 🎥 , предложу помощь в качестве видеооператора...',
          bool: 'Защитник'
        },
        {
          answer: 'Составлю сценарий, 🙇‍♂️ организую людей для съёмки, предложу помощь в качестве главного героя ролика.',
          bool: 'Атлет'
        },
        {
          answer: 'Проведу интервью, предложу помощь в качестве монтажёра видео.',
          bool: 'Исследователь'
        },
        {
          answer: 'Подберу локации и договорюсь 🗣 , чтобы нас туда пропустили, предложу помощь в качестве идейного вдохновителя.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Какое описание наиболее точно тебя характеризует?',
        type: 'pick',
        img: '/which_3.png',
        answers: [{
          answer: 'Готов двигаться вперёд, стремлюсь быть лучше и работаю над этим 🚀 , ставлю цели, отстаиваю свои интересы. Меня считают решительным, независимым, сильным 🐯 и надёжным человеком.',
          bool: 'Защитник'
        },
        {
          answer: 'Соблюдаю распорядок дня, слежу за выполнением другими своих обязанностей, беру инициативу 🙋‍♀ в свои руки. Меня считают хорошим организатором и ответственным человеком.',
          bool: 'Атлет'
        },
        {
          answer: 'Изучаю, думаю 🌍 , читаю, обсуждаю, делаю выводы. Меня считают серьёзным и образованным человеком 🧠',
          bool: 'Исследователь'
        },
        {
          answer: 'Всегда помогаю другим, забочусь о животных 🐊 , интересуюсь настроением близких. Меня считают дружелюбным, надёжным и отзывчивым человеком.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Тебе поручили организовать в классе юнармейский праздник. Что будешь делать в первую очередь?',
        type: 'pick',
        img: '/which_4.png',
        answers: [{
          answer: 'Составлю план с точными 📅 сроками.',
          bool: 'Защитник'
        },
        {
          answer: 'Выберу интересные подвижные игры и конкурсы 👌 для праздника.',
          bool: 'Атлет'
        },
        {
          answer: 'Составлю список того 📋 , что нужно собрать или купить, посчитаю расходы.',
          bool: 'Исследователь'
        },
        {
          answer: 'Подумаю ✍️ , что смогу сделать самостоятельно и чья помощь мне понадобится.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Ты постоянный автор школьной газеты. О чём ты обычно пишешь?',
        type: 'pick',
        img: '/which_5.png',
        answers: [{
          answer: '📚 Сочиняю остросюжетные детективные истории.',
          bool: 'Защитник'
        },
        {
          answer: 'Пишу полезные статьи 📑 о здоровых привычках и правильном питании.',
          bool: 'Атлет'
        },
        {
          answer: '📋 Составляю кроссворды, ребусы, головоломки.',
          bool: 'Исследователь'
        },
        {
          answer: 'Веду колонку ответов на вопросы 🧾 школьников.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Впереди ответственное мероприятие. Что ты делаешь, чтобы меньше волноваться?',
        type: 'pick',
        img: '/which_6.png',
        answers: [{
          answer: '⏱️ Делаю вдох-выдох, вдох-выдох, выпрямляясь, расправляю плечи.',
          bool: 'Защитник'
        },
        {
          answer: 'Пью травяной чай ☕ , делаю точечный массаж.',
          bool: 'Атлет'
        },
        {
          answer: 'Думаю 💤 😎 о постороннем: вспоминаю таблицу умножения, любимое стихотворение или дни рождения друзей.',
          bool: 'Исследователь'
        },
        {
          answer: 'Пишу свои мысли в блокнот 🎨 или личный дневник. Чем больше напишу, тем спокойнее буду себя чувствовать.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Ваш отряд собирается в туристический поход на природу. Руководителю нужна помощь в организации этого мероприятия. Есть несколько важных задач, при выполнении которых ты можешь помочь. Что выберешь?',
        type: 'pick',
        img: '/which_7.png',
        answers: [{
          answer: '🗺 Составление маршрута и контроль его прохождения.',
          bool: 'Защитник'
        },
        {
          answer: 'Установка режима дня и 🕵 контроль за его соблюдением.',
          bool: 'Атлет'
        },
        {
          answer: 'Ведение прямых эфиров в соцсетях, 📋 размещение познавательного контента.',
          bool: 'Исследователь'
        },
        {
          answer: 'Установление связей 🚵‍♂️ 🚴‍♂️ между членами туристической группы и разрешение конфликтных ситуаций.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Утро. Пора собираться на занятия. Что ты наденешь?',
        type: 'pick',
        img: '/which_8.png',
        answers: [{
          answer: '🎒 Школьную форму.',
          bool: 'Защитник'
        },
        {
          answer: '👟 Главное — кроссовки.',
          bool: 'Атлет'
        },
        {
          answer: '🎅 Что угодно, лишь бы были карманы.',
          bool: 'Исследователь'
        },
        {
          answer: '👔 Деловой костюм.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Представь свой идеальный выходной день. Какими событиями он будет наполнен?',
        type: 'pick',
        img: '/which_9.png',
        answers: [{
          answer: 'Поеду в исторический музей 🏛, потом — на мастер-класс по конструированию оружия из бумаги.',
          bool: 'Защитник'
        },
        {
          answer: 'Сделаю зарядку, прогуляюсь 🌳 с родителями в лесу или парке, поиграю с друзьями в футбол.',
          bool: 'Атлет'
        },
        {
          answer: 'Буду собирать пазлы из тысячи кусочков, дочитаю интересную 📔 книгу.',
          bool: 'Исследователь'
        },
        {
          answer: 'Весь день проведу с друзьями 💯 на улице, вечером поеду к брату на день рождения.',
          bool: 'Созидатель'
        }
        ] 
      },
      {
        question: 'Каждое утро ты преодолеваешь путь от дома до школы. Как ты это делаешь? Чем занимаешься по пути?',
        type: 'pick',
        img: '/which_10.png',
        answers: [{
          answer: 'Смотрю по сторонам 🧐 , мысленно отмечаю, что изменилось вокруг.',
          bool: 'Защитник'
        },
        {
          answer: 'Я бегу! Стараюсь использовать это время для физического развития 😜',
          bool: 'Атлет'
        },
        {
          answer: 'Наблюдаю за природой ☘ , фотографирую её обитателей 🐥 , собираю всё, что привлекает моё внимание.',
          bool: 'Исследователь'
        },
        {
          answer: 'Встречаю приятеля 👬 , делюсь с ним новыми идеями.',
          bool: 'Созидатель'
        }
        ] 
      },
    ]
  }
  ] 

  const getTest = () => {
    return <Test data={isTest} setIsTest={setIsTest} isWhich={isWhich} setisWhich={setisWhich}/>
  }

  const handleWhich = () => {
    setisWhich(true);
    setIsTest(which[0].questionList);
  }

    return (
    <div className={styles.wrapper}>
      <div className={styles.content}>

        {
          isTest && isTest.length > 0 ?
          getTest()
          :
          <>
            <div className={styles.title}>{testTitle}</div>
            <div className={styles.postTitle}>Пройди тест и узнай каким юнармейцем видят тебя окружающие. Ответь на вопросы и определи свои сильные стороны. Вперед!</div>
            <div className={styles.item} onClick={() => handleWhich()}>{which[0].title}</div>
            {data.map(el =>
              <div className={styles.item} onClick={() => setIsTest(el.questionList)}>{el.title}</div>
            )}
            <img src={buttonBack} className={styles.buttonBack} onClick={() => setPage('default')}/>
          </>
        }
      </div>
    </div>
  )
}

export default TestWrapper
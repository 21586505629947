import React from 'react'
import styles from './Panel.module.css'
import logo from './img/logo.png'
import header from './img/header.png'
import emblemAthlete from './img/emblemAthlete.png'
import emblemCreater from './img/emblemCreater.png'
import emblemDefender from './img/emblemDefender.png'
import emblemResearcher from './img/emblemResearcher.png'
import {Link} from 'react-router-dom'

const Panel = () => {
  return (
    <div className={styles.wrapper}>
        <div className={styles.header}>
            <img src={header} className={styles.headerBg}/>
            <div className={styles.headerInfo}>
            <img src={logo} className={styles.logo}/>
            <div className={styles.titleBlock}>
                <div className={styles.titleH2}>ВСЕРОССИЙСКОЕ ВОЕННО-ПАТРИОТИЧЕСКОЕ <br/>ОБЩЕСТВЕННОЕ ДВИЖЕНИЕ</div>
                <div className={styles.titleH1}>"ЮНАРМИЯ"</div>
            </div>
            </div>
        </div>
        <div className={styles.doptitle}>Цифровой Дом "ЮНАРМИИ"</div>
        <div className={styles.mainWrapper}>
        <div className={styles.wrapperButtons}>
            <div className={styles.buttonsList}>
                <Link to='/panel/athlete'><img src={emblemAthlete} /></Link>
                <Link to='/panel/creator'><img src={emblemCreater} /></Link>
            </div>
            <div className={styles.buttonsList}>
                <Link to='/panel/defender'><img src={emblemDefender} /></Link>
                <Link to='/panel/researcher'><img src={emblemResearcher} /></Link>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Panel
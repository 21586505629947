import React, {useEffect, useState} from 'react'
import styles from "./Compare.module.css";

const Compare = ({question, setStep, setPoints}) => {

  const [isCheck, setIsCheck] = useState(null)
  const [isType, setIsType] = useState(null)
  const [localQuestion, setLocalQuestion] = useState(question)
  const [isPoint, setIsPoint] = useState(0)

  useEffect(() => {
    if (localQuestion == null) {
        setStep(prev => (prev + 1))
    }
  }, [localQuestion])  

    useEffect(() => {
        if (isPoint == question?.answersL?.length) {
            setPoints(prev => prev + 1)
        }
    }, [isPoint])


    const handleCheck = (id, type) => {
        if (isCheck && type != isType) {

            if (1) {
                let newVal = localQuestion;
                let answersL = newVal.answersL.filter(el => el.id != id);
                let answersR = newVal.answersR.filter(el => el.id != id);

                if (isCheck == id) {
                    setIsPoint(isPoint + 1)
                    setIsCheck(null)
                }

                if (answersL.length == 0) {
                    setLocalQuestion(null)        
                    setIsCheck(null)
                    setIsType(null)
                } else {
                    setLocalQuestion(prev => ({...prev, answersL: answersL, answersR: answersR}))
                    setIsCheck(null)
                    setIsType(null)
                }
            }
        } else {
            setIsCheck(id)
            setIsType(type)
        }
    }
    
    return (
    <div className={styles.wrapper}>
        <div className={styles.answersL}>
            {localQuestion?.answersL?.map(el => <div key={el.id} onClick={() => handleCheck(el.id, 'left')}>{el.answer}</div>)}
        </div>
        <div className={styles.answersR}>
            {localQuestion?.answersR?.map(el => <div key={el.id} onClick={() => handleCheck(el.id, 'right')}>{el.answer}</div>)}
        </div>
    </div>
  )
}

export default Compare
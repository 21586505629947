import React from 'react'
import { useState } from 'react'
import Modal from 'react-responsive-modal'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed'
import Badges from '../Badges/Badges'
import styles from './Unarmiyans.module.css'
import { AiFillPrinter } from "react-icons/ai";
import Konva from '../../components/Konva/Konva'
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react'
import moment from 'moment'
import { ConsoleWriter } from 'istanbul-lib-report'
import { textPlugs } from '../../utils/const'



export default function CurrentProfilesPrintBadges({
  currentProfiles,
  onClose,
  open,
  fromEvents
}) {
  
  const [badgeForPrint, setBadgeForPrint] = useState(null)
  const [printProfiles, setPrintProfiles] = useState(null)

  const componentRef = useRef(null)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  
  const printButtonClick = () => {
    if (badgeForPrint && !printProfiles) {
      
      const fioData = textPlugs[0].split(' ').join('\n')
      
      const photoIndex = badgeForPrint.conf.components.findIndex(comp => comp.data==='/img/photoplace.png')
      const fioIndex = badgeForPrint.conf.components.findIndex(comp => comp.data===fioData)
      const regionIndex = badgeForPrint.conf.components.findIndex(comp => comp.data==='Регион')
      const dateIndex = badgeForPrint.conf.components.findIndex(comp => comp.data==='Дата')
      const eventIndex = badgeForPrint.conf.components.findIndex(comp => comp.data==='Мероприятие')
      const localDepIndex = badgeForPrint.conf.components.findIndex(comp => comp.data==='Местное отделение')
      const detachmentIndex = badgeForPrint.conf.components.findIndex(comp => comp.data==='Отряд')
      const printList = currentProfiles.map(profile => profileForPrint(
        profile,
        photoIndex,
        fioIndex,
        regionIndex,
        dateIndex,
        eventIndex,
        localDepIndex,
        detachmentIndex
      ))
      setPrintProfiles(printList)
    } else {
      handlePrint()
    }
  }

  const profileForPrint = (
    profile,
    photoIndex,
    fioIndex,
    regionIndex,
    dateIndex,
    eventIndex,
    localDepIndex,
    detachmentIndex
  ) => {
    let photoLink
    if (fromEvents) {
      const photo = profile.profile_photo
      photoLink = photo && `${photo.path}${photo.name}`
    } else {
      const photoList = photoIndex>-1 && profile.images && profile.images.filter(img => img?.group === "photo")
      photoLink = photoList?.length && `${photoList[0].path}${photoList[0].name}`
    }
    const badge = JSON.parse(JSON.stringify(badgeForPrint.conf))
    photoIndex > -1 && photoLink && (badge.components[photoIndex].data = photoLink)
    fioIndex > -1 && (badge.components[fioIndex].data = `${profile.lastName}\n${profile.firstName}\n${profile.fathersName}`)
    regionIndex > -1 && (badge.components[regionIndex].data = profile.region_name)
    dateIndex > -1 && (badge.components[dateIndex].data = moment().format('DD.MM.YYYY'))
    eventIndex > -1 && (badge.components[eventIndex].data = profile.article_name)
    localDepIndex > -1 && (badge.components[localDepIndex].data = profile.domestic_department_name)
    detachmentIndex > -1 && (badge.components[detachmentIndex].data = profile.detachment_name)
    return badge
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{
        closeButton: styles.closeBtn,
        modal: styles.modal
      }}
    >
      <div className={styles.printBadgesWrapper}>
        <h3>{`Печать бейджей (выбрано профилей: ${currentProfiles.length})`}</h3>
        {printProfiles
        ? <div className={styles.printPreviewWrap} >
            <div className={styles.printPreviewBlock} ref={componentRef}>
              {printProfiles.map((profile, ind) => {
                return (
                  <>
                    <div key={ind}>
                      <Konva
                        data={profile}
                        badgeSizes={profile.size || {width: '525px', height: '750px'}}
                      />
                    </div>
                    {!((ind+1)%4) && <div style={{pageBreakAfter: "always"}}/>}
                  </>
                )
              }
                )
              }
            </div>
        </div>
        : <Badges
            badgeForPrint={badgeForPrint}
            setBadgeForPrint={setBadgeForPrint}
            fromUnarmians
          />
        }
        <div className={styles.printButtonsBlock}>
          <ButtonRed
            onClick={printButtonClick}
            style={{fontSize: '20px', padding: '.5rem 1rem', margin: '1rem auto'}}
          >
            <span style={{margin: '0 .5rem 0 0'}}>Печать</span>
            <AiFillPrinter/>
          </ButtonRed>
          {printProfiles &&
          <ButtonRed
            onClick={() => setPrintProfiles(null)}
            style={{fontSize: '20px', padding: '.5rem 1rem', margin: '1rem auto'}}
          >
            Отмена
          </ButtonRed>}
        </div>
      </div>
    </Modal>
  )
}

import React, { useState, useEffect, useRef } from "react";
import styles from "./AddEvent.module.css";
import { IoIosArrowRoundBack, IoMdTrash } from "react-icons/io";
import { RiFileExcel2Line } from "react-icons/ri";
import FindField from "../../assets/common/FindField/FindField";
import { BACKEND_DOMAIN, requestStatuses, selects } from "../../utils/const";
import { useReactToPrint } from 'react-to-print';
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CurrentProfilesPrintBadges from "../Unarmiyans/CurrentProfilesPrintBadges";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { getExcelFromTable } from "../../utils/excel";
import { boolean } from "yup";
import CheckMode from "./CheckMode/CheckMode";
import { useSelector } from "react-redux";


const conformity = {
  knowledge_percent: 'Знания',
  motivation_percent: 'Мотивация',
  skill_percent: 'Умения',
  responsibility_percent: 'Ответственность',
  ick_content_percent: 'ИЦК в сфере контента',
  ick_communication_percent: 'ИЦК в сфере коммуникации',
  ick_technosphere_percent: 'ИЦК в техносфере',
  ick_consumption_percent: 'ИЦК в сфере потребления',
  ick_percent: 'ИЦК',
  task_6: 'Задание 6',
  task_7: 'Задание 7',
  task_8: 'Задание 8',
  task_9: 'Задание 9',
  task_10: 'Задание 10'
}

export default function RequestsMode({
  setRequestsMode,
  eventRequests,
  initialValues,
  editEventRequest,
  getQuestionAnswers,
  removeEventRequest,
  answers,
  loading,
  loadingbut,
  excelList,
  getListForExcel,
  regionsList,
}) {

  const { eventStages } = useSelector(state => state.schedule)

  const [displayModal, setDisplayModal] = useState(null);
  const [visibleStages, setVisibleStages] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [_eventRequests, setEventRequests] = useState([])
  const [printBadgesMode, setPrintBadgesMode] = useState(false)
  const [confirmation, setConfirmation] = useState(null)
  const [refusalDescriptionMode, setRefusalDescriptionMode] = useState(null)
  const [checkMode, setCheckMode] = useState(null);

  const table = useRef();
  const componentRef = useRef()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    let arr
    if (eventRequests) {
      if (searchInput) {
        arr = eventRequests.filter(it =>
          it.lastName.toLowerCase().includes(searchInput) ||
          it.firstName.toLowerCase().includes(searchInput) ||
          it.fathersName.toLowerCase().includes(searchInput) ||
          it.personalId.includes(searchInput)
        )
      } else {
        arr = eventRequests
      }
    }
    setEventRequests(arr)
  }, [eventRequests, searchInput])
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  
  const handleChangeStatus = (id, i) => (e) => {
    const status = e.target.value
    const value = { status: status };
    editEventRequest(initialValues?.id, id, value);
    status==3 && setRefusalDescriptionMode(i)
  };

  const handleChangeDescription = (id, description) => {
    const value = { description: description }
    editEventRequest(initialValues?.id, id, value);
  }

  const handleRequestAnswers = (i, reqId, profileId, fromRow) => {
    setDisplayModal(i);
    setVisibleStages([])
    fromRow && getQuestionAnswers(reqId, profileId);
  };

  const toggleVisibleStages = (ind) => {
    if (visibleStages.includes(ind)) {
      const arr = [...visibleStages]
      const _arr = arr.filter(it => it!==ind)
      setVisibleStages(_arr)
    } else {
      setVisibleStages(prev => [...prev, ind])
    }
  }

  useEffect(() => {
    getListForExcel(initialValues?.id);
  }, [])

  const giveExcel = () => {
    const table = document.getElementById("table")
    getExcelFromTable(table, 'Заявки на участие в мероприятие')
  }

  const acceptConfirmation = async () => {
    const {profileId, stageId} = confirmation
    await removeEventRequest(stageId, profileId)
    setConfirmation(null)
  }

  const handleTrashClick = (profileId, stageId) => (e) => {
    e.stopPropagation()
    setConfirmation({
      profileId: profileId,
      stageId: stageId
    })
  }

  const closeAllModals = () => {
    setDisplayModal(null)
    setRefusalDescriptionMode(null)
  }
  const renderAnswer = (answer) => {
    switch (answer.type) {
          case "file":
        return answer.answer
          ? 
          <>
          <a
          href={`${BACKEND_DOMAIN || window.location.origin}/${answer.answer}`}
          target="_blank"
          rel="noopener noreferrer"
          >
          файл
          </a>
          </>
          : 'Нет ответа'
      case "url":
        return answer.answer
          ? <a
              href={`${answer.answer.includes('http') ? '' : '//'}${answer.answer}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {answer.answer}
            </a>
          : 'Нет ответа'
      case "dnd":
          return answer.answer
            ? 'Верно'
            : 'Неверно'
      case "other":
        return `${answer.param}: ${answer.value}`
      case "choice":
           return answer.answer
              ? 
             <>
             {answer?.answer?.map((answ)=>
              <div style={{paddingRight:"10px"}}>
              <p>{answ}</p>
              </div>
              )}
              </>
             
              
             : "Нет ответа"
      default:
          return answer.answer.length
          ? 
          answer.answer.map(el =>
            <div style={{paddingRight:"10px"}}>
            <p>{el}</p>
            </div>
            )
            : "Нет ответа"
          
          }
  }
  
  

  const renderAnswers = (profileId) => (stage, index) => {
    const answers = Array.isArray(stage.questions)
      ? stage.questions.map(question => {
        const geo = question.length>0 && question.answer !== null 
              && typeof(question.answer) === 'object'
              && question.answer[0]?.geo!==null
              ? question.answer[0]?.geo 
              : ""
          if (!question.answer) {
            return {
              type: question.type,
              answer: '',
              geo: ''
            }
            // TODO для чего данный режим служит?
          // } else if (question.type=== 'choice' ) { 
          //   return {
          //     type: question.type,
          //     answer:  question.answer[0].answer,  
          //     geo
          //   }
        } else if (question.type==="file") {
          return {
            type: question.type,
            answer: !!question.answer?.length 
                && question.answer[0]?.files[0]!==null 
                && question.answer[0]?.files[0]!==undefined 
                && question.answer[0]?.files[0],
            geo
          }
          } else if (question.type==="url") {
            return {
              type: question.type,
              answer: !!question.answer?.length && question.answer[0]?.answer,
              geo
            }
          } else if (question.type==='dnd') {
            return {
              type: question.type,
              answer: question.answer,
              geo
            }
          } else {
            const result = []
            const peopleAnswers = question.answer?.map(el => el) || []
            for (const answ of peopleAnswers) {
              const answer = question.options?.length 
              ? question.options.find(opt =>  
                opt.id === answ.answer || opt.answer === answ.answer) 
                || peopleAnswers.find(peop =>  
                peop.answer === question.options[0].id || peop.answer === question.options[0].answer)
                || answ
              : answ
                result.push(answer.answer || answer)
            }
            return {
              type: question.type,
              answer: result,
              geo
            }
          }
        })
      : (Object.keys(conformity).map(questKey => ({
        type: 'other',
        param: conformity[questKey],
        value: typeof(stage.questions[questKey])==='number'
          ? `${stage.questions[questKey]}%`
          : stage.questions[questKey]
            ? 'Верно'
            : 'Не верно'
      })))
      
      const withAnswers = answers.find(answer => Array.isArray(answer.answer) ? answer.answer.length : answer.answer)
     
        /**
         * rolloverGeo для переворота гео координат для Яндекса
         * result во вх-х данных надо избавиться от пробела 
         * markGeo = none предварительно\для запрета на вывод пустых ссылок
         */
        let markGeo
        if(answers[0]?.geo.length>0 && answers[0]?.geo !== null && answers[0]?.geo !== '') { 
          let result = answers[0]?.geo.replace(/ /g,'');
          const rolloverGeo = result.split(',').reverse().join(',') 
          markGeo = `https://yandex.ru/maps/?pt=${rolloverGeo}&z=16.26`
        } else {
          markGeo = "none"
        }

       
      return (
        
        <div className={styles.answerItem} key={`${stage.question}`}>
        
          <div onClick={() => toggleVisibleStages(index)}>
            {stage.name}
            {withAnswers &&
            <IoMdTrash
              onClick={handleTrashClick(profileId, stage.id)}
            />}
          </div>
          {visibleStages.includes(index) &&
            <div>
              <div>{`Проходной балл: ${stage.passing_score}`}</div>
              <div>
                <span> Ответы: </span>
                {answers.map((answer, ind) =>
                  <div className={styles.answerBox} key={ind} > 
                  <div className={styles.questionItem}>
                  <p>{`${ind+1}`}</p> 
                  {<div className={styles.answerBoxItems} >{renderAnswer(answer)}</div>}
                  </div>
                  {markGeo!=="none" && <a href={markGeo} rel="noreferrer" target="_blank">Координаты</a>}
                  </div>
                  )}
              </div>
            </div>
          }
        </div>
    )
  }
  
  const renderTd = (answerObject, question) => {
    const answer = answerObject?.answer
    const currentQuestion = allAnswersList?.find(({question_name}) => question_name === question)
    const correctAnswersList = currentQuestion?.question_answers?.map(({answer}) => answer)
    const finalAnswer = correctAnswersList ? answer?.map(answ => `${correctAnswersList.indexOf(answ)+1}. ${answ}`) : answer 
    const answerText = finalAnswer?.join(', ').replace('false', 'Не выполнено').replace('true', 'Выполнено')
    return (
      <td>{answerText}</td>
    )
  }

  const allAnswersList = excelList?.reduce((result, stage) => [...result, ...stage.all_answers], [])

  const renderExcelTable = () => {
    const questionsList = excelList.reduce((result, stage) => {
      const newStageQuestions = stage.questions || []
      return [...result, ...newStageQuestions]
    }, [])
    return (
      <table id="table" ref={table} style={{display: "none"}}>
        <thead>
          <tr>
            <th rowSpan="2">Фамилия</th>
            <th rowSpan="2">Имя</th>
            <th rowSpan="2">Отчество</th>
            <th rowSpan="2">Личный номер</th>
            <th rowSpan="2">Статус</th>
            <th rowSpan="2">Регион</th>
            <th rowSpan="2">Адрес</th>
            <th rowSpan="2">Образовательная организация</th>
            <th rowSpan="2">E-mail</th>
            <th rowSpan="2">Контактный телефон</th>
            <th rowSpan="2">Руководитель отряда, контактный телефон</th>
            <th rowSpan="2">Статус заявки</th>
            {excelList.map(stage => <th colSpan={stage.questions?.length}>{stage.name}</th>)}
          </tr>
          <tr>
            {questionsList.map(question => <th>{question}</th>)}
          </tr>
        </thead>
        <tbody>
          {excelList[0]?.profiles?.map((profile, ind) => {
            
            const statusOption = selects.status.find(i => i.value===profile.status).name
            const statusRequestOption = requestStatuses.find(i => i.value===profile.article_request).name
            const chiefInfo = `${profile.chief},  ${profile.chief_phone}`
            
            // const renderAnswers = excelList.map(stage => Object.values(stage.profiles[ind].answers || {}).map(renderTd))
            const answersList = excelList.reduce((result, stage) => ({...result, ...stage.profiles[ind].answers}), {})
            const renderAnswers = questionsList.map(question => renderTd(answersList[question], question))
            return (
              <tr>
                <td>{profile.last_name}</td>
                <td>{profile.first_name}</td>
                <td>{profile.fatders_name}</td>
                <td>{profile.personal_id}</td>
                <td>{statusOption}</td>
                <td>{profile.region}</td>
                <td>{profile.address}</td>
                <td>{profile.school}</td>
                <td>{profile.email}</td>
                <td>{profile.phone}</td>
                <td>{chiefInfo}</td>
                <td>{statusRequestOption}</td>
                {renderAnswers}
              </tr>
            )
          }
          )}
        </tbody>
      </table>
    )
  }

  const displayExcelButton = !!excelList?.length || loadingbut

  return (
    <>
      {checkMode 
        ? <CheckMode
            goBack={() => setCheckMode(false)}
            stageId={checkMode}
          />
        : (
          <div className={styles.container}>
            {excelList && renderExcelTable()}
            {confirmation &&
            <Confirmation
              title='Вы уверены что хотите очистить ответы по данному этапу??'
              acceptConfirmation={acceptConfirmation}
              rejectConfirmation={() => setConfirmation(null)}
            />}
            <div className={styles.navBlock}>
              <div onClick={() => setRequestsMode(false)} className={styles.goBack}>
                <IoIosArrowRoundBack size={20} />
                назад
              </div>
              <FindField
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
            </div>
            
            <div>Список заявок:</div>
            <div className={styles.requestItem}>
              <div className={styles.reqItem + " " + styles.first}>
                <div>Фамилия:</div>
                <div>Имя:</div>
                <div>Отчество:</div>
                <div>Статус:</div>
                <div>Личный номер:</div>
                <div>Статус заявки:</div>
              </div>
            </div>
            {(_eventRequests && _eventRequests.length) ?
              _eventRequests.map((item, i) => {
                const confirmOwner = item.profile === confirmation?.profileId
                const descriptionMode = i===refusalDescriptionMode
                const modalVisible = i===displayModal || confirmOwner || descriptionMode
                const activeToggle = item.status == 3
                return (
                  <div className={styles.requestItem} key={`${item.profile} ${i}`}>
                    <RequestModal
                      descriptionMode={descriptionMode}
                      modalVisible={modalVisible}
                      handleRequestAnswers={handleRequestAnswers}
                      setRefusalDescriptionMode={setRefusalDescriptionMode}
                      closeAllModals={closeAllModals}
                      setDisplayModal={setDisplayModal}
                      loading={loading}
                      answers={answers}
                      handleChangeDescription={handleChangeDescription}
                      renderAnswer={renderAnswers}
                      activeToggle={activeToggle}
                      data={item}
                      index={i}
                    />
                    <div
                      className={styles.reqItem}
                      onMouseEnter={() => refusalDescriptionMode===null && handleRequestAnswers(i, item.article, item.profile, true)}
                      onMouseLeave={() => setDisplayModal(null)}
                    >
                      <div>{item.lastName}</div>
                      <div>{item.firstName}</div>
                      <div>{item.fathersName}</div>
                      <div>{selects.status.find(i => i.value===item.profile_status)?.name}</div>
                      <div>{item.personalId}</div>
                      <div>
                        <select
                          value={item.status}
                          onChange={handleChangeStatus(item.id, i)}
                        >
                          {requestStatuses.map(status =>
                            <option value={status.value}>
                              {status.name}
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              ''}
              {printBadgesMode && eventRequests &&
                <CurrentProfilesPrintBadges
                  currentProfiles={eventRequests.filter(profile => profile.status == 2)}
                  open={printBadgesMode}
                  onClose={() => setPrintBadgesMode(false)}
                  fromEvents
                />
              }
              {/* {modal && !!eventRequests.length &&
                <Modal
                  open={modal}
                  onClose={() => setModal(false)}
                  classNames={{
                    modal: 'requestModal',
                    closeButton: styles.closeButton,
                  }}
                  style={{maxWidth: "none"}}
                >
                  <div
                    style={{maxHeight: '80vh', overflowY: 'auto'}}
                  >
                  <div
                    ref={componentRef}
                  >
                      <BadgesComponent
                        profilesList={eventRequests}
                      />
                  </div>
                  </div>
                  <ButtonRed
                    onClick={handlePrint}
                    style={{position: 'absolute', right: '50px', bottom: '10px'}}
                  >
                    Печать
                  </ButtonRed>
                </Modal>
              } */}

              <div className={styles.checkStagesBlock}>
                <p>Задания, требующие проверки (этапы):</p>
                <ul>{eventStages.map(({name, id}) => <li onClick={() => setCheckMode(id)}>{name || 'Без названия'}</li>)}</ul>
              </div> 
               
              {
                displayExcelButton &&
                  <div
                    className={!loadingbut ? styles.downloadExcel : styles.downloadExcelLoad}
                    onClick={() => !loadingbut ? giveExcel() : null}
                  >
                    <i><RiFileExcel2Line/></i>
                    <span>{!loadingbut ? 'Скачать .xlsx' : 'Создаем файл...'}</span>
                  </div>
              }

              {(eventRequests && !!eventRequests.length) &&
                  <div
                    className={styles.downloadExcel}
                    onClick={() => setPrintBadgesMode(true)}
                  >
                    Печать бейджей
                  </div>
              }
          </div>
        )
      }
    </>
  );
}


const RequestModal = ({
  descriptionMode,
  modalVisible,
  handleRequestAnswers,
  closeAllModals,
  handleChangeDescription,
  setRefusalDescriptionMode,
  setDisplayModal,
  loading,
  answers,
  renderAnswer,
  activeToggle,
  data,
  index
}) => {

  const [item, setItem] = useState({...data})

  const saveButtonClick = () => {
    data.description!==item.description
      && handleChangeDescription(item.id, item.description)
    closeAllModals()
  }

  const handleToggleClick = () => {
    if (descriptionMode) {
      setDisplayModal(index)
      setRefusalDescriptionMode(null)
    } else {
      setRefusalDescriptionMode(index)
    }
  }

  return (
    <div
      className={styles.modal}
      style={modalVisible ? {opacity: "1", visibility: 'initial'} : null}
      onMouseEnter={() => handleRequestAnswers(index, item.article, item.profile)}
      onMouseLeave={() => setDisplayModal(null)}
    >
      <div className={styles.toggle}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={descriptionMode}
            disabled={!activeToggle}
          />
          <span
            className={styles.slider} 
            onClick={activeToggle && handleToggleClick}
          />
        </label>
      </div>
      {descriptionMode
        ? <>
            <h3>Укажите причину отказа:</h3>
            <textarea
              value={item.description}
              onChange={(e) => setItem(prev => ({...prev, description: e.target.value}))}
            />
            <div className={styles.modalButtons}>
              <ButtonRed onClick={saveButtonClick}>Сохранить</ButtonRed>
              <ButtonRed onClick={closeAllModals}>Отменить</ButtonRed>
            </div>
          </>
        : <>
            <h3>Ответы на вопросы:</h3>
            <div>
              {loading
                ? <div>Загрузка...</div>
                : (answers && !!answers.length)
                  ? answers.map(renderAnswer(item.profile))
                  : <div>Ответы отсутствуют</div>}
            </div>
          </>
      }
    </div>
  )
}
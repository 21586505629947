import * as types from "../actions/types";

const initialState = {
  region_departments: null,
  local_departments: null,
  detachments: null,
  detachment_details: null,
  regs: [],
  excel: [],
  count: null,
  excelLoading: false,
  fromListLoading: false,
  troopTypes: [],
  loading: false,
  imgLoad: false,
  depsByReg: null,
  detachment_details_load: false
};

export default function departments(state = initialState, action) {
  switch (action.type) {
    case types.SET_REGION_DEPARTMENTS:
      return {
        ...state,
        region_departments: action.payload,
      };
    case types.SET_LOCAL_DEPARTMENTS:
      return {
        ...state,
        local_departments: action.payload,
      };
    case types.REMOVE_LOCAL_DEP:
      return {
        ...state,
        local_departments: null
      };
    case types.SET_DETACHMENTS:
      return {
        ...state,
        detachments: action.payload,
      };
    case types.SET_DETACHMENTS_LOAD:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_DETACHMENT_DETAILS:
      return {
        ...state,
        detachment_details: action.payload,
      };
    case types.SET_DETACHMENT_DETAILS_LOAD:
      return {
        ...state,
        detachment_details_load: action.payload,
      };
    case types.SET_REGS:
      return {
        ...state,
        regs: action.payload,
      };
    case types.GET_EXCEL:
      return {
        ...state,
        excel: action.payload,
      };
    case types.GET_EXCEL_LOADING:
      return {
        ...state,
        excelLoading: action.payload,
      };
    case types.SET_DEPARTMENT_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case types.SET_DEPARTMENT_STAFF:
      return {
        ...state,
        staff: action.payload,
      };
    case types.SET_DEPARTMENTS_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case types.SET_DETACHMENTS_BY_REGION:
      return {
        ...state,
        depsByReg: action.payload,
      };
    case types.REMOVE_DETACHMENTS:
      return {
        ...state,
        detachments: null,
        excel: [],
      };
    case types.SET_DETACHMENTS_IMG_LOAD:
      return {
        ...state,
        imgLoad: state.imgLoad * action.payload,
      };
    case types.SET_TROOP_TYPES:
      return {
        ...state,
        troopTypes: action.payload,
      };
    case types.SET_FROM_LIST_LOADING:
      return {
        ...state,
        fromListLoading: action.payload,
      };
    case types.REMOVE_DEPARTMENTS:
      return initialState;
    default:
      return state;
  }
}

import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { 
  getVideoCount,
  getVideoItems,
  deleteVideoItem,
  addVideoItem,
  editVideoItem,
  uploadFile,
  deleteFile
} from '../../actions/videos'
import VideosModal from './VideosModal';
import Loader from '../../components/Loader/Loader';
import styles from './Videos.module.css'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import { Pagination } from '../../assets/common/Pagination/Pagination';
import { IoMdClose } from "react-icons/io";
import { createNotification } from '../../components/Notifications/Notifications';
import Confirmation from '../../assets/common/Confirmation/Confirmation';
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import ItemsPerPage from '../../components/ItemsPerPage';

function Videos({
  getVideoCount,
  getVideoItems,
  deleteVideoItem,
  addVideoItem,
  editVideoItem,
  deleteFile,
  uploadFile,
  filesList,
  pageCount,
  data,
  loading,
  itemsOnPage,
  accessRights,
  auth,
  userRegions
}) {
  
  const [numberPage, setNumberPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null);
  const [sort, setSort] = useState("");


  useEffect(() => {
    getVideoCount()
    getVideoItems(numberPage+1, sort)
  }, [numberPage, itemsOnPage, sort])
  
  const openModal = (type, item) => () => {
    const readRights = accessRights.ac_read || (accessRights.ac_read_by_region && userRegions.includes(item?.regionId))
    
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(type==='ADD'||readRights)
    !readRights && type!=='ADD' && createNotification('error', 'Доступ к данному объекту видео отсутствует')
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteVideo = (id) => {
    deleteVideoItem(id, numberPage+1)
  }
  
  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };
  
  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  // useEffect(() => {
  //   if (selectedItem && selectedItem.id) {
  //     const obj = data.find(it => it.id === selectedItem.id)
  //     setSelectedItem(obj)    
  //   }
  // }, [data])

  const acceptConfirmation = () => {
    setConfirmation(null)
    handleDeleteVideo(confirmation.id)
  };

  const rejectConfirmation = () => {
    setConfirmation(null)
  };

    return (
    <>
      <div className={styles.container}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation.name}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {loading && !isModalOpen && <Loader top="45%" left="45%" />}
        <div className={styles.title}>Видео</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
            {(accessRights.ac_create || accessRights.ac_create_by_region) && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Видео</div>
            <div className={styles.username}></div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {data &&
              data.map((a) => {
                const deleteRights = accessRights.ac_delete || (accessRights.ac_delete_by_region && userRegions.includes(a.regionId))
                return (
                  <div key={a.id} className={styles.tableRow}>
                    <div
                      className={styles.displayname}
                      onClick={openModal("EDIT", a)}
                    >
                      <span>
                        {a.name}
                      </span>
                    </div>
                    <div className={styles.username}>{a.groupname}</div>
                    <div className={styles.superuser}></div>
                    <div className={styles.actions}>
                      {deleteRights
                        ? <IoMdClose onClick={() => setConfirmation({name: a.name, id: a.id})}/>
                        : <div style={{height:'1.5rem'}}></div>
                      }
                    </div>
                  </div>
                )
              })}
          </div>
          <div className={styles.pagination}>
            <Pagination pageCount={pageCount} numberPage={numberPage} onPageChange={handlePageClick}/>
          </div>
          <div className={styles.controls}>
            <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
            <ItemsPerPage/>
          </div>
        </div>
        
      </div>
      {isModalOpen &&
      <VideosModal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        numberPage={numberPage}
        loading={loading}
        deleteFile={deleteFile}
        uploadFile={uploadFile}
        filesList={filesList}
        editVideoItem={editVideoItem}
        // getVideoItems={getVideoItems}
        addVideoItem={addVideoItem}
        accessRights={accessRights}
        userRegions={userRegions}
        // auth={auth}
      />}
    </>
  )
}

const mapStateToProps = (state) => ({
    pageCount: state.videos.page_count,
    itemsOnPage: state.auth.itemsOnPage,
    data: state.videos.data,
    filesList: state.videos.files_list,
    loading: state.videos.loading,
    lastUpdate: state.schedule.lastUpdate,
    accessRights: state.auth.accessRights.find(i => i.name==='video')||{},
    userRegions: state.auth.userRegions,
    // auth: state.auth
  })

export default connect(mapStateToProps, {
  getVideoCount,
  getVideoItems,
  deleteVideoItem,
  addVideoItem,
  editVideoItem,
  uploadFile,
  deleteFile,
})(Videos)
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getYunarmyHousesData,
  getCurrentYunarmyHouse,
  deleteYunarmyHouse,
  editYunarmyHouse,
  addYunarmyHouse,
  getInfoForExcel,
  deleteInfoForExcel,
  getYunarmyHousesCount
} from "../../actions/yunarmyHouses";
import { getDepartments, removeLocalDep, getDepartmentsByRegion } from "../../actions/departments";
import YunarmyHousesModal from "./YunarmyHousesModal";
import styles from "./YunarmyHouses.module.css";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import { Pagination } from "../../assets/common/Pagination/Pagination";
import Loader from "../../components/Loader/Loader";
import { getRegions } from "../../actions/auth";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { getRegionOptions } from "../../utils/getOptions";

import logo from '../../assets/images/label.png';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../fonts/fonts'

import { getExcelFromTable } from "../../utils/excel";
import ItemsPerPage from "../../components/ItemsPerPage";


const YunarmyHouses = ({
  getYunarmyHousesData,
  getYunarmyHousesCount,
  getCurrentYunarmyHouse,
  getDepartmentsByRegion,
  deleteYunarmyHouse,
  editYunarmyHouse,
  removeLocalDep,
  addYunarmyHouse,
  getDepartments,
  data,
  pageCount,
  currentItem,
  loading,
  depsByReg,
  itemsOnPage,
  accessRights,
  regions,
  regionDeps,
  getRegions,
  localDeps,
  getInfoForExcel,
  info_for_excel,
  info_for_excel_load,
  deleteInfoForExcel,
}) => {
  
  const deleteRights = accessRights.ac_delete

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [numberPage, setNumberPage] = useState(0);
  const [sort, setSort] = useState("");
  const [byReg, setByReg] = useState('0');
  const [typeFile, setTypeFile] = useState('');

  const title = 'Выгрузка домов юнармии';
  const orientation = 'l';

  const noNull = (val) => {
    if (val) {
      return val;
    } else {
      return '';
    }
  }
  
  const getInfoForPdf = (list) => {
    
    const newList = list.map((el, i) => {
      return [
        i + 1, 
        el.region_department_name, 
        el.city,
        el.name,
        el.placement,
        `${noNull(el.house_info.address)}\n${noNull(el.house_info.phone)}\n${noNull(el.house_info.email)}\n${noNull(el.house_info.website)}`,
        `${noNull(el.chief_info.fio)}\n${noNull(el.chief_info.position)}\n${noNull(el.chief_info.phone)}\n${noNull(el.chief_info.email)}`,
        el.employees_count,
        el.yunarmians_count,
        el.associations_count,
        el.protocol_gsh,
        el.status_year
      ]
    })

    return newList;

  }
 
  useEffect(() => {
    if (data && data.length == 0) {
      if (numberPage == 0) {
        setNumberPage(0);
      } else {
        setNumberPage(prev => prev -1);
      }
    }
  }, [data])


  useEffect(() => {
    getRegions();
    getDepartments();
  }, [])
  
  useEffect(() => {
    getYunarmyHousesData(numberPage+1, sort, byReg);
    getYunarmyHousesCount(byReg);
  }, [numberPage, itemsOnPage, sort, byReg]);

  const openModal = (type, item) => () => {
    setSelectedItem(item);
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const acceptConfirmation = () => {
    deleteYunarmyHouse(confirmation.id, numberPage+1, byReg);
    setConfirmation(null);
  };

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const handleSelectRegion = (e) => {
    const value = e.target.value;
    setByReg(value);
  }

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    doc.setFontSize(18)
		doc.setFont("RobotoRegular");
    doc.text(`Реестр домов "ЮНАРМИИ"`, 150, 25, "center" )
    const img = new Image()
    img.src = logo
    
    // Формирование таблицы в pdf
    doc.autoTable({
      head: [
        [
          "№", 
          "Региональное отделение", 
          "Город размещения", 
          "Наименование дома `ЮНАРМИИ`", 
          "Базовая организация размещения дома `ЮНАРМИИ`",
          "Адрес размещения, телефон, e-mail, сайт",
          `Руководитель дома "ЮНАРМИИ" (фио, должность, телефон, e-mail)`,
          "Количество работников дома `ЮНАРМИИ`",
          "Количество юнармейцев дома `ЮНАРМИИ`",
          "Количество объединений дома `ЮНАРМИИ`",
          "Протокол заседания ГШ",
          "Год присвоения статуса" 
        ]

      ],
      body: getInfoForPdf(info_for_excel), 
      startY: 30,
      theme: "grid",
      pageBreak: 'auto',
      showHead: 'firstPage',
      rowPageBreak: 'avoid',
      margin: {left: 5, right: 5, bottom: 12},
      columnStyles: { 4: { halign: 'center', valign: 'middle', cellWidth: 35},
                      5: { halign: 'center', valign: 'middle', cellWidth: 35},
                      6: { halign: 'center', valign: 'middle', cellWidth: 35},
                    },
      headStyles: {
        textColor: [0,0,0],
        fillColor: [226,239,217],
        font: "RobotoBold",
        lineWidth: 0.5, 
        fontSize: 8,
        overflow: 'linebreak',
        halign: 'center', 
        valign: 'middle',
        lineColor: [0,0,0],
        },
      bodyStyles : {
        lineColor: [0,0,0],
        textColor: [0,0,0],
        fontSize: 8,
        lineWidth: 0.5, 
        overflow: 'linebreak',
        font: "RobotoRegular",
        halign: 'center',
        valign: 'middle'
    },
    })

    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`));
    deleteInfoForExcel();
  }

  const handleDownload = (id) => {
    setTypeFile(id);
    getInfoForExcel(byReg);
  }

  useEffect(() => {
    if (info_for_excel && info_for_excel.length > 0 && typeFile) {
      
      if (typeFile == 'pdf') {
        toPdf();
        setTypeFile('');
      }

      if (typeFile == 'excel') {
        toExcel();
        setTypeFile('');
      }
    }
  }, [typeFile, info_for_excel])
  

  // Формирование таблицы в excel
  const toExcel = () => {
    const table = document.getElementById("table")
    getExcelFromTable(table, 'Выгрузка домов юнармии')
    deleteInfoForExcel();
  }

  return (
    <>
      <div className={styles.container}>
      
      <table id="table"  style={{display: "none"}}>
        <tr>
          <th>№</th>
          <th>Региональное отделение</th>
          <th>Город размещения</th>
          <th>Наименование дома "ЮНАРМИИ"</th>
          <th>Базовая организация размещения дома "ЮНАРМИИ"</th>
          <th>Адрес размещения, телефон, e-mail, сайт</th>
          <th>Руководитель дома "ЮНАРМИИ" (фио, должность, телефон, e-mail)</th>
          <th>Количество работников дома "ЮНАРМИИ"</th>
          <th>Количество юнармейцев дома "ЮНАРМИИ"</th>
          <th>Количество объединений дома "ЮНАРМИИ"</th>
          <th>Протокол заседания ГШ</th>
          <th>Год присвоения статуса</th>
        </tr>
        {info_for_excel?.map((el, i) => 
              <tr key={i}>
                <th>{i+1}</th>
                <th>{el.region_department_name}</th>
                <th>{el.city}</th>
                <th>{el.name}</th>
                <th>{el.placement}</th>
                <th>{el.house_info.address} {el.house_info.phone} {el.house_info.email} {el.house_info.website}</th>
                <th>{el.chief_info.fio} {el.chief_info.position} {el.chief_info.phone} {el.chief_info.email}</th>
                <th>{el.employees_count}</th>
                <th>{el.yunarmians_count}</th>
                <th>{el.associations_count}</th>
                <th>{el.protocol_gsh}</th>
                <th>{el.status_year}</th>
            </tr>
            )   
        }
      </table>

        <div className={styles.title}>Дома Юнармии</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
            {accessRights.ac_create && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
            
            <div className={styles.wrapperButDown}>
            <div style={{position:'relative'}}>
            {<ButtonRed onClick={() => handleDownload('pdf')}>Скачать PDF 
            {info_for_excel_load && typeFile == 'pdf' ? <Loader top="-13px" color="#fff" left="auto" right="-15px" size="20"/> : <></>}
            </ButtonRed>}
            </div>

            <div style={{position:'relative'}}>
            {<ButtonRed onClick={() => handleDownload('excel')}>Скачать XLSX
            {info_for_excel_load && typeFile == 'excel' ? <Loader top="-13px" color="#fff" left="auto" right="-15px" size="20"/> : <></>}</ButtonRed>}
            </div>
            </div>

            <div>
            <CustomSelect
              // width="500px"
              title="Выберите регион"
              options={[{name: "Все", value: "0"}, ...getRegionOptions(regions)]}
              onChange={handleSelectRegion}
              withoutFirst
            />
            </div>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Дом</div>
            <div className={styles.username}></div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          {loading && !isModalOpen ? (
            <Loader top="40%" left="47%" />
          ) : (
            <div className={styles.table}>
              {data && 
                data.map((a) => (
                  <div key={a.id} className={styles.tableRow}>
                    <div
                      className={styles.displayname}
                      onClick={openModal("EDIT", a)}
                    >
                      <span>
                        {a.name}
                      </span>
                    </div>
                    <div className={styles.username}></div>
                    <div className={styles.superuser}></div>
                    <div className={styles.actions}>
                      {deleteRights
                        ? <IoMdClose onClick={() => setConfirmation({ text: a.name, id: a.id })}/>
                        : <div style={{height:'1.5rem'}}></div>
                      }
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div className={styles.pagination}>
            <Pagination
              pageCount={pageCount}
              numberPage={numberPage}
              onPageChange={handlePageClick}
            />
          </div>
          <div className={styles.controls}>
            <div
                onClick={handleSortClick}
                className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
              >
                  {sort && sort==='desc'
                  ? <BsSortDown/>
                  : <BsSortDownAlt/>}
            </div>
            <ItemsPerPage/>
          </div>
        </div>
      </div>
      {confirmation && (
        <Confirmation
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      )}
      {isModalOpen && (
        <YunarmyHousesModal
          open={isModalOpen}
          type={modalType}
          onClose={closeModal}
          loading={loading}
          page={numberPage}
          itemId={selectedItem?.id}
          currentItem={currentItem}
          regionDeps={regionDeps}
          localDeps={localDeps}
          accessRights={accessRights}
          getCurrentYunarmyHouse={getCurrentYunarmyHouse}
          editYunarmyHouse={editYunarmyHouse}
          addYunarmyHouse={addYunarmyHouse}
          getDepartments={getDepartments}
          removeLocalDep={removeLocalDep}
          getDepartmentsByRegion={getDepartmentsByRegion}
          regions={regions}
          byReg={byReg}
          depsByReg={depsByReg}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.yunarmy_houses.data,
  pageCount: state.yunarmy_houses.page_count,
  loading: state.yunarmy_houses.loading,
  currentItem: state.yunarmy_houses.current_house,
  itemsOnPage: state.auth.itemsOnPage,
  regions: state.auth.regions,
  regionDeps: state.departments.region_departments,
  depsByReg: state.departments.depsByReg,
  localDeps: state.departments.local_departments,
  accessRights: state.auth.accessRights.find(i => i.name==='yunarmy_house')||{},
  info_for_excel: state.yunarmy_houses.info_for_excel,
  info_for_excel_load: state.yunarmy_houses.info_for_excel_load,
});

export default connect(mapStateToProps, {
  getYunarmyHousesData,
  getYunarmyHousesCount,
  getCurrentYunarmyHouse,
  getDepartmentsByRegion,
  deleteYunarmyHouse,
  removeLocalDep,
  editYunarmyHouse,
  addYunarmyHouse,
  getDepartments,
  getRegions,
  getInfoForExcel,
  deleteInfoForExcel
})(YunarmyHouses);

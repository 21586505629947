import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import Modal from "react-responsive-modal";
import { InputFile } from "../../assets/common/InputFile/InputFile"
import { IoMdTrash } from 'react-icons/io';
import styles from "./styles.module.css";
import {Slider} from './Slider';
import Loader from '../../components/Loader/Loader';

import { SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.css';
import { useFormik } from "formik";
import preview from '../../assets/images/preview.png';
import { useDispatch } from "react-redux";
import { createAward, updateAward } from "../../actions/virtualAwards";
import { BACKEND_DOMAIN } from "../../utils/const";
import * as yup from 'yup'
import { createNotification } from "../../components/Notifications/Notifications";


const initialValues = {
  name: '',
  description: '',
  bw_image: null,
  colored_image: null
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Поле "Наименование награды" является обязательным'),
  description: yup.string().required('Поле "Описание" является обязательным'),
  bw_image: yup.mixed().required('Поле "Ч/б изображение" является обязательным'),
  colored_image: yup.mixed().required('Поле "Цветное изображение" является обязательным')
})


const AwardsModal = ({
  open,
  closeModal,
  addMode,
  selectedItem,
  numberPage,
  loading
}) => {
  
  const dispatch = useDispatch();

  useEffect(() => {
    setValues(addMode ? initialValues : selectedItem)
  }, [selectedItem]);

  
  const {
    values, 
    errors, 
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: (values, formik) => {
      addMode ? addAwardsItem(values) : editAwardsItem(values)
    }
  })

  const onSaveClickHandler = () => {
    const error = Object.values(errors)[0]
    if (error) return createNotification('error', error);
    handleSubmit()
  }

  const addAwardsItem = async (values) => {
    const success = await dispatch(createAward(values, numberPage))
    success && closeModal()
  };
  
  const editAwardsItem = async ({id, ...values}) => {
    ['bw_image', 'colored_image'].forEach(key => {
      if (values[key].id) {
        delete values[key]    
      }
    })
    const success = await dispatch(updateAward(id, values, numberPage))
    success && closeModal()
  };  


  const setPhotoHandler = (e) => {
    const { name, files } = e.target
    setFieldValue(name, files[0])
  }

  const renderPhoto = (fieldName) => {
    const image = values[fieldName]
    const imageUrl = !image
      ? preview
      : image.id
        ? `${BACKEND_DOMAIN || ''}/${image.path}`
        : URL.createObjectURL(image)
    return (
      <SwiperSlide key={imageUrl}>
        <div className={`photoWrapper`}>
          {!!image &&
            <i className={styles.deletePhoto} onClick={() => setFieldValue(fieldName, null)}>
              <IoMdTrash />
            </i>
          }
          <img
            src={imageUrl}
            alt="o_O"
            className={styles.imgUploder}
          />
        </div>
      </SwiperSlide>
    )
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {addMode ? "Добавление наград" : "Редактирование наград"}
      </div>
      <div className={styles.modalBody}>
        {loading &&<Loader top='45%' left='47%'/>}
        <div>
          <div className={styles.firstBlock}>
            <div className={styles.firstBlockInner}>
              <p className="news__label">Наименование награды</p>
              <input
                name='name'
                value={values.name}
                onChange={handleChange}
                className={styles.input}
                placeholder="Наименование награды"
              />
              
              <p className="news__label">Описание</p>
              <ReactQuill
                name='description'
                value={values.description}
                onChange={text => setFieldValue("description", text)}
              />
            </div>
            <div className={styles.imageBlock}>

                <div className={styles.wrapperSliderAwards}>
                  <p>Ч/б изображение</p>
                  <Slider>
                    {renderPhoto('bw_image')}
                  </Slider>
                  <div className={styles.wrapperBut}>
                    <InputFile
                      title="Выберите изображение"
                      type="file"
                      name='bw_image'
                      accept="image/png, image/gif, image/jpeg"
                      onChange={setPhotoHandler}
                    />
                  </div>
                </div>

                <div className={styles.wrapperSliderAwards}>
                  <p>Цветное изображение</p>
                  <Slider>
                    {renderPhoto('colored_image')}
                  </Slider>
                  <div className={styles.wrapperBut}>
                    <InputFile
                      title="Выберите изображение"
                      type="file"
                      name='colored_image'
                      accept="image/png, image/gif, image/jpeg"
                      onChange={setPhotoHandler}
                    />
                  </div>
                </div>

            </div>
          </div>
        </div>
        <div className="settingModal__btns">
          <button
            className="newsListHeader__btn btn_grey"
            type="button"
            disabled={loading}
            onClick={onSaveClickHandler}
          >
            {addMode ? "Добавить" : "Сохранить"}
          </button>
          <button
            className="newsListHeader__btn btn_grey"
            onClick={closeModal}
            disabled={loading}
            type="button"
          >
            Закрыть
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AwardsModal;

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import MinButton from './MinButton';
import Menu from './Menu';
import { PATHS } from '../../routing/Routes';

import checked from '../../assets/images/checked.png';
import star from '../../assets/images/start.png';
import starEmpty from '../../assets/images/star-empty.png';
import downArrow from '../../assets/images/dropdown-arrow-icon.png';



const RenderRow = ({
  values,
  index,
  editNews,
  showMenu,
  closeMenu,
  pathname,
  havePublishRight,
  haveDeleteRight,
  selectedNewsId,
  userInfo,
  deleteNews
}) => {
  

  return (
    <div key={index}>
      <div key={index} className="newsListRow">
        <div className="newsListRow__state">
          <div className="newsListRow__stateBtns">
            <MinButton
              className="newsListRow__stateBtn newsListRow__stateBtn_check"
              image={values?.status === 2 && checked}
              onClick={editNews(values.id, 'status', values.status === 2 ? 1 : 2)}
              disabled={!havePublishRight}
            />
            <MinButton
              className="newsListRow__stateBtn newsListRow__stateBtn_star"
              image={values.starred ? star : starEmpty}
              onClick={editNews(values.id, 'starred')}
              disabled={!havePublishRight}
              />
            <MinButton
              className="newsListRow__stateBtn newsListRow__stateBtn_drop"
              image={downArrow}
              onClick={() => showMenu(values.id)}
              disabled={!haveDeleteRight}
            />
          </div>
          {values.id === selectedNewsId && (
            <div className="newsListTableDropdown">
              <Menu id={values.id} status={values.status} editNews={editNews} deleteNews={deleteNews} closeMenu={() => closeMenu()} />
            </div>
          )}
        </div>
  
        <div className="newsListRow__title">
          <Link
            to={{
              pathname:
                pathname === '/event'
                  ? `/event/edit_event/${values.id}`
                  : pathname === '/news' ? `/news/edit_news/${values.id}` : `/ads/edit_ads/${values.id}`
              // state: {
              //   ...values,
              //   image: values.imageurl && `${values.imageurl}`,
              // },
            }}
          >
            {values.title}
          </Link>
        </div>
        <div className="newsListRow__author">{values.author}</div>
        <div className="newsListRow__date">
          {moment(values.pubdate).format('DD-MM-YYYY')}
        </div>
      </div>
    </div>
  );
}

export default RenderRow;

import React from "react";
import { Field } from "redux-form";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const WrappedDropdownField = ({ input, options, ...props }) => {
  return (
    <select {...props} {...input} className={props.className}>
      <option selected>Не выбрано</option>
      {options.map((it) => (
        <option
          key={it.value}
          value={it.value}
          selected={input.value === it.value}
        >
          {it.label}
        </option>
      ))}
    </select>
  );
};

const SelectField = (props) => (
  <Field component={WrappedDropdownField} {...props} />
);

export default SelectField;

import React from 'react'
import styles from './AuthForm.module.css'
import { useFormik } from 'formik';
import * as Yup from 'yup'


const validationSchema = Yup.object().shape({
  username: Yup.string().required("поле является обязательным"),
  password: Yup.string().required("поле является обязательным")
})

export default function AuthForm({
    authAction,
    history,
    loading
  }) {

    const authForm = useFormik({
        initialValues: {username: "", password: ""},
        validationSchema,
        onSubmit: (values) => {
            authAction(values, history)
        },
      });
    
    const error = (name) => authForm.touched[name] && authForm.errors[name]

    return (
        <form className={styles.form}>
            <AuthInput
              placeholder="логин"
              name="username"
              onChange={authForm.handleChange}
              onBlur={authForm.handleBlur}
              error={error("username")}
              />
            <AuthInput
              placeholder="пароль"
              name="password"
              type="password"
              onChange={authForm.handleChange}
              onBlur={authForm.handleBlur}
              error={error("password")}
              />
            <button
                // disabled={loading}
                onClick={authForm.handleSubmit}
            >
              Войти
            </button>
        </form>
    )
}


const AuthInput = ({error, ...props}) => {
  return (
    <div className={styles.inputBlock}>
      <input {...props}/>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  )
}
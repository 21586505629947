import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { SET_AWARD_COUNT, SET_AWARD_DATA, SET_AWARD_IMAGE, REMOVE_AWARD_IMAGE, SET_AWARD_LOADING, ERROR, SET_AWARD_DATA_BY_ID
} from "./types.js";
import { createNotification } from "../components/Notifications/Notifications";


export const removeEquipmentImage = () => {
  return {type: REMOVE_AWARD_IMAGE}
}

export const getEquipmentCount = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const newsOnPage = getState().auth.itemsOnPage
    const response = await fetch(`${BACKEND_URL_PREFIX}/departmental_award/count`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const pageCount = Math.ceil(data.departmental_awards_count / newsOnPage);
      dispatch({
        type: SET_AWARD_COUNT,
        payload: { data: data, pageCount: pageCount },
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getAwardsObject =
  (page=1, sort) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      let urlParams = '';
      if (page == 'all') {
        
      } else {
        const newsOnPage = getState().auth.itemsOnPage
        const offset = page * newsOnPage - newsOnPage;
        const params = {
          offset: offset,
          limit: newsOnPage
        }
        sort && ( params.alph = sort )
        urlParams = new URLSearchParams(params).toString();
      }
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/departmental_award?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_AWARD_DATA, payload: data.departmental_awards });

      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };
  
  export const getAwardById =
  (id) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/departmental_award/${id}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_AWARD_DATA_BY_ID, payload: data.departmental_award });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteEquipmentObject =
  (data, id, status, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    
    if (getState().auth.token) {
      const newData = data.find(el => el.id == id);
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/departmental_award/${id}`,
        {
          method: "PUT",
          body: JSON.stringify({id: id, status: !newData.status}),
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        createNotification('success', 'Успешно');  
        dispatch(getAwardsObject(numberPage));
      } else {
        const respData = await response.json()
        createNotification('error', respData.error);
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const editEquipmentObject =
  (id, data, numberPage) => async (dispatch, getState) => {

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/departmental_award/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        createNotification('success', 'Успешно');
        dispatch(getAwardsObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
        createNotification('error', "Ошибка");  
      }
    }
  };

 
  export const addEquipmentObject =
  (data, numberPage, images = []) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/departmental_award`, {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });

      if (response.ok) {
        const data = await response.json();
        
        if (images && images.length > 0) {
          images.forEach(el => 
            dispatch(uploadImage(data.departmental_award.id, {file: el}, numberPage)))
          }
        createNotification('success', 'Успешно');
      } else {
        dispatch(getEquipmentCount());
        dispatch(getAwardsObject(numberPage));
        dispatch({ type: ERROR, payload: response });
        createNotification('error', "Ошибка");  
      }
    }
  };


export const uploadImage =
  (id, image, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", image.file);

    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/departmental_award/${id}/image`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
        }
      );
      if (response.ok) {
        dispatch(getEquipmentCount());
        dispatch(getAwardsObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteImage = (id, numberPage) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/departmental_award/${id}/image`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getEquipmentCount());
      dispatch(getAwardsObject(numberPage));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getAwardsImage =
(id) => async (dispatch, getState) => {
  dispatch({type: SET_AWARD_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/departmental_award/${id}/image`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        }
      }
    );
    if (response.ok) {
      const image = await response.json();
      dispatch({type: SET_AWARD_IMAGE, payload: image});
      dispatch({type: SET_AWARD_LOADING, payload: false})
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};
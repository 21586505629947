import React, { useState, useRef, useEffect } from 'react'
import CustomSelect from '../CustomSelect/CustomSelect'
import CustomInput from '../CustomInput/CustomInput'
import styles from './styles.module.scss'
import { MdModeEdit } from "react-icons/md";
import { IoIosClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import Loader from '../Loader/Loader';
import { getAllYunarmiansData, removeDataForPrint } from '../../actions/yunarmiyans';
import { getRegions } from '../../actions/users';
import  { useOnClickOutside } from '../../hooks'

const YunprofileLinkComponent = ({ setUserProfile, profileName, profileLink, style }) => {
  
  const popUpRef = useRef();
  const { pdf_loading: popupLoading, all_profiles: profiles } = useSelector(state => state.yunarmians)
  const { regionList: regions } = useSelector(state => state.users)
  const dispatch = useDispatch()

  const [profileFilters, setProfileFilters] = useState({})

  useEffect(() => {
    !regions?.length && dispatch(getRegions())
  }, []);

  useEffect(() => {
    !Object.keys(profileFilters).length && removeDataForPrint()
    const { fathersName, firstName, lastName} = profileFilters
    const noFIOLetters = !lastName && !firstName && !fathersName
    if (!profileFilters.regions || noFIOLetters) return;
    dispatch(getAllYunarmiansData(profileFilters))
  }, [profileFilters])

  const [profilePopUp, setProfilePopUp] = useState(false)

  const popUpFiltersChanges = debounce((e) => {
    setProfileFilters(prev => ({...prev, [e.target.name] : e.target.value}))
  }, 500)

  const profileClickHandler = (profile) => {
    closePopUp()
    setUserProfile(profile)
  }

  const closePopUp = () => {
    setProfilePopUp(false)
    dispatch(removeDataForPrint())
  }
  
  useOnClickOutside(popUpRef, closePopUp)

  return (
    <div
      className={styles.profileBlock}
      style={style}
      ref={popUpRef}
    >
        <div>Профиль юнармейца</div>
        <div className={styles.profilleBlockIcons}>
          <MdModeEdit size={18} onClick={() => setProfilePopUp(true)}/>
          <IoIosClose size={30} onClick={() => profileClickHandler()}/>
        </div>
        <a
          className={styles.profileName}
          href={profileLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {profileName}
        </a>
        {profilePopUp &&
          <div className={styles.popup}>
            <CustomSelect
              selwidth="100%"
              title="Выберите регион"
              name="regions"
              options={regions.map(it=>({name: `${it.regionName} ${it.federalUnit}`, value: it.id}))}
              onChange={popUpFiltersChanges}
              />
            <CustomInput
              title="Фамилия"
              name="lastName"
              onChange={popUpFiltersChanges}
            />
            <CustomInput
              title="Имя"
              name="firstName"
              onChange={popUpFiltersChanges}
            />
            <CustomInput
              title="Отчество"
              name="fathersName"
              onChange={popUpFiltersChanges}
            />
            <div className={styles.popupLabel}>Выберите профиль:</div>
            <div className={styles.popupProfiles}>
              {popupLoading
                ? <Loader size={40} top='32%' left="44%"/>
                : !!profiles?.length && profiles.map(it =>
                    <p onClick={() => profileClickHandler(it)}>
                      {`${it.lastName} ${it.firstName} ${it.fathersName} (${it.personalId})`}
                    </p>
              )}
            </div>
          </div>
        }
    </div> 
  )
}

export default YunprofileLinkComponent
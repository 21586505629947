import * as types from "../../actions/types";



const initialState = {
  accessEntities: null,
  groupAccess: null,
  loading: false,
};


export function access_control (state = initialState, action) {
  switch (action.type) {
    case types.SET_ACCESS_ENTITIES:
      return {
        ...state,
        accessEntities: action.payload
      };
    case types.SET_GROUP_ACCESS:
      return {
        ...state,
        groupAccess: action.payload
      };
    case types.SET_ACCESS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
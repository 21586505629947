import React, { useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getAwardsList } from '../../actions/virtualAwards'
import { useState } from 'react'
import { useOnClickOutside } from '../../hooks'
import { BACKEND_DOMAIN } from '../../utils/const'
import cn from 'classnames'



const AwardsBlock = ({selected, onChange, rightMode}) => {
  
  const dispatch = useDispatch()
  const { data } = useSelector(state => state.virtual_awards)

  const awardsBlockRef = useRef(null)

  const [visibleList, setVisibleList] = useState(false)

  useOnClickOutside(awardsBlockRef, () => setVisibleList(false))

  useEffect(() => { dispatch(getAwardsList())}, [])
  
  const liClassNames = (condition) => cn({[styles.selected]: condition})
  
  const selectHandler = (id) => () => {
    onChange(id || null)
    setVisibleList(false)
  }

  const renderLi = (award, i) => {
    const imgLink = `${BACKEND_DOMAIN || ''}/${award.colored_image.path}`
    const selectedAward = selected == award.id
    return (
      <li
        className={liClassNames(selectedAward)}
        onClick={selectHandler(award.id)}
      >
        <img src={imgLink} alt="o_O"/>
      </li>
    )
  }

  const selectedLink = data?.find(({id}) => id == selected)?.colored_image.path

  const listClassNames = cn(
    styles.awardsList,
    {
      [styles.visibleList]: visibleList,
      [styles.awardsListRight]: rightMode

    }
  )

  return (
    <div className={styles.awardsBlock} ref={awardsBlockRef}>
      <div className={styles.currentAward} onClick={() => setVisibleList(true)}>
        {
          selectedLink
            ? <img src={`${BACKEND_DOMAIN || ''}/${selectedLink}`} alt="o_O"/>
            : 'Не выбрана'
        }
      </div>
      <ul
        className={listClassNames}
      >
        <li
          className={liClassNames(!selected)}
          onClick={selectHandler(null)}
        >
          Не выбрана
        </li>
        {data?.map(renderLi)}
      </ul>
    </div>
  )
}

export default AwardsBlock
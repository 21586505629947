import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import styles from "./Quests.module.css";
import { IoMdClose, IoIosAdd } from "react-icons/io";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { eventLevels } from "../../utils/const";
import Loader from "../../components/Loader/Loader"
import { createNotification } from "../../components/Notifications/Notifications";


const QuestsModal = ({
  getTaskObject,
  addTaskObject,
  editTaskObject,
  deleteTaskObject,
  getTaskCount,
  uploadImage,
  deleteImage,
  type,
  selectedItem,
  eventOptions,
  editQuestsObject,
  addQuestsObject,
  numberPage,
  taskPageCount,
  tasks,
  removeDepartments,
  getDepartments,
  regDepartments,
  localDepartments,
  detachments,
  accessRights,
  removeQuestTasks,
  getForAny,
  loading,
  history,
  ...props
}) => {
  
  const updateRights = type === "ADD" || accessRights.ac_update
  
  const [item, setItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null)

  useEffect(() => {
    removeDepartments()
    return () => {
      removeQuestTasks()
    }
  }, [])
  

  useEffect(() => {
    if (type === "ADD") {
      setItem({
        name: "",
        level: null
      });
    } else {
      selectedItem && setItem(selectedItem);
    }
  }, [selectedItem, type]);

  useEffect(() => {
    selectedItem && getTaskObject(null, selectedItem.id);
  }, [selectedItem]);

  useEffect(() => {
    if (item) {
      if (item.level && (item.level==2 || item.level==3)) {
        !regDepartments && getDepartments()
        if (regDepartments && item.level==3) {
          const payload = item.departments?.filter(id => regDepartments.map(i => i.id).includes(id)) || []
          getForAny(payload)
        }
      } else {
        removeDepartments()
      }
    }
  }, [item?.level, regDepartments])

  const handleAddQuest = () => {
    const errorField = !item.name ? "Поле название"
                      :  (!item.level ||item.level==0) ? "Поле уровень"
                      : ""
    if (!errorField) {
      item.event && item.event == 0 && delete item.event
      addQuestsObject(item, numberPage+1);
      props.onClose();
    } else {
      createNotification("error", `${errorField} является обязательным`)
    }
  };

  const handleEditQuest = () => {
    const errorField = !item.name ? "Поле название"
                      :  (!item.level ||item.level==0) ? "Поле уровень"
                      : ""
    if (!errorField) {
      item.event && item.event == 0 && delete item.event
      editQuestsObject(item.id, item, numberPage+1);
      props.onClose();
    } else {
      createNotification("error", `${errorField} является обязательным`)
    }
  };

  const statusToggleClick = () => {
    const status = item.status === 2 ? 1 : 2
    setItem(prev => ({...prev, status}))
  }

  const handleDeleteTask = (id) => {
    deleteTaskObject(id, null, item.id, [])
  }

  const acceptConfirmation = () => {
    handleDeleteTask(confirmation.id)
    setConfirmation(null)
  }

  const handleSelectLevel = (e) => {
    setItem((prev) => ({
      ...prev,
      level: e.target.value,
      departments: []
    }))
  }

  const handleSelectTask = (item) => {
    history.push({
      pathname: '/tasks',
      state: item
    })
  }

  const handleSelectDepartment = (id, selectRegDepartment) => {
    let newDep = item.departments ? [...item.departments] : []
      if (item.departments.includes(id)) {
        newDep = item.departments.filter(it => it!=id)
      } else {
      newDep = [...item.departments, id]
      }
    setItem(prev => ({...prev, departments: newDep}))
    if (selectRegDepartment) {
      const payload = item.departments.filter(id => regDepartments.map(i => i.id).includes(id))
      getForAny(payload)
    }
  }


  const handleAddTask = () => {
    history.push({
      pathname: '/tasks',
      state: {
        addQuestTask: true,
        questId: item.id
      }
    })
  }

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div>
          <div className={styles.title}>
            {type === "ADD" ? "Добавление квеста" : "Редактирование квеста"}
          </div>
          {item && (
            <div className={styles.modalBody}>
              {confirmation && <Confirmation 
              confirmation={confirmation.text}
              acceptConfirmation={acceptConfirmation}
              rejectConfirmation={() => setConfirmation(null)}
              />}
              <div>
                <div className={styles.firstRow}>
                  <div>
                    <CustomInput
                      max="200"
                      value={item.name}
                      disabled={!updateRights}
                      title="Название квеста"
                      inputWidth="90%"
                      onChange={(e) =>
                        setItem((prev) => ({ ...prev, name: e.target.value }))
                      }
                    />
                  </div>
                  <div>
                    <CustomSelect
                      options={eventLevels}
                      title="Уровень"
                      disabled={!updateRights}
                      onChange={handleSelectLevel}
                      selectedValue={item.level}
                    />
                  </div>
                </div>
                {regDepartments &&
                <div>
                  <div className={styles.label}>Региональные отделения:</div>
                  <div className={styles.regionDepartmentsList}>
                    <div>{regDepartments.slice(0, Math.ceil(regDepartments.length/2)).map(it => 
                    <CustomCheckbox
                      label={it.name}
                      disabled={!updateRights}
                      value={item.departments?.includes(it.id)}
                      onChange={() => handleSelectDepartment(it.id, true)}
                    />)}</div>
                    <div>{regDepartments.slice(Math.ceil(regDepartments.length/2)).map(it => 
                    <CustomCheckbox
                      label={it.name}
                      disabled={!updateRights}
                      value={item.departments?.includes(it.id)}
                      onChange={() => handleSelectDepartment(it.id, true)}
                    />)}</div>
                  </div>
                </div>}
                {item.level=="3" &&
                  <div className={styles.otherDepartmentsList}>
                    {localDepartments &&
                    <div style={{width: "48%", marginRight: "15px"}}>
                      <div className={styles.label}>Местные отделения:</div>
                      <div className={styles.checkBoxBlock}>
                        {loading
                          ? <Loader/>
                          : localDepartments.map(it => 
                            <CustomCheckbox
                              label={it.name}
                              disabled={!updateRights}
                              value={item.departments?.includes(it.id)}
                              onChange={() => handleSelectDepartment(it.id)}
                            />
                        )}
                      </div>
                    </div>}
                    {detachments &&
                    <div>
                      <div className={styles.label}>Отряды:</div>
                      <div className={styles.checkBoxBlock}>
                        {loading
                        ? <Loader/>
                        : detachments.map(it => 
                          <CustomCheckbox
                            label={it.name}
                            disabled={!updateRights}
                            value={item.departments?.includes(it.id)}
                            onChange={() => handleSelectDepartment(it.id)}
                          />
                        )}
                      </div>
                    </div>}                
                  </div>}
                <CustomSelect
                  options={eventOptions?.map((it) => ({value: it.id, name: it.title}))}
                  disabled={!updateRights}
                  title="Относится к мероприятию"
                  onChange={(e) => setItem((prev) => ({ ...prev, event: e.target.value }))}
                  selectedValue={item.event}
                />
                <CustomCheckbox
                  label='Отображать в мобильном приложении'
                  value={item?.status === 2}
                  onClick={statusToggleClick}
                />
                {/* Добавление заданий временно попросили убрать */}
                
                {/* <div className={styles.tasksBlock}>
                  <h3>Задания:</h3>
                  {updateRights && <div className={styles.addTask}><IoIosAdd onClick={handleAddTask}/></div>}
                  {tasks && !!tasks.length && (
                    <div style={{position: "relative", paddingBottom: "80px"}}>
                      <table>
                        <thead>
                          <tr className={styles.headRow}>
                            <th>№</th>
                            <th>Задание:</th>
                            <th>Баллы:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tasks.map((it, i) => (
                            <tr
                              key={i}
                              className={styles.tableItem}
                              
                            >
                              <td>{i + 1}</td>
                              <td onClick={() => handleSelectTask(it)}>{strip(it.name)}</td>
                              <td>{it.points}</td>
                              <td>
                                {updateRights && <IoMdClose onClick={() => setConfirmation({text: it.name, id: it.id})}/>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div> */}
              </div>
              <div className={styles.modalButtons}>
                {updateRights &&
                <ButtonRed
                  onClick={type === "ADD" ? handleAddQuest : handleEditQuest}
                >
                  {type === "ADD" ? "Добавить" : "Сохранить"}
                </ButtonRed>}
                <ButtonRed onClick={props.onClose}>Закрыть</ButtonRed>
              </div>
            </div>
          )}
        </div>
    </Modal>
  );
};

export default QuestsModal;

import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdCreate, IoMdClose } from "react-icons/io";
import Input from '../../components/CustomInput/CustomInput'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import Loader from "../../components/Loader/Loader";
import styles from "./Videos.module.css";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { BACKEND_DOMAIN, 
  // BACKEND_URL_PREFIX
 } from "../../utils/const";
import previewImage from "../../assets/images/preview.png"

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import "react-fine-uploader/gallery/gallery.css";
import { createNotification } from "../../components/Notifications/Notifications";
import moment from "moment";

const VideosModal = ({
  type,
  numberPage,
  selectedItem,
  editVideoItem,
  addVideoItem,
  loading,
  // getVideoItems,
  accessRights,
  userRegions,
  // deleteFile,
  // auth,
  ...props
}) => {

  // const uploader = new FineUploaderTraditional({
  //   options: {
  //     chunking: {
  //       enabled: true,
  //       mandatory: true
  //     },
  //     callbacks: {
  //       onComplete: (id) => {
  //         setProgress({totalBytes: 0, uploadedBytes: 0});
  //         validateFile(uploader.methods.getFile(id));
  //         createNotification("success", `Файл загружен`); 
  //         getVideoItems();
  //         setIsLoad(false);
  //       },
  //       onProgress: function(id, name, uploadedBytes, totalBytes) {
  //         setIsLoad(true);
  //         setProgress({uploadedBytes: uploadedBytes, totalBytes: totalBytes});
  //       },
  //     },
  //     // acceptFiles: '.mp4',
  //     deleteFile: {
  //       enabled: true,
  //       endpoint: "/uploads"
  //     },
  //     validation: {
  //       allowedExtensions: ['mp4'],
  //     },
  //     request: {
  //       endpoint: `${BACKEND_URL_PREFIX}/video/${selectedItem?.id}/video`,
  //       customHeaders: {Authorization: `Basic ${btoa(`${auth.usernane}:${auth.token}`)}`},
  //     },
  //     retry: {
  //       enableAuto: false
  //     },
  //     autoUpload: false,
  //   }
  // });

  const updateRights = type==='ADD' || accessRights.ac_update || (accessRights.ac_update_by_region && userRegions.includes(selectedItem?.regionId))

  const [item, setItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [existingFile, setExistingFile] = useState(null);
  const [addFile, setAddFile] = useState(null);
  const [preview, setPreview] = useState(null);
  // const [progress, setProgress] = useState({totalBytes: 0, uploadedBytes: 0});
  const [isload, setIsLoad] = useState(false);
  const [isVideo, setIsVideo] = useState(false);


  useEffect(() => {
    if (type === "ADD") {
      setItem({ name: "" })
    } else {
      if (selectedItem) {
        setItem(selectedItem)
        const file = selectedItem.files ? selectedItem.files[0] : null
        setExistingFile(file)
      }
    }
  }, [selectedItem, type]);

  const addVideo = () => {
    
    addVideoItem(item, numberPage+1, addFile, preview);
    props.onClose();
    // getVideoItems();
  };

  const editVideo = () => {
    if (!isload) {
      const incomingFile = selectedItem.files[0]
      const removeFile = incomingFile && !existingFile && incomingFile.file
      const removePreview = existingFile && !existingFile.preview && incomingFile.preview?.name
      editVideoItem(item, numberPage+1, removeFile, addFile, preview, removePreview);
      props.onClose();
      // getVideoItems();
    }
  };

  
  const handleSelectFile = (e) => {	
    const file = e.target.files[0]	
    const name = file.name	
    setAddFile({file, name})	
    setExistingFile(null) 	
  };

  const handleDeleteFile = (e) => {
    const name = existingFile?.file || addFile.name
    e.stopPropagation()
    setConfirmation(name);
  };

  const acceptConfirmation = (name) => {
    // deleteFile(selectedItem, selectedItem.files[0].file, numberPage+1);
    setConfirmation(null);
    setExistingFile(null);
    setAddFile(null);
    // setItem(prev => ({...prev, length: null}));
  };

  const rejectConfirmation = () => {
    setConfirmation(null)
  };
  
  const downloadFile = () => {
    if (existingFile) {
      const link = document.createElement('a')
      link.setAttribute('href', `${BACKEND_DOMAIN || window.location.origin}/${existingFile.fileurl}`);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', 'download');
      link.click()
    }
  }

  const handleSelectPreview = (e) => {
    e.stopPropagation()
    const input = document.getElementById('inputPreview')
    input.click()
  }

  const handleSetPreview = (e) => {
    const file = e.target.files[0]
    setPreview(file)
    existingFile &&
      setExistingFile(prev => ({...prev, preview: null}))
  }

  const clearPreview = (e) => {
    e.stopPropagation()
    setPreview(null)
    existingFile &&
      setExistingFile(prev => ({...prev, preview: null}))
  }

  const statusToggleClick = () => {
    const status = item.status === 2 ? 1 : 2
    setItem(prev => ({...prev, status}))
  }

  // const getMegabytes = (bytes) => {
  //   return (bytes/1024/1024).toFixed(1)
  // }

  // const handleClose = () => {
  //   if (!isload) {
  //     setItem(null);
  //     props.onClose();
  //     getVideoItems(numberPage + 1);
  //   }
  // }

  // const  validateFile = (file) => {

  //   var video = document.createElement('video');
  //   video.preload = 'metadata';

  //   video.onloadedmetadata = function() {
  //     window.URL.revokeObjectURL(video.src);
  //     const formattedTime = moment.utc(video.duration*1000).format('HH:mm:ss');
  //     editVideoItem({...item, length: formattedTime}, numberPage+1)
  //   }

  //   video.src = URL.createObjectURL(file);
  // }

  const handleCancel = () => {
    if (!isload) {
      props.onClose();
    }
  }

  return (
    <Modal
    {...props}
      // open={props.open}
      // onClose={handleClose}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {loading && <Loader top="40%" left="40%" />}
      <div className={styles.title}>
        {type === "ADD" ? "Добавление документа" : "Редактирование документа"}
      </div>
      <div className={styles.modalBody}>
          <Input
            title="Название"
            value={item?.name}
            // disabled={!updateRights}
            onChange={(e) =>
              setItem((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          {/* <div 
            className={styles.fileBlock}> */}
            {(existingFile?.file || addFile) &&
            <>
            <p>Файл:</p>
            <input
              type="file"
              id="inputPreview"
              // disabled={!updateRights}
              accept="image/png, image/jpeg"
              onChange={handleSetPreview}
              hidden
            />
            <div
              className={styles.fileItem}
              onClick={downloadFile}>
               {updateRights && <IoMdCreate onClick={handleSelectPreview}/>}
               {updateRights && <IoMdClose onClick={handleDeleteFile}/>}
              {/* {
                updateRights ?
                <>
                <IoMdCreate onClick={handleSelectPreview} title='Изменить превью'/>
                <IoMdClose onClick={handleDeleteFile}  title='Удалить видео'/>
                </>
                :
                <></>
              } */}
              <div className={styles.filePreview}>
                <img
                  src={(existingFile && existingFile.preview) 
                        ? `${BACKEND_DOMAIN || window.location.origin}/${existingFile.preview.path}/${existingFile.preview.name}`
                        : (addFile && addFile.preview)
                          ? addFile.preview
                          : preview
                            ? URL.createObjectURL(preview)
                            : previewImage
                      }
                  alt="o_O"
                />
                {(existingFile?.preview || preview) && updateRights &&
                <button
                  onClick={clearPreview}
                >
                  Очистить
                </button>}
              </div>
              <div className={styles.fileTitle}>{existingFile ? existingFile.file : addFile.name}</div>
            </div>
            </>
          }
          {/* </div> */}
          {/* {!(existingFile?.file || addFile) 
          // && type === 'EDIT' 
          && updateRights
            ? 
            <div className={styles.input}>
              <Gallery 
              uploader={uploader} 
              accept="video/mp4,video/x-m4v,video/*"/>
              {
              progress.totalBytes
               ?
              <div className="progressbarWrapper">
              <span className="progressBytes">{getMegabytes(progress.totalBytes) + 'МБ'+ ' / ' + getMegabytes(progress.uploadedBytes) + 'МБ'}</span>
                <span id="greenBar" style={{width: `${100/progress.totalBytes*progress.uploadedBytes}%`}}></span>
              </div>
              : <></>}
            </div>
            : 
            ''
            } */}
          <CustomCheckbox
            label='Отображать в мобильном приложении'
            value={item?.status === 2}
            onClick={statusToggleClick}
          />
           {!(existingFile || addFile) && updateRights &&	
            <div className={styles.input}>	
            <InputFile	
              title="Добавить видео"	
              type="file"	
              accept="video/mp4,video/x-m4v,video/*"	
              onChange={(e) => handleSelectFile(e)}	
            />	
          </div>}
        <div className={styles.modalButtons}>
          <ButtonRed onClick={type === "ADD" ? addVideo : editVideo}>
            {type === "ADD" ? "Добавить" : "Сохранить"}
          </ButtonRed>
          <ButtonRed onClick={() => handleCancel()}>Закрыть</ButtonRed>
        </div>
      </div>
    </Modal>
  );
};

export default VideosModal;
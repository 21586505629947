import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import {
  getYunarmianProfile,
  editYunarmianProfile,
  clearProfile,
  addYunarmianProfile,
  uploadDocs,
  deleteDocs,
  getTroopHistory,
  removeTroopHistory,
  getTroops,
  removeTroopList,
  transferTroop,
  backUploadToggle,
  rotateDocumentImage,
  editAwardsPerson,
  addAwardProfile,
  sendDocuments,
  getDocsInfo,
  getCountry,
  getAwardsPerson,
  deleteAwardsPerson,
  deleteProfile,
  getPortfolio
} from "../../actions/yunarmiyans";
import {getAwardsObject} from '../../actions/awards'
import { getDepartments } from "../../actions/departments";
import { getRegions } from "../../actions/users";
import styles from "./Profile.module.scss";
import {
  IoIosArrowBack,
  IoIosDocument,
  IoMdCreate,
  IoIosCheckmark,
  IoIosCloseCircle,
  IoIosCloseCircleOutline,
  IoMdTime,
  IoIosClose,
  IoMdShuffle,
  IoMdMedal,
  IoMdTrash
} from "react-icons/io";
import { IoPersonRemove } from "react-icons/io5";
import FormInput from "../../assets/common/FormInput/FormInput";
import FormDatePicker from "../../assets/common/FormDatePicker/FormDatePicker";
import FormSelect from "../../assets/common/FormSelect/FormSelect";
import Loader from "../../components/Loader/Loader";
import { BACKEND_DOMAIN, errorFieldsList, selects, profile_types } from "../../utils/const";
import "swiper/swiper-bundle.css";
import { useDispatch } from "react-redux";
import moment from "moment";
import FormCheckbox from "../../assets/common/FormCheckbox/FormCheckbox";
import { createDocData } from "./ProfilePdf";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import { createNotification } from "../../components/Notifications/Notifications";
import { getRegionOptions, getUserRegionsOptions } from "../../utils/getOptions";
import FileLabel from '../../components/FileLabel/FileLabel'
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { CharacteristicModal, EventsBlock, SportsActivities, SliderComp } from './Components'




function Profile({
  history,
  data,
  photos,
  getYunarmianProfile,
  editYunarmianProfile,
  addYunarmianProfile,
  getRegions,
  getDepartments,
  regions,
  clearProfile,
  uploadDocs,
  deleteDocs,
  loading,
  newID,
  regionDep,
  localDep,
  authData,
  sportData,
  activityData,
  getTroopHistory,
  troopHistory,
  removeTroopHistory,
  deleteProfile,
  troopList,
  getTroops,
  addAwardProfile,
  removeTroopList,
  transferTroop,
  deleteAwardsPerson,
  backUploadToggle,
  isBack,
  getPortfolio,
  rotateDocumentImage,
  getAwardsObject,
  sendDocuments,
  docsInfo,
  getDocsInfo,
  editAwardsPerson,
  getCountry,
  country,
  accessRights,
  superuser,
  userRegions,
  awardsList = [],
  getAwardsPerson,
  awardsProfileList = [],
  ...props
}) {
  
  const updateRights = accessRights.ac_update || (accessRights.ac_update_by_region && userRegions.includes(data?.regionId))
  const addMode = props.match.params.userId === "add"

  const [editMode, setEditMode] = useState(false);
  const [photosDocs, setPhotosDocs] = useState([]);
  const [photosPassport, setPhotosPassport] = useState([]);
  const [photosHumon, setPhotosHumon] = useState([]);
  const [photoCert, setSetPhotoCert] = useState([]);
  const [goEdit, setGoEdit] = useState(null);
  const [goAdd, setGoAdd] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [showTroop, setShowTroop] = useState(null);
  const [searchTroop, setSearchTroop] = useState("");
  const [changeTroop, setChangeTroop] = useState(null);
  const [sportPopup, setSportPopup] = useState({path: null, name: null});
  const [certif, setCertif] = useState(false);
  const [fill, setFill] = useState(false);
  const [status, setStatus] = useState('');

  const [profileType, setProfileType] = useState('Юнармеец')
  const [charModalMode, setCharModalMode] = useState('')

  const [awards, setAwards] = useState([]);
  // const [awardsDelete, setAwardsDelete] = useState([]);
  const [awardsOnPerson, setAwardsOnPerson] = useState([]);
  const [editAwardsOnPerson, setEditAwardsOnPerson] = useState([]);
  const [confirmation, setConfirmation] = useState(null)

  useEffect(() => {
    setCertif(data?.has_certificate);
    setFill(data?.profile_filled);
    getCountry();
    data && getDocsInfo(data.id);
    if (data?.id) {
      getAwardsPerson(data?.id)
    }
  }, [data])


  const dispatch = useDispatch();

  const phoneRegExp = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
  const cyrillicRegEx = /[\u0401\u0451\u0410-\u044f]/g
  const fio = /^[а-яА-ЯёЁ0-9-\s]+$/u
  const personalId = /^[0-9-\s]+$/u

  const defaultValidationSchema = Yup.object().shape({
    lastName: Yup.string().required("поле является обязательным"),
    firstName: Yup.string().required("поле является обязательным"),
    fathersName: Yup.string().required("поле является обязательным"),
    regionId: Yup.string().test('test', 'поле является обязательным', val => val && val[0]!=0).required("поле является обязательным"),
    country_id: Yup.string().test('test', 'поле является обязательным', val => val && val[0]!=0).required("поле является обязательным"),
    birthday: Yup.string().required("поле является обязательным"),
    signDate: Yup.string().nullable().when(
      'status',
      (_, schema) => _ == 'external_participant'
        ? schema
        : schema.required("поле является обязательным")
    ),
    sex: Yup.string().test('test', 'поле является обязательным', val => val && val[0]!=0).required("поле является обязательным"),
    email: Yup.string().when(
      'firstName',
      (_, schema) => status == 'deleted' || status == 'entered_by_mistake'
        ? schema
        : schema.trim().email("формат: user@example.ru").required("поле является обязательным")
    ),
    phone: Yup.string().when(
      'firstName',
      (_, schema) => status == 'deleted' || status == 'entered_by_mistake'
        ? schema
        : schema.trim().matches(phoneRegExp, "формат: +7 999 7654321").required("поле является обязательным")
    ),
    personalId: Yup.string().nullable().matches(personalId, 'некорректное значение').when(
      'firstName',
      (_, schema) => editMode == true && props.match.params.userId != 'add'
        ? schema.required("поле является обязательным")
        : schema
    ),
    status: Yup.string().required("поле является обязательным"),
    postalCode: Yup.string().nullable().min(6, "длина индекса - 6 символов").max(6, "длина индекса - 6 символов"),
    aboutSelf: Yup.string().nullable().matches(cyrillicRegEx, "некорректное значение"),
    city: Yup.string().nullable().matches(cyrillicRegEx, "некорректное значение"),
    address: Yup.string().nullable().matches(cyrillicRegEx, "некорректное значение"),
    joiningReason: Yup.string().nullable().matches(cyrillicRegEx, "некорректное значение"),
    motherLastName: Yup.string().nullable().matches(fio, "некорректное значение, при отсутствии родителя в поле 'фамилия' укажите 'нет'"),
    motherFirstName: Yup.string().nullable().matches(fio, "некорректное значение"),
    motherFathersName: Yup.string().nullable().matches(fio, "некорректное значение"),
    fatherLastName: Yup.string().nullable().matches(fio, "некорректное значение, при отсутствии родителя в поле 'фамилия' укажите 'нет'"),
    fatherFirstName: Yup.string().nullable().matches(fio, "некорректное значение"),
    fatherFathersName: Yup.string().nullable().matches(fio, "некорректное значение"),
    guardianLastName: Yup.string().min(1, "no").nullable().matches(fio, "некорректное значение, при отсутствии родителя в поле 'фамилия' укажите 'нет'"),
    guardianFirstName: Yup.string().nullable().matches(fio, "некорректное значение"),
    guardianFathersName: Yup.string().nullable().matches(fio, "некорректное значение"),
    school: Yup.string().nullable(),
    class: Yup.string().nullable(),
    sportSectionsName: Yup.string().nullable().matches(cyrillicRegEx, "некорректное значение"),
    sport_section_result: Yup.string().nullable().matches(cyrillicRegEx, "некорректное значение"),
    motherContactPhone: Yup.string().nullable().matches(phoneRegExp, "формат: +7 999 7654321"),
    fatherContactPhone: Yup.string().nullable().matches(phoneRegExp, "формат: +7 999 7654321"),
    guardianContactPhone: Yup.string().nullable().matches(phoneRegExp, "формат: +7 999 7654321"),
    motherEmail: Yup.string().trim().nullable().email("формат: user@example.ru"),
    fatherEmail: Yup.string().trim().nullable().email("формат: user@example.ru"),
    guardianEmail: Yup.string().trim().nullable().email("формат: user@example.ru"),
    regional_department_id: Yup.string().nullable().when(
      'status',
      (_, schema) => _ == 'external_participant'
        ? schema
        : schema.required("поле является обязательным")
    ),
    domestic_department_id:  Yup.string().nullable().when(
      'regional_department_id',
      (_, schema) => localDep?.length && formik.values.status != 'external_participant'
        ? schema.required("поле является обязательным")
        : schema
    )
  });

  const formik = useFormik({
    initialValues: addMode ? {
      lastName: "",
      firstName: "",
      fathersName: "",
      unaprofile_type: 'Юнармеец'
    } : {...data},
    enableReinitialize: true,
    validationSchema: defaultValidationSchema,
    validateOnChange: true,
    validateOnMount: props.match.params.userId==="add" ? false : true, 
    onSubmit: (values) => {
      addMode
      ? handleAddProfile(values)
      : handleEditProfile(values)
    },
  });
// console.log(formik.values.status)
  useEffect(() => {
    formik.validateForm()
  }, [localDep])

  const check1 = (mas) => {
    
    const rows = mas.map(obj => {
     if (obj.id && obj.date_assigment && obj.order_number) {
      return true
     } else {
      return false
     }
    })
    return !(rows.includes(false))
  }

  const check2 = (mas) => {
    const rows = mas.map(obj => {
     if (obj.hasOwnProperty('date_assigment')) {
      if (obj.date_assigment) {
        return true
      } else return false
     }
     if (obj.hasOwnProperty('order_number')) {
      if (obj.order_number) {
        return true
      } else return false
     }
    })
    return !(rows.includes(false))
  }

  useEffect(() => {
    if (formik.values.regionId != 96 && formik.values.regionId != 97) {
      formik.values.country_id = 174;
    } else if (formik.values.regionId == 96 || formik.values.regionId == 97) {
      formik.values.country_id = 0;
    }
  }, [formik.values.regionId])
  
  useEffect(() => {
    setProfileType(formik?.values.unaprofile_type);
  }, [formik?.values.unaprofile_type])

  useEffect(() => {
    newID && setGoAdd(!goAdd);
  }, [newID]);

  useEffect(() => {
    if (formik?.values?.status == 'entered_by_mistake' || formik?.values?.status == 'deleted') {
      const x = formik?.values?.status;
      setStatus(prev => x);
    } else {
      setStatus('');
    }
  }, [formik?.values?.status])
  

  useEffect(() => {
    photos &&
      photos !== null &&
      setPhotosDocs(photos.filter((photo) => photo.group == "docs"));
    photos &&
      photos !== null &&
      setPhotosHumon(photos.filter((photo) => photo.group == "photo"));
    photos &&
      photos !== null &&
      setPhotosPassport(photos.filter((photo) => photo.group == "passport"));
    photos &&
      photos !== null &&
      setSetPhotoCert(
        photos.filter((photo) => photo.group == "trp_certificate")
      );
  }, [photos]);

  useEffect(() => {
    if (addMode) {
      setEditMode(true);
      setPhotosDocs([]);
      setPhotosHumon([]);
      setPhotosPassport([]);
      setSetPhotoCert([]);
      setGoAdd(false);
      setGoEdit(false);
      dispatch({ type: "SET_UNARMIAN_NEW_ID", payload: null });
    } else {
      getYunarmianProfile(props.match.params.userId);
    }
    getRegions();
    getDepartments();
    return () => {
      clearProfile();
    };
  }, []);

  useEffect(() => {
    if (data) {
      getTroopHistory(data?.id);
      getTroops(data?.regional_department_id);
      getDepartments();
    }
  }, [data]);

  useEffect(() => {
    formik.setFieldValue("profile_filled", fill)
    if (formik.values && fill) {
      formik.setFieldValue("profile_filled_date", formik.values.profile_filled_date)
    } else if (formik.values && !fill) {
      formik.setFieldValue("profile_filled_date", null)
    }
  }, [fill]);

  useEffect(() => {
    formik.setFieldValue("has_certificate", certif)
    if (formik.values && certif) {
      formik.setFieldValue("certificate_issuing_date", formik.values.certificate_issuing_date)
    } else if (formik.values && !certif) {
      formik.setFieldValue("certificate_issuing_date", null)
    }
  }, [certif]);

  useEffect(() => {
    if (formik.values) {
      if (formik.values.regional_department_id) {getDepartments(formik.values.regional_department_id);}
      if (editMode) {
        console.log(123)
        formik.setFieldValue("domestic_department_id", null)
        formik.setFieldValue("detachment", null)        
        setChangeTroop(null)
        // formik.setFieldValue("domestic_department_id", null)
      }
      setShowTroop(null);
    }
  }, [formik.values?.regional_department_id]);

  useEffect(() => {
    if (
      formik.values?.domestic_department_id ||
      formik.values?.regional_department_id
    ) {
      getTroops(
        formik.values.domestic_department_id &&
          formik.values.domestic_department_id != 0
          ? formik.values.domestic_department_id
          : formik.values?.regional_department_id
      );

      if (props.match.params.userId !== "add" && data) {
        getTroopHistory(data?.id);
      }
    }
  }, [
    formik.values?.regional_department_id,
    formik.values?.domestic_department_id,
  ]);

  useEffect(() => {
    if (!changeTroop) {
      formik.setFieldValue("chiefLastName", null)
      formik.setFieldValue("chiefFirstName", null)
      formik.setFieldValue("chiefFathersName", null)
      formik.setFieldValue("chiefContactPhone", null)
    }
  }, [changeTroop])
  
  useEffect(() => {
    getAwardsObject('all');
  }, [])

    useEffect(() => {
      data?.id && getAwardsPerson(data?.id);
  }, [data?.id])

  useEffect(() => {
    setAwardsOnPerson(JSON.parse(JSON.stringify(awardsProfileList)));
  }, [awardsProfileList])


  const handleGetHistory = () => {
    getTroopHistory(data?.id);
    getTroops(formik.values.regional_department_id);
    setShowTroop(1);
  };

  const handleCloseTroop = () => {
    setShowTroop(null);
  };
   const handleTransfer = (data) => {
    formik.setFieldValue("chiefLastName", data.chief_lastname || '')
    formik.setFieldValue("chiefFirstName", data.chief_firstname || '')
    formik.setFieldValue("chiefFathersName", data.chief_fathersname || '')
    formik.setFieldValue("chiefContactPhone", data.phone || '')
    setChangeTroop(data.id || 'remove');
    setShowTroop(null);
  };

  const removeFromTroop = (data) => {
    formik.setFieldValue("chiefLastName", data.chief_lastname)
    formik.setFieldValue("chiefFirstName", data.chief_firstname)
    formik.setFieldValue("chiefFathersName", data.chief_fathersname)
    formik.setFieldValue("chiefContactPhone", data.phone)
    setChangeTroop(data.id);
  }

  const goBackHandler = () => {
    backUploadToggle(!isBack);
    history.goBack();
  };

  const handleSetRegion = (e) => {
    const {value} = e.target
    formik.handleChange(e)

    const regionDepId = regionDep?.find(({id, regionId}) => regionId == value && id !== 547)?.id
    regionDepId && formik.setFieldValue("regional_department_id", regionDepId)
  }

  const cancelChanges = () => {
    formik.resetForm();
    setEditMode(false);
    setChangeTroop(troopHistory?.movement_history[0]?.to_detachment || null);
    setSearchTroop("");
    setAwards([]);
  };

  const handleChangeTroop = () => {
    if (data && data.id) {
      getTroopHistory(data.id);
    }
    getTroops(formik.values.regional_department_id);
    setShowTroop(2);
    
  };
  

  const handleEditProfile = (fullValue) => {
    const {articles_files, sport_activity, ...values  } = fullValue
    if (values.status === "active" && !values.signDate) {
      createNotification("error", "Необходимо указать дату заполнения анкеты")
      return
    }
    
    editYunarmianProfile(data.id, values);
    if (changeTroop) {
      const troopId = changeTroop === 'remove' ? null : changeTroop
      transferTroop(data.id, troopId);

    }
    
    setGoEdit(true);
    setSearchTroop("");
    
  };

  const handleAddAward = (e) => {
      setAwards(prev => [...prev, {id: null, order_number: '', date_assigment: null, uniId: Date.now()}])
  }

  const handleDeleteAward = (id, type) => {
    if (type == 'new') {
      const awardsListNew = [...awards];
      const filterAwardsListNew = awardsListNew.filter((el, i) => el.uniId != id);
      setAwards(filterAwardsListNew);
    }
  }

  const handleAddProfile = (values) => {
    
    if (values.status === "active" && !values.signDate) {
      createNotification("error", "Необходимо указать дату заполнения анкеты")
      return
    }

    if (values.profile_filled_date) {
      const newFormat = moment(values.profile_filled_date, 'LLL').format("DD.MM.YYYY")
      formik.setFieldValue('profile_filled_date', newFormat)
    }

    addYunarmianProfile(values, history, changeTroop, awards);
    
  };

  const checkType = (type) => {
    if (type == 'Юнармеец' || type == 'Руководитель отряда') {
      return true;
    } else {
      return false;
    }
  }

  const toPdf = async () => {
    const detachName = formik.values.detachment?.Department[0]?.name
    const doc = await createDocData(formik.values, regions, regionDep, localDep, authData, sportData, activityData, detachName)
    doc.save(`profile.pdf`)
  };

  const submitForm = () => {
    if (certif == true && formik.values.certificate_issuing_date == null) {
      createNotification("error", "Укажите дату торжественного вручения книжки")
    } else {
      if (!formik.values.signDate && formik.values.status != "external_participant") {
        createNotification("error", "Укажите дату заполнения анкеты")
      } else {
        const errorFields = Object.keys(formik.errors)
        const fieldNamesList = errorFields.map(item => errorFieldsList[item])
      
        const x = errorFields.filter(el => el != 'email' && el != 'phone');
          
          if (status) {
            if (x.length > 0) {
              if (errorFields.length) {
                createNotification("error", `Некорректно заполнены поля: ${fieldNamesList.join(', ')}`)
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
              })
              } 
            } else {
              if (check1(awards) && check2(editAwardsOnPerson)) {
                formik.handleSubmit();

                if (awards.length > 0 && data && data.id) {
                  awards.forEach(el => 
                    addAwardProfile(data.id, el.id, el)
                  )
                  setAwards([]);
                }
  
                if (editAwardsOnPerson.length > 0) {
                  editAwardsOnPerson.forEach(el => 
                    editAwardsPerson(awardsProfileList, data.id, el.id, el)
                  )
                }

              } else {
                createNotification("error", `Не заполнены поля в блоке "Награды"`)
              }
              // if (awardsDelete.length > 0) {
              //   awardsDelete.forEach(el => 
              //     dispatch(deleteAwardsPerson(data.id, el))
              //   )
              // }
          }
        } else {
            if (errorFields.length) {
              createNotification("error", `Некорректно заполнены поля: ${fieldNamesList.join(', ')}`)
              window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
          } else {
            if (check1(awards) && check2(editAwardsOnPerson)) {
              formik.handleSubmit();
              
            if (awards.length > 0 && data && data.id) {
              awards.forEach(el => 
                addAwardProfile(data.id, el.id, el)
              )
              setAwards([]);
            }

            if (editAwardsOnPerson.length > 0) {
              editAwardsOnPerson.forEach(el => 
                editAwardsPerson(awardsProfileList, data.id, el.id, el)
              )
            }

            } else {
              createNotification("error", `Не заполнены поля в блоке "Награды"`)
            }
            // if (awardsDelete.length > 0) {
            //   awardsDelete.forEach(el => 
            //     dispatch(deleteAwardsPerson(data.id, el))
            //   )
            // }
          }
        } 
      }
    }
  }

  const getPortfolioHandler = async () => {
    const path = await getPortfolio(data.id)
    if (!path) return;
    const link = document.createElement('a')
    link.setAttribute('href', `${BACKEND_DOMAIN || ''}/files${path}`);
    link.setAttribute('download', 'portfolio');
    link.click()
  }

  const handleChangeAward = (id, e, name, type, idAward) => {
    if (type == 'old') {
      const awardsCopy = [...awardsOnPerson];
      const changeAwards = awardsCopy.map(el => (
        el.departmental_award_id === id
        ? {...el, [name]: e.target.value}
        : el
        ));
        setAwardsOnPerson(changeAwards);
        
        const changeObj = {id: idAward, [name]: e.target.value};
        const awardsOnPerson2 = awardsOnPerson.find(el => el.id == idAward);
        awardsOnPerson2[name] = e.target.value;
        
        let editAwardsOnPerson2 = [];
        let editAwardsOnPersonCopy = [...editAwardsOnPerson];

        if (editAwardsOnPersonCopy.length > 0 && editAwardsOnPersonCopy.find(el => el.id === idAward)) {
          editAwardsOnPerson2 = editAwardsOnPersonCopy.map(el => (
            el.id === idAward 
            ? 
            {...el, id: idAward, [name]: e.target.value}
            : {...el}
            ));

        } else if (editAwardsOnPersonCopy.length > 0 && editAwardsOnPersonCopy.find(el => el.id != idAward)) {
          editAwardsOnPersonCopy.push(changeObj);
          editAwardsOnPerson2 = editAwardsOnPersonCopy;
          
        } else {
          editAwardsOnPerson2.push(changeObj);
        }
        setEditAwardsOnPerson(editAwardsOnPerson2);
          
    }

    
    if (type == 'new') {
      const awardsCopy = [...awards];
      const changeAwards = awardsCopy.map(el => (
        el.uniId === id
        ? {...el, [name]: e.target.value}
        : el
        ));
        setAwards(changeAwards);
      }
  }

  const handleChanger = (e, id, status) => {
    e.stopPropagation();
    if (editMode) {
      deleteAwardsPerson(data.id, id, status);
    }
  }

  const deleteProfileHandler = async (id) => {
    await deleteProfile(id)
    goBackHandler()
  }

  const acceptConfirm = () => {
    deleteProfileHandler(confirmation)
    setConfirmation(null)
  }

  const rejectConfirm = () => {
    setConfirmation(null)
  }

  const detachmentName = () => {
    const actualDetachment = formik.values.detachment?.Department[0]
    if (changeTroop) {
      if (changeTroop === 'remove') return 'Отряд не выбран'
      return troopList?.find((_el) => _el.id == changeTroop)?.name
    } else {
      return actualDetachment ? actualDetachment.name : 'Отряд не выбран'
    }
  }

  if (loading) return <Loader />;
  if (!addMode && !data) return null;

  const openCharModal = (mode) => () => {
    setCharModalMode(mode)
  }

  return (
    <div className={styles.wrapper}>
      {confirmation && <Confirmation acceptConfirmation={acceptConfirm} rejectConfirmation={rejectConfirm} title='Вы уверены, что хотите удалить профиль?'/>}
      <CharacteristicModal
        open={!!charModalMode}
        onClose={() => setCharModalMode('')}
        userId={props.match.params.userId}
        mode={charModalMode}
        formik={formik}
        profileData={data}
        createPdf={toPdf}
        loadPortfolio={getPortfolioHandler}
      />
      {isPopup && (
        <>
          <div className={styles.bg}></div>
          <div className={styles.popup} onClick={() => setIsPopup(null)}>
            <i className={styles.popup_close} onClick={() => setIsPopup(null)}>
              <IoIosCloseCircle />
            </i>
            <img
              src={
                typeof isPopup === "string"
                  ? isPopup
                  : `${BACKEND_DOMAIN || ""}${isPopup.path}${isPopup.name}`
              }
              alt="o_O"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </>
      )}
      <div className={styles.fio}>
        {addMode
          ? "Добавление пользователя"
          : `${data.lastName} ${data.firstName} ${data.fathersName}`
        }
      </div>
      {(data || addMode) && (
        <Formik>
          <div className={styles.descriptionWrap}>
            <div className={styles.description}>
              {editMode ? (
              data ? (
                <div className={styles.buttonsTop}>
                  <ButtonRed
                    style={{ borderRadius: "5px", height: "30px" }}
                    onClick={submitForm}
                  >
                    <IoIosCheckmark size={26} />
                    <span>СОХРАНИТЬ</span>
                  </ButtonRed>
                  <ButtonRed
                    style={{ borderRadius: "5px", height: "30px" }}
                    onClick={cancelChanges}
                  >
                    <IoIosCloseCircleOutline size={26} />
                    <span>ОТМЕНИТЬ ИЗМЕНЕНИЯ</span>
                  </ButtonRed>
                </div>
              ) : (
                <div className={styles.buttonsTop}>
                  <ButtonRed
                    style={{ borderRadius: "5px", height: "30px" }}
                    onClick={() => history.goBack()}
                  >
                    <IoIosArrowBack size={22} />
                    <span>НАЗАД</span>
                  </ButtonRed>
                  <ButtonRed
                    style={{ borderRadius: "5px", height: "30px" }}
                    onClick={submitForm}
                  >
                    <IoIosCheckmark size={26} />
                    <span>ДОБАВИТЬ</span>
                  </ButtonRed>
                </div>
              )
            ) : (
              <div className={styles.buttonsTop}>
                <ButtonRed
                  style={{ borderRadius: "5px", height: "30px" }}
                  onClick={goBackHandler}
                >
                  <IoIosArrowBack size={22} />
                  <span>НАЗАД</span>
                </ButtonRed>
                <ButtonRed
                  style={{ borderRadius: "5px", height: "30px" }}
                  onClick={openCharModal('portfolio')}
                >
                  <IoIosDocument size={22} />
                  <span>ПОРТФОЛИО PDF</span>
                </ButtonRed>
                <ButtonRed
                  style={{ borderRadius: "5px", height: "30px" }}
                  onClick={openCharModal('profile')}
                >
                  <IoIosDocument size={22} />
                  <span>ПРОФИЛЬ PDF</span>
                </ButtonRed>
                {updateRights &&
                <ButtonRed
                  onClick={() => setEditMode(true)}
                  style={{ borderRadius: "5px", height: "30px" }}
                >
                  <IoMdCreate size={22} />
                  <span>РЕДАКТИРОВАТЬ</span>
                </ButtonRed>}
                {superuser && data?.regionId === 99 && 
                <ButtonRed
                  onClick={() => setConfirmation(data.id)}
                  style={{ borderRadius: "5px", height: "30px" }}
                >
                  <IoIosClose size={30} />
                  <span>УДАЛИТЬ</span>
                </ButtonRed>}
              </div>
            )}
              <div className={styles.personalData}>
                <div className={styles.title}>Личные данные</div>
                <div className={styles.personalDataBlock}>
                  <div className={styles.personalDataLeft}>
                    <div className={styles.dataRow}>
                      <FormInput
                      onBlur={formik.handleBlur}
                        // width="250px"
                        title="Фамилия *"
                        value={formik.values.lastName}
                        notValid={formik.errors.lastName}
                        editMode={editMode}
                        name="lastName"
                        onChange={formik.handleChange}
                      />
                      <FormInput
                        // width="250px"
                        title="Имя *"
                        value={formik.values.firstName}
                        notValid={formik.errors.firstName}
                        editMode={editMode}
                        name="firstName"
                        onChange={formik.handleChange}
                      />
                      <FormInput
                        // width="250px"
                        title="Отчество *"
                        editMode={editMode}
                        value={formik.values.fathersName}
                        notValid={formik.errors.fathersName}
                        name="fathersName"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className={styles.dataRow}>
                      <FormSelect
                        // width="250px"
                        title="Пол *"
                        editMode={editMode}
                        value={formik.values.sex}
                        notValid={formik.errors.sex}
                        name="sex"
                        options={selects.sex.slice(1)}
                        onChange={formik.handleChange}
                      />
                      <FormDatePicker
                        // width="250px"
                        title="День рождения *"
                        editMode={editMode}
                        value={formik.values.birthday}
                        notValid={formik.errors.birthday}
                        name="birthday"
                        onChange={formik.handleChange}
                        />
                      <FormInput
                        // width="250px"
                        title="Личный номер"
                        editMode={editMode}
                        value={formik.values.personalId}
                        notValid={formik.errors.personalId}
                        name="personalId"
                        onChange={formik.handleChange}
                      />
                    </div>
    
                      <div className={styles.dataRow}>
                      <FormSelect
                        options={accessRights.ac_create
                          ? getRegionOptions(regions)
                          : getUserRegionsOptions(regions, userRegions)
                        }
                        title="Регион *"
                        width="fit-content"
                        name="regionId"
                        value={formik.values.regionId}
                        onChange={handleSetRegion}
                        editMode={editMode}
                        notValid={formik.errors.regionId}
                      />
                      
                      {
                        (formik.values.regionId == 96 || formik.values.regionId == 97) ? 
                        <FormSelect
                        options={country.map((it) => ({
                          name: `${it.name || it.short_name}`,
                          value: it.id,
                        }))}
                        title="Страна *"
                        width="fit-content"
                        name="country_id"
                        value={formik.values.country_id}
                        onChange={formik.handleChange}
                        editMode={editMode}
                        notValid={formik.errors.country_id}
                      />
                      :
                      <></>
                      }
                    </div>

                    
                    <div className={styles.dataRow}>
                    {checkType(profileType) ?
                      <div style={{margin: '0 .5rem'}}>
                      <FormInput
                      width="80px"
                      title="Индекс"
                      editMode={editMode}
                      value={formik?.values?.postalCode}
                      notValid={formik?.errors?.postalCode}
                      name="postalCode"
                      onChange={formik?.handleChange}
                      type="number"
                    /></div>
                      : <></>}

                      <div style={{margin: '0 .5rem'}}>
                      <FormInput
                        width="300px"
                        title="E-mail"
                        editMode={editMode}
                        value={formik.values.email}
                        notValid={formik.errors.email}
                        name="email"
                        onChange={formik.handleChange}
                      />
                      </div>
                      <div style={{margin: '0 .5rem'}}>
                      <FormInput
                        width="180px"
                        title="Контактный телефон"
                        editMode={editMode}
                        value={formik.values.phone}
                        notValid={formik.errors.phone}
                        name="phone"
                        onChange={formik.handleChange}
                      />
                      </div>
                      <div style={{margin: '0 .5rem'}}>
                      <FormInput
                        width="150px"
                        title="VK ID"
                        editMode={editMode}
                        value={formik.values.vkid}
                        notValid={formik.errors.vkid}
                        name="vkid"
                        onChange={formik.handleChange}
                      />
                      </div>
                    </div>
                    {checkType(profileType) ?
                    <></>
                    : <div className={styles.dataRow}>
                    <FormDatePicker
                      width="380px"
                      title="Дата принятия в ЮНАРМИЮ"
                      editMode={editMode}
                      value={formik.values.signDate}
                      name="signDate"
                      onChange={formik.handleChange}
                    />
                    <FormSelect
                      width="380px"
                      title="Текущий статус"
                      editMode={editMode}
                      value={formik.values.status}
                      name="status"
                      notValid={formik.errors.status}
                      onChange={formik.handleChange}
                      options={selects.status.slice(1)}
                    />
                  </div>}

                    {checkType(profileType) ?
                    <div className={styles.dataRow}>

                      <FormInput
                        width="400px"
                        title="Населенный пункт"
                        editMode={editMode}
                        value={formik?.values?.city}
                        notValid={formik?.errors?.city}
                        name="city"
                        onChange={formik.handleChange}
                      />

                      <FormInput
                        width="780px"
                        title="Адрес"
                        editMode={editMode}
                        value={formik?.values?.address}
                        notValid={formik?.errors?.address}
                        name="address"
                        onChange={formik.handleChange}
                        placeholder="Образец: г.Москва, 3-я улица Строителей, дом 25, кв. 12 "
                      />

                    </div>
                    : <></>}
                  </div>
                  <SliderComp
                    newID={newID}
                    photos={photosHumon}
                    editMode={editMode}
                    type="photo"
                    selectedItem={data}
                    setGoEdit={setGoEdit}
                    goEdit={goEdit}
                    goAdd={goAdd}
                    setEditMode={setEditMode}
                    rotateDocumentImage={rotateDocumentImage}
                    isAvatar={true}
                  />
                </div>
              </div>
              <div className={styles.formData}>
                <div className={styles.title}>Анкетные данные</div>
                <div className={styles.formDataBlock}>
                  <div className={styles.dataRow}>
                    <FormSelect
                      width="420px"
                      title="Региональное отделение"
                      editMode={editMode}
                      value={formik.values.regional_department_id}
                      notValid={formik.errors.regional_department_id}
                      name="regional_department_id"
                      onChange={formik.handleChange}
                      options={regionDep?.map((it) => ({
                        name: it.name,
                        value: it.id,
                      }))}
                    />
                    {
                      editMode ?
                      <FormSelect
                        width="420px"
                        title="Местное отделение"
                        editMode={editMode}
                        value={formik.values.domestic_department_id}
                        notValid={formik.errors.domestic_department_id}
                        name="domestic_department_id"
                        onChange={formik.handleChange}
                        options={localDep?.map((it) => ({
                          name: it.name,
                          value: it.id,
                        }))}
                      />  
                    :
                      <FormInput
                        width="400px"
                        title="Местное отделение"
                        editMode={editMode}
                        value={formik.values.domestic_department_name}
                        notValid={formik.errors.domestic_department_name}
                        name="domestic_department_name"
                        onChange={formik.handleChange}
                      />
                    }
                    <div className={`${styles.inputWrapper}`}>
                      <div className={styles.titleTroop}>Название отряда</div>
                      <div className={`${styles.block} ${styles.blockTroop}`}>
                        {detachmentName()}
                        <div className={styles.iconsBlock}>
                          {props.match.params.userId !== "add" ? (
                            <i
                              onClick={handleGetHistory}
                              title="Посмотреть историю"
                            >
                              <IoMdTime />
                            </i>
                          ) : (
                            ""
                          )}
                          {editMode ? (
                            <>
                              <i
                                onClick={handleChangeTroop}
                                title="Сменить группу"
                              >
                                <IoMdShuffle />
                              </i>
                              
                              {!!changeTroop && 
                                  <i
                                    title="Исключить из отряда"
                                    onClick={() => removeFromTroop(
                                    {
                                      chief_lastname: '',
                                      chief_firstname: '',
                                      chief_fathersname: '',
                                      phone: '',
                                      id: null
                                    }
                                  )}>
                                  <IoPersonRemove/>
                                </i>
                              }

                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {troopHistory && troopHistory.movement_history && showTroop == 1
                        ? (
                            <ul className={styles.historyBlock}>
                              <i
                                className={styles.closeHistory}
                                onClick={handleCloseTroop}
                              >
                                <IoIosClose />
                              </i>
                              <span className={styles.titleHistory}>
                                История перемещения по отрядам:
                              </span>
                              {troopHistory && troopHistory.movement_history && !!troopHistory.movement_history.length
                                ? (
                                    troopHistory?.movement_history.map((el) => {
                                      const userName = data ? `${data.lastName} ${data.firstName} ${data.fathersName}` : 'неизвестный пользователь'
                                      const userChanged = el.updated_by || userName
                                      const dateChanged = moment(el.updated).format('DD.MM.YYYY')
                                      return (
                                        <li className={styles.historyName} key={el.id}>
                                          <span>{el.to_detachment_name || 'Без отряда'}</span>
                                          <span>({userChanged} {dateChanged})</span>
                                        </li>
                                      )
                                    })
                                  )
                                : (
                                    <li className={styles.historyName}>
                                      Информация отсутствует.
                                    </li>
                                  )
                              }
                            </ul>
                          )
                        : ""
                      }
                      {troopList && troopList.length > 0 && showTroop == 2 && (
                        <div className={styles.troopPopup}>
                          <i
                            className={styles.closeHistory}
                            onClick={handleCloseTroop}
                          >
                            <IoIosClose />
                          </i>
                          <span className={styles.titleHistory}>
                            Быстрый поиск:{" "}
                            <input
                              value={searchTroop || ""}
                              onChange={(e) => setSearchTroop(e.target.value)}
                              className={styles.inputSearch}
                            />
                          </span>
                          <ul className={styles.troopListBlock}>
                            <ButtonRed className={styles.removeButton} onClick={() => handleTransfer({})}>Исключить</ButtonRed>
                            {troopList && troopList ? (
                              troopList
                                .filter((_el) =>
                                  _el.name
                                    .toLowerCase()
                                    .includes(searchTroop.toLowerCase())
                                )
                                .map((el) => (
                                  <li
                                    className={
                                      el.id == changeTroop
                                        ? styles.troop_active
                                        : styles.troopListName
                                    }
                                    onClick={() => handleTransfer(el)}
                                    key={el.id}
                                  >
                                    {el.name}
                                  </li>
                                ))
                            ) : (
                              <li className={styles.historyName}>
                                Информация отсутствует.
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=''>
                {/* <div className={styles.title}>Руководитель отряда</div> */}
                <div className={styles.headSquadBlock}>
                  <div className={styles.dataRow}>
                    <FormSelect
                      width="300px"
                      title="Тип профиля"
                      editMode={editMode}
                      value={formik.values.unaprofile_type}
                      name="unaprofile_type"
                      // notValid={formik.errors?.unaprofile_type}
                      onChange={formik.handleChange}
                      withOutFirst={true}
                      options={profile_types}
                    />
                    {profileType == 'Руководитель отряда'
                    ?
                    <></>
                    :
                    <>
                      <FormInput
                        width="fit-content"
                        title="Руководитель отряда ФИО"
                        editMode={editMode}
                        value={formik.values?.detachment?.Department[0]?.chief}
                        notValid={formik.errors?.detachment?.Department[0]?.chief}
                        name="chiefLastName"
                        onChange={formik.handleChange}
                      />
                      <FormInput
                        width="fit-content"
                        title="Руководитель отряда контактный телефон"
                        editMode={editMode}
                        value={formik.values?.detachment?.Department[0]?.phone}
                        notValid={formik.errors?.detachment?.Department[0]?.phone}
                        name="chiefContactPhone"
                        onChange={formik.handleChange}
                      />
                    </>
                    }

                  </div>
                </div>
              </div>
              </div>
                </div>
              {checkType(profileType) ?
              <>
              <div className={styles.dataRow}>
                    <FormInput
                      width="1180px"
                      title="О себе"
                      editMode={editMode}
                      value={formik.values.aboutSelf}
                      name="aboutSelf"
                      onChange={formik.handleChange}
                      notValid={formik.errors.aboutSelf}
                    />
              </div>

              <div className={styles.dataRow}>
                <FormInput
                  width="380px"
                  title="Причина вступления в ЮНАРМИЮ"
                  editMode={editMode}
                  value={formik.values.joiningReason}
                  notValid={formik.errors.joiningReason}
                  name="joiningReason"
                  onChange={formik.handleChange}
                />
                <FormDatePicker
                  width="380px"
                  title="Дата принятия в ЮНАРМИЮ"
                  editMode={editMode}
                  value={formik.values.signDate}
                  notValid={formik.errors.signDate}
                  name="signDate"
                  onChange={formik.handleChange}
                />
                <FormSelect
                  width="380px"
                  title="Текущий статус"
                  editMode={editMode}
                  value={formik.values.status}
                  name="status"
                  notValid={formik.errors.status}
                  onChange={formik.handleChange}
                  options={selects.status.slice(1)}
                />
              </div>

              <div className={styles.personalDataBlock}>
                  <div className={styles.personalDataLeft}>
                <div className={styles.dataRow}>
                <FormDatePicker
                  width="380px"
                  title="Дата первичного информирования"
                  editMode={editMode}
                  value={formik.values.initial_notification_date}
                  name="initial_notification_date"
                  onChange={formik.handleChange}
                />
                <FormCheckbox
                  editMode={editMode}
                  value={certif}
                  name="has_certificate"
                  title="Выдана книжка"
                  onChange={() => setCertif(!certif)}
                />
                {certif && (
                  <FormDatePicker
                    width="380px"
                    title="Дата торжественного вручения книжки"
                    editMode={editMode}
                    value={formik.values.certificate_issuing_date}
                    name="certificate_issuing_date"
                    onChange={formik.handleChange}
                  />
                )}
              </div>
              </div>
              </div>
                  
              <div className={styles.documents}>
                <div className={styles.title}>
                  Копии заявления и основного документа
                </div>
                <div className={styles.documentsBlock}>
                  <div className={styles.dataRow}>
                    <FormInput
                      width="580px"
                      title="Серия-номер основного документа (свидетельство о рождении / паспорт)"
                      editMode={editMode}
                      value={formik.values.passport}
                      name="passport"
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div
                    className={styles.dataRow}
                    style={{ justifyContent: "space-evenly" }}
                  >
                    <SliderComp
                      newID={newID}
                      photos={photosPassport}
                      editMode={editMode}
                      type="passport"
                      selectedItem={data}
                      setGoEdit={setGoEdit}
                      goEdit={goEdit}
                      goAdd={goAdd}
                      setEditMode={setEditMode}
                      rotateDocumentImage={rotateDocumentImage}
                      withPdfMode
                    />
                    <SliderComp
                      newID={newID}
                      photos={photosDocs}
                      editMode={editMode}
                      type="docs"
                      selectedItem={data}
                      setGoEdit={setGoEdit}
                      goEdit={goEdit}
                      goAdd={goAdd}
                      setEditMode={setEditMode}
                      rotateDocumentImage={rotateDocumentImage}
                      withPdfMode
                      />
                  </div>
                </div>
              </div>
              <div className={styles.mother}>
                <div className={styles.title}>Родители</div>
                <div className={styles.motherBlock}>
                  <div className={styles.dataRow}>
                    <FormInput
                      width="200px"
                      title="Фамилия матери"
                      editMode={editMode}
                      value={formik.values.motherLastName}
                      notValid={formik.errors.motherLastName}
                      name="motherLastName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="200px"
                      title="Имя матери"
                      editMode={editMode}
                      value={formik.values.motherFirstName}
                      notValid={formik.errors.motherFirstName}
                      name="motherFirstName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="200px"
                      title="Отчество матери"
                      editMode={editMode}
                      value={formik.values.motherFathersName}
                      notValid={formik.errors.motherFathersName}
                      name="motherFathersName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="250px"
                      title="Контактный телефон"
                      editMode={editMode}
                      value={formik.values.motherContactPhone}
                      notValid={formik.errors.motherContactPhone}
                      name="motherContactPhone"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="250px"
                      title="E-mail"
                      editMode={editMode}
                      value={formik.values.motherEmail}
                      notValid={formik.errors.motherEmail}
                      name="motherEmail"
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={styles.dataRow}>
                    <FormInput
                      width="200px"
                      title="Фамилия отца"
                      editMode={editMode}
                      value={formik.values.fatherLastName}
                      notValid={formik.errors.fatherLastName}
                      name="fatherLastName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="200px"
                      title="Имя отца"
                      editMode={editMode}
                      value={formik.values.fatherFirstName}
                      notValid={formik.errors.fatherFirstName}
                      name="fatherFirstName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="200px"
                      title="Отчество отца"
                      editMode={editMode}
                      value={formik.values.fatherFathersName}
                      notValid={formik.errors.fatherFathersName}
                      name="fatherFathersName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="250px"
                      title="Контактный телефон"
                      editMode={editMode}
                      value={formik.values.fatherContactPhone}
                      notValid={formik.errors.fatherContactPhone}
                      name="fatherContactPhone"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="250px"
                      title="E-mail"
                      editMode={editMode}
                      value={formik.values.fatherEmail}
                      notValid={formik.errors.fatherEmail}
                      name="fatherEmail"
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={styles.dataRow}>
                    <FormInput
                      width="200px"
                      title="Фамилия опекуна"
                      editMode={editMode}
                      value={formik.values.guardianLastName}
                      notValid={formik.errors.guardianLastName}
                      name="guardianLastName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="200px"
                      title="Имя опекуна"
                      editMode={editMode}
                      value={formik.values.guardianFirstName}
                      notValid={formik.errors.guardianFirstName}
                      name="guardianFirstName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="200px"
                      title="Отчество опекуна"
                      editMode={editMode}
                      value={formik.values.guardianFathersName}
                      notValid={formik.errors.guardianFathersName}
                      name="guardianFathersName"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="250px"
                      title="Контактный телефон"
                      editMode={editMode}
                      value={formik.values.guardianContactPhone}
                      notValid={formik.errors.guardianContactPhone}
                      name="guardianContactPhone"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="250px"
                      title="E-mail"
                      editMode={editMode}
                      value={formik.values.guardianEmail}
                      notValid={formik.errors.guardianEmail}
                      name="guardianEmail"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.education}>
                <div className={styles.title}>Образовательная организация</div>
                <div className={styles.educationBlock}>
                  <div className={styles.dataRow}>
                    <FormInput
                      width="580px"
                      title="Название"
                      editMode={editMode}
                      value={formik.values.school}
                      notValid={formik.errors.school}
                      name="school"
                      onChange={formik.handleChange}
                    />
                    <FormInput
                      width="580px"
                      title="Класс"
                      editMode={editMode}
                      value={formik.values.class}
                      notValid={formik.errors.class}
                      name="class"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.otherOrganizations}>
                <div className={styles.title}>
                  Участие в других общественных организациях
                </div>
              </div>
              <div className={styles.experience}>
                <div className={styles.title}>Опыт</div>
              </div>
              <div className={styles.sport}>
                <div className={styles.title}>Посещение спортивных секций</div>
                <div className={styles.sportBlock}>
                  <div className={styles.dataRow}>
                    <FormDatePicker
                      width="380px"
                      title="Период посещения спортивных секций с"
                        editMode={editMode}
                        value={formik.values.sport_section_from_date}
                        notValid={formik.errors.sport_section_from_date}
                        name="sport_section_from_date"
                        onChange={formik.handleChange}
                    />
                    <FormDatePicker
                      width="380px"
                      title="Период посещения спортивных секций по"
                        editMode={editMode}
                        value={formik.values.sport_section_to_date}
                        notValid={formik.errors.sport_section_to_date}
                        name="sport_section_to_date"
                        onChange={formik.handleChange}
                    />
                    <FormInput
                      width="380px"
                      title="Название"
                      editMode={editMode}
                      value={formik.values.sportSectionsName}
                      notValid={formik.errors.sportSectionsName}
                      name="sportSectionsName"
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={styles.dataRow}>
                    <FormInput
                      width="1180px"
                      title="Краткое резюме достижений"
                      editMode={editMode}
                      value={formik.values.sport_section_result}
                      notValid={formik.errors.sport_section_result}
                      name="sport_section_result"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                
                <div className={styles.sportBlockSecond}>
                  {
                    sportData && sportData.length > 0 ?
                    sportData.map(sport => 
                    <div className={styles.dataRow} key={`${sport.name}`}>
                    <FormInput
                      title="Название"
                      editMode={false}
                      value={sport.name}
                      name="sportName"
                    />
                    <FormInput
                      title="Краткое резюме достижений"
                      editMode={false}
                      value={sport.result}
                      name="sportResult"
                    />
                    <FormInput
                      title="Год"
                      width="320px"
                      editMode={false}  
                      value={sport.year}
                      name="sportYear"
                    />
                    {
                      sport?.files.length > 0 
                      ?
                      <div className={styles.sportBlockDoc}>
                      <div className={styles.titleDoc}>Документ</div>
                      <div>
                      <i className={styles.medalIcon}>
                      <IoMdMedal onClick={() => setSportPopup(prev => ({...prev, path: sport.files[0].path, name: sport.files[0].name}))}/>
                      </i>
                      </div>
                      </div>
                      :
                      ''
                    }
                  </div>
                  )
                  :
                  ''
                  }
                  </div>
              </div>

              
              {sportPopup.path && sportPopup.name ?
              <>
              <div className={styles.bg}></div>
              <div className={styles.popup} 
              onClick={() => setSportPopup(prev => ({...prev, path: null, name: null}))}
              >
                <i
                  className={styles.popup_close}
                  onClick={() => setSportPopup(prev => ({...prev, path: null, name: null}))}
                >
                  <IoIosCloseCircle />
                </i>
                <img
                  src={`${BACKEND_DOMAIN || ""}/${sportPopup.path}/${sportPopup.name}`}
                  alt='o_O'
                  type='image/jpeg'
                />
              </div>
              </>
              :
              ''}

              <div className={styles.sport}>
                <div className={styles.title}>Заполненность анкеты</div>
                <div className={styles.sportBlock}>
                  <div className={styles.dataRow}>
                    <FormCheckbox
                      editMode={editMode}
                      value={fill}
                      name="profile_filled"
                      title="Заполнено 100% анкеты"
                      onChange={() => setFill(!fill)}
                    />
                    {fill && <FormDatePicker
                      width="250px"
                      title="Дата заполнения профиля"
                      editMode={editMode}
                      value={formik.values.profile_filled_date}
                      name="profile_filled_date"
                      onChange={formik.handleChange}
                    />}
                  </div>
                </div>
              </div>

              <div className={styles.medicine}>
                <div className={styles.title}>Награды</div>
                <div className={styles.medicineBlock}>
                  <div className={styles.dataRow}>
                    <div className={styles.rowWrapAward}>
                      {
                      awardsOnPerson.map((el, index) => 
                        <div className={styles.awardItem} key={el.id}>
                        <div className={styles.awardInput}>
                          <FormSelect
                            width="100%"
                            title="Наименование награды"
                            editMode={editMode}
                            value={el.departmental_award_id}
                            name={`${el.departmental_award_id}`}
                            notValid={el.departmental_award_id ? false : true}
                            onChange={(e) => handleChangeAward(el.departmental_award_id, e, 'departmental_award_id', 'old', el.id)}
                            options={awardsList.filter(el => el.status == true).map(el => ({name: el.name, value: el.id}))}
                          />
                          </div>
                          
                          <div className={styles.awardInput}>
                          {<FormDatePicker
                          width="100%"
                          title="Дата присвоения"
                          editMode={editMode}
                          value={el.date_assigment}
                          notValid={el.date_assigment ? false : true}
                          name={`date_assigment_${el.departmental_award_id}`}
                          onChange={(e) => handleChangeAward(el.departmental_award_id, e, 'date_assigment', 'old', el.id)}
                        />}
                          </div>
                          <div className={styles.awardInput}>
                          <FormInput
                          width="100%"
                          title="Номер приказа"
                          editMode={editMode}
                          value={el.order_number}
                          notValid={el.order_number ? false : true}
                          name={`order_number_${el.departmental_award_id}`}
                          onChange={(e) => handleChangeAward(el.departmental_award_id, e, 'order_number', 'old', el.id)}
                        />
                        </div>
                  
                        <div className={styles.awardLabel}>
                          <label className={styles.switch}>
                            <input
                              type="checkbox"
                              checked={el.status}
                              // disabled={!editRights}
                            />
                            <span
                              className={styles.slider} 
                              onClick={e => handleChanger(e, el.departmental_award_id, el.status)}
                            />
                            {/* editRights &&  */}
                          </label>
                          </div>
                        </div>
                        
                        )
                      }
                    {
                      awards.map((el, index) => 
                        <div className={styles.awardItem} key={el.uniId}>
                          <div className={styles.awardInput}>
                          <FormSelect
                            width="100%"
                            title="Наименование награды"
                            editMode={editMode}
                            value={el.id}
                            name={`${el.uniId}`}
                            notValid={el.id ? false : true}
                            onChange={(e) => handleChangeAward(el.uniId, e, 'id', 'new')}
                            options={awardsList.filter(el => el.status == true).map(el => ({name: el.name, value: el.id}))}
                          />
                          </div>

                          <div className={styles.awardInput}>
                          {<FormDatePicker
                          width="100%"
                          title="Дата присвоения"
                          editMode={editMode}
                          value={el.date_assigment}
                          notValid={el.date_assigment ? false : true}
                          name={`date_assigment_${el.uniId}`}
                          onChange={(e) => handleChangeAward(el.uniId, e, 'date_assigment', 'new')}
                        />}
                          </div>
                            
                          <div className={styles.awardInput}>
                          <FormInput
                          width="100%"
                          title="Номер приказа"
                          editMode={editMode}
                          value={el.order_number}
                          notValid={el.order_number ? false : true}
                          // name={`order_number`}
                          onChange={(e) => handleChangeAward(el.uniId, e, 'order_number', 'new')}
                        />
                        </div>

                        {<i title='Удалить награду' className={styles.trashIcon} onClick={() => handleDeleteAward(el.uniId, 'new')}><IoMdTrash/></i>}
                    </div>
                        )
                    }
                    </div>
                  </div>
                  <div>
                    {editMode && (<ButtonRed 
                    onClick={(e) => handleAddAward(e)}
                    style={{width: '150px'}}>Добавить</ButtonRed>)}
                  </div>
                </div>
              </div>

              <div className={styles.medicine}>
                <div className={styles.title}>Показатели ГТО</div>
                <div className={styles.medicineBlock}>
                  <div className={styles.dataRow}>
                    <div className={styles.rowWrap}>
                      <div>
                      <FormSelect
                        width="300px"
                        title="Результат"
                        editMode={editMode}
                        value={formik.values.trp_result}
                        name="trp_result"
                        onChange={formik.handleChange}
                        options={selects.GTO}
                      />
                      </div>
                      <div>
                      <FormSelect
                        width="300px"
                        title="Степень"
                        editMode={editMode}
                        value={formik.values.trp_grade}
                        name="trp_grade"
                        onChange={formik.handleChange}
                        options={selects.level}
                      />
                      </div>
                    </div>
                    <div className={styles.rowWrap}>
                      <div>
                      <FormInput
                        width="300px"
                        title="Номер удостоверения"
                        editMode={editMode}
                        value={formik.values.trp_cert_number}
                        name="trp_cert_number"
                        onChange={formik.handleChange}
                      />
                      </div>
                      <div>
                      <FormDatePicker
                        width="300px"
                        title="Дата выдачи"
                        editMode={editMode}
                        value={formik.values.trp_cert_issued}
                        name="trp_cert_issued"
                        onChange={formik.handleChange}
                      />
                      </div>
                    </div>
                    <div className={styles.gtoWrapper}>
                      <SliderComp
                        newID={newID}
                        photos={photoCert}
                        editMode={editMode}
                        type="trp_certificate"
                        selectedItem={data}
                        setGoEdit={setGoEdit}
                        goEdit={goEdit}
                        goAdd={goAdd}
                        setEditMode={setEditMode}
                        isAvatar={false}
                        rotateDocumentImage={rotateDocumentImage}
                        />
                    </div>
                  </div>
                </div>
              </div>

              <SportsActivities
                data={formik.values.sport_activity}
                profileId={data?.id}
                editMode={editMode && !addMode}
              />

              <EventsBlock
                data={formik.values.articles_files}
                profileId={data?.id}
                editMode={editMode && !addMode}
              />
            </>
          : <></>
        }

              <div className={styles.createAndApdateTime}>
                {data && data.createdAt && (
                  <span>
                    <div>
                    Когда создал:{" "}
                    <time>{moment(data.createdAt).format("LLL")}</time></div> 
                    <div>
                    Кто создал:{" "}
                    {data && data.created_by_name && (
                      <cite>{data.created_by_name}</cite>
                    )}
                    </div>
                  </span>
                )}
                {data && data.updatedAt && (
                  <span>
                    <div>
                    Когда изменил:{" "}
                    <time>{moment(data.updatedAt).format("LLL")}</time>
                    </div>
                    <div>
                    Кто изменил:{" "}
                    {data && data.updated_by_name && (
                      <cite>{data.updated_by_name}</cite>
                    )}
                    </div>
                  </span>
                )}
              </div>

            </div>

            {editMode ? (
              data ? (
                <div className={styles.buttons}>
                  <ButtonRed
                    style={{ borderRadius: "5px", height: "30px" }}
                    onClick={submitForm}
                  >
                    <IoIosCheckmark size={26} />
                    <span>СОХРАНИТЬ</span>
                  </ButtonRed>
                  <ButtonRed
                    style={{ borderRadius: "5px", height: "30px" }}
                    onClick={cancelChanges}
                  >
                    <IoIosCloseCircleOutline size={26} />
                    <span>ОТМЕНИТЬ ИЗМЕНЕНИЯ</span>
                  </ButtonRed>
                </div>
              ) : (
                <div className={styles.buttons}>
                  <ButtonRed
                    style={{ borderRadius: "5px", height: "30px" }}
                    onClick={() => history.goBack()}
                  >
                    <IoIosArrowBack size={22} />
                    <span>НАЗАД</span>
                  </ButtonRed>
                  <ButtonRed
                    style={{ borderRadius: "5px", height: "30px" }}
                    onClick={submitForm}
                  >
                    <IoIosCheckmark size={26} />
                    <span>ДОБАВИТЬ</span>
                  </ButtonRed>
                </div>
              )
            ) : (
              <div className={styles.wrapper_buttons}>
                {data &&
                <ButtonRed
                  style={{ borderRadius: "5px", minHeight: "30px" }}
                  onClick={() => sendDocuments(data.id)}
                  title="На e-mail юнармейца будут отправлены для заполнения следующие документы: Заявление на вступление, Согласие на вступление (с 14 до 18 лет), Согласие на обработку персональных данных"
                >
                  Отправить пакет документов на вступление
                </ButtonRed>}
                <div className={styles.buttons}>
                <ButtonRed
                  style={{ borderRadius: "5px", height: "30px" }}
                  onClick={goBackHandler}
                >
                  <IoIosArrowBack size={22} />
                  <span>НАЗАД</span>
                </ButtonRed>
                <ButtonRed
                  style={{ borderRadius: "5px", height: "30px" }}
                  onClick={openCharModal('portfolio')}
                >
                  <IoIosDocument size={22} />
                  <span>ПОРТФОЛИО PDF</span>
                </ButtonRed>
                <ButtonRed
                  style={{ borderRadius: "5px", height: "30px" }}
                  onClick={openCharModal('profile')}
                >
                  <IoIosDocument size={22} />
                  <span>ПРОФИЛЬ PDF</span>
                </ButtonRed>
                {updateRights &&
                <ButtonRed
                  onClick={() => setEditMode(true)}
                  style={{ borderRadius: "5px", height: "30px" }}
                >
                  <IoMdCreate size={22} />
                  <span>РЕДАКТИРОВАТЬ</span>
                </ButtonRed>}
                {superuser && data?.regionId === 99 && 
                <ButtonRed
                onClick={() => setConfirmation(data.id)}
                style={{ borderRadius: "5px", height: "30px" }}
                >
                  <IoIosClose size={30} />
                  <span>УДАЛИТЬ</span>
                </ButtonRed>}
              </div>
            </div>
            )}
            {docsInfo?.length &&
            <div className={styles.docTable}>
              <table>
                <thead>
                    <tr>
                      <th>Кто отправил</th>
                      <th>Дата</th>
                    </tr>
                </thead>
                <tbody>
                    {docsInfo.map(row =>
                    <tr>
                      <td>{row.who_post_fio}</td>
                      <td>{moment(row.post_date).format('LLL')}</td>
                    </tr>)}
                </tbody>
              </table>
            </div>}
          </div>
        </Formik>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.yunarmians.profile,
  photos: state.yunarmians.photos,
  loading: state.yunarmians.loading,
  regions: state.users.regionList,
  newID: state.yunarmians.newID,
  regionDep: state.departments.region_departments,
  localDep: state.departments.local_departments,
  authData: state.auth.user,
  sportData: state.yunarmians.sport,
  activityData: state.yunarmians.activity,
  troopHistory: state.yunarmians.troopHistory,
  troopList: state.yunarmians.troopList,
  isBack: state.yunarmians.isBack,
  docsInfo: state.yunarmians.docsInfo,
  country: state.yunarmians.country,
  accessRights: state.auth.accessRights.find(i => i.name==='unaprofile')||{},
  userRegions: state.auth.userRegions,
  awardsList: state.awards.data,
  awardsProfileList: state.yunarmians.awardsList,
  superuser: state.auth.superuser
});

export default connect(mapStateToProps, {
  getYunarmianProfile,
  editYunarmianProfile,
  addYunarmianProfile,
  getRegions,
  getDepartments,
  clearProfile,
  uploadDocs,
  deleteDocs,
  getPortfolio,
  getTroopHistory,
  removeTroopHistory,
  getTroops,
  editAwardsPerson,
  removeTroopList,
  transferTroop,
  backUploadToggle,
  deleteProfile,
  rotateDocumentImage,
  getAwardsPerson,
  sendDocuments,
  deleteAwardsPerson,
  addAwardProfile,
  getAwardsObject,
  getDocsInfo,
  getCountry
})(Profile);

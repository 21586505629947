import * as types from "../actions/types";

const initialState = {
    heroes: null,
    loading: false,
    troops: [],
    hero: {},
    pageCount: null,
    hero_image: []
};

export default function hero (state = initialState, action) {
  switch (action.type) {
    case types.SET_HERO:
      return {
        ...state,
        heroes: action.payload,
      };
    case types.SET_HERO_COUNT:
    return {
        ...state,
        pageCount: action.payload,
    };
      case types.SET_HERO_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_HERO_DATA_BY_ID:
      return {
        ...state,
        hero: action.payload,
      };
    case types.SET_HERO_IMAGE:
      return {
        ...state,
        hero_image: action.payload,
      };
      case types.REMOVE_HORE_IMAGE:
        return {
          ...state,
          hero_image: [],
        };
    case types.GET_TROOPS:
      return {
        ...state,
        troops: action.payload,
      };
      default:
        return state;
    }
}
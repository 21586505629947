import { createNotification } from "../components/Notifications/Notifications";
import { BACKEND_URL_PREFIX } from "../utils/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { SET_VIRTUAL_AWARDS_COUNT, SET_VIRTUAL_AWARDS_DATA, SET_VIRTUAL_AWARDS_LOADING } from "./types";




export const getAwardsList = (pageNumber) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (!getState().auth.token) return;
  
  dispatch({ type: SET_VIRTUAL_AWARDS_LOADING, payload: true });
  
  const limit = getState().auth.itemsOnPage
  const offset = pageNumber * limit
  const params = typeof pageNumber === 'undefined' ? {} : {limit, offset}
  const urlParams = new URLSearchParams(params).toString();

  const response = await fetch(`${BACKEND_URL_PREFIX}/awards?${urlParams}`,
    {
      method: "GET",
      ...buildDefaultHeaders(getState),
    }
  );
  
  if (response.ok) {
    const { count, rows} = await response.json();
    const pageCount = Math.ceil(count / getState().auth.itemsOnPage);    
    dispatch({ type: SET_VIRTUAL_AWARDS_COUNT, payload: {count, pageCount} });
    dispatch({ type: SET_VIRTUAL_AWARDS_DATA, payload: rows });
  }

  dispatch({ type: SET_VIRTUAL_AWARDS_LOADING, payload: false });

};

export const createAward = (data, pageNumber) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (!getState().auth.token) return;
  let returnState
  try {
    dispatch({ type: SET_VIRTUAL_AWARDS_LOADING, payload: true });
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
  
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
        body: formData
      }
    );
    
    if (response.ok) {
      await dispatch(getAwardsList(pageNumber))
      returnState = true
    } else {
      returnState = false
    }
  } catch (ERR) {
    returnState = false

  } finally {
    dispatch({ type: SET_VIRTUAL_AWARDS_LOADING, payload: false });
    return returnState
  }
};

export const updateAward = (awardId, data, pageNumber) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (!getState().auth.token) return;
  let returnState
  try {
    dispatch({ type: SET_VIRTUAL_AWARDS_LOADING, payload: true });
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
  
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards/${awardId}`,
      {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
        body: formData
      }
    );
    
    if (response.ok) {
      await dispatch(getAwardsList(pageNumber))
      returnState = true
    } else {
      returnState = false
    }
  } catch (ERR) {
    returnState = false

  } finally {
    dispatch({ type: SET_VIRTUAL_AWARDS_LOADING, payload: false });
    return returnState
  }
};

export const deleteAward = (awardId, pageNumber) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (!getState().auth.token) return;
  try {
    dispatch({ type: SET_VIRTUAL_AWARDS_LOADING, payload: true });

    const response = await fetch(`${BACKEND_URL_PREFIX}/awards/${awardId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    
    if (response.ok) {
      await dispatch(getAwardsList(pageNumber))
    } else {
      const {message} = await response.json();
      message && createNotification('error', message);      
    }
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_VIRTUAL_AWARDS_LOADING, payload: false });
  }
};


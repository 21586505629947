import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import styles from "./CustomDateppicker.module.css"

export default function CustomDatepicker({
    title = "",
    value = "",
    name = "",
    onChange = () => {},
    style = {},
    validate = false,
    dateFormat = "DD.MM.YYYY",
    ...props
}) {
  return (
    <div className={styles.dateBlock} style={style}>
      <div className={styles.title}>{title}:</div>
      <DatePicker
        dateFormat={dateFormat}
        selected={ value ? value.length === 10 ? moment(value, dateFormat) : moment(value) : null }
        onChange={e => onChange({target: {value: e ? moment(e).format(dateFormat) : null, name: name, id: name}})}
        locale={"ru"}
        showYearDropdown
        {...props}
      />
      {validate && <div className={styles.validate}>поле является обязательным</div>}
    </div>
  );
}

import React, {useState, useEffect} from 'react'
import styles from "../Tasks.module.css";
import ReactQuill from "react-quill";
import { createNotification } from '../../../components/Notifications/Notifications';
import { optionsStap } from '../../../utils/const';

const EditSteps = ({selectedStep, addStepsMode, stepsPageCount, addTaskStep, selectedItem, stepsPage, number, editTaskStep, ...props}) => {

const [item, setItem] = useState(null);
const [select, setSelect] = useState(0);

useEffect(() => {
    if (addStepsMode) {
      setItem({
        number: number?.length + 1,
        name: '',
        description: '',
        result: 0
        })
    } else {
      selectedStep && setItem(selectedStep);
    }
    }, [selectedStep, addStepsMode]);

    const handlSelect = (e) => {
        setSelect(e.target.value);
        setItem((prev) => ({ ...prev, result: e.target.value }));
    }

    const addStepItem = () => {
      if(item.name && item.description && /* !item.number.length == 0 && */ item.number > 0) {
            addTaskStep(selectedItem.id, item, stepsPage + 1);
            props.onClose();
          } else {
            createNotification('error', 'Заполнены не все поля')
          }
    };
    
    const editStepItem = () => {
      editTaskStep(selectedItem.id, selectedStep.id, item);
      props.onClose();
    };

  return (
    <div>
      <div className={styles.title}>
        {addStepsMode
          ? "Добавление шага"
          : "Редактирование шага"}
      </div>
      {item &&
      <div className={styles.modalBody}>
        <div>
          <div className={styles.firstBlock}>
            <div className={styles.firstBlock__left}>
              <p className={styles.task__label}>Номер шага:</p>
                    <div className={styles.stepWrapperEdit}>
                    <span className={styles.stepNumber}><span>№</span> 
                    <input
                        type='text'
                        value={item?.number}
                        onChange={(e) =>
                          setItem((prev) => ({ ...prev, number: e.target.value }))
                        }
                        className={styles.input}
                      /></span>
                    <br />
                    <div>
                      <p className={styles.task__label}>Название шага:</p>
                      <input
                        type='text'
                        value={item?.name}
                        onChange={(e) =>
                          setItem((prev) => ({ ...prev, name: e.target.value }))
                        }
                        className={styles.input}
                      />
                    </div>
                    <br />

                    <div>
                      <p className={styles.task__label}>Описание шага:</p>
                      <ReactQuill
                        value={item?.description}
                        onChange={(e) => setItem((prev) => ({ ...prev, description: e }))}
                      />
                    </div>
                    <br />

                    <div>
                        <p className={styles.task__label}>Результат выполнения:</p>
                        <select
                          className={styles.stepSelect}
                          onChange={(e) => handlSelect(e)}
                        >
                            {optionsStap.map(el => <option selected={item.result == el.value} value={el.value}>{el.name}</option>)}
                        </select>
                    </div>
                  </div>
            </div>
          </div>
        </div>
        <div className="settingModal__btns">
          <button
            className="newsListHeader__btn btn_grey"
            type="button"
            onClick={addStepsMode ? addStepItem : editStepItem}
          >
            {addStepsMode ? "Добавить" : "Сохранить"}
          </button>
          <button
            className="newsListHeader__btn btn_grey"
            onClick={() => props.onClose()}
            type="button"
          >
            Закрыть
          </button>
        </div>
      </div>}
    </div>
  )
}

export default EditSteps
import moment from 'moment';
import _ from 'lodash';

import * as types from '../actions/types';

moment.locale('ru');

const initialState = {
  newsList: null,
  newsPages: null,
  currentPage: 0,
  currentEvent: null,
  currentNew: null,
  news: null,
  eventStages: null,
  questions_list: null,
  question_answers: null,
  question_images: null,
  eventRequests: null,
  eventFiles: [],
  eventStat: null,
  authors: null,
  editedNews: null,
  lastUpdate: Date.now(),
  excelList: null,
  deps: null,
  localDep: null,
  regDep: null,
  allEvents: [],
  goClick: null,
  categories: null,
  animations: null,
  manualProfiles: null,
  manualAnswers: null,
  loadingbut: false,
  loading: false
};

export default function schedule(state = initialState, action) {
  switch (action.type) {
    case types.FETCHED_NEWSLIST: {
      return {
        ...state,
        newsList: action.newsList,
        lastUpdate: Date.now()
      };
    }
    case types.FETCHED_NEWS: {
      const updatedNewsList = state.newsList && [...state.newsList];
      try {
        const oldNewsId = updatedNewsList !== null && updatedNewsList.map(a => a.id).indexOf(action.news.id);
        if (!~oldNewsId) updatedNewsList[oldNewsId] = action.news;
        return {
          ...state,
          news: action.news,
          newsList: updatedNewsList,
          lastUpdate: Date.now(),
        };
      } catch(e) {
        return {
          ...state,
          news: action.news
        };
      }
    }
    case types.REMOVE_NEWS: {
      return {
        ...state,
        newsList: state.newsList.filter(c => c.id !== action.id),
        lastUpdate: Date.now(),
      };
    }
    case types.FETCH_NEWSPAGES: {
      return {
        ...state,
        newsPages: action.newsPages,
      };
    }
    case types.SET_ANIMATIONS_LIST: {
      return {
        ...state,
        animations: action.payload,
      };
    }
    case types.FETCH_AUTHORS: {
      return {
        ...state,
        authors: action.authors,
      };
    }
    case types.EDITED_NEWS: {
      return {
        ...state,
        editedNews: action.editedNews,
      };
    }
    case types.SET_EVENT_QUESTIONS: {
      return {
        ...state,
        questions_list: action.payload,
      };
    }
    case types.SET_EVENT_QUESTIONS_DEF: {
      return {
        ...state,
        questions_list_def: action.payload,
      };
    }
    case types.SET_CURRENT_EVENT: {
      return {
        ...state,
        currentEvent: action.payload,
      };
    }
    case types.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      };
    }
    case types.SAVE_AND_CLICK: {
      return {
        ...state,
        goClick: action.payload
      };
    }
    case types.SET_EVENT_REQUESTS: {
      return {
        ...state,
        eventRequests: action.payload
      };
    }
    case types.SET_QUESTION_ANSWERS: {
      return {
        ...state,
        question_answers: action.payload
      };
    }
    case types.SET_SCHEDULE_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case types.SET_EVENT_STAGES: {
      return {
        ...state,
        eventStages: action.payload
      };
    }
    case types.SET_EVENT_FILES_LIST: {
      return {
        ...state,
        eventFiles: action.payload
      };
    }
    case types.SET_EVENT_STATISTIC: {
      return {
        ...state,
        eventStat: action.payload
      };
    }
    case types.SET_LIST_FOR_EXCEL: {
      return {
        ...state,
        excelList: action.payload
      };
    }
    case types.SET_LOADING_BUT: {
      return {
        ...state,
        loadingbut: action.payload
      };
    }
    case types.SET_DETACHMENTS_NEWS: {
      return {
        ...state,
        deps: action.payload
      };
    }
    case types.SET_LOCAL_DEPARTMENTS_NEWS: {
      return {
        ...state,
        localDep: action.payload
      };
    }
    case types.SET_REGION_DEPARTMENTS_NEWS: {
      return {
        ...state,
        regDep: action.payload
      };
    }
    case types.SET_CURRENT_NEW: {
      return {
        ...state,
        currentNew: action.payload
      };
    }
    case types.SET_ALL_EVENTS: {
      return {
        ...state,
        allEvents: action.payload
      };
    }
    case types.SET_MANUAL_PROFILES: {
      return {
        ...state,
        manualProfiles: action.payload
      };
    }
    case types.SET_MANUAL_ANSWERS: {
      return {
        ...state,
        manualAnswers: action.payload
      };
    }
    case types.SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      };
    }
    default:
      return state;
  }
}

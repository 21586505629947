import { buildDefaultHeaders } from "./helper";
import { BACKEND_URL_PREFIX } from "../utils/const";
import { checkAndUpdateToken } from "./auth"
import { SET_DOCUMENTS, SET_DOCTYPES, SET_DOCUMENTS_FILES_LIST, SET_DOCUMENTS_COUNT, ERROR } from "./types";



export const getDocTypes = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/documenttype`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
          const data = await response.json();
          dispatch({ type: SET_DOCTYPES, payload: data });
        } else {
          dispatch({ type: ERROR, payload: response });
        }
  }
}

export const getDocuments = (article, page, sort, filter) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const newsOnPage = getState().auth.itemsOnPage
      const offset = page * newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage
      }
      sort && ( params.alph = sort )
      filter && ( params.is_expired = filter )
      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(`${BACKEND_URL_PREFIX}/document/${article}?${urlParams}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const { cnt, documents } = await response.json();
            const pageCount = Math.ceil(cnt / newsOnPage);
            dispatch({ type: SET_DOCUMENTS_COUNT, payload: { data: cnt, pageCount }});
            dispatch({ type: SET_DOCUMENTS, payload: documents });
          } else {
            dispatch({ type: ERROR, payload: response });
          }
    }
}

export const deleteDocument = (id, article, page, sort, filter) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/document/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
  });
      if (response.ok) {
        dispatch(getDocuments(article, page, sort, filter))
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const editDocument = (id, article, data, page, files, sort, filter) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const deletedFiles = [...data.deletedFiles]
    delete data.deletedFiles
    const response = await fetch(`${BACKEND_URL_PREFIX}/document/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (deletedFiles.length) {
      for (const name of deletedFiles) {
        await dispatch(deleteFile(id, name))
      }
    }
    if (files.length) {
        for (const file of files) {
          await dispatch(uploadFile(id, file))
        }
    }
      if (response.ok) {
        dispatch(getDocuments(article, page, sort, filter))
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const addDocument = (data, page, files, sort, filter) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/document`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        const resp = await response.json()
        const id = resp.id
          if (files.length) {
            for (const file of files) {
              await dispatch(uploadFile(id, file))
            }
          }
          dispatch(getDocuments(data.type, page, sort, filter))
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const getFilesList = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/document/${id}/file`, {
          method: "GET",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
          const data = await response.json();
          dispatch({ type: SET_DOCUMENTS_FILES_LIST, payload: data });
      } else {
          dispatch({ type: ERROR, payload: response });
      }
  }
}

export const uploadFile = (id, file) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())

  const fd = new FormData()
  fd.append('file', file)

  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/document/${id}/file`, {
          method: "POST",
          headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
      })
      if (response.ok) {
          console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const deleteFile = (id, name) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/document/${id}/file/${name}`, {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}
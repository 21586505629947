import React from 'react';
import { Field } from 'redux-form';

const WrappedTextArea = ({ className, cols, rows, placeholder, input }) => (
  <textarea
    className={className}
    cols={cols}
    rows={rows}
    placeholder={placeholder}
    {...input}
  />
);

const TextAreaField = (props, className, cols, rows, placeholder) => (
  <Field
    component={WrappedTextArea}
    className={className}
    cols={cols}
    rows={rows}
    placeholder={placeholder}
    {...props}
  />
);

export default TextAreaField;

import React from 'react'
import styles from "./PickImage.module.css";

const PickImage = ({answers, setStep, setPoints}) => {
  
    const handleCheck = (bool) => {
        if (bool) {
            setPoints(prev => prev + 1);
        }
        setStep(prev => prev + 1);
    }

    return (
    <div className={styles.wrapper}>
        {answers.map(el => <div style={{backgroundImage: `url(../img${el.answer})`}} className={styles.image} onClick={() => handleCheck(el.bool)}></div>)}
    </div>
  )
}

export default PickImage
import React from 'react'
import styles from "./FormSelect.module.css"



export default function FormSelect({
  title = "",
  width = "",
  name = "",
  value,
  onChange,
  editMode = false,
  options = [],
  notValid = false,
  withOutFirst = false
}) 
{



  return (
    <div className={`${styles.inputWrapper} ${editMode && notValid ? styles.notValid : null}`} style={{ width: width }}>
      {editMode && notValid && <div className={styles.validDescr}>{notValid}</div>}
      <div className={styles.title}>{title}</div>
      {editMode ? (
        <select className={styles.select} onChange={onChange} name={name}>
            {withOutFirst ? '' : <option value=''>Выбрать</option>}
            {options.map((item) => <option key={item.name} value={item.value} selected={value && item.value==value}>{item.name}</option>)}
        </select>
      ) : (
        <div className={styles.block}>{!!value && (value!=0 && options.find(i => i.value===value) ? options.find(i => i.value===value).name : "Не указано")}</div>
      )}
    </div>
  )
}

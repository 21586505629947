import * as types from '../actions/types';

const initialState = {
    data: null,
    loading : false
  };

export default function yunarmy_stat (state = initialState, action) {
  switch (action.type) {
    case types.SET_YUNARMY_STAT:
      return {
        ...state,
        data : action.payload
      };
    case types.SET_YUNARMY_LOADING:
        return {
          ...state,
          loading : action.payload
        };
    default:
      return state;
  }
}
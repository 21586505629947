import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import Loader from "../../components/Loader/Loader";
import styles from "./Private.module.css";
import {Slider} from './Slider';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import { BACKEND_DOMAIN, BACKEND_URL_PREFIX } from "../../utils/const";
import moment from "moment";
import ReactQuill from "react-quill";


const VideosModal = ({
  numberPage,
  selectedItem,
  filesList,
  editVideoItem,
  loading,
  closeModal,
  getStreamById,
  selected,
  selectedId,
  ...props
}) => {
  
  const [item, setItem] = useState(null);
  const [imagesPreviews, setImagesPreviews] = useState([])

  useEffect(() => {
    selectedId && getStreamById(selectedId);
  }, [selectedId]);

  useEffect(() => {
    selected && setItem(selected);
  }, [selected])

  useEffect(() => {
    if (Array.isArray(item?.images)) {
        setImagesPreviews(item?.images.map(el => `${BACKEND_DOMAIN || ""}/${el.path}/${el.name}`))
      }
  }, [item])
  

  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <SwiperSlide key={photo}>
          <div className={`photoWrapper`}>
            <img
              src={photo}
              alt="фото"
              className={styles.imgUploder}
            />
          </div>
        </SwiperSlide>
      )
    })
  }

  const handleCloseModal = () => {
    closeModal()
    setItem(null)
  }

  const previewImage = item?.preview && `${BACKEND_DOMAIN || ''}/${item.preview.path}/${item.preview.name}`

    return (
    <Modal
      {...props}
      onClose={handleCloseModal}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
    
      {loading && <Loader top="40%" left="40%" />}
      <div className={styles.title}>
      Личное событие юнармейца
      </div>
      <div className={styles.modalBody}>
        <div>
          <div className={styles.inputBlock}>
          <span>ФИО</span>
          <input
            value={item?.profile_fio}
            className={styles.input}
            placeholder="Автор"
            style={{ marginBottom: "10px" }}
          />
          </div>

          <div className={styles.inputBlock}>
          <span>Регион</span>
          <input
            value={item?.region_name}
            className={styles.input}
            placeholder="Регион"
            style={{ marginBottom: "10px" }}
          />
          </div>

          <div className={styles.inputBlock}>
          <span>Даты и время публикации</span>
          <input
            value={moment(item?.createat).subtract(moment.duration("03:00:0")).format('DD-MM-YYYY, h:mm:ss')}
            type="data"
            className={styles.input}
            placeholder="Даты и время публикации"
            style={{ marginBottom: "10px" }}
          />
          </div>

          <div className={styles.inputBlock}>
            <span>Текст</span>
            <ReactQuill
              value={item?.text ? item?.text : ''}
              onChange={(e) => setItem((prev) => ({ ...prev, description: e }))}
              className={styles.input}
              placeholder="Текст"
              style={{ marginBottom: "10px" }}
            />
          </div>

          {item?.video &&
            <>
              <span>Видео</span>
              <video width='100%' height={350} controls="controls" poster={previewImage}>
                <source src={`${BACKEND_DOMAIN || ''}/${item.video.path}/${item.video.name}`}/>
              </video>
            </>
          }

          <Slider>{imagesPreviews.length > 0 && renderPhotos(imagesPreviews)}</Slider>
         
        </div>
        <div className={styles.modalButtons}>
          <ButtonRed onClick={handleCloseModal}>Закрыть</ButtonRed>
        </div>
      </div>
    </Modal>
  );
};

export default VideosModal;

import React, {useEffect, useState} from "react";
import styles from "./Home.module.css";
import avatar from "../../assets/images/avatar.png";
import mail from "../../assets/images/mail.png";
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { getDashboardById, getRegions } from '../../actions/auth'
import { IoMdArrowDropdownCircle } from 'react-icons/io';

import rectangle from "../../assets/images/home/Rectangle.png"
import diagr from "../../assets/images/home/diagr.png"
import diagr2 from "../../assets/images/home/diagr2.png"
import deti from "../../assets/images/home/deti.png"
import phone from "../../assets/images/home/phone.png"
import boy from "../../assets/images/home/boy.png"
import girl from "../../assets/images/home/girl.png"
import planshet from "../../assets/images/home/planshet.png"
import chelovek from "../../assets/images/home/chelovek.png"
import uchenik from "../../assets/images/home/uchenik.png"
import PieChart from "../../components/PieChart/PieChart";
import BarChart from "../../components/BarChart/BarChart";
import Loader from "../../components/Loader/Loader";
import LineBarChart from "../../components/LineBarChart/LineBerChart";
import { BACKEND_DOMAIN } from "../../utils/const";
import moment from "moment";
import Athlete from "../../pages/Panel/img/athlete.png";
import Researcher from "../../pages/Panel/img/researcher.png";

function Home({
  dashboard,
  token,
  info,
  getRegions,
  getDashboardById,
  regions,
  dashboard_loading,
  id,
  updateDash
}) {

  const [dash, setDash] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isActiveId, setIsActiveId] = useState(dashboard?.region_id || null);
  

  useEffect(() => {

    getDashboardById(info.region_id);
    getRegions();

    return () => {
      setDash(null);
    }
  }, [])

  useEffect(() => {
    dashboard && setDash(dashboard);
    setIsActiveId(dashboard?.region_id || null)
    return () => {
      setDash(null);
    }
  }, [dashboard])


  const handleSuperDashboard = (id) => {
    setIsActive(false);
    setIsActiveId(id);
    getDashboardById(id);
  }


  return (
      <div style={{width: '100%', minHeight: '50vh'}}>
      {dashboard_loading ?
      <Loader top="40vh" left="48%"/>
      :
      (!!dash && <div className={styles.content}>
      <div className={styles.block1}>
      <div className={styles.block1_1}>

        <div>
          <div className={styles.block1_1_number_big}>{dash.profiles_qty_data.total_by_region}</div>
          <div className={styles.block1_1_text_big}>всего юнармейцев<br/>ЗАРЕГИСТРИРОВАНО В БД</div>
        </div>

        <div style={{display: 'flex'}}>

        <div className={styles.block1_numbers}>
          <div className={styles.block1_1_number}>{dash.profiles_qty_data.total_by_region_active}</div>
          <div className={styles.block1_1_number}>{dash.profiles_qty_data.total_by_region_candidate}</div>
          <div className={styles.block1_1_number}>{dash.profiles_qty_data.total_by_region_other}</div>
        </div>

        <div className={styles.block1_texts}>
          <div className={styles.block1_1_text}>актив</div>
          <div className={styles.block1_1_text}>кандидат</div>
          <div className={styles.block1_1_text}>иные</div>
        </div>

        </div>



      </div>
      <div className={styles.block1_2}>
        <div className={styles.block1_2_image}>
          {/* <img src={diagr} alt="o_O"/> */}
          <PieChart percent={dash.profiles_qty_data.relative_by_total_yunarmy} labels={["вступили", "остальные"]}/>
        </div>
        <div className={styles.block1_2_text}>
          <div>{dash.profiles_qty_data.relative_by_total_yunarmy}% - от общей численности детей в регионе</div>
          <div>среднее по движению - {dash.profiles_qty_data.average_perc}%</div>
        </div>
      </div>
      <div className={styles.block1_3}>
        <div className={styles.block1_3_1}>прирост численности</div>
        <div className={styles.block1_3_2}>
          <div style={{display: "flex"}}>
            <div>
              <img src={boy} alt="o_O"/>
            </div>
            <div style={{marginLeft: "-20px"}}>
            <img src={girl} alt="o_O"/>
            </div>
          </div>
          <div className={styles.block1_3_2_text}>
            <div>за год: <span>{dash.profiles_qty_data.growth_data.by_year.by_region_total}</span> чел</div>
            <div className={styles.block1_3_2descr}>(из них <span>{dash.profiles_qty_data.growth_data.by_year.by_region_total_mobile}</span> через мобильное приложение)</div>
            <div>за месяц: <span>{dash.profiles_qty_data.growth_data.by_month.by_region_total}</span> чел</div>
            <div className={styles.block1_3_2descr}>(из них <span>{dash.profiles_qty_data.growth_data.by_month.by_region_total_mobile}</span> через мобильное приложение)</div>
        </div>
        </div>
        <div className={styles.block1_3_3}>
            <div>
              <BarChart percent={(dash.profiles_qty_data.growth_data.by_year.by_region_total / dash.profiles_qty_data.total_by_region * 100).toFixed(2)} width="100px" height="150px"/>
              <div style={{color: "#9d0a0e"}}>за год</div>
              <div>среднее по движению - {(dash.profiles_qty_data.growth_data.by_year.by_region_total / dash.profiles_qty_data.total_by_region * 100).toFixed(2)}%</div>
            </div>
            <div>
              <BarChart percent={(dash.profiles_qty_data.growth_data.by_month.by_region_total / dash.profiles_qty_data.total_by_region * 100).toFixed(2)} width="100px" height="150px"/>
              <div>за месяц</div>
              <div>среднее по движению - {(dash.profiles_qty_data.growth_data.by_month.by_region_total / dash.profiles_qty_data.total_by_region * 100).toFixed(2)}%</div>
            </div>
        </div>
      </div>
      <div className={styles.block1_4}>
        <div>
          <div className={styles.block1_4_text}>нерассмотренных заявлений на вступление - </div>
          <div className={styles.block1_4_number}>{dash.profiles_qty_data.unconfirmed_requests}</div>
        </div>
        <div>
          <div className={styles.block1_4_text}>нерассмотренных заявлений на вступление более месяца - </div>
          <div className={styles.block1_4_number}>{dash.profiles_qty_data.unconfirmed_requests_more_than_month}</div>
        </div>
      </div>
      {
        dash.region_id != 0 ?
        <div className={styles.block_Chief}>
        <span className={styles.block_Chief_title}>Начальник регионального штаба:</span>
        <div className={styles.blockInfoChief}>
        <div className={styles.avatar}>
            <img
              src={dash?.department.image
                      ? `${BACKEND_DOMAIN || ""}/${dash?.department?.image}`
                      : avatar
                  }
              alt='o_O'
            />
        </div>
        <div className={styles.inputBlock}>
        <div className={styles.block4_info}>
          <span className={styles.titleChief}>ФИО:</span><span>{dash?.department.chief}</span>
        </div>
        <div className={styles.block4_info}>
          <span className={styles.titleChief}>E-mail:</span><span>{dash?.department.email}</span>
        </div>
        <div className={styles.block4_info}>
          <span className={styles.titleChief}>Телефон:</span><span>{dash?.department.phone}</span>
        </div>
        </div>
        </div>
      </div>
      :
      ''
      }
      </div>
      <div className={styles.block2}>
        <div className={styles.titleBLock}>
          <div>
            {dash.region_id == 0 ? 'Российская Федерация' : ''}
          {regions && regions.filter(reg => reg.id == dash.region_id)[0]?.regionName} {regions && regions.filter(reg => reg.id == dash.region_id)[0]?.federalUnit}
          </div>
          { (info?.superuser || info?.agsh) &&
            <>
              <i 
                className={isActive ? styles.icon_active : styles.icon}
                onClick={() => setIsActive(prev => !prev)}
                >
                  <IoMdArrowDropdownCircle/></i>
              
                {
                  isActive &&
                  <div className={styles.regionBlock}>
                    <ul className={styles.regionList}>
                    <li 
                      key={0} 
                      className={isActiveId == 0 ? styles.regionItem_active : styles.regionItem}
                      onClick={() => handleSuperDashboard(0)}
                      >
                      Российская Федерация
                    </li>
                      {regions && regions.map(reg => 
                        <li 
                        key={reg.id} 
                        className={isActiveId == reg.id ? styles.regionItem_active : styles.regionItem}
                        onClick={() => handleSuperDashboard(reg.id)}
                        >
                          {`${reg.regionName} ${reg.federalUnit}`}
                        </li>  
                      )}
                    </ul>
                  </div>
                }
          </>
          }
        </div>
        <div className={styles.updatedBlock}>Последнее обновление: {moment(updateDash).format('DD.MM.YYYY HH:mm')}</div>
        
      </div>
      <div className={styles.block3}>
        <div className={styles.block3_bar}>
          {/* <img src={rectangle} alt="o_O"/> */}
          <LineBarChart
            percent={dash.profiles_filling_data.filling_by_region_perc}
            average={dash.profiles_filling_data.filling_by_yunarmy_perc}
          />
        </div>
        
        <div className={styles.desktop}>
        <div className={styles.block3_text}>
          <div className={styles.block3_texts}>
          <span>профилей без фото —</span>
          <span>профилей без телефона —</span>
          <span>профилей без e-mail —</span>
          <span>Профилей без привязки к МО —</span>
          <span>Профилей без привязки к отрядам —</span>
          </div>

          <div className={styles.block3_digitals}>
          <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_photo_total}</span></div>
          <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_phone_total}</span></div>
          <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_email_total}</span></div>
          <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_domestic_department_total}</span></div>
          <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_detachment_total}</span></div>
          </div>

          <div className={styles.block3_digitals}>
          <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.po_photo_perc}%)</span></div>
          <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.no_phone_perc}%)</span></div>
          <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.no_email_perc}%)</span></div>
          <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.no_domestic_department_perc}%)</span></div>
          <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.no_detachment_perc}%)</span></div>
          </div>
        </div>
        </div>

          <div className={styles.mobile} style={{width: '100%'}}>
        <div className={styles.block3_text} style={{width: '100%', flexWrap: 'wrap'}}>
          <div style={{margin: '0 0 .2rem 0', justifyContent: 'space-between'}}>
            <div className={styles.block3_texts}>
            <span style={{width: '120px'}}>профилей без фото</span>
            </div>

            <div className={styles.block3_digitals} style={{width: '70px'}}>
            <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_photo_total}</span></div>
            </div>

            <div className={styles.block3_digitals} style={{width: '50px'}}>
            <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.po_photo_perc}%)</span></div>
            </div>
          </div> 

          <div style={{margin: '0 0 .2rem 0', justifyContent: 'space-between'}}>
            <div className={styles.block3_texts}>
            <span style={{width: '120px'}}>профилей без телефона</span>
            </div>

            <div className={styles.block3_digitals} style={{width: '70px'}}>
            <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_phone_total}</span></div>
            </div>

            <div className={styles.block3_digitals} style={{width: '50px'}}>
            <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.no_phone_perc}%)</span></div>
            </div>  
          </div> 

          <div style={{margin: '0 0 .2rem 0', justifyContent: 'space-between'}}>
            <div className={styles.block3_texts}>
            <span style={{width: '120px'}}>профилей без e-mail</span>
            </div>

            <div className={styles.block3_digitals} style={{width: '70px'}}>
            <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_email_total}</span></div>
            </div>

            <div className={styles.block3_digitals} style={{width: '50px'}}>
            <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.no_email_perc}%)</span></div>
            </div>  
          </div> 

          <div style={{margin: '0 0 .2rem 0', justifyContent: 'space-between'}}>
            <div className={styles.block3_texts}>
            <span style={{width: '120px'}}>Профилей без привязки к МО</span>
            </div>

            <div className={styles.block3_digitals} style={{width: '70px'}}>
            <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_domestic_department_total}</span></div>
            </div>

            <div className={styles.block3_digitals} style={{width: '50px'}}>
            <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.no_domestic_department_perc}%)</span></div>
            </div>
          </div> 

          <div style={{margin: '0 0 .2rem 0', justifyContent: 'space-between'}}>
            <div className={styles.block3_texts}>
            <span style={{width: '120px'}}>Профилей без привязки к отрядам</span>
            </div>

            <div className={styles.block3_digitals} style={{width: '70px'}}>
            <div><span className={styles.block3_digitals_1}>{dash.profiles_filling_data.no_detachment_total}</span></div>
            </div>

            <div className={styles.block3_digitals} style={{width: '50px'}}>
            <div><span className={styles.block3_digitals_2}>({dash.profiles_filling_data.no_detachment_perc}%)</span></div>
            </div>  
          </div> 
          </div>
        </div>

      </div>
      <div className={styles.block4}>
        <div className={styles.block4_1}>
          {/* <img src={diagr} alt="o_O"/> */}
          <PieChart
            percent={dash.mobile_app_data.avg_logins_by_regions_perc}
            labels={["% восп-сь", "остальные"]}
          />
        </div>
        <div className={styles.block4_2}>
            <div className={styles.block4_2_text}>Входы в мобильное приложение уникальных пользователей за сегодня</div>
            <div className={styles.block4_2_number}>- {dash.mobile_app_data.unique_logins_today}</div>
        </div>
        <div className={styles.block4_3}>
          <div className={styles.block4_3_text}>средний процент входов в мобильное приложение по регионам за сегодня</div>
          <div className={styles.block4_3_number}>- {dash.mobile_app_data.avg_logins_by_regions_perc}%</div>
        </div>
        <div className={styles.block4_4}>
          <img src={deti} alt="o_O"/>
        </div>
        <div className={styles.block4_5}>
          <div className={styles.block4_5_number}>{dash.mobile_app_data.total_logins_today}</div>
          <div className={styles.block4_5_text}>-  количество входов в мобильное приложение за сегодня</div>
        </div>
        <div className={styles.block4_6}>
          <div className={styles.block4_6_number}>{dash.mobile_app_data.total_logins_year}</div>
          <div className={styles.block4_6_text}>- количество входов в мобильное приложение за год</div>
        </div>
        <div className={styles.block4_7}>
          <div className={styles.block4_7_image}>
            <img src={phone} alt="o_O"/>          
          </div>
          <div className={styles.block4_7_text}>
            <div className={styles.block4_7_firstrow}>
              <div className={styles.block4_7_firstrow_text}>Количество юнармейцев, <br/> ни разу не входивших <br/> в мобильное приложение</div>
              <div className={styles.block4_7_firstrow_numbers}>
                <div>{dash.mobile_app_data.never_logged_in.by_region}</div>
                <div>{dash.mobile_app_data.never_logged_in.by_region_perc}%</div>
              </div>
            </div>
            <div className={styles.block4_7_secondrow}>
              Среднее значение по регионам - {dash.mobile_app_data.never_logged_in.avg_by_regions}%
            </div>
          </div>
        </div>
        <div className={styles.block4_8}>
          <div>                     
            <BarChart percent={dash.profiles_qty_data.growth_data.by_month.by_region_total_mobile} 
            width="100px" 
            height="150px"
            />
          </div>
          <div>
            <br/>прирост <br/> количества <br/> пользователей <br/> мобильного <br/> приложения за <br/> месяц
          <span style={{display: 'block', width: '100%'}}>{dash.profiles_qty_data.growth_data.by_month.by_region_total_mobile}</span>
          </div>
        </div>
      </div>
      <div className={styles.block5}>
      <div className={styles.block5_1}>
        <div className={styles.block5_1_1}>
          участников<br/>мероприятий
        </div>
        <div className={styles.block5_1_2}>
          <div>{dash.articles_data.articles_active_participated}</div>
          <div>+ {dash.articles_data.articles_active_participated_month} за месяц</div>
        </div>
      </div>
      <div className={styles.block5_2}>
          <div className={styles.block5_2_1}>
            {/* <img src={diagr} alt="o_O"/> */}
            <PieChart 
              percent={dash.articles_data.requests_qty_share} 
              labels={[ "подали заявку", "остальные"]}/>
          </div>
          <div className={styles.block5_2_2}>
            <div>заявки на <br/> участие в <br/> мероприятиях</div>
            <div>{dash.articles_data.requests_qty}</div>
          </div>
          <div className={styles.block5_2_3}>
            <img src={chelovek} alt="o_O"/>
          </div>
      </div>
      <div className={styles.block5_3}>
        <div>доля юнармейцев, подавших заявку на участие <span>- {dash.articles_data.requests_qty_share}%</span></div>
        <div>среднее значение по регионам - {dash.articles_data.requests_qty_share_all_regions}%</div>
      </div>
      <div className={styles.block5_4}>
        <div className={styles.block5_4_1}>
        <img src={uchenik} alt="o_O"/>
        </div>
        <div className={styles.block5_4_2}>
          <div className={styles.block5_4_2_item}>
            <div>выполнено <br/> заданий</div>
            <div>- {dash.activity.tasks}</div>
          </div>
          <div className={styles.block5_4_2_item}>
            <div>добавлено <br/> событий</div>
            <div>- {dash.activity.streams}</div>
          </div>
        </div>
      </div>
      </div>
      <div className={styles.block6}>
        <div className={styles.block6_1}>
          <div className={styles.block6_1_1}>
            <div>{dash.profiles_filling_data.profile_duplicates_other_regions}</div>
            <div>дублей личных<br/>дел юнармейцев<br/>в других регионах</div>
          </div>
          <div className={styles.block6_1_2}>
            <span>количество регионов с дублями - </span>
            <span>{dash.profiles_filling_data.total_regions_with_duplicates}</span>
          </div>
        </div>
        <div className={styles.block6_2}>
          <img src={planshet} alt="o_O"/>
        </div>
      </div>
      <div className={styles.block7}>
        <div>
          <span>количество местных отделений - </span>
          <span>{dash.department.domestic_detachments}</span>
        </div>
          <span>количество отрядов - {dash.department.named_detachments + dash.department.profiled_detachments + dash.department.other_detachments}</span>
        <div className={styles.block7_1}>
          <div className={styles.block7_detachments}>
          <div>
          <span style={{marginLeft: '-29px'}}>из них:</span>
            
            <div>
              <span>именных - </span>
              <span>{dash.department.named_detachments}</span>
            </div>

            <div>
              <span>профильных - </span>
              <span>{dash.department.profiled_detachments}</span>
            </div>

            <div>
              <span>остальных - </span>
              <span>{dash.department.other_detachments}</span>
            </div>
          </div>

          <div className={styles.block7_images}>
            <img src={Researcher} width={'60px'} className={styles.researcher} alt="o_O"/>
            <img src={Athlete} width={'80px'} alt="o_O"/>
          </div>
          </div>
        </div>
        <div>
          <span>количество домов юнармии - {dash?.department?.yunarmy_house}</span>
        </div>
      </div>
    </div>)}
      </div>
  );
}


const mapStateToProps = (state) => ({
  token: state.auth.token,
  dashboard: state.auth.dashboard?.dashboard,
  updateDash: state.auth.dashboard?.updated,
  info: state.auth.user,
  regions: state.auth.regions,
  dashboard_loading: state.auth.dashboard_loading,
  id: state.auth.id,
});

export default connect(mapStateToProps, { getRegions, getDashboardById })(Home);

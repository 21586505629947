import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import Modal from "react-responsive-modal";
import { InputFile } from "../../assets/common/InputFile/InputFile"
import { IoMdTrash } from 'react-icons/io';
import styles from "./Equipment.module.css";
import {Slider} from './Slider';
import Loader from '../../components/Loader/Loader';

import { SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.css';
import { BACKEND_DOMAIN } from "../../utils/const";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";

const EquipmentModal = ({
  type,
  numberPage,
  selectedItem,
  editObject,
  addObject,
  uploadImage,
  deleteImage,
  getEquipmentImage,
  data,
  accessRights,
  removeEquipmentImage,
  images,
  loading,
  ...props
}) => {
  
  const updateRights = type === "ADD" || accessRights.ac_update
  const createRights = accessRights.ac_create

  const [item, setItem] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [toDeleteImages, setToDeleteImage] = useState([])
  const [imagesPreviews, setImagesPreviews] = useState([])

  useEffect(() => {
    item && item.id && getEquipmentImage(item.id)
  }, [item?.id, data])

  useEffect(() => {
    setImagesPreviews([])
    setToDeleteImage([]);
    setSelectedFiles([]);
    if (type === "ADD") {
      setItem({
        name: "",
        price: "",
        sizes: "",
        description: "",
        code: ""
      });
    } else {
      selectedItem && setItem({...selectedItem});
      if (Array.isArray(images)) {
        setImagesPreviews(images.map(el => `${BACKEND_DOMAIN || ""}${el}`))
      }
    }
  }, [selectedItem, type, images]);

  const addEquipmentItem = async () => {
    if (item.name) {
      await addObject(item, numberPage+1, selectedFiles.map(el => el.file));
      handleClose();
    }
  };

  const editEquipmentItem = async () => {
    if (item.name) {
      await editObject(item.id, item, numberPage+1);

      if (selectedFiles.length > 0) {
        selectedFiles.forEach(el => uploadImage(selectedItem.id, { file: el.file }, numberPage+1));
      } 
      if (toDeleteImages.length > 0) {
        toDeleteImages.forEach(el => deleteImage(item.id, el, numberPage+1))
      }

      handleClose()
    }
  };

  const handleUploadImage = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map(file => ({ file, url: URL.createObjectURL(file) }));

      setSelectedFiles(prev => [...prev, ...filesArray]);
      setImagesPreviews(prev => [...prev, ...filesArray.map(el => el.url)])
    }
  };

  const deletePhoto = (photo) => {
    if (!/^blob/g.test(photo)) {
      setToDeleteImage(prev => [...prev, photo])
    } else {
      const file = imagesPreviews.find(el => el.url === photo)

      URL.revokeObjectURL(file)

      setSelectedFiles(selectedFiles.filter(el => el.url !== photo))
    }

    setImagesPreviews(imagesPreviews.filter(name => name !== photo))
  };

  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <SwiperSlide key={photo}>
          <div className={`photoWrapper`}>
            <i className={styles.deletePhoto} onClick={() => deletePhoto(photo)}>
              {(createRights || updateRights) && <IoMdTrash />}
            </i>
            <img
              src={photo}
              alt="фото"
              className={styles.imgUploder}
            />
          </div>
        </SwiperSlide>
      )
    })
  }


  const handleClose = () => {
    setItem(null);
    props.onClose();
  }

  const statusToggleClick = () => {
    const status = item.status === 2 ? 1 : 2
    setItem(prev => ({...prev, status}))
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD"
          ? "Добавление экипировки"
          : "Редактирование экипировки"}
      </div>
      <div className={styles.modalBody}>
        <div>
          <div className={styles.firstBlock}>
            <div className={styles.firstBlockInner}>
              <input
                required
                maxLength="200"
                value={item?.name}
                disabled={!updateRights}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, name: e.target.value }))
                }
                className={styles.input}
                placeholder="Название"
              />
              <br />
              <input
                value={item?.code}
                disabled={!updateRights}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, code: e.target.value }))
                }
                className={styles.input}
                placeholder="Артикул"
              />
              <br />
              <input
                value={item?.sizes}
                disabled={!updateRights}
                onKeyPress={(event) => {
                  if (!/[0-9, ]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, sizes: e.target.value }))
                }
                className={styles.input}
                placeholder="Размеры"
              />
              <br />
            </div>
            <div className={styles.imageBlock}>

                <div className={styles.wrapperSliderEquipment}>
                  {
                    !loading ? 
                    <>
                    <Slider>{imagesPreviews.length > 0 && renderPhotos(imagesPreviews)}</Slider>
                    {(createRights || updateRights) &&
                    <div className={styles.wrapperBut}>
                      <InputFile
                        multiple
                        title="Выберите изображение"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => handleUploadImage(e)}
                      />
                    </div>}
                    </>
                    :
                    <div className={styles.load}><Loader top="30px" left="40%"/></div>
                  }
                  
                <div className={styles.wrapperSliderEquipmentSmall}>
                </div>
              </div>
            </div>
          </div>
          <div>
            <span className={styles.descriptionTitle}>Описание:</span>
            <ReactQuill
              value={item?.description || ''}
              readOnly={!updateRights}
              onChange={(e) => setItem((prev) => ({ ...prev, description: e }))}
            />
          </div>
          <CustomCheckbox
            label='Отображать в мобильном приложении'
            value={item?.status === 2}
            onClick={statusToggleClick}
          />
        </div>
        <div className="settingModal__btns">
          {(createRights || updateRights) &&
          <button
            className="newsListHeader__btn btn_grey"
            type="button"
            onClick={type === "ADD" ? addEquipmentItem : editEquipmentItem}
          >
            {type === "ADD" ? "Добавить" : "Сохранить"}
          </button>}
          <button
            className="newsListHeader__btn btn_grey"
            onClick={handleClose}
            type="button"
          >
            Закрыть
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EquipmentModal;

import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken, getUserInfo, signOut } from "./auth";
import { BACKEND_URL_PREFIX } from "../utils/const";
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  EDIT_USER_FAILURE,
  EDIT_USER_SUCCESS,
  EDIT_USER_REQUEST,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SET_REGION_LIST,
  SET_USER_REGIONS,
  SET_USER_DATA,
  RESET_USER_INFO,
  SET_USER_PHOTO_LOADING,
  ERROR,
  SET_USER_DEPARTMENTS,
  SET_CHANGED_DEPARTMENTS,
  SET_USER_LOADING,
  SET_REGION_GROUPS,
  SET_FEDERAL_DISTRICTS,
} from "./types";
import { createNotification } from "../components/Notifications/Notifications";



export const editUserDeps = (payload) => ({ type: SET_USER_DEPARTMENTS, payload})
export const editChangedDeps = (payload) => ({ type: SET_CHANGED_DEPARTMENTS, payload})
export const resetUserData = () => ({ type: RESET_USER_INFO })

export const fetchUsers = (history, word="", sort) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    
    dispatch({ type: FETCH_USERS_REQUEST });
    const params = {}
    sort && ( params.alph = sort )
    word && ( params.name = word )
    const urlParams = new URLSearchParams(params).toString();

    const response = await fetch(`${BACKEND_URL_PREFIX}/user?${urlParams}`, {
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: FETCH_USERS_SUCCESS, payload });
    } else {
      dispatch({ type: FETCH_USERS_FAILURE });
      response.status === 401 && dispatch(signOut(history));
    }
  }
};

export const addUser = (values, history, regions=null, avatarFile, closeModal) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: ADD_USER_REQUEST });
    const response = await fetch(`${BACKEND_URL_PREFIX}/user`, {
      ...buildDefaultHeaders(getState),
      method: "post",
      body: JSON.stringify(values),
    });

    if (response.ok) {
      const payload = await response.json();
      if (!payload.error) {
        const id = payload.id
        if (regions && regions.length) {
          await dispatch(editUserRegions(id, regions))
        }
        avatarFile && await dispatch(uploadUserImage(id, avatarFile))
        dispatch({ type: ADD_USER_SUCCESS, payload });
        dispatch(fetchUsers(history));
        createNotification("success", "Пользователь успешно добавлен");
        closeModal();
      } else {
        if (payload.error && payload.error==="User exist") {
          createNotification("error", "Пользователь с такими данными уже существует");
        } else {
          createNotification("error", "Ошибка добавления пользователя");
        }
        dispatch({ type: ADD_USER_FAILURE, error: payload.error });
      }
    } else {
      dispatch({ type: ADD_USER_FAILURE });
      response.status === 401 && dispatch(signOut(history));
      response.status === 409 && createNotification('error', 'Пользователь с такими данными уже существует')
    }
  }
};

export const editUser = (id, values, history) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: EDIT_USER_REQUEST });
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}`, {
      ...buildDefaultHeaders(getState),
      method: "put",
      body: JSON.stringify(values),
    });

    if (response.ok) {
      const changedDepartments = getState().users.changedDepartments
      for (const item of changedDepartments) {
        await dispatch(editUserDepartments(id, item))
      }
      dispatch({ type: EDIT_USER_SUCCESS });
      dispatch(fetchUsers(history));
      dispatch(getUserInfo());
      dispatch(editChangedDeps([]))
    } else {
      dispatch(editChangedDeps([]))
      dispatch({ type: EDIT_USER_FAILURE });
      response.status === 401 && dispatch(signOut(history));
    }
  }
};

export const changePassword =
  (values, history) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: CHANGE_PASSWORD_REQUEST });
      const response = await fetch(`${BACKEND_URL_PREFIX}/user/password`, {
        ...buildDefaultHeaders(getState),
        method: "put",
        body: JSON.stringify(values),
      });

      if (response.ok) {
        dispatch({ type: CHANGE_PASSWORD_SUCCESS });
      } else {
        dispatch({ type: CHANGE_PASSWORD_FAILURE });
        response.status === 401 && dispatch(signOut(history));
      }
    }
  };

export const getRegions = (sort) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: FETCH_USERS_REQUEST });
    const params = {}
    sort && ( params.alph = sort )
    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/regions?${urlParams}`, {
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_REGION_LIST, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getUserRegions = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: FETCH_USERS_REQUEST });
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/regions`, {
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_USER_REGIONS, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const editUserRegions = (id, arr) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: FETCH_USERS_REQUEST });
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/regions`, {
      ...buildDefaultHeaders(getState),
      method: "put",
      body: JSON.stringify(arr),
    });

    if (response.ok) {
      dispatch(getUserRegions(id));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const editRegion = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: FETCH_USERS_REQUEST });
    const id = data.id
    delete data.id
    const response = await fetch(`${BACKEND_URL_PREFIX}/region/${id}`, {
      ...buildDefaultHeaders(getState),
      method: "put",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      dispatch(getRegions());
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getUserData = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const userInfo = await fetch(`${BACKEND_URL_PREFIX}/user/${id}`, {
      headers: {
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        "Access-Control-Allow-Origin": "*",
      },
    });
    if (userInfo.ok) {
      const info = await userInfo.json();
      dispatch({ type: SET_USER_DATA, payload: info[0] });
    } else {
      dispatch({ type: ERROR, payload: userInfo });
    }
  }
};

export const uploadUserImage = (id, image) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: FETCH_USERS_REQUEST, payload: true });

    const fd = new FormData();
    fd.append("file", image);

    if (getState().auth.token) {
      dispatch({ type: SET_USER_PHOTO_LOADING, payload: true });
      await dispatch(deleteUserImage(id));
      const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/image`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
        body: fd,
      });
      if (response.ok) {
        console.log("ok");
        await dispatch(getUserData(id));
        id == getState().auth.id && dispatch(getUserInfo());
      } else {
        dispatch({ type: ERROR, payload: response });
      }
      dispatch({ type: SET_USER_PHOTO_LOADING, payload: false });
    }
  }
};

export const deleteUserImage = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/image`, {
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      dispatch(getUserData(id));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getUserDepartments = (id, regId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/region/${regId}/domestic_departments`, {
      headers: {
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        "Access-Control-Allow-Origin": "*",
      }
    });
    if (response.ok) {
      const json = await response.json();
      const _data = json.domestic_departments[0] ? json.domestic_departments[0]?.domestic_departments : []
      dispatch({ type: SET_USER_DEPARTMENTS, payload: _data });
  } else {
    dispatch({ type: ERROR, payload: response });
  }
}
};

export const editUserDepartments = (id, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/domestic_departments `, {
      ...buildDefaultHeaders(getState),
      method: "put",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      console.log("ok")
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getRegionGroups = (sort) => async (dispatch, getState) => {
  dispatch({ type: SET_USER_LOADING, payload: true });
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const params = {}
    sort && ( params.alph = sort )
    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/region_groups?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({
        type: SET_REGION_GROUPS,
        payload: data,
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({ type: SET_USER_LOADING, payload: false });
};

export const deleteRegionGroup = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/region_groups/${id}`, {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
          dispatch(getRegionGroups())
      } else {
          dispatch({ type: ERROR, payload: response });
      }
  }
}

export const addRegionGroup = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/region_groups`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
          dispatch(getRegionGroups())
      } else {
          dispatch({ type: ERROR, payload: response });
      }
  }
}

export const editRegionGroup = (data) => async (dispatch, getState) => {
  const id = data.id
  delete data.id
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/region_groups/${id}`, {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
          dispatch(getRegionGroups())
      } else {
          dispatch({ type: ERROR, payload: response });
      }
  }
}

export const getFederalDistricts = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/federal_districts`, {
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_FEDERAL_DISTRICTS, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};
import React from "react";
import styles from "./CustomDateppicker.module.css"

export default function CustomDatepicker({
    title = "",
    style,
    validate,
    ...props
}) {
  return (
    <div className={styles.dateBlock} style={style}>
      <div className={styles.title}>{title}:</div>
      <input {...props} type='date'/>
      {validate && <div className={styles.validate}>поле является обязательным</div>}
    </div>
  );
}

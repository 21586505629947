import {
    ERROR,
    GET_ABOUT_UNARMAINS,
    GET_ABOUT_UNARMAINS_LOADING
  } from "./types";
  import { BACKEND_URL_PREFIX } from "../utils/const";
  import { buildDefaultHeaders } from "./helper";
  import { checkAndUpdateToken } from "./auth";

  export const getAboutUnarmians = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    dispatch({ type: GET_ABOUT_UNARMAINS_LOADING, payload: true });
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/about_yunarmy `, {
        ...buildDefaultHeaders(getState),
      });
  
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: GET_ABOUT_UNARMAINS, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
      dispatch({ type: GET_ABOUT_UNARMAINS_LOADING, paload: false });
    }
  };

  export const editAboutUnarmians = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    dispatch({ type: GET_ABOUT_UNARMAINS_LOADING, payload: true });
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/about_yunarmy/1`, {
        method: "PUT",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
    });
        if (response.ok) {
            // dispatch(getDigitalCount());
        } else {
          dispatch({ type: ERROR, payload: response });
        }
        dispatch({ type: GET_ABOUT_UNARMAINS_LOADING, paload: false });
    }
  }

  export const uploadPhotoAboutUnarmians = (image) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", image.file);

    if (getState().auth.token) {
      dispatch({ type: GET_ABOUT_UNARMAINS_LOADING, payload: true });
      const response = await fetch(`${BACKEND_URL_PREFIX}/about_yunarmy/1/image`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
        body: fd,
      });
      if (response.ok) {
        await dispatch(getAboutUnarmians());
      } else {
        dispatch({ type: ERROR, payload: response });
      }
      dispatch({ type: GET_ABOUT_UNARMAINS_LOADING, payload: false });
    }
  };

  export const deletePhotoAboutUnarmians = (image_id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/about_yunarmy/image/${image_id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        await dispatch(getAboutUnarmians());
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };
import React, { useState, useEffect } from "react";
import { IoIosArrowDown, IoIosCloseCircle } from "react-icons/io";
import { filterItems, selects, profile_types } from "../../../utils/const";
import styles from "./FilterItem.module.css";
import DatePicker from "react-datepicker";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { getYearsArr } from "../../../utils/yunarmyStat"

export default function FilterItem({
  item,
  i,
  active,
  toggleActive,
  getFilteredGroup,
  getFilteredLocalDep,
  filterRes,
  removeFilteredGroup,
  removeFilteredLocalDep,
  filteredGroups,
  setFilteredGroups,
  handleChangeInputFilter,
  value,
  handleReg,
  ageList,
  regList,
  handleAge,
  actived,
  country,
  removeFilteredGroupCountry,
  setByCountry,
  departments,
  byCountry,
  setActive,
  abroad, 
  setAbroad
}) {

  const [isActiveArrow, setIsActiveArrow] = useState([]);
  const [newFilterItems, setNewFilterItems] = useState(null);
  const [inputValue, setInputValue] = useState('');
  
   useEffect(() => {
    if (!byCountry) {
      
      setNewFilterItems(filterItems.filter((el, i) => i != 0).filter(el => el != 'Страна'));
      setActive([...active].filter(el => el != 1));
      setIsActiveArrow([...isActiveArrow.filter(el => el != 'Страна')])
    } else {
      setNewFilterItems(filterItems);
    }
  }, [byCountry, country, abroad])

  const inputChanges = (e) => {
    setInputValue(e.target.value)
    if (item.group === "localDep") {
      e.target.value.length >= 1
        ? getFilteredLocalDep(e.target.value)
        : removeFilteredLocalDep();
    } else if (item.group === "country") {
        e.target.value.length >= 2
          ? getFilteredGroup(item.group, e.target.value)
          : removeFilteredGroupCountry();
  } else  {
      e.target.value.length >= 2
        ? getFilteredGroup(item.group, e.target.value)
        : removeFilteredGroup(item.group);
    }
  };

  const addItem = (it) => {
    let obj = { ...filteredGroups };
    let arr = [...filteredGroups[item.group]];
    if (item.group === "localDep") {
      if (!arr.map((i) => i.id).includes(it.id)) {
        arr.push(it);
        obj[item.group] = arr;
        setFilteredGroups(obj);
      }
      removeFilteredLocalDep();
    } else if (item.group === "country") { 
      if (!arr.map((i) => i.id).includes(it.id)) {
        arr.push(it);
        obj[item.group] = arr;
        setFilteredGroups(obj);
      }
      removeFilteredGroupCountry();
    } else {
      if (!arr.map((i) => i.regionCode).includes(it.regionCode)) {
        arr.push(it);
        obj[item.group] = arr;
        setFilteredGroups(obj);
      }
      removeFilteredGroup(item.group);
    }

    if (["regions", "localDep", "country"].includes(item.group)) {
      setInputValue('');
    }
  };

  const deleteItem = (it) => {
    let obj = { ...filteredGroups };
    let arr = [...filteredGroups[item.group]];
    let filteredArr;
    if (item.group === "localDep") {
      filteredArr = arr.filter((el) => el.id !== it.id);
    } else if (item.group === "country") {
      filteredArr = arr.filter((el) => el.id !== it.id);
    } else {
      filteredArr = arr.filter((el) => el.regionName !== it.regionName);
    }
    obj[item.group] = filteredArr;
    setFilteredGroups(obj);
  };

  const handleInput = (e, it, i) => {
    let obj = { ...filteredGroups };
    obj[item.group] = selects[item.group][i].value;
    setFilteredGroups(obj);
  };

  const handleCheckboxToggle = (filterName) => {
    let obj = { ...filteredGroups };
    obj[filterName] = !obj[filterName];
    setFilteredGroups(obj);

    if (filterName == 'abroad') {
      removeFilteredGroupCountry();
      setByCountry(prev=>!prev);
      obj.country = [];
      setFilteredGroups(obj);
      setAbroad(!abroad)
    }
  };

  const handleYearChange = (e) => {
    let obj = { ...filteredGroups };
    obj["birthday_year"] = e.target.title
    setFilteredGroups(obj);
  }

  const handleTypeChange = (e) => {
    let obj = { ...filteredGroups };
    obj["unaprofile_type"] = e.target.title
    setFilteredGroups(obj);
  }

  const handleClick = (i, name) => {
    toggleActive(i);
    if (!isActiveArrow.includes(name)) {
      setIsActiveArrow(prev => [...prev, name])
    } else {
      setIsActiveArrow(prev => [...prev.filter(el => el !== name)])
    }
  }

  return (
    <div
      className={`${item.group == 'country' ? styles.filterItemCountry : styles.filterItem}`}
      onClick={() => removeFilteredGroup(item.group)}
    >
      <div
        key={item.name}
        index={i}
        onClick={() => handleClick(i, item.name)}
        className={styles.filterTitle}
      >
        <div>
          {item.icon}
          <span className={actived ? styles.filterNameActived : styles.filterName}>{item.name}</span>
        </div>
        <i className={isActiveArrow.includes(item.name) ? styles.arrowActive : styles.arrow}><IoIosArrowDown /></i>
      </div>

      {active.includes(i) && (
        <div className={styles.selectBlock}>
          {["regions", "localDep"].includes(item.group)
            ? <>
              <div className={styles.inputBlock}>
                <input
                  onClick={(e) => e.stopPropagation()}
                  onChange={inputChanges}
                  onFocus={inputChanges}
                  value={inputValue}
                />
                {!!filterRes[item.group].length && (
                  <div className={styles.itemList}>
                    {filterRes[item.group].map((item, i) => (
                      <div key={`${i}`} onClick={() => addItem(item)}>
                        {item?.regionName ? `${item?.regionName} ${item?.federalUnit}` : item?.name}
                      </div>
                    ))}
                  </div>
                )}
                {item.group === "regions" && (
                  <CustomCheckbox
                    label={`Соотечественники за рубежом`}
                    value={abroad}
                    onClick={() => handleCheckboxToggle('abroad')}
                  />
                )}
              </div>
              {!!filteredGroups[item.group].length && (
                <div className={styles.selectedBlock}>
                  {filteredGroups[item.group].map((it, i) => (
                    <div key={`${i}`}>
                      {it.regionName || it.name}
                      <i>
                        <IoIosCloseCircle
                          size={20}
                          onClick={() => deleteItem(it)}
                          color="grey"
                        />
                      </i>
                    </div>
                  ))}
                </div>
              )}
              {item.group === "localDep" && (
                  <CustomCheckbox
                    label={`Не привязаны к местным отделениям`}
                    value={filteredGroups.nodep}
                    onChange={() => handleCheckboxToggle("nodep")}
                  />
                )}
              </>
            : (item.group === "country" && byCountry)
              ? <>
                <div className={styles.inputBlock}>
                    <input
                      onClick={(e) => e.stopPropagation()}
                      onChange={inputChanges}
                      onFocus={inputChanges}
                      value={inputValue}
                    />

                    {!!filterRes[item.group]?.length && (
                      <div className={styles.itemList}>
                        {filterRes[item.group].map((item, i) => (
                          <div key={`${i}`} onClick={() => addItem(item)}>
                            {item.name || item.short_name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  
                  {!!filteredGroups[item.group].length && (
                    <div className={styles.selectedBlock}>
                      {filteredGroups[item.group].map((it, i) => (
                        <div key={`${i}`}>
                          {it.name || it.short_name}
                          <i>
                            <IoIosCloseCircle
                              size={20}
                              onClick={() => deleteItem(it)}
                              color="grey"
                            />
                          </i>
                        </div>
                      ))}
                    </div>
                  )}
                </>
           : item.group === "lastName"
            ? (
            <div className={styles.lastNameBlock}>
              <input
                value={filteredGroups.lastName}
                placeholder="Фамилия"
                name="lastName"
                onChange={handleChangeInputFilter}
              />
              <input
                value={filteredGroups.firstName}
                placeholder="Имя"
                name="firstName"
                onChange={handleChangeInputFilter}
              />
              <input
                value={filteredGroups.fathersName}
                placeholder="Отчество"
                name="fathersName"
                onChange={handleChangeInputFilter}
              />
            </div>
            )
            : item.group === "detachment"
              ? (
            <div className={styles.lastNameBlock}>
              <input
                value={filteredGroups.detachment}
                placeholder="Отряд"
                name="detachment"
                onChange={handleChangeInputFilter}
              />
              <CustomCheckbox
                label={`Не привязаны к отрядам`}
                value={filteredGroups.nodet}
                onChange={() => handleCheckboxToggle("nodet")}
                />
            </div>
          )
            : item.group === "email"
            ? (
            <div className={styles.lastNameBlock}>
              <input
                value={filteredGroups.email}
                placeholder="E-mail"
                name="email"
                onChange={handleChangeInputFilter}
              />
            </div>
            )
            : ["school", "city", "personal_id"].includes(item.group)
            ? (<div className={styles.lastNameBlock}>
              <input
                value={filteredGroups[item.group]}
                name={item.group}
                onChange={handleChangeInputFilter}
              />
            </div>
            )
            : item.group === "from_date"
            ? (<div className={styles.datesBlock}>
              c{" "}
              <DatePicker
                selected={filteredGroups["from_date"]}
                showYearDropdown
                onChange={(date) =>
                  handleChangeInputFilter({
                    target: { value: date, name: "from_date" },
                  })
                }
              />{" "}
              по{" "}
              <DatePicker
                selected={filteredGroups["to_date"]}
                showYearDropdown
                onChange={(date) =>
                  handleChangeInputFilter({
                    target: { value: date, name: "to_date" },
                  })
                }
              />
            </div>)
            : item.group === "profile_filled_from_date"
            ? (<div className={styles.datesBlock}>
              c{" "}
              <DatePicker
                selected={filteredGroups["profile_filled_from_date"]}
                showYearDropdown
                onChange={(date) =>
                  handleChangeInputFilter({
                    target: { value: date, name: "profile_filled_from_date" },
                  })
                }
              />{" "}
              по{" "}
              <DatePicker
                selected={filteredGroups["profile_filled_to_date"]}
                showYearDropdown
                onChange={(date) =>
                  handleChangeInputFilter({
                    target: { value: date, name: "profile_filled_to_date" },
                  })
                }
              />
            </div>)
            : item.group === "age"
            ? (<>
              {selects["age"].map((item, index) => (
                <div className={styles.selectItem}>
                  <label>
                    <input
                      type="checkbox"
                      key={item.id}
                      onClick={(e) => handleAge(e, item.value)}
                      title={item.name}
                      checked={ageList.includes(item.value)}
                    />
                    {item.name}
                  </label>
                </div>
              ))}
              <div className={styles.selectItem}>
                  <label>
                    <input
                      type="checkbox"
                      key={"Старше 18"}
                      onClick={(e) => handleCheckboxToggle("is_adult")}
                      title={"Старше 18"}
                      checked={filteredGroups.is_adult}
                    />
                    Старше 18
                  </label>
                </div>
            </>)
            : item.group === "regional_department_ids"
            ? (<>
              {departments && departments.map((item, index) => (
                <div className={styles.selectItem}>
                  <label>
                    <input
                      type="checkbox"
                      key={item.id}
                      onClick={(e) => handleReg(e, item.id)}
                      title={item.name}
                      checked={regList.includes(item.id)}
                    />
                    {item.name}
                  </label>
                </div>
              ))}
            </>)
            : item.group === "createdAt"
            ? (<div className={styles.datesBlock}>
              c{" "}
              <DatePicker
                selected={filteredGroups["createdAt"]}
                showYearDropdown
                onChange={(date) =>
                  handleChangeInputFilter({
                    target: { value: date, name: "createdAt" },
                  })
                }
              />{" "}
              по{" "}
              <DatePicker
                selected={filteredGroups["createdAtTo"]}
                showYearDropdown
                onChange={(date) =>
                  handleChangeInputFilter({
                    target: { value: date, name: "createdAtTo" },
                  })
                }
              />
            </div>
            )
            : item.group === "on_date"
            ? (<div className={styles.datesBlock}>
              Дата{" "}
              <DatePicker
                selected={filteredGroups["on_date"]}
                showYearDropdown
                onChange={(date) =>
                  handleChangeInputFilter({
                    target: { value: date, name: "on_date" },
                  })
                }
              />{" "}
              <div >
              Исполняется{" "}
              <input
                style={{marginTop: '.5rem'}}
                value={filteredGroups.is_age}
                placeholder="Возраст"
                name="is_age"
                onChange={handleChangeInputFilter}
                type="number"
              />
              </div>
            </div>
            )
            : item.group === "birthday_year"
            ? (<>
            <div key={`${i}`} className={styles.selectItem}>
              <label>
                    <input
                      type="radio"
                      onChange={handleYearChange}
                      name="birthday_year"
                      title=''
                    />
                    {"Любой год"}
              </label>
            </div>
              {getYearsArr(1970).map((it, i) => (
                <div key={`${i}`} className={styles.selectItem}>
                  <label>
                    <input
                      type={item.type}
                      key={it}
                      onChange={handleYearChange}
                      title={it}
                      name={item.group}
                    />
                    {it}
                  </label>
                </div>
              ))}
            </>
          )
          : item.group === "unaprofile_type"
            ? (<>
            <div key={`${i}`} className={styles.selectItem}>
              <label>
                    <input
                      type="radio"
                      onChange={handleTypeChange}
                      name="unaprofile_type"
                      title=''
                    />
                    {"Все профили"}
              </label>
            </div>
              {profile_types.map((it, i) => (
                <div key={`${i}`} className={styles.selectItem}>
                  <label>
                    <input
                      type={item.type}
                      key={it.value}
                      onChange={handleTypeChange}
                      title={it.value}
                      name={item.group}
                    />
                    {it.value}
                  </label>
                </div>
              ))}
            </>
          ) 
          : (
            <>
              {
                (item.group != 'country') &&
                newFilterItems && newFilterItems[i]?.map((it, i) => (
                    <div key={`${i}`} className={styles.selectItem}>
                      <label>
                        <input
                          type={item.type}
                        key={it}
                        onChange={(e) => handleInput(e, it, i, value)}
                        title={it}
                        value={it}
                          name={item.group}
                          checked={
                            filteredGroups[item.group] ===
                            selects[item.group][i]?.value
                          }
                        />
                        {it}
                      </label>
                    </div>
                  ))
                }
              </>
          )}
        </div>
      )}
    </div>
  );
}

const thrash = (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
        <path d="M14.8462 17.5153H5.1539C4.41544 17.5153 3.76929 16.8692 3.76929 16.1307V3.66919H16.2308V16.1307C16.2308 16.8692 15.5847 17.5153 14.8462 17.5153Z" stroke="#314561" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.9231 13.3615V7.823" stroke="#314561" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.0769 13.3615V7.823" stroke="#314561" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 3.66919H19" stroke="#314561" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.0768 0.899902H7.92295C7.18448 0.899902 6.53833 1.54606 6.53833 2.28452V3.66913H13.4614V2.28452C13.4614 1.45375 12.8153 0.899902 12.0768 0.899902Z" stroke="#314561" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

const checked = (color) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <   path d="M1 4.85739L4.75 10.0002L11 1.00024" stroke="#314561" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
)

export { thrash, checked }
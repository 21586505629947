import React from 'react';

const MinButton = ({ image, onClick, label, className, disabled }) => (
  <div
    className={className}
    onClick={onClick}
    style={disabled ? {opacity: 0.5, pointerEvents: "none", userSelect: "none"} : null}
  >
    {image && <img src={image} alt={label} />}
    {label && <p>{label}</p>}
  </div>
);

export default MinButton;

import React from 'react';
import { Field } from 'redux-form';

const WrappedTextInput = ({ type='text', input, labelClass, label, name, ...props}) => (
  <div>
    {label && <label className={`input__label ${labelClass}`} htmlFor={name}>{label}</label>}
    <input type={type} name={name} {...props} {...input} />
  </div>
);

const TextField = (props) => (
  <Field
    component={WrappedTextInput}
    {...props}
  />
);

export default TextField;

import { BACKEND_URL_PREFIX } from "../utils/const";
import { checkAndUpdateToken } from "./auth"
import { buildDefaultHeaders } from "./helper";
import { SET_MATERIAL_GROUPS, SET_MATERIALS, SET_FILES_LIST, ERROR } from "./types";





export const getGroups = (sort) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const params = {}
        sort && ( params.alph = sort )
        const urlParams = new URLSearchParams(params).toString();
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterialgroup?${urlParams}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: SET_MATERIAL_GROUPS, payload: data });
          } else {
            dispatch({ type: ERROR, payload: response });
          }
    }
}


export const deleteGroup = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterialgroup/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            dispatch(getGroups())
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const addGroup = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterialgroup`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            dispatch(getGroups())
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const editGroup = (data) => async (dispatch, getState) => {
    const id = data.id
    delete data.id
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterialgroup/${id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            dispatch(getGroups())
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const getMaterials = (sort) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const params = {}
        sort && ( params.alph = sort )
        const urlParams = new URLSearchParams(params).toString();
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterial?${urlParams}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: SET_MATERIALS, payload: data });
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const addMaterial = (data, files) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterial`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            const resp = await response.json()
            const id = resp.id
            if (files.length) {
                for (const file of files) {
                await dispatch(uploadFile(id, file))
                }
            }
            dispatch(getMaterials())
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const deleteMaterial = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterial/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            dispatch(getMaterials())
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const editMaterial = (id, data, files) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const deletedFiles = [...data.deletedFiles]
        delete data.deletedFiles
        delete data.id
        delete data.groupname
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterial/${id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        })
        if (deletedFiles.length) {
            for (const name of deletedFiles) {
              await dispatch(deleteFile(id, name))
            }
          }
          if (files.length) {
              for (const file of files) {
                await dispatch(uploadFile(id, file))
              }
          }
        if (response.ok) {
            dispatch(getMaterials())
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const getFilesList = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterial/${id}/file`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: SET_FILES_LIST, payload: data });
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const uploadFile = (id, file) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())

    const fd = new FormData()
    fd.append('file', file)

    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterial/${id}/file`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        })
        if (response.ok) {
            console.log("ok")
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

export const deleteFile = (id, name) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachingmaterial/${id}/file/${name}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            console.log("ok")
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
}

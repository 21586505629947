import React, {  useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getQuestsCount,
  getQuestsObject,
  deleteQuestsObject,
  editQuestsObject,
  addQuestsObject,
  removeQuestTasks
} from "../../actions/quests";
import {
  removeDepartments,
  getDepartments,
  getForAny
} from "../../actions/departments";
import {
  getArticles,
  getTaskCount,
  getTaskObject,
  deleteTaskObject,
  editTaskObject,
  addTaskObject,
  uploadImage,
  deleteImage
} from '../../actions/task'
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import QuestsModal from "./QuestsModal";
import styles from "./Quests.module.css"
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import { Pagination } from "../../assets/common/Pagination/Pagination";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import ItemsPerPage from "../../components/ItemsPerPage";


const Quests = ({
  getQuestsCount,
  getQuestsObject,
  deleteQuestsObject,
  editQuestsObject,
  addQuestsObject,
  getArticles,
  getTaskObject,
  addTaskObject,
  editTaskObject,
  deleteTaskObject,
  getTaskCount,
  taskPageCount,
  uploadImage,
  deleteImage,
  removeQuestTasks,
  data,
  tasks,
  pageCount,
  eventOptions,
  removeDepartments,
  accessRights,
  getDepartments,
  regDepartments,
  localDepartments,
  detachments,
  getForAny,
  itemsOnPage,
  loading,
  history
}) => {

  const deleteRights = accessRights.ac_delete

  const [numberPage, setNumberPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const [sort, setSort] = useState("");

  useEffect(() => {
    const questId = history.location.state?.questId
    if (questId && data) {
      const questData = data.find(item => item.id===questId)
      openModal("EDIT", questData)
    }
  }, [])
  
  useEffect(() => {
    getQuestsCount()
    getQuestsObject(numberPage+1, sort)
    getArticles()
  }, [itemsOnPage, numberPage, sort]);

  const openModal = (type, item) => {
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteQuest = (id) => {
    deleteQuestsObject(id, numberPage+1)
  }

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const acceptConfirmation = () => {
    handleDeleteQuest(confirmation.id)
    setConfirmation(null)
  }

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Квесты</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
            {accessRights.ac_create && <ButtonRed onClick={() => openModal("ADD")}>Добавить</ButtonRed>}
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Отделения:</div>
          </div>
          <div className={styles.table}>
            {data &&
              data.map((a) => (
                <div key={a.id} className={styles.tableRow}>
                  <div
                    className={styles.displayname}
                    onClick={() => openModal("EDIT", a)}
                  >
                    <span>
                      {a.name}
                    </span>
                  </div>
                  <div className={styles.actions}>
                    {deleteRights
                      ? <IoMdClose onClick={() => setConfirmation({text: a.name, id: a.id})}/>
                      : <div style={{height:'1.5rem'}}></div>
                    }
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.pagination}>
            <Pagination pageCount={pageCount} numberPage={numberPage} onPageChange={handlePageClick}/>
          </div>
          <div className={styles.controls}>
            <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
            <ItemsPerPage/>
          </div>
        </div>
      </div>
      {confirmation &&
      <Confirmation 
        confirmation={confirmation.text}
        acceptConfirmation={acceptConfirmation}
        rejectConfirmation={() => setConfirmation(null)}

      />}
      {isModalOpen &&
      <QuestsModal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        eventOptions={eventOptions}
        editQuestsObject={editQuestsObject}
        getTaskObject={getTaskObject}
        addTaskObject={addTaskObject}
        editTaskObject={editTaskObject}
        deleteTaskObject={deleteTaskObject}
        getTaskCount={getTaskCount}
        uploadImage={uploadImage}
        deleteImage={deleteImage}
        accessRights={accessRights}
        addQuestsObject={addQuestsObject}
        numberPage={numberPage}
        tasks={tasks}
        taskPageCount={taskPageCount}
        removeDepartments={removeDepartments}
        getDepartments={getDepartments}
        regDepartments={regDepartments}
        localDepartments={localDepartments}
        detachments={detachments}
        removeQuestTasks={removeQuestTasks}
        getForAny={getForAny}
        history={history}
        loading={loading}
      />}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.quests.data,
  pageCount: state.quests.page_count,
  eventOptions: state.task.articles,
  tasks: state.quests.questTasks,
  taskPageCount: state.task.page_count,
  regDepartments: state.departments.region_departments,
  localDepartments: state.departments.local_departments,
  detachments: state.departments.detachments,
  itemsOnPage: state.auth.itemsOnPage,
  loading: state.departments.fromListLoading,
  accessRights: state.auth.accessRights.find(i => i.name==='quest')||{}
})

export default connect(mapStateToProps, {
  getQuestsCount,
  getQuestsObject,
  deleteQuestsObject,
  editQuestsObject,
  addQuestsObject,
  getArticles,
  getTaskObject,
  addTaskObject,
  editTaskObject,
  deleteTaskObject,
  uploadImage,
  deleteImage,
  removeQuestTasks,
  getTaskCount,
  removeDepartments,
  getDepartments,
  getForAny
})(
  Quests
);

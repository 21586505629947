const originalFetch = window.fetch;




window.fetch = async (...args) => {
  let [resource, config] = args;
  let response = await originalFetch(resource, config);
  if (response.status === 502 || response.status === 503) window.location.href = '/unavailable';
  return response;
};
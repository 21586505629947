import * as types from "../actions/types";

const initialState = {
  data: null,
  loading: false,
  selected: null,
  pages: {
    data: null,
    pageCount: null
  }
};

export default function private_stream(state = initialState, action) {
  switch (action.type) {
    case types.SET_STREAMS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_STREAMS_BY_ID:
      return {
        ...state,
        selected: action.payload,
      };
    case types.STREAM_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case types.SET_STREAMS_COUNT:
        return {
            ...state,
            pages: {data: action.payload.data, pageCount: action.payload.pageCount},
        };
      default:
        return state;
    }
}
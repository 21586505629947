import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { getFormValues } from "redux-form";
import {
  fetchUsers,
  addUser,
  editUser,
  changePassword,
  getRegions,
  getUserData,
  getUserRegions,
  editUserRegions,
  deleteUserImage,
  uploadUserImage,
  getUserDepartments,
  editUserDepartments,
  editUserDeps,
  editChangedDeps,
  resetUserData
} from "../../actions/users";
import { getAllYunarmiansData, removeDataForPrint } from "../../actions/yunarmiyans";
import { getDepartments } from "../../actions/departments"
import Modal from "./AddEditUserModal";
import FindField from "../../assets/common/FindField/FindField";
import Confirmation from '../../assets/common/Confirmation/Confirmation';
import { createNotification } from "../../components/Notifications/Notifications";
import Loader from "../../components/Loader/Loader";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";



const regex = /[^a-zA-Z0-9/@%!"#?¨'-_.,]/

const Users = ({
  editUser,
  fetchUsers,
  addUser,
  history,
  users,
  changePassword,
  userData,
  loading,
  getUserData,
  isSuperUser,
  deleting,
  getRegions,
  getUserRegions,
  regions,
  userRegions,
  userDeps,
  editUserRegions,
  deleteUserImage,
  uploadUserImage,
  getDepartments,
  getUserDepartments,
  popupLoading,
  editUserDepartments,
  departments,
  editUserDeps,
  changedDeps,
  editChangedDeps,
  resetUserData,
  getAllYunarmiansData,
  removeDataForPrint,
  profiles,
  formValues
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [confirmation, setConfirmation] = useState(null);
  const [sort, setSort] = useState("");


  useEffect(() => {
    getDepartments()
    !regions?.length && getRegions();
  }, []);

  useEffect(() => {
    fetchUsers(history, searchInput, sort);
  }, [searchInput, sort])
  

  useEffect(() => {
    const usersArr = getUserPage(users, pageNumber);
    setDisplayedUsers(usersArr);
  }, [users, pageNumber]);

  const getUserPage = (users, pageNumber) =>
    users.slice(pageNumber * 20, pageNumber * 20 + 20);

  const handleEditUser = (values, id) => () => {
    const {
      superuser,
      active,
      displayname,
      email,
      phone,
      position,
      department_id,
      agsh,
      nrsh,
      nmo,
      serial_number,
      description,
      unaprofile_id,
      management
    } = values;

    editUser(
      id,
      {
        management,
        superuser,
        active,
        displayname,
        email,
        phone,
        position,
        department_id,
        agsh,
        nrsh,
        nmo,
        serial_number: serial_number || null,
        description,
        unaprofile_id
      },
      history
    );

    if (isModalOpen) {
      closeModal();
    }
  };

  const handleAddUser = (values, regions, avatarFile) => {
    const user = { ...values };
    let errorMessage
    const necessarilyFields = ["superuser", "agsh", "nrsh", "management", "nmo"]

    necessarilyFields.forEach(fieldName =>
      !values.hasOwnProperty(fieldName) && ( user[fieldName] = false ))
    
    !values.department_id && (errorMessage = 'Укажите региональное отделение')
    !values.email && (errorMessage = 'Не заполнен e-mail')
    !values.displayname && (errorMessage = 'Укажите ФИО')
    regex.test(values.password) && (errorMessage = 'Пароль должен состоять из букв латинского алфавита, цифр и специальных символов')
    !values.password && (errorMessage = 'Не заполнен пароль')
    regex.test(values.username) && (errorMessage = 'Логин должен состоять из букв латинского алфавита, цифр и специальных символов')
    !values.username && (errorMessage = 'Укажите логин')
    
    if (errorMessage) {
      createNotification("error", errorMessage);
    } else {
      addUser(user, history, regions, avatarFile, closeModal);
      // closeModal();
    }
  };

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  const handleChangePassword = (values) => {
    const { username, password } = values;

    changePassword({ username, newpassword: password }, history);
  };

  const openModal = (type, user) => () => {
    setModalType(type);
    setIsModalOpen(true);
    setSelectedUser(user);
    if (type!=="ADD") {
      getUserRegions(user.id);
      getUserData(user.id);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser({});
  };

  const handlePageClick = (data) => {
    setPageNumber(data.selected);
    const usersArr = getUserPage(users, data.selected);
    setDisplayedUsers(usersArr);
  };

  if (!isSuperUser) return null;

  const acceptConfirmation = () => {
    editUser(
      confirmation.id,
      { active: false },
      history
    );
    setConfirmation(null);
  }

  return (
    <>
      <div className="container newsListContainer">
        <div className="newsListContainer__title">Пользователи</div>
        <div className="newsList">
          <div className="newsListHeader">
            <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>
            <FindField
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
          </div>
          <div className="newsListRow newsListRow__header userListRow">
            <div className="userListRow__displayname">ФИО</div>
            <div className="userListRow__username">Логин</div>
            <div className="userListRow__superuser">SuperUser</div>
            <div className="userListRow__actions" />
          </div>
          {loading ? <Loader top="50%" left="45%" /> :
            <div className="newsListTable">
            {displayedUsers &&
              displayedUsers.map((a) => (
                <div key={a.id} className="newsListRow">
                  <div
                    className="userListRow__displayname fio"
                    onClick={openModal("EDIT", a)}
                  >
                    {a.displayname}
                  </div>
                  <div className="userListRow__username">{a.username}</div>
                  <div className="userListRow__superuser">
                    <input
                      type="checkbox"
                      defaultChecked={a.superuser}
                      onClick={handleEditUser(
                        {
                          ...a,
                          superuser: !a.superuser,
                        },
                        a.id
                      )}
                    />
                  </div>
                  <div className="userListRow__actions">
                    <i
                    onClick={() => setConfirmation({a: a.username, id: a.id, text: a.username})}
                    className={`deletefile-icon ${deleting && "deleting"}`}>
                      <IoMdClose fontSize={24} color={'red'}/>
                    </i>
                  </div>
                </div>
              ))}
            <div className="newsListPagination">
              <ReactPaginate
                previousLabel="В начало"
                nextLabel="В конец"
                breakLabel={"..."}
                breakClassName="break-me"
                pageCount={Math.ceil(users.length / 20) || 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                forcePage={pageNumber}
              />
            </div>
          </div>}
          <div className='controls'>
          <div
            onClick={handleSortClick}
            className={`sortBlock ${sort ? 'sortActive' : ''}`}
          >
              {sort && sort==='desc'
              ? <BsSortDown/>
              : <BsSortDownAlt/>}
          </div>
        </div>
        </div>
      </div>
      { isModalOpen &&
      <Modal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        addUser={handleAddUser}
        getUserData={getUserData}
        editUser={handleEditUser}
        userData={userData}
        loading={loading}
        changePassword={handleChangePassword}
        initialValues={selectedUser}
        regions={regions}
        userRegions={userRegions}
        editUserRegions={editUserRegions}
        deleteUserImage={deleteUserImage}
        uploadUserImage={uploadUserImage}
        departments={departments}
        popupLoading={popupLoading}
        getUserDepartments={getUserDepartments}
        userDeps={userDeps}
        editUserDeps={editUserDeps}
        editUserDepartments={editUserDepartments}
        changedDeps={changedDeps}
        editChangedDeps={editChangedDeps}
        resetUserData={resetUserData}
        getUnarmyansData={getAllYunarmiansData}
        profiles={profiles}
        history={history}
        removeDataForPrint={removeDataForPrint}
        formValues={formValues}
      />}
      {confirmation && <Confirmation 
        confirmation={confirmation.text}
        acceptConfirmation={acceptConfirmation}
        rejectConfirmation={() => setConfirmation(null)}

      />}
    </>
  );
};

export default connect(
  ({ users, auth, departments, yunarmians, ...state }) => {
    const form = getFormValues("user")(state);
    return {
    users: users.userList,
    deleting: users.deleting,
    isSuperUser: auth.superuser,
    regions: users.regionList,
    userRegions: users.userRegions,
    userDeps: users.userDepartments,
    userData: users.userData,
    loading: users.loading,
    departments: departments.region_departments,
    changedDeps: users.changedDepartments,
    profiles: yunarmians.all_profiles,
    popupLoading: yunarmians.pdf_loading,
    formValues: form
  }},
  {
    fetchUsers,
    addUser,
    editUser,
    changePassword,
    getRegions,
    getUserRegions,
    editUserRegions,
    getUserData,
    deleteUserImage,
    uploadUserImage,
    getDepartments,
    getUserDepartments,
    editUserDepartments,
    editUserDeps,
    editChangedDeps,
    resetUserData,
    getAllYunarmiansData,
    removeDataForPrint
  }
)(Users);

import * as types from '../actions/types';

const initialState = {
    count: null,
    page_count: null,
    data: null,
    loading : false,
    image: null,
    award: null
  };

export default function awards (state = initialState, action) {
  switch (action.type) {
    case types.SET_AWARD_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount
      };
      case types.SET_AWARD_DATA:
      return {
        ...state,
        data: action.payload
      };
      case types.SET_AWARD_DATA_BY_ID:
      return {
        ...state,
        award: action.payload
      };
      case types.SET_AWARD_IMAGE:
      return {
        ...state,
        image: action.payload
      };
      case types.REMOVE_AWARD_IMAGE:
      return {
        ...state,
        image: null
      };
      case types.SET_AWARD_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { 
  getRegulationObject, 
  deleteRegulationObject, 
  editRegulationObject, 
  addRegulationObject, 
  uploadImage, 
  deleteFile, 
  delSearch,
  searchByTitle,
  getUserList,
  getRegions
} from '../../actions/regulation'
import styles from "./Regulations.module.css"
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import { Pagination } from '../../assets/common/Pagination/Pagination';
import RegulationsModal from './RegulationsModal';
import Confirmation from '../../assets/common/Confirmation/Confirmation';
import { IoIosSearch, IoMdClose } from "react-icons/io";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";


function Regulations({
  getRegulationObject,
  deleteRegulationObject,
  editRegulationObject,
  uploadImage,
  deleteFile,  
  pageCount,
  data,
  articles,
  addRegulationObject,
  searchByTitle,
  searchData,
  delSearch,
  history,
  usersList,
  regulationAccessRights,
  ordersAccessRights,
  getUserList,
  regionList,
  getRegions,
  sUser
}) {
  
  const [numberPage, setNumberPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const [page, setPage] = useState(null)
  const [search, setSearch] = useState('')
  const [reg, setReg] = useState('')
  const [sort, setSort] = useState("");


  const addRights = (page === 1 && regulationAccessRights.ac_create) || (page === 2 && ordersAccessRights.ac_create) 
  
  useEffect(() => {
    getRegions();
    searchByTitle('');
  }, [])
  
  useEffect(() => {
    setSearch('');
    setReg('');
    setPage(history?.location?.pathname == '/regulation' ? 1 : 2)
    return () => {
      setPage(null)
    }
}, [history?.location?.pathname])

  useEffect(() => {
    !!page && getRegulationObject(numberPage+1, page, null, null, sort)
  }, [numberPage, page, sort])
  
  const openModal = (type, item) => () => {
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDocument = (id) => {
    deleteRegulationObject(id, numberPage+1, page)
  }
  
  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const acceptConfirmation = () => {
    handleDeleteDocument(confirmation.id)
    setConfirmation(null)
  }

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  const handleCanselSearch = () => {
    setSearch('');
    setReg('')
    getRegulationObject(numberPage+1, page, null, null, sort)
  }

  const handleGet = (e) => {
    setReg(e.target.value);
    getRegulationObject(numberPage+1, page, search, e.target.value||'', sort)
  }

  const strip = (html) => {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText;
  };

    return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{page === 1 ? ' Положения' : page === 2 ? 'Приказы' : ''} о мероприятиях</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
            {addRights && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
            <div className={styles.searchBlock}>
            {
              !!regionList && 
              <select
              className={styles.regionSelect}
              placeholder="Регион"
              onChange={e => handleGet(e) }>
                  <option selected={reg === ''} value=''>Все</option>
                {regionList.map(_reg => 
                    <option
                      selected={_reg.id === reg}
                      key={_reg.id}
                      value={_reg.id}
                    >
                      {_reg.federalUnit + ' ' + _reg.regionName}
                    </option>)
                }
              </select>
            }
              <input
                  type='text'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={styles.input}
                />
                {!!search &&
                <i className={styles.searchIcon} onClick={handleCanselSearch}>
                  <IoMdClose/>
                </i>}
                <i className={styles.searchIcon} onClick={() => getRegulationObject(numberPage+1, page, search)}>
                  <IoIosSearch/>
                </i>
            </div>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Список{page === 1 ? ' положений' : page === 2 ? ' приказов' : ''}:</div>
            <div className={styles.username}></div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {data &&
              data.map((a) => {
                const deleteRights = (page === 1 && regulationAccessRights.ac_delete) || (page === 2 && ordersAccessRights.ac_delete)
                return (
                  <div key={a.id} className={styles.tableRow}>
                    <div
                      className={styles.displayname}
                      onClick={openModal("EDIT", a)}
                    >
                      <span>
                        {strip(a.name)}
                      </span>
                    </div>
                    <div className={styles.username}>{a.groupname}</div>
                    <div className={styles.superuser}></div>
                    <div className={styles.actions}>
                      {deleteRights
                        ? <IoMdClose onClick={() => setConfirmation({text: strip(a.name), id: a.id})}/>
                        : <div style={{height:'1.5rem'}}></div>
                      }
                    </div>
                  </div>
                )
              })}
          </div>
          <div className={styles.pagination}>
            <Pagination
              pageCount={pageCount}
              numberPage={numberPage}
              onPageChange={handlePageClick}
            />
          </div>
        </div>
        <div className={styles.controls}>
          <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
          </div>
      </div>
      {confirmation &&
      <Confirmation 
        confirmation={confirmation.text}
        acceptConfirmation={acceptConfirmation}
        rejectConfirmation={() => setConfirmation(null)}
      />}
      <RegulationsModal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        editObject={editRegulationObject}
        addObject={addRegulationObject}
        numberPage={numberPage}
        uploadImage={uploadImage}
        deleteFile={deleteFile}
        articles={articles}
        searchByTitle={searchByTitle}
        searchData={searchData}
        delSearch={delSearch}
        page={page}
        regulationAccessRights={regulationAccessRights}
        ordersAccessRights={ordersAccessRights}
        usersList={usersList}
        getUserList={getUserList}
        sUser={sUser}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
    pageCount: state.regulation.page_count,
    data: state.regulation.data,
    articles: state.regulation.articles,
    lastUpdate: state.schedule.lastUpdate,
    stepsData: state.regulation.steps_data,
    searchData: state.regulation.searchData,
    usersList: state.regulation.usersList,
    regionList: state.regulation.regionList,
    count: state.regulation.count,
    sUser: state.auth.superuser,
    regulationAccessRights: state.auth.accessRights.find(i => i.name==='regulation')||{},
    ordersAccessRights: state.auth.accessRights.find(i => i.name==='orders')||{}
  })

export default connect(mapStateToProps,
   { 
    getRegulationObject, 
    deleteRegulationObject, 
    editRegulationObject, 
    uploadImage, 
    addRegulationObject, 
    deleteFile, 
    searchByTitle,
    delSearch,
    getUserList,
    getRegions
  })
    (Regulations)
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import AwardsModal from './VirtualAwardsModal';
import styles from "./styles.module.css"
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import { Pagination } from '../../assets/common/Pagination/Pagination';
import Confirmation from '../../assets/common/Confirmation/Confirmation';
import { IoMdClose } from "react-icons/io";
import { deleteAward, getAwardsList } from '../../actions/virtualAwards';
import ItemsPerPage from '../../components/ItemsPerPage';
import Loader from '../../components/Loader/Loader';



function VirtualAwards () {
  
  const dispatch = useDispatch()
  const { pageCount, data, loading } = useSelector(state => state.virtual_awards)
  const { itemsOnPage } = useSelector(state => state.auth)

  const [numberPage, setNumberPage] = useState(0)
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);

  useEffect(() => {
    dispatch(getAwardsList(numberPage))
  }, [numberPage, itemsOnPage])
  
  const openModal = (data) => () => {
    setSelectedItem(data)
  };

  const closeModal = () => {
    setSelectedItem(null)
  };
  
  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const acceptConfirmation = () => {
    dispatch(deleteAward(confirmation, numberPage))
    setConfirmation(null)
  }

  const renderRow = (award) => (
    <div key={award.id} className={styles.tableRow}>
      
      <div className={styles.displayname} onClick={openModal(award)}>
        <span>
          {award.name}
        </span>
      </div>

      <div className={styles.actions}>
        <IoMdClose onClick={() => setConfirmation(award.id)}/>
      </div>

    </div>
  )
  
  return (
    <div className={styles.container}>
      <div className={styles.title}>Виртуальные награды</div>
      <div className={styles.newsList}>
        
        <div className={styles.newsListHeader}>
          <ButtonRed onClick={openModal({})}>Добавить</ButtonRed>
        </div>

        <div className={styles.tableHeader}>
          <div className={styles.displayname}>Виртуальные награды</div>
        </div>

        <div className={styles.table}>
          {(loading && !selectedItem) ? <Loader top='45%' left='47%'/> : data?.map(renderRow)}
        </div>

        <div className={styles.pagination}>
          <Pagination pageCount={pageCount} numberPage={numberPage} onPageChange={handlePageClick}/>
        </div>

        <div className={styles.controls}>
          <ItemsPerPage/>
        </div>

      </div>

      {confirmation &&
        <Confirmation 
          confirmation='награду'
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      }

      <AwardsModal
        open={!!selectedItem}
        addMode={!selectedItem?.id}
        selectedItem={selectedItem}
        closeModal={closeModal}
        numberPage={numberPage}
        loading={loading}
      />

    </div>
  )
}

export default VirtualAwards
import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import styles from "./LocalDepartments.module.css";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { MdPictureAsPdf } from "react-icons/md";
import { IoMdTrash, IoIosClose } from "react-icons/io";
import { BACKEND_DOMAIN } from "../../utils/const";
import { MdModeEdit } from "react-icons/md";
import Yamap from "../../components/shared/Yamap";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import YunprofileLinkComponent from "../../components/YunprofileLinkComponent";



const LocalDepartmentsModal = ({
  type,
  selectedItem,
  addDepartment,
  editDepartment,
  parentVal,
  uploadFile,
  deleteFile,
  data,
  dataReg,
  loading,
  getAllYunarmiansData,
  removeDataForPrint,
  parentDep,
  deleteDepartment,
  profiles,
  history,
  regions,
  regionId,
  accessRights,
  userRegions,
  ...props
}) => {

  const [item, setItem] = useState(null);
  const [file, setFile] = useState({file: null, name: null});
  const [isDelete, setIsDelete] = useState(false);
  const [profileName, setProfileName] = useState("Профиль не указан")
  const [confirmation, setConfirmation] = useState(null)

  const updateRights = type==='ADD' || accessRights.ac_update || (accessRights.ac_update_by_region && userRegions.includes(selectedItem?.regionId))

  const setUserProfile = (profile) => {
    const newProfileName = profile?.id || "Профиль не указан"
    const chief_id = profile?.id || null
    setItem(prev => ({...prev, chief_id}))
    setProfileName(newProfileName)
  }

  useEffect(() => {
    if (type === "ADD") {
      setItem({
        chief: "",
        city: "",
        email: "",
        name: "",
        phone: "",
        position: "",
        parent: parentVal,
        regionId: regionId,
        decision: "",
      });
    } else {
      selectedItem && setItem(selectedItem);
      setProfileName(selectedItem?.chief_id || "Профиль не указан")
    }
  }, [selectedItem, parentVal]);
  
  let check = '';
  useEffect(() => {
    check = data && data.length && data.find(it => it.id === item?.id)
    setItem(check)
  }, [data])

  const handleAddDepartment = () => {
    const obj = { ...item };
    addDepartment(obj, parentVal, false, file);
    setItem(null);
    props.onClose();
    setFile({file: null, name: null});
  };

  const handleEditDepartment = () => {
    editDepartment(item.id, item, parentVal);
    if(!selectedItem.imageurl) {
        deleteFile(selectedItem.id, item.parent, false, 0, file, item.parent);
        if (!selectedItem.imageurl) {
          uploadFile(item.id, file, item.parent);
          setFile({file: null, name: null});
        }
      }
      if (isDelete == true) {
        deleteFile(selectedItem.id);
      }
      props.onClose();
      setItem(null);
  };

  const handleFile = (e) => {
    if(!updateRights) {
      e.stopPropagation()
    } else {
      setFile({ file: e.target.files[0], name: e.target.files[0].name});
      setIsDelete(false);
    }
};

  const handleDeleteFile = (e) => {
    if(!updateRights) {
      e.stopPropagation()
    } else {
      setFile({file: null, name: null});
      setIsDelete(true);
    }
  };

  const handleClose = () => {
    setItem(null);
    setFile({file: null, name: null});
    setIsDelete(false);
    props.onClose();
  }

  const handleDeleteDepartment = (id) => {
    deleteDepartment(id, parentDep)
    handleClose()
  }

  const acceptConfirmation = () => {
    handleDeleteDepartment(confirmation.id)
    setConfirmation(null)
  }

  const handleInput = (name, e) => {
    if(!updateRights) {
      e.stopPropagation()
    } else {
      setItem((prev) => ({ ...prev, [name]: e.target.value }));
    }
  }

  const unaprofileLink = item?.chief_id && `/profile/${item.chief_id}`

  return (
    <Modal
      onClose={handleClose}
      open={props.open}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {confirmation &&
        <Confirmation
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      }
      <div className={styles.title}>
        {type === "ADD" ? "Добавление отделения" : "Редактирование отделения"}
      </div>
      <div className={styles.modalBody}>
        <div>
        <CustomInput
            value={item?.name}
            title="Название отделения"
            disabled={!updateRights}
            onChange={(e) => handleInput('name', e)}
          />
          <CustomInput
            value={item?.city}
            title="Населенный пункт"
            disabled={!updateRights}
            onChange={(e) => handleInput('city', e)}
          />
          <CustomInput
            value={item?.address}
            title="Адрес"
            disabled={!updateRights}
            onChange={(e) => setItem((prev) => ({ ...prev, address: e.target.value }))}
          />
          <div className={styles.mapBlock}>
            <span className={styles.mapTitle}>Местоположение (укажите точку на карте):</span>
            <Yamap
              coordinate={item?.geo}
              setItem={setItem}
              width='100%'
              height='280px'
              name='geo'
            />
          </div>
          <div style={{display: "flex", alignItems: "flex-start"}}>
            <CustomInput
              width="70%"
              value={item?.chief}
              disabled={!updateRights}
              title="Руководитель"
              onChange={(e) => handleInput('chief', e)}
            />
            <YunprofileLinkComponent
              setUserProfile={setUserProfile}
              profileName={profileName}
              profileLink={unaprofileLink}
              style={{marginLeft: "1rem"}}
            />
          </div>
          <CustomInput
            value={item?.position}
            title="Должность"
            disabled={!updateRights}
            onChange={(e) => handleInput('position', e)}
          />
          <CustomInput
            value={item?.phone}
            title="Телефон"
            disabled={!updateRights}
            onChange={(e) => handleInput('phone', e)}
          />
          <CustomInput
            value={item?.email}
            title="Email"
            disabled={!updateRights}
            onChange={(e) => handleInput('email', e)}
          />
          <CustomInput
              value={item?.decision}
              title="Основание для создания"
              disabled={!updateRights}
              onChange={(e) => handleInput('decision', e)}
              max="300"
            />
        </div>
        {updateRights &&
          <div className={styles.input}>
            {(item && item !== null && item?.imageurl && !isDelete) || (file?.file !== null && !isDelete) ? 
              <div className={styles.pdf}>
                <a
                  href={`${BACKEND_DOMAIN || ""}${item?.imageurl}`}
                  className={styles.linkpdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={styles.imagePDF}><MdPictureAsPdf/></i>
                  <span className={styles.titlePDF}>
                    {file?.name || item?.imageurl.split('/')[item?.imageurl.split('/').length-1]}
                  </span>
                </a>
                <i className={styles.toTrash} onClick={e => handleDeleteFile(e)}><IoMdTrash/></i>
              </div>
            :
              <InputFile 
                accept="application/pdf" 
                title="Выберите файл для загрузки" 
                onChange={(e) => handleFile(e)} />
            }
          </div>}
        <div className={styles.modalButtons}>
          {updateRights
            ? <ButtonRed onClick={ type === "ADD" ? handleAddDepartment : handleEditDepartment }>
                {type === "ADD" ? "Добавить" : "Сохранить"}
              </ButtonRed>
            : <></>
          }
          <ButtonRed onClick={handleClose}>Закрыть</ButtonRed>
          {type !== 'ADD' && <ButtonRed onClick={() => setConfirmation({text: item.name, id: item.id})}>Удалить</ButtonRed>}
        </div>
      </div>
    </Modal>
  );
};

export default LocalDepartmentsModal;

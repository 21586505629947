import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import styles from "./Regions.module.css";

const RegionsModal = ({
  type,
  selectedItem,
  loading,
  userInfo,
  editRegion,
  regionGroups,
  getFederalDistricts,
  districts,
  ...props
}) => {
  
  const [item, setItem] = useState(null);

  useEffect(() => {
    getFederalDistricts()
  }, [])
  
  useEffect(() => {
    if (type === "ADD") {
      setItem({
        chief: "",
        city: "",
        email: "",
        regionId: "",
        name: "",
        phone: "",
        position: "",
        decision: "",
      });
    } else {
      selectedItem && setItem(selectedItem);
    }
  }, [selectedItem, loading]);


  const handleAddRegion = () => {
  };

  const handleEditRegion = () => {
    editRegion(item)
    props.onClose()
  };

  const handleClose = () => {
    setItem(null);
    props.onClose();
  };

  const handleInputChange = (e) => {
    const obj = { ...item };
    obj[e.target.name] = e.target.value || null;
    var regex = new RegExp('^[0-9]+$');
    obj.children_qty && !regex.test(obj.children_qty) && ( obj.children_qty = 0 )
    setItem(obj);
  };

  return (
    <Modal
      onClose={handleClose}
      open={props.open}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD" ? "Добавление региона" : "Редактирование региона"}
      </div>
      {item && (
        <div className={styles.modalBody}>
          <div>
            <CustomInput
              name="federalUnit"
              value={item.federalUnit}
              title="Статус региона"
              disabled
              onChange={handleInputChange}
            />
            <CustomInput
              name="regionName"
              value={item.regionName}
              title="Название региона"
              disabled
              onChange={handleInputChange}
            />
            <CustomSelect
              name="district_id"
              selectedValue={item.district_id}
              title="Федеральный округ"
              onChange={handleInputChange}
              options={districts.map(({name, id}) => ({name, value: id}))}
              selwidth="100%"
              disabled
            />
            <CustomInput
              name="regionCode"
              value={item.regionCode}
              title="Код региона"
              // inputWidth="50px"
              disabled
              onChange={handleInputChange}
              type="number"
            />
            <CustomSelect
              name="group_id"
              title="Группа регионов"
              options={[{name: "Без группы", value: ''},
                        ...regionGroups.map(gr => ({name: gr.group_name, value: gr.id}))]}
              selectedValue={item.group_id}
              onChange={handleInputChange}
              selwidth="100%"
              withoutFirst
            />
            <CustomInput
              name="corporateEMail"
              value={item.corporateEMail}
              title="Корпоративный E-mail"
              disabled={!userInfo.superuser}
              onChange={handleInputChange}
            />
            <CustomInput
              name="ordinaryEMail"
              value={item.ordinaryEMail}
              title="Обычный E-mail"
              disabled={!userInfo.superuser}
              onChange={handleInputChange}
            />
            <CustomInput
              name="children_qty"
              value={item.children_qty}
              title="Количество детей в регионе"
              // inputWidth="100px"
              disabled={!userInfo.superuser}
              onChange={handleInputChange}
              />
          </div>
          <div className={styles.modalButtons}>
            {userInfo.superuser && (
              <ButtonRed
                onClick={
                  type === "ADD" ? handleAddRegion : handleEditRegion
                }
              >
                {type === "ADD" ? "Добавить" : "Сохранить"}
              </ButtonRed>
            )}
            <ButtonRed onClick={handleClose}>Закрыть</ButtonRed>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RegionsModal;

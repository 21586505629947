export const getPlaceList = (list = []) => {
  let place = 1
  const placesList = []
  list.forEach((region, index) => {
    const newPlace = index && (region.total_rating < list[index-1].total_rating)
    placesList.push(newPlace ? ++place : place)
  })
  return placesList
}

export const getCriterionsTree = (list) => {
  const map = {}
  list.forEach((item) => (map[item.id] = {...item, children: []}))
  list.forEach(item => item.parent && map[item.parent].children.push(item))
  return Object.values(map).filter(item => !item.parent)
}
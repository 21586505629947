import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import {
  SET_YUNARMY_HOUSES_DATA,
  SET_YUNARMY_HOUSES_LOADING,
  SET_YUNARMY_HOUSES_COUNT,
  SET_CURRENT_HOUSE_DATA,
  ERROR,
  SET_INFO_FOR_EXCEL,
  INFO_FOR_EXCEL_LOADING,
} from "./types";

import { createNotification } from "../components/Notifications/Notifications";

export const deleteInfoForExcel = () => ({type: SET_INFO_FOR_EXCEL, payload: null})

export const getYunarmyHousesData =
  (page=1, sort, byReg) =>
  async (dispatch, getState) => {
    dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const newsOnPage = getState().auth.itemsOnPage;
      const offset = page * newsOnPage - newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage
      }
      sort && ( params.alph = sort )
      byReg && byReg != 0 && ( params.region_ids = byReg )
      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_house?${urlParams}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_YUNARMY_HOUSES_DATA, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: false });
  };

  export const getYunarmyHousesCount = (region_id = 0) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

      const params = {
      }

      region_id && ( params.region_ids = region_id )
      const urlParams = new URLSearchParams(params).toString();
      const newsOnPage = getState().auth.itemsOnPage
      const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_house/count?${urlParams}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        const pageCount = Math.ceil(data / newsOnPage);
        dispatch({
          type: SET_YUNARMY_HOUSES_COUNT,
          payload: { data: data, pageCount: pageCount },
        });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const getCurrentYunarmyHouse =
  (id) =>
  async (dispatch, getState) => {
    dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_house/${id}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_CURRENT_HOUSE_DATA, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: false });
  };

export const deleteYunarmyHouse =
  (id, numberPage, byReg) => async (dispatch, getState) => {
    dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/yunarmy_house/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        createNotification("success", "Успешно");
        dispatch(getYunarmyHousesData(numberPage, '',  byReg));
        dispatch(getYunarmyHousesCount(byReg));
      } else {
        createNotification("error", "Ошибка");
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: false });
  };

export const editYunarmyHouse =
  (_data, addFilesList = [], deleteFilesList = [], numberPage, byReg) =>
  async (dispatch, getState) => {
    dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: true });

    const data = {..._data}

    data.employees_count = parseInt(data.employees_count);
    data.yunarmians_count = parseInt(data.yunarmians_count);
    data.associations_count = parseInt(data.associations_count);
    data.domestic_department_id = parseInt(data.domestic_department_id);

    try {
      await dispatch(checkAndUpdateToken());
      if (getState().auth.token) {
        const id = data.id;
        delete data.id;
        delete data.files;
        const response = await fetch(
          `${BACKEND_URL_PREFIX}/yunarmy_house/${id}`,
          {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
          }
        );
        if (deleteFilesList.length) {
          for (const fileId of deleteFilesList) {
            await dispatch(deleteFile(id, fileId));
          }
        }
        if (addFilesList.length) {
          for (const file of addFilesList) {
            await dispatch(uploadFile(id, file));
          }
        }
        if (response.ok) {
          createNotification("success", "Успешно");
          dispatch(getYunarmyHousesData(numberPage, '',  byReg));
          dispatch(getYunarmyHousesCount(byReg));
          return true
        } else {
          const respData = await response.json()
          createNotification('error', respData.error || 'Ошибка сохранения')
        }
      }
    } catch (ERR) {
      createNotification("erorr", "Ошибка");
      console.log(ERR);
    } finally {
      dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: false });
    }
  };

export const addYunarmyHouse =
  (data, filesList, numberPage, byReg) => async (dispatch, getState) => {
    dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: true });

    
    data.employees_count = parseInt(data.employees_count);
    data.yunarmians_count = parseInt(data.yunarmians_count);
    data.associations_count = parseInt(data.associations_count);
    data.domestic_department_id = parseInt(data.domestic_department_id);

    try {
      await dispatch(checkAndUpdateToken());
      if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_house`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
          createNotification("success", "Успешно");
          const resp = await response.json();
          const id = resp.id;
          if (filesList.length) {
            for (const file of filesList) {
              await dispatch(uploadFile(id, file));
            }
          }
          dispatch(getYunarmyHousesData(numberPage, '',  byReg));
          dispatch(getYunarmyHousesCount(byReg));
          return true
        } else {
          const respData = await response.json()
          createNotification('error', respData.error || 'Ошибка сохранения')
        }
      }
    } catch (ERR) {
      console.log(ERR);
      createNotification("error", "Ошибка");
    } finally {
      dispatch({ type: SET_YUNARMY_HOUSES_LOADING, payload: false });
    }
  };

export const uploadFile = (id, file) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  const fd = new FormData();
  fd.append("file", file.file);
  fd.append("description", file.description);

  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/yunarmy_house/${id}/file`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
        body: fd,
      }
    );
    if (response.ok) {
      console.log("ok");
    }
  }
};

export const deleteFile = (houseId, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/yunarmy_house/${houseId}/file/${id}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      console.log("ok");
    }
  }
};

export const getInfoForExcel =
  (id = 0) =>
  async (dispatch, getState) => {
    dispatch({ type: INFO_FOR_EXCEL_LOADING, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/yunarmy_house/${id}/excel`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_INFO_FOR_EXCEL, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({ type: INFO_FOR_EXCEL_LOADING, payload: false });
  };

import React, {  useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getDepartments,
  deleteDepartment,
  editDepartment,
  removeDetachments,
  addDepartment,
  uploadFile,
  deleteFile,
  getDetachmentDetails,
  removeDetachmentDetails,
  getRegions,
  getDepartmentsExcel,
  getDepartmentsAll,
  getDepartmentsCount,
  removeLocalDep,
  exelLoadingStop,
  getTroopTypes
} from "../../actions/departments";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import DetachmentsModal from "./DetachmentsModal";
import styles from "./Detachments.module.css"
import CustomSelect from "../../components/CustomSelect/CustomSelect"
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { RiFileExcel2Line, RiLoader2Fill, RiLoader2Line, RiLoader3Fill } from "react-icons/ri";
import { MdPictureAsPdf } from "react-icons/md";
import { createNotification } from "../../components/Notifications/Notifications";
import { Pagination } from "../../assets/common/Pagination/Pagination";
import departments from "../../reducers/departments";
import Loader from "../../components/Loader/Loader";
import { getAllYunarmiansData, removeDataForPrint } from "../../actions/yunarmiyans";
import { getRegions as getRegionsAll } from "../../actions/users";
import { getRegionDepsOptions, getUserRegionDepsOptions } from "../../utils/getOptions";

import logo from '../../assets/images/label.png';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../fonts/fonts'
import { selects } from "../../utils/const";
import moment from "moment";
import CustomInput from "../../components/CustomInput/CustomInput";
import { getExcelFromTable } from "../../utils/excel";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";


const detachmentsCategories = [
  {name: "Все", value: ''},
  {name: "Именные отряды", value: 'domestic'},
  {name: "Профильные отряды", value: 'profile'}
]


const domesticParamsList = [
  {name: 'Герой Российской Федерации - участник СВО', value: 'is_hero'},
  {name: 'Участник ВОВ', value: 'vov'},
  {name: 'Участник СВО', value: 'svo'},
  {name: 'Участник Афганской войны', value: 'afghanistan'},
  {name: 'Участник локальных войн', value: 'local_wars'},
  {name: 'Космонавт', value: 'cosmonaut'},
  {name: 'Выдающийся спортсмен', value: 'sportsman'},
  {name: 'Иное', value: 'other'},
]

const profileParamsList = [
  {name: 'Юнавиа', value: 'Юнавиа'},
  {name: 'Юнфлот', value: 'Юнфлот'},
  {name: 'Юнтех', value: 'Юнтех'}
]


const Detachments = ({
  regionDep,
  localDep,
  detachments,
  getDepartments,
  deleteDepartment,
  editDepartment,
  addDepartment,
  uploadFile,
  deleteFile,
  getDetachmentDetails,
  detailsLoading,
  removeDetachmentDetails,
  details,
  getRegions,
  id,
  getDepartmentsExcel,
  excel,
  getDepartmentsAll,
  profilesLoading,
  count,
  getDepartmentsCount,
  regList,
  removeDetachments,
  removeLocalDep,
  loading,
  troopTypes,
  getTroopTypes,
  exelLoadingStop,
  excelLoading,
  regions,
  history,
  getAllYunarmiansData,
  removeDataForPrint,
  profiles,
  getRegionsAll,
  accessRights,
  userRegions
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [parentDep, setParentDep] = useState('0');
  const [parentLocalDep, setParentLocalDep] = useState('0');
  const [domesticDep, setDomesticDep] = useState('');
  const [categoryParams, setCategoryParams] = useState(null);
  const [numberPage, setNumberPage] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [regionId, setRegionId] = useState(null);
  const [downloadType, setDownloadType] = useState({type: null, id: null});
  const [name, setName] = useState('');

  const table = useRef();
  const tablePersonal = useRef();

  useEffect(() => {
    getRegionsAll()
    !troopTypes.length && getTroopTypes()
  }, [])

  useEffect(() => {
    parentDep=='0' && parentLocalDep=='0' && getDepartments()
  }, [numberPage, domesticDep, parentLocalDep]);

  useEffect(() => {
    parentDep!=='0' && getDepartments(parentDep)
  }, [parentDep]) 

  useEffect(() => {
    regionDep && regionDep.length &&
    setParentDep('0')
  }, [regionDep])
  
  useEffect(() => {
    getRegions(id);
  }, [id]);

  useEffect(() => {
    let newParamsValue
    switch (domesticDep) {
      case 'domestic':
        const domesticParams = domesticParamsList.reduce((acc, item) => ({...acc, [item.value]: true}), {})
        newParamsValue = {...domesticParams, domestic_detachment: true} 
        break;
      case 'profile':
        newParamsValue = {profile_detachment: profileParamsList.map(({value}) => value).toString()}
        break;
      default:
        newParamsValue = null
        break;
    }
    setCategoryParams(newParamsValue)
  }, [domesticDep])
  
  const categoryParamsNotUpdate = (domesticDep === 'domestic' && !categoryParams?.hasOwnProperty('vov')) || domesticDep === 'profile' && !categoryParams?.hasOwnProperty('profile_detachment') || (domesticDep === 'all' && !!categoryParams)


  useEffect(() => {
    
    setNumberPage(0);
    const domDepValue = domesticDep === 'domestic'
    if (categoryParamsNotUpdate) return;

    if (parentLocalDep !== '0') {
      getDepartmentsCount(parentLocalDep, true, domDepValue, numberPage + 1, name, categoryParams)
    } else if (parentDep !== '0' && parentLocalDep == '0') {
      getDepartmentsCount(parentDep, true, domDepValue, numberPage + 1, name, categoryParams)
    } else if (parentDep == '0' && parentLocalDep == '0') {
      getDepartmentsCount(0, true, domDepValue, numberPage + 1, name, categoryParams)
    }
    
  }, [parentLocalDep, parentDep, domesticDep, name, categoryParams])
  
  useEffect(() => {
    const domDepValue = domesticDep === 'domestic'
    if (categoryParamsNotUpdate) return;

    if (parentLocalDep !== '0') {
      getDepartments(parentLocalDep, true, domDepValue, 10, numberPage + 1, name, categoryParams)
    } else if (parentDep !== '0' && parentLocalDep == '0') {
      getDepartments(parentDep, true, domDepValue,  10, numberPage + 1, name, categoryParams)
    } else if (parentDep == '0' && parentLocalDep == '0') {
      getDepartmentsAll(0, true, domDepValue, 10, numberPage + 1, name, categoryParams)
    }

  }, [parentDep, parentLocalDep, domesticDep, numberPage, name, categoryParams])

  
  const handleExel = (e) => {
    const domDepValue = domesticDep === 'domestic'
    if (excelLoading) {
      e.stopPropagation();
    } else {
      if (parentLocalDep !== '0') {
        getDepartmentsExcel(parentLocalDep, true, domDepValue, name)
      } else if (parentDep !== '0' && parentLocalDep == '0') {
        getDepartmentsExcel(parentDep, true, domDepValue, name)
      } else if (parentDep == '0' && parentLocalDep == '0') {
        getDepartmentsExcel(0, true, domDepValue, name)
      }
      setIsSearch(true)
    }
  }
  
  const openModal = (type, item) => () => {
    const readRights = accessRights.ac_read || (accessRights.ac_read_by_region && userRegions.includes(item?.regionId))
    
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(type==='ADD'||readRights)
    !readRights && type!=='ADD' && createNotification('error', 'Доступ к данному отряду отсутствует')
  }


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDepartment = (id) => {
    deleteDepartment(id, parentDep, true, numberPage)
  }

  const handleSelectRegionDep = (e) => {
    const {value} = e.target
    const regionId = regionDep.find(rd => rd.id==value)?.regionId
    setRegionId(regionId);
    
    if (value !== '0') {
      getDepartments(value)
      setParentDep(value)
    } else if (value === '0') {
      setParentDep('0')
    }

    setParentLocalDep('0');
    setDomesticDep('all')
    setCategoryParams(null)
  }
  
  const handleSelectLocalDep = (e) => {
    const {value} = e.target
    setParentLocalDep(value)
    setDomesticDep('all')
    setCategoryParams(null)
  }

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const parse = () => {
    const table = document.getElementById("table")
    getExcelFromTable(table, 'Выгрузка отрядов')
    exelLoadingStop();
  }

  const parseTroop = () => {
    const table = document.getElementById("tablePersonal")
    getExcelFromTable(table, 'Состав отряда')
    removeDetachmentDetails();
  }

  useEffect(() => {
    if (isSearch) {
      if (excel && excel?.length > 0) {
        parse();
        setIsSearch(false)
        removeDetachments();
      } else {
        createNotification("error", "С данными фильтрами в выгрузку не попал ни один отряд")
        setIsSearch(false)
      }
    }
    
  }, [excel])

  const createDocData = async () => {
    
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'l', lineHeight: 0.5 })
		doc.setFont("Montserrat");

    doc.setFontSize(14)
    doc.text(`Списочный состав юнармейского отряда "${details?.detachment?.name}"`, 150, 10, "center" )
    doc.text(`Региональное отделение: ${regionDep?.filter(el => el.id == parentDep)[0]?.name || 'Все'}`, 150, 20, "center" )
    doc.text(`Местное отделение: ${localDep?.filter(el => el.id == parentLocalDep)[0]?.name || 'Все'}`, 150, 30, "center" )
    const img = new Image()
    img.src = logo
    doc.addImage(img, 'png', 8, 5, 25, 25)
    doc.autoTable({
      html: `#tablePersonalPdf`,
      startY: doc.pageCount > 1? doc.autoTableEndPosY() + 10 : 35,
      styles: {
        font: 'Montserrat',
        fontSize: 8
      },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
      },
      didParseCell: (data) => {
        if (data.cell.colSpan===3) {
          data.cell.styles.fillColor = 'rgb(248, 207, 249)';
        }
      }
    })

    return doc;
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`Состав отряда.pdf`))
    removeDetachmentDetails();
  }

  useEffect(() => {
    if (details && details?.detachment?.id && details?.participants?.length > 0) {
      if (details?.participants?.length && downloadType.type == 'pdf') {
        toPdf();
        setDownloadType({type: null, id: null});
      } else if (details?.participants?.length && downloadType.type == 'excel') {
        parseTroop();
        setDownloadType({type: null, id: null});
      } 
    } else if (details?.participants?.length == 0 && downloadType.type != null) {
      createNotification("error", "В отряде нет ни одного юнармейца");
      removeDetachmentDetails();
      setDownloadType({type: null, id: null});
    }
  }, [downloadType, details?.detachment?.id])
  

  const handleGetParticipantsToPdf = (id) => (e) => {
    e.stopPropagation()
    setDownloadType({type: 'pdf', id: id});
    getDetachmentDetails(id);
  }
  
  const handleGetParticipantsToExcel = (id) => (e) => {
    e.stopPropagation()
    setDownloadType({type: 'excel', id: id});
    getDetachmentDetails(id);
  }

  const handleParamsChange = (name) => () => {
    if (domesticDep === 'domestic') {
      setCategoryParams(prev => ({...prev, [name]: !prev[name]})) 
    } else {
      const stringValue = Object.values(categoryParams)[0]
      const values = stringValue ?  stringValue.split(',') : []
      const profile_detachment = values.includes(name)
        ? values.filter(el => el !== name).toString()
        : [...values, name].toString()
      setCategoryParams({ profile_detachment })
    }
  }

  const actualParams = domesticDep === 'domestic' ? domesticParamsList : profileParamsList

  return (
    <>
      <table 
        id="table" 
        ref={table} 
        style={{display: "none"}}
        >
        <thead>
          <tr>
            <th colSpan="1">Реестр отрядов</th>
          </tr>
          <tr>
            <th colSpan="2">Региональное отделение: {regionDep?.filter(el => el.id == parentDep)[0]?.name || 'Все'}</th>
          </tr>
          <tr>
            <th colSpan="2">Местное отделение: {localDep?.filter(el => el.id == parentLocalDep)[0]?.name || 'Все'}</th>
          </tr>
          <tr>
            <th colSpan="1"></th>
          </tr>
          <tr>
            <th>Категория</th>
            <th>Название отряда</th>
            <th>Региональное отделение</th>
            <th>Местное отделение</th>
            <th>Населенный пункт</th>
            <th>Количество юнармейцев</th>
            <th>Руководитель отряда</th>
            <th>Должность руководителя</th>
            <th>Телефон руководителя</th>
            <th>e-mail руководителя</th>
            <th>Основание для создания</th>
            <th>Статус героя</th>
            <th>Описание подвига</th>
          </tr>
        </thead>
        <tbody>
        {excel?.map(detachment => {
          const {id, name, other_desc, yun_count, achievement_desc, domestic_detachment, parentname, city, chief, position, phone, email, decision, vov, svo, local_wars, cosmonaut, afghanistan, sportsman, other} = detachment
          const detachmentType = domestic_detachment ? 'Именной' : 'Местный'
          const meritsList = [
            {value: vov, text: 'Участиник ВОВ'}, 
            {value: svo, text: 'Участиник СВО'}, 
            {value: local_wars, text: 'Участиник локальных войн'}, 
            {value: cosmonaut, text: 'Космонавт'}, 
            {value: afghanistan, text: 'Участиник Афганской войны'}, 
            {value: sportsman, text: 'Выдающийся спортсмен'}, 
            {value: other, text: other_desc}
          ]
          const meritsString = meritsList.filter(({value}) => value).map(({text}) => text).join(', ')
          return (
            <tr key={id}>
              <td>{detachmentType}</td>
              <td>{name}</td>
              <td>{parentname}</td>
              <td></td>
              <td>{city}</td>
              <td>{yun_count}</td>
              <td>{chief}</td>
              <td>{position}</td>
              <td>{phone}</td>
              <td>{email}</td>
              <td>{decision}</td>
              <td>{meritsString}</td>
              <td>{achievement_desc}</td>
            </tr>
          )
        })}
        </tbody>
      </table>

      <table 
        id="tablePersonal" 
        ref={tablePersonal} 
        style={{display: "none"}}
        >
        <thead>
          <tr>
            <th colSpan="8">Списочный состав юнармейского отряда "{`${details?.detachment?.name}`}"</th>
          </tr>
          <tr>
            <th colSpan="8">Региональное отделение: {regionDep?.filter(el => el.id == parentDep)[0]?.name || 'Все'}</th>
          </tr>
          <tr>
            <th colSpan="8">Местное отделение: {localDep?.filter(el => el.id == parentLocalDep)[0]?.name || 'Все'}</th>
          </tr>
          <tr>
            <th>№ п/п</th>
            <th>ФИО</th>
            <th>Личный номер</th>
            <th>Дата рождения</th>
            <th>Телефон</th>
            <th>E-mail</th>
            <th>Адрес</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {details?.participants?.map((el, i) => {
            return (
              <tr key={el.id}>
                <td>{i + 1}</td>
                <td>{el?.lastName + ' ' + el?.firstName + ' ' + el?.fathersName}</td>

                <td>{ el?.personalId}</td>
                <td>{ el?.birthday ? moment(el?.birthday).format("DD.MM.YYYY") : ''}</td>
                <td>{ el?.phone}</td>
                <td>{ el?.email}</td>
                <td>{ el?.address}</td>
                <td>{ selects?.status.find(({ value }) => value === el?.status)?.name}</td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <table 
        id="tablePersonalPdf" 
        ref={tablePersonal} 
        style={{display: "none"}}
        >
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>ФИО</th>
            <th>Личный номер</th>
            <th>Дата рождения</th>
            <th>Телефон</th>
            <th>E-mail</th>
            <th>Адрес</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
        {details && details?.participants && details?.participants?.map((el, i) => 
          <tr key={el.id}>
            <td>{i + 1}</td>
            <td>{el?.lastName + ' ' + el?.firstName + ' ' + el?.fathersName}</td>

            <td>{ el?.personalId}</td>
            <td>{ el?.birthday ? moment(el?.birthday).format("DD.MM.YYYY") : ''}</td>
            <td>{ el?.phone}</td>
            <td>{ el?.email}</td>
            <td>{ el?.address}</td>
            <td>{ selects?.status.find(({ value }) => value === el?.status)?.name}</td>
          </tr>
        )}
        </tbody>
      </table>

       <div className={styles.container}>
        <div className={styles.title}>Реестр юнармейских отрядов</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
            {(accessRights.ac_create || accessRights.ac_create_by_region) && parentDep!=0
            ? <>
                <ButtonRed disabled={loading} onClick={openModal("ADD")}>Добавить</ButtonRed>
                <div className={styles.downloadExcel} onClick={e => handleExel(e)}>
                  <i className={styles.excelLogo}><RiFileExcel2Line/></i><span>Скачать .xlsx</span>
                  {!!excelLoading && <i className={styles.excelLoad}><RiLoader3Fill/></i>}
                </div>
              </>
            : <></>}
          </div>
          <div className={styles.filters}>
          <div className={styles.filterBlock}>
          <CustomSelect
            title="Выберите региональное отделение"
            onChange={handleSelectRegionDep}
            selectedValue={parentDep}
            // withoutFirst
            selwidth='100%'
            padding='.5rem'
            options={accessRights.ac_create
              ? getRegionDepsOptions(regionDep)
              : getUserRegionDepsOptions(regionDep, userRegions)  
            }
          />
          <CustomInput
            value={name}
            name="name"
            width="100%"
            padding='.5rem'
            title="Наименование отряда"
            onChange={(e) => setName(e.target.value)}
          />
          </div>

          <div className={styles.filterBlock}>
          
          <CustomSelect
            title="Выберите местное отделение"
            onChange={handleSelectLocalDep}
            selwidth='100%'
            defaultValName={'Все'}
            padding='.5rem'
            selectedValue={parentLocalDep}
            options={parentDep !== '0' && localDep ? localDep.map(it => ({name: it?.name, value: it?.id})) : []}
          />

          <CustomSelect
            title="Категория отряда"
            onChange={(e) => setDomesticDep(e.target.value)}
            withoutFirst
            selectedValue={domesticDep}
            options={detachmentsCategories}
            selwidth='100%'
            padding='.5rem'
          />

          </div>
        {domesticDep && categoryParams &&
          <div className={styles.categoryParamsBlock}>
          {actualParams.map(({name, value}) => {
            const firstValue = Object.values(categoryParams)[0]
            const checked =  typeof firstValue === 'string'
              ? firstValue.split(',').includes(value)
              : categoryParams[value]
            return (
              <CustomCheckbox
                key={name}
                label={name}
                onChange={handleParamsChange(value)}
                value={checked}
              />
            )
          })}
        </div>}

          </div>
          <div className={styles.tableHeader}>
            <div className={styles.firstTd}>№</div>
            <div className={styles.displayname}>Наименование отряда</div>
            <div className={styles.superuser}>Подчиненность</div>
            <div className={styles.count}>Кол-во детей</div>
            <div className={styles.fifthTd}>Категория</div>
            <div className={styles.sixthTd}>Подкатегория</div>
          </div>
          {
            loading ?
              <Loader top="50%" left="45%"/>
            :
            <>
            <div className={styles.table}>
            {detachments?.map((a, index) => {
                const deleteRights = accessRights.ac_delete || (accessRights.ac_delete_by_region && userRegions.includes(a.regionId))
                const category = a.profile_detachment ? 'Профильный' : a.domestic_detachment ? 'Именной' : null
                const number = numberPage * 10 + index + 1
                return (
                  <div key={a.id} onClick={openModal("EDIT", a)} className={styles.tableRow}>

                    <div className={styles.firstTd}>{number}</div>

                    <div className={styles.displayname}>
                      <span>{a.name}</span>
                    </div>

                    <div className={styles.superuser} style={{color: "grey"}}>{a.parentname}</div>
                    
                    <div className={styles.count}>
                      <p>{a.yun_count}</p>
                      <i 
                        onClick={handleGetParticipantsToPdf(a.id)}
                        title="Скачать в .pdf"
                        className={styles.iconDownload}
                      >
                        <MdPictureAsPdf color="red"/>
                      </i>
                      <i
                        onClick={handleGetParticipantsToExcel(a.id)}
                        title="Скачать в .excel"
                        className={styles.iconDownload}
                      >
                        <RiFileExcel2Line color="rgb(117, 194, 181)"/>
                      </i>
                    </div>

                    <div className={styles.fifthTd}>{category}</div>
                    <div className={styles.sixthTd}>{a.subcategory}</div>

                    {detailsLoading && a.id == downloadType.id && <Loader size="20" right="0" top="6px" />}
                  </div>
                )
              })}
          </div>
          
           <div className={styles.pagination}>
              <Pagination 
                pageCount={count?.pageCount} 
                numberPage={numberPage} 
                onPageChange={handlePageClick}
              />
            </div>
            </>
          }
        </div>
      </div>

      <DetachmentsModal
        open={isModalOpen}
        type={modalType}
        parentVal={parentDep}
        localParentVal={parentLocalDep}
        onClose={closeModal}
        selectedItem={selectedItem}
        editDepartment={editDepartment}
        addDepartment={addDepartment}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        data={detachments}
        handleDeleteDepartment={handleDeleteDepartment}
        getDetachmentDetails={getDetachmentDetails}
        details={details}
        removeDetachmentDetails={removeDetachmentDetails}
        regionDep={regionDep}
        localDep={localDep}
        getDepartments={getDepartments}
        troopTypes={troopTypes}
        removeLocalDep={removeLocalDep}
        parentDep={parentDep}
        setParentDep={setParentDep}
        parentLocalDep={parentLocalDep}
        setParentLocalDep={setParentLocalDep}
        domesticDep={domesticDep}
        setDomesticDep={setDomesticDep}
        numberPage={numberPage}
        regions={regions}
        history={history}
        regionId={regionId}
        setRegionId={setRegionId}
        getAllYunarmiansData={getAllYunarmiansData}
        removeDataForPrint={removeDataForPrint}
        profiles={profiles}
        profilesLoading={profilesLoading}
        accessRights={accessRights}
        userRegions={userRegions}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  regionDep: state.departments.region_departments,
  localDep: state.departments.local_departments,
  detachments: state.departments.detachments,
  details: state.departments.detachment_details,
  detailsLoading: state.departments.detachment_details_load,
  agsh: state.auth.user.agsh,
  nrsh: state.auth.user.nrsh,
  superuser: state.auth.superuser,
  regs: state.departments.regs,
  id: state.auth.id,
  excel: state.departments.excel,
  count: state.departments.count,
  regList: state.auth.regions,
  loading: state.departments.loading,
  excelLoading: state.departments.excelLoading,
  profilesLoading: state.yunarmians.pdf_loading,
  regions: state.users.regionList,
  profiles: state.yunarmians.all_profiles,
  userReg: state.auth.user.department_id,
  troopTypes: state.departments.troopTypes,
  accessRights: state.auth.accessRights.find(i => i.name==='detachment')||{},
  userRegions: state.auth.userRegions
})

export default connect(mapStateToProps, {
  getDepartments,
  deleteDepartment,
  editDepartment,
  addDepartment,
  uploadFile,
  deleteFile,
  getDetachmentDetails,
  removeDetachmentDetails,
  getRegions,
  getDepartmentsExcel,
  getDepartmentsAll,
  getDepartmentsCount,
  removeDetachments,
  removeLocalDep,
  exelLoadingStop,
  getAllYunarmiansData,
  removeDataForPrint,
  getTroopTypes,
  getRegionsAll
})(
  Detachments
);

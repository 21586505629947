import React, {useState, useEffect} from 'react'
import { connect } from "react-redux";
import styles from "./Private.module.css";
import {getStreamList, getStreamById, changeStream} from '../../actions/private';
import {getRegions} from '../../actions/auth';
import { Pagination } from '../../assets/common/Pagination/Pagination';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import PrivateModal from './PrivateModal'
import CustomDatepicker from '../../components/CustomDatepicker/CustomDatepicker';
import { useHistory, useParams } from 'react-router-dom';

const Private = ({
    getStreamList,
    data,
    loading,
    getStreamById,
    selected,
    changeStream,
    pages,
    getRegions,
    regionList,
    accessRights
}) => {

const options = [
    { value: 'published', label: "Опубликовано" },
    { value: 'blocked', label: "Не опубликовано" },
  ];

  const history = useHistory()
  const params = useParams()

    const [numberPage, setNumberPage] = useState(0);
    const [state, setState] = useState('');
    const [reg, setReg] = useState('');
    const [author, setAuthor] = useState('');
    const [text, setText] = useState('');
    const [dateFrom, setDateFrom] = useState(moment().subtract(7,'d'));
    const [dateTo, setDateTo] = useState(moment());

    useEffect(() => {
        getRegions();
    }, [])

    useEffect(() => {
      getStreamList(numberPage + 1, state, reg, author, text, dateFrom, dateTo)
    }, [state, reg, author, text, dateFrom, dateTo, numberPage])

    const editRights = accessRights.ac_update 

    const openModal = (data) => () => {
        history.push(`/stream/${data.id}`)
    };
      
    const closeModal = () => {
        history.push(`/stream`)
    };

    const handleChanger = (e, id) => {
        e.stopPropagation();
        changeStream(id, numberPage + 1, state, reg, author, text, dateFrom, dateTo);
    }

    const handleGet = (e, name) => {

        switch (name) {
            case 'state':
                setState(e.target.value);
                break;
            case 'reg':
                setReg(e.target.value);
                break;
            default:
                break;
        }
    }
    
    const handlePageClick = (data) => {
        setNumberPage(data.selected);
    };

  return (
    <div className={styles.container}>
      {loading && !params.id && <Loader top="45%" left="45%" />}
        <div className={styles.title}>Личные события юнармейцев</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>

            <div className={styles.selectBlock}>
                <span>Статус</span>
                <select
                    className={styles.regionSelect}
                    placeholder="Состояние"
                    onChange={e => handleGet(e, 'state') }>
                        <option selected={state === ''} value=''>Все</option>
                        {
                        options.map(it => 
                            <option selected={it.value === state} key={it.value} value={it.value}>{it.label}</option>)
                        }
                </select>
            </div>

            <div className={styles.selectBlock}>
                <span>Регион</span>
                <select
                    className={styles.regionSelect}
                    placeholder="Регион"
                    onChange={e => handleGet(e, 'reg') }>
                        <option selected={reg === ''} value=''>Все</option>
                        {
                        regionList && regionList.map(it => 
                            <option selected={it.id === reg} key={it.id} value={it.id}>{`${it.regionName} ${it.federalUnit}`}</option>)
                        }
                </select>
            </div>
            
            <div className={styles.selectBlock}>
                <span>Автор</span>
                <input value={author} onChange={e => setAuthor(e.target.value)} placeholder="Поиск по автору"/>
            </div>

            
            <div className={styles.selectBlock}>
                <span>Текст</span>
                <input value={text} onChange={e => setText(e.target.value)} placeholder="Поиск по тексту"/>
            </div>

            <div className={styles.selectBlock}>
                <div className={styles.dateBlock}>
                    <CustomDatepicker
                        title="Дата c"
                        value={dateFrom || moment().subtract(7,'d')}
                        name="dateFrom"
                        onChange={e => setDateFrom(e.target.value)}
                    />
                    <CustomDatepicker
                        title="Дата до"
                        value={dateTo}
                        name="dateTo"
                        onChange={e => setDateTo(e.target.value)}
                    />
                </div>
            </div>

          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Статус</div>
            <div className={styles.username}>ФИО</div>
            <div className={styles.superuser}>Текст</div>
            <div className={styles.report_count}>Жалоб</div>
            <div className={styles.report_comment}>Коммент-в</div>
            <div className={styles.report_likes}>Лайков</div>
            <div className={styles.actions}>Дата</div>
          </div>
          <div className={styles.table}>
            {data &&
              data?.map((a) => (
                <div key={a.id} className={styles.tableRow}>
                  <div
                    className={styles.displayname}
                  >
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        checked={!a.blocked}
                        disabled={!editRights}
                      />
                      <span
                        className={styles.slider} 
                        onClick={e => editRights && handleChanger(e, a.id)}
                      />
                    </label>
                  </div>
                  <div className={styles.blockOpen} onClick={openModal(a)}>
                    <div className={styles.username}>{a.profile_fio}</div>
                    <div className={styles.superuser}>{a.text}</div>
                    <div className={styles.report_count}>{a.report_count}</div>
                    <div className={styles.report_comment}>{a.comments_count}</div>
                    <div className={styles.report_likes}>{a.likes_count}</div>
                    <div className={styles.actions}>{moment(a?.createat).format('DD-MM-YYYY, HH:mm')}</div>
                  </div>
                    {/* <div className={styles.actions}>
                    <IoMdClose 
                    // onClick={() => handleDeleteVideo(a.id)}
                    />
                  </div> */}
                </div>
              ))}
          </div>
          <div className={styles.pagination}>
            <Pagination pageCount={pages?.pageCount} numberPage={numberPage} 
            onPageChange={handlePageClick}
            />
          </div>
          <div className={styles.itemsCount}>
            </div>
        </div>
        
        <PrivateModal
          open={!!params.id}
          closeModal={closeModal}
          numberPage={numberPage}
          getStreamById={getStreamById}
          selected={selected}
          selectedId={params.id}
        />
      </div>
  )
}

const mapStateToProps = (state) => ({
    data: state.private_stream.data,
    selected: state.private_stream.selected,
    loading: state.private_stream.loading,
    pages: state.private_stream.pages,
    regionList: state.auth.regions,
    accessRights: state.auth.accessRights.find(i => i.name==='stream')||{}
});
  
  export default connect(mapStateToProps, {
    getStreamList,
    getStreamById,
    changeStream,
    getRegions
  })(Private);

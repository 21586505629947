import React from 'react'
import Modal from 'react-responsive-modal'
import { ButtonRed } from '../../../assets/common/ButtonRed/ButtonRed'
import styles from './styles.module.scss'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { editYunarmianProfile } from '../../../actions/yunarmiyans'




const CharacteristicModal = ({
  open,
  onClose,
  mode,
  formik,
  userId,
  createPdf,
  loadPortfolio
}) => {
  
  const dispatch = useDispatch()
  const {profile} = useSelector(state => state.yunarmians)
  
  
  const {values, setFieldValue} = formik
  
  const onOKClick = async () => {
    const { characteristic } = values
    await dispatch(editYunarmianProfile(userId, { characteristic }))
    mode === 'profile' ? createPdf() : loadPortfolio()
    onClose()
  }
  
  const onCancelHandler = () => {
    setFieldValue('characteristic', profile.characteristic)
    onClose()
  }

  return (
    <Modal
      classNames={{modal: styles.modal}}
      showCloseIcon={false}
      open={open}
    >
      <h1>Характеристика юнармейца</h1>
      
      <ReactQuill
        className={styles.quill}
        value={values.characteristic}
        onChange={value => setFieldValue('characteristic', value)}
      />

      <div className={styles.buttonsBlock}>
        <ButtonRed onClick={onOKClick}>OK</ButtonRed>
        <ButtonRed onClick={onCancelHandler}>Отмена</ButtonRed>
      </div>

    </Modal>
  )
}

export default CharacteristicModal